import React, { Component } from "react";
import { Header, Footer, LazyImage, ModalEvent } from "./../../components";
import { Cover, TextHeader } from "./components";
import styled from "styled-components";
import { Row, Col, Button } from "react-bootstrap";
import Dotdotdot from "react-dotdotdot";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link as LinkScroll, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import "./style.css";

//StepCheckPriceContainer
import imageEasyBook from "../../assets/images/homepage/hover/easy-book.svg";
import imagePricing from "../../assets/images/homepage/hover/pricing.svg";
import imageTruckType from "../../assets/images/homepage/hover/truck-type.svg";
import imageTrustShipment from "../../assets/images/homepage/hover/trust-shipment.svg";

import imageEasyBookW from "../../assets/images/homepage/hover/easy-book-w.svg";
import imagePricingW from "../../assets/images/homepage/hover/pricing-w.svg";
import imageTruckTypeW from "../../assets/images/homepage/hover/truck-type-w.svg";
import imageTrustShipmentW from "../../assets/images/homepage/hover/trust-shipment-w.svg";

//PromotionContainer
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//privilege Container
import imagePrivilege1 from "../../assets/images/homepage/privilege/privilege01.jpg";
import imagePrivilege2 from "../../assets/images/homepage/privilege/privilege02.png";
import imagePrivilege3 from "../../assets/images/homepage/privilege/privilege03.png";
import imagePrivilege4 from "../../assets/images/homepage/privilege/privilege05.jpg";

//DeviceContainer
import imageMockupAllApplication from "../../assets/images/applicationDownload/mockupui_800px.png";
import imageMockupAllApplicationWebp from "../../assets/images/applicationDownload/mockupui wedp.webp";
import imageQRCode from "../../assets/images/applicationDownload/qr.png";
import imageAppStore from "../../assets/images/homepage/device/logo-app-store.svg";
import imagePlayStore from "../../assets/images/homepage/device/play-store-01.svg";

//SponsorContainer
import imageSponsor01 from "../../assets/images/homepage/sponsor/SCG-Add-Venture.png";
import imageSponsor02 from "../../assets/images/homepage/sponsor/scg.png";
import imageSponsor04 from "../../assets/images/homepage/sponsor/dtac-accelerate-logo.png";
import imageSponsor06 from "../../assets/images/homepage/sponsor/500tuktuk.png";
import imageSponsor07 from "../../assets/images/homepage/sponsor/Michelin.png";
import imageSponsor08 from "../../assets/images/homepage/sponsor/wavemaker_logo.png";
import imageSponsor09 from "../../assets/images/homepage/sponsor/kk-fund.png";
import imageSponsor10 from "../../assets/images/homepage/sponsor/SK_LOGO.png";

import color from "../../config/color";
import "./style.css";
import appUrl from "./../../config/appUrl";
import moment from "moment";

import { withNamespaces } from "react-i18next";

import ReactModal from "react-modal";

AOS.init({
  delay: 100,
});

const customStylesReactModal = {
  content: {
    top: "40%",
    overflow: "none",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: 0,
    transition: "all 2s linear",
  },
  overlay: {
    zIndex: 1010,
    backgroundColor: "rgba(0,0,0,0.45)",
  },
};

const Container = styled.div``;

const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & .text-button-more {
    color: #b3b3b3;
    cursor: pointer;
    text-decoration: none;

    & i {
      font-size: 30px;
    }
  }
`;

const StepCheckPriceContainer = styled.div`
	text-align: center;
	margin-top: 220px;

	& .box-container {
		height: 310px;
		display: flex;
		align-items: center;
	}

	& .box {
		flex: 1;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		border: 1px solid #eeeeee50;
		padding: 35px 20px;
		background-color: #ffffff;
    box-sizing: border-box;
		border-radius: 5px;
		height: 280px;
		vertical-align: middle;

		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: all .3s;

    &:hover {
			height: 310px;
      background-image: linear-gradient(to bottom right, ${color.Primary}, #9D0000);
			box-shadow: 0px 5px 10px rgba(217, 1, 1, 0.2);
			color: #FFFFFF;
			line-height: 1;

			& .box-title-subtitle {
				display: block;
			}

			& .box-image {
				display: none;

				&-hover {
					display: block;
				}
			}
    }

		&:not(:last-child) {
			margin-right: 20px;
		}

		& .box-image {
			display: block;

			& img {
				width: 120px
				height: 120px;
			}

			&-hover {
				display: none;

				& img {
					width: 120px
					height: 120px;
				}
			}
		}

		& .box-title {
			margin-top: 30px;
			font-weight: 700;
			text-align: center;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: none;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

const PromotionContainer = styled.div`
  margin-top: 240px;

  .image-slider {
    border-radius: 6px;
    background-color: #eeeeee;

    & a {
      outline: none;
    }

    & img {
      width: 100%;
      border-radius: 6px;
    }
  }

  .slick-slide {
    padding: 0px 5px;
  }
`;

const PromotionContainerNew = styled.div`
  position: relative;
  width: 100%;
  max-width: 500px;

	@media screen and (max-width: 499px) {
		max-width: 320px;
	}

  & .close-modal {
    position: absolute;
    color: #000000;
    top: 0px;
    right: 0px;
    background-color: #ffffff;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
  }

  & img {
    width: 100%;
  }

  & .close-modal {
    cursor: pointer;
  }
`;

const PrivilegeContainer = styled.div`
  font-size: 24px;
  margin-top: 120px;
  margin-bottom: 170px;

  & .privilege-cover {
    border-radius: 6px;
    border: 1px solid #fafafafa;

    & img {
      width: 100%;
      border-radius: 6px;
    }
  }

  & .privilege-title {
    margin-top: 10px;
    font-weight: 700;
  }

  & .privilege-detail {
    color: #00000090;
    line-height: 1;
  }

  & .privilege-button-more {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    & a {
      text-decoration: none;
      color: #000000;
      line-height: 1;
    }

    &:hover {
      font-weight: 700;
      border-bottom: 2px solid #d90101;
    }
  }
`;

const PromotionsContainer = styled.div`
  font-size: 24px;
  margin-top: 200px;

  & .promotion-banner {
    & img {
      width: 100%;
    }
  }
`;

const DeviceContainer = styled.div`
  position: relative;
  margin-top: 200px;

  & .device-content {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #fafafafa;
    padding: 40px;

    & .download-container {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
    }

    & .download-image-grcode {
      & img {
        width: 130px;
      }
    }

    & .download-line-vertical {
      width: 1px;
      background-color: #e3e3e3;
      margin: 0 20px;
    }

    & .download-image-store-container {
      display: flex;
      flex-direction: column;

      & img {
        width: 100%;
        max-width: 175px;
        margin-bottom: 10px;
      }
    }
  }

  & .device-image {
    margin-top: 50px;
    width: 260%;
    position: absolute;
    right: 0;

    & img {
      width: 100%;
    }
  }

  & .text-download {
    margin-top: 20px;
  }

  @media (max-width: 992px) {
    .device-image {
      margin-top: 100px;
      width: 220%;
    }
  }
`;

const CheckButton = styled(Button)`
  height: 54px;
  font-size: 26px !important;
  border-radius: 6px;
  margin-top: 20px;
  box-shadow: 0px 6px 16px #d9010120;
  background-color: #d90101;

  & i {
    font-size: 24px !important;
  }

  &:hover {
    background-color: #d90101;
    box-shadow: 0px 10px 20px #d9010140;
  }
`;

const SponsorContainer = styled.div`
  margin-top: 100px;
  margin-bottom: 125px;

  & .sponsor-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    max-width: 1050px;
    margin: 0 auto;
  }

  & .sponsor-image {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 70px;
    margin-top: 30px;

    & img {
      height: 60px;
    }

    &:not(:last-child) {
      margin-right: 30px;
    }
  }
`;

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
`;

const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
  centerPadding: 30,
};

const settingsSliderPromotion = {
  dots: true,
  infinite: true,
  arrows: false,
  autoplay: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerPadding: 30,
};

const ModalWrapper = styled(ReactModal)`
  & .ReactModal__Overlay {
    // transition: all 500ms ease-in-out;
  }

  & .ReactModal__Overlay--after-open {
    // transform: translateX(0px);
  }
`;

class App extends Component {
  state = {
    isTop: true,
    isOpenModalPromotion: false,
    showAgain: true,
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  checkShowPromotion(date) {
    const format = "YYYY-MM-DD HH:mm:ss";
    const current = moment();
    return current.isSameOrBefore(
      moment(`${date} 23:59:59`, format),
      null,
      "[]"
    );
  }

  checkBetweenDateTime = (startDateTime, endDateTime) => {
    const format = "YYYY-MM-DD HH:mm:ss";
    const current = moment();
    const startDate = moment(startDateTime, format);
    const endDate = moment(endDateTime, format);
    const range = current.isBetween(startDate, endDate, null, "[]");
    return range;
  };

  handlerShowPromotionModal = () => {
    this.setState({
      isOpenModalPromotion: true,
    });
  };

  render() {
    var isChrome =
      !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    return (
      <Container className="backgroundCircle">
        <ModalEvent />
        <Helmet>
          <title>
            GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง
            รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
          </title>
          <meta
            name="description"
            content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
          />
        </Helmet>
        <Header
          bgcolor={this.state.isTop ? "transparent" : "#FFFFFF"}
          border={this.state.isTop ? false : true}
        />

        <div className="container">
          <div className="cover-height-home">
            {/* บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย */}
            <Cover isShowModal={this.handlerShowPromotionModal} />

            {/* ดูเนื้อหาเพิ่มเติม */}
            <MoreContainer
              style={{ position: "absolute", bottom: 20, width: "100%" }}
            >
              <div style={{ textAlign: "center" }}>
                <LinkScroll
                  className="text-button-more"
                  to="stepCheckPriceContainer"
                  smooth={true}
                  duration={1500}
                >
                  {this.props.t("homepage:LearnMore")}
                  <div>
                    <i className="fal fa-angle-double-down" />
                  </div>
                </LinkScroll>
              </div>
            </MoreContainer>
          </div>

          {/* เราทำให้ธุรกิจคุณดีขึ้นได้อย่างไร */}
          <Element name="stepCheckPriceContainer" />
          {this.checkBetweenDateTime(
            "2020-03-01 00:00:00",
            "2021-03-31 23:59:59"
          ) && (
            <PrivilegeContainer>
              <div data-aos="fade-up" data-aos-duration="1000">
                <TextHeader
                  style={{ marginBottom: 30 }}
                  title={this.props.t("homepage:Privilegesfromsponsors")}
                  subTitle={this.props.t(
                    "homepage:PrivilegesfromoursponsorsspecialforGIZTIXsCustomerthatsyoushouldntmiss"
                  )}
                />
              </div>

              <div>
                <Row>
                  <Col md={6} sm={12}>
                    <div data-aos="fade-up" data-aos-duration="1300">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.skzicthailand.com/home/product_zic"
                      >
                        <div className="privilege-cover">
                          <LazyImage
                            src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/18-mar-2020/Banner-ZIC-Home-Page-536x200.jpg"
                            alt="privilege"
                          />
                        </div>
                      </a>
                      <div className="privilege-title">
                        <Dotdotdot clamp={1}>ความเหมือน..ที่แตกต่าง</Dotdotdot>
                      </div>
                      <div className="privilege-detail">
                        <Dotdotdot clamp={2}>
                          yubase ( Base Oil Group 3 ) 100%
                          ทุกหยดจากประเทศเกาหลีใต้
                        </Dotdotdot>
                      </div>
                      <div className="privilege-button-more">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://www.skzicthailand.com/home/product_zic"
                        >
                          {this.props.t("homepage:Readmore")}
                        </a>
                      </div>
                    </div>
                  </Col>

                  <Col md={6} sm={12}>
                    <div data-aos="fade-up" data-aos-duration="1300">
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${appUrl}`}
                      >
                        <div className="privilege-cover">
                          <LazyImage
                            src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-Firsttruck-536x200.png"
                            alt="privilege"
                          />
                        </div>
                      </a>
                      <div className="privilege-title">
                        <Dotdotdot clamp={1}>
                          จองรถขนส่งครั้งแรก ลด 5%
                          เมื่อจองครั้งแรกรับส่วนลดไปเลย!
                        </Dotdotdot>
                      </div>
                      <div className="privilege-detail">
                        <Dotdotdot clamp={2}>
                          สามารถจองรถส่งสินค้าได้ทุกประเภททั้งรถกระบะตู้ทึบ/คอก,
                          รถบรรทุก 6 ล้อตู้ทึบ / ลิฟท์ท้าย/เครน,รถบรรทุก 10 ล้อ
                          ตู้ทึบ/คอก/ลิฟท์ท้าย,และรถเทรลเลอร์พื้นเรียบ
                        </Dotdotdot>
                      </div>
                      <div className="privilege-button-more">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`${appUrl}`}
                        >
                          {this.props.t("homepage:Readmore")}
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </PrivilegeContainer>
          )}

          <PromotionsContainer>
            <div data-aos="fade-up" data-aos-duration="1000">
              <TextHeader
                style={{ marginBottom: 30 }}
                title="โปรโมชั่นพิเศษ"
                subTitle="ใช้รหัสส่วนลดตอนนี้ก่อนสิทธิ์หมด หรือหมดเขตรับเท่านั้น จองตอนนี้เลย"
              />
            </div>

            <div>
              <Row>
                <Col md={12}>
                  <div data-aos="fade-up" data-aos-duration="1300">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`${appUrl}/giztixbusiness`}
                    >
                      <div className="promotion-banner">
                        <LazyImage
                          src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-BU-Register-1088x160.png"
                          alt="privilege"
                        />
                      </div>
                    </a>
                  </div>
                </Col>
              </Row>
            </div>
          </PromotionsContainer>
        </div>

        <div className="backgroundCircle2">
          <div className="container">
            {this.checkShowPromotion("2019-08-11") && (
              <PromotionContainer>
                <div data-aos="fade-up" data-aos-duration="1000">
                  <TextHeader
                    style={{ marginBottom: 30 }}
                    title={this.props.t("homepage:SpecialPromotion")}
                    subTitle={this.props.t(
                      "homepage:UsingpromotioncodesbeforepromotionexpiredBooknow"
                    )}
                  />
                </div>

                <div data-aos="fade-up" data-aos-duration="1300">
                  <Slider {...settingsSlider}>
                    {this.checkShowPromotion("2019-08-11") && (
                      <div className="image-slider">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://bit.ly/GTX_GTXTRUCK"
                        >
                          <LazyImage
                            src={`${require("../../assets/images/homepage/promotion/GTXTRUCK.png")}`}
                            alt="GTXTRUCK"
                          />
                        </a>
                      </div>
                    )}

                    {this.checkShowPromotion("2019-08-11") && (
                      <div className="image-slider">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://bit.ly/GTX_GTXTRL"
                        >
                          <LazyImage
                            src={`${require("../../assets/images/homepage/promotion/GTXTRL.png")}`}
                            alt="GTXTRL"
                          />
                        </a>
                      </div>
                    )}

                    {this.checkShowPromotion("2019-08-11") && (
                      <div className="image-slider">
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href="http://bit.ly/GTX_AAN300"
                        >
                          <LazyImage
                            src={`${require("../../assets/images/homepage/promotion/AAN300.png")}`}
                            alt="AAN300"
                          />
                        </a>
                      </div>
                    )}
                  </Slider>
                </div>
              </PromotionContainer>
            )}

            <StepCheckPriceContainer>
              <div data-aos="fade-up" data-aos-duration="1000">
                <TextHeader
                  style={{ marginBottom: 60 }}
                  title={this.props.t("homepage:Howwemakeyourbusinessbetter")}
                  subTitle={this.props.t(
                    "homepage:Checkpriceandbookingtruckinstantlyjust4steps"
                  )}
                />
              </div>

              <Row>
                <Col md={3} xs={3} sm={3}>
                  <div data-aos="fade-up" data-aos-duration="1300">
                    <div className="box-container">
                      <div className="box">
                        <div className="box-image">
                          <LazyImage src={imageEasyBook} alt="box" />
                        </div>
                        <div className="box-image-hover">
                          <LazyImage src={imageEasyBookW} alt="box" />
                        </div>
                        <div className="box-title">
                          {this.props.t("homepage:EasyBooingGettrucksure")}
                        </div>
                        <div className="box-title-subtitle">
                          {this.props.t(
                            "homepage:Gettruckthroughwebsiteapplication"
                          )}
                          <br />
                          {this.props.t("homepage:everyday24hours")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={3} sm={3}>
                  <div data-aos="fade-up" data-aos-duration="1600">
                    <div className="box-container">
                      <div className="box">
                        <div className="box-image">
                          <LazyImage src={imagePricing} alt="box" />
                        </div>
                        <div className="box-image-hover">
                          <LazyImage src={imagePricingW} alt="box" />
                        </div>
                        <div className="box-title">
                          {this.props.t("homepage:Priceasactualusing")}
                        </div>
                        <div className="box-title-subtitle">
                          {this.props.t("homepage:Rateiscalculatedbydistance")}
                          <br />
                          {this.props.t("homepage:nohiddenprice")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={3} sm={3}>
                  <div data-aos="fade-up" data-aos-duration="1900">
                    <div className="box-container">
                      <div className="box">
                        <div className="box-image">
                          <LazyImage src={imageTruckType} alt="box" />
                        </div>
                        <div className="box-image-hover">
                          <LazyImage src={imageTruckTypeW} alt="box" />
                        </div>
                        <div className="box-title">
                          {this.props.t(
                            "homepage:Providingvarioustruckservices"
                          )}
                        </div>
                        <div className="box-title-subtitle">
                          {this.props.t(
                            "homepage:Selecttruckserviceandmatching"
                          )}
                          <br />
                          {this.props.t("homepage:tocargotypeandshipment")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={3} xs={3} sm={3}>
                  <div data-aos="fade-up" data-aos-duration="2100">
                    <div className="box-container">
                      <div className="box">
                        <div className="box-image">
                          <LazyImage src={imageTrustShipment} alt="box" />
                        </div>
                        <div className="box-image-hover">
                          <LazyImage src={imageTrustShipmentW} alt="box" />
                        </div>
                        <div className="box-title">
                          {this.props.t("homepage:Assuredeveryshipment")}
                        </div>
                        <div className="box-title-subtitle">
                          {this.props.t(
                            "homepage:Trackingshipmentstatuseveryshipments"
                          )}
                          <br />
                          {this.props.t("homepage:onapplicationandwebsite")}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </StepCheckPriceContainer>

            <DeviceContainer>
              <Row>
                <Col md={9} sm={9}>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-anchor-placement="top-center"
                  >
                    <Col className="device-content">
                      <Row>
                        <Col md={7} sm={8}>
                          <TextHeader
                            style={{ marginBottom: 30, textAlign: "left" }}
                            title={this.props.t("homepage:Supporteverydevices")}
                            subTitle={this.props.t(
                              "homepage:YoucancheckpriceandbookingtransportationservicesonmobiletabletlapbookandPC"
                            )}
                          />

                          <div>
                            <CheckButton
                              bsStyle="primary"
                              block
                              onClick={() => (window.location = `${appUrl}`)}
                            >
                              <i
                                className="fal fa-search"
                                style={{ marginRight: 10 }}
                              />
                              {this.props.t("homepage:FreeCheckPriceNow")}
                            </CheckButton>
                          </div>
                          <div className="text-download">
                            {this.props.t("homepage:orDownloadApplicationhere")}
                          </div>
                          <div className="download-container">
                            <div className="download-image-grcode">
                              <LazyImage src={imageQRCode} alt="QRCode" />
                            </div>
                            <div className="download-line-vertical"></div>
                            <div className="download-image-store-container">
                              <div>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://play.google.com/store/apps/details?id=com.giztix.app"
                                >
                                  <LazyImage
                                    src={imagePlayStore}
                                    alt="giztix download on google play"
                                  />
                                </a>
                              </div>
                              <div>
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href="https://itunes.apple.com/app/id1439755826"
                                >
                                  <LazyImage
                                    src={imageAppStore}
                                    alt="giztix download on app store"
                                  />
                                </a>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </div>
                </Col>

                <Col md={3} sm={3}>
                  <div
                    data-aos="fade-up"
                    data-aos-duration="1500"
                    data-aos-anchor-placement="top-center"
                  >
                    <div className="device-image">
                      {!isChrome ? (
                        <LazyImage
                          src={imageMockupAllApplication}
                          alt="giztix apllication mockup"
                        />
                      ) : (
                        <LazyImage
                          src={imageMockupAllApplication}
                          alt="giztix apllication mockup"
                        />
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </DeviceContainer>

            <div data-aos="fade-in" data-aos-duration="1000">
              <LineHorizontal style={{ marginTop: 100 }} />
            </div>

            <SponsorContainer>
              <Row>
                <Col md={12} lg={12}>
                  <div data-aos="fade-up" data-aos-duration="1000">
                    <TextHeader
                      style={{ marginBottom: 20 }}
                      title={this.props.t("homepage:Sponsors")}
                    />
                  </div>

                  <div
                    className="sponsor-container"
                    data-aos="fade-up"
                    data-aos-duration="1300"
                  >
                    {/* <div className="sponsor-image"><LazyImage src={imageSponsor10} alt="sponsor" /></div>
										<div className="sponsor-image"><LazyImage src={imageSponsor07} alt="sponsor" /></div> */}
                    <div className="sponsor-image">
                      <LazyImage src={imageSponsor01} alt="sponsor" />
                    </div>
                    <div className="sponsor-image">
                      <LazyImage src={imageSponsor02} alt="sponsor" />
                    </div>
                    <div className="sponsor-image">
                      <LazyImage src={imageSponsor04} alt="sponsor" />
                    </div>
                    <div className="sponsor-image">
                      <LazyImage src={imageSponsor06} alt="sponsor" />
                    </div>
                    <div className="sponsor-image">
                      <LazyImage src={imageSponsor08} alt="sponsor" />
                    </div>
                    <div className="sponsor-image">
                      <LazyImage src={imageSponsor09} alt="sponsor" />
                    </div>
                  </div>
                </Col>
              </Row>
            </SponsorContainer>
          </div>
        </div>

        <ReactModal
          isOpen={this.state.isOpenModalPromotion && this.state.showAgain}
          onRequestClose={() =>
            this.setState({ isOpenModalPromotion: false, showAgain: false })
          }
          style={customStylesReactModal}
        >
          {this.checkBetweenDateTime(
            "2022-04-01 00:00:00",
            "2022-04-30 23:59:59"
          ) ? (
            <PromotionContainerNew style={{ maxWidth: 600 }}>
              <div
                className="close-modal"
                onClick={() =>
                  this.setState({
                    isOpenModalPromotion: false,
                    showAgain: false,
                  })
                }
              >
                <i className="fal fa-times" />
              </div>
              <a
                target="_blank"
                href={"https://app.giztix.com/?promotion_code=GTXSKD22"}
              >
                <img
                  style={{ width: "100%", marginTop: 50 }}
                  src={require("../../assets/images/popup/popup_songkran_2022.jpg")}
                  alt="โปรโมชั่นสงกรานต์"
                />
              </a>
            </PromotionContainerNew>
          ) : (
            <PromotionContainerNew style={{ maxWidth: 600 }}>
              <div
                className="close-modal"
                onClick={() =>
                  this.setState({
                    isOpenModalPromotion: false,
                    showAgain: false,
                  })
                }
              >
                <i className="fal fa-times" />
              </div>
              {/* <Slider {...settingsSliderPromotion}> */}
              {this.checkBetweenDateTime(
                "2022-06-01 00:00:00",
                "2022-07-31 23:59:59"
              ) ? (
                //   <div className="image-slider">
                <a
                  target="_blank"
                  href={"https://app.giztix.com/giztixbusiness"}
                >
                  <img
                    style={{ width: "100%", marginTop: 50 }}
                    src={
                      "https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/promotion/GTX3KB-invitecode.png"
                    }
                    alt="GTX3KB"
                  />
                </a>
              ) : (
                //   </div>
                <a
                  target="_blank"
                  href={
                    "https://blog.giztix.com/6-มาตรการสำคัญ-เพื่อความปลอดภัยจาก-covid-19/"
                  }
                >
                  <img
                    style={{ width: "100%", marginTop: 50 }}
                    src={require("../../assets/images/Pop-Up-covid.png")}
                    alt="covid"
                  />
                </a>
              )}

              {/* <div className="image-slider">
                  <a
                    target="_blank"
                    href={
                      "https://blog.giztix.com/6-มาตรการสำคัญ-เพื่อความปลอดภัยจาก-covid-19/"
                    }
                  >
                    <img
                      style={{ width: "100%", marginTop: 50 }}
                      src={require("../../assets/images/Pop-Up-covid.png")}
                      alt="covid"
                    />
                  </a>
                </div>
              </Slider> */}
            </PromotionContainerNew>
          )}
        </ReactModal>
        <Footer />
      </Container>
    );
  }
}

export default withNamespaces()(App);
