import React, { Component } from 'react';
import { HeaderMobile, FooterMobile, LazyImage, ModalEvent } from './../../components';
import { CoverMobile, TextHeader } from './components';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import Dotdotdot from 'react-dotdotdot';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import './style.css';
import ReactModal from 'react-modal';

//StepCheckPriceContainer
import imageEasyBook from '../../assets/images/homepage/hover/easy-book.svg';
import imagePricing from '../../assets/images/homepage/hover/pricing.svg';
import imageTruckType from '../../assets/images/homepage/hover/truck-type.svg';
import imageTrustShipment from '../../assets/images/homepage/hover/trust-shipment.svg';

import imageEasyBookW from '../../assets/images/homepage/hover/easy-book-w.svg';
import imagePricingW from '../../assets/images/homepage/hover/pricing-w.svg';
import imageTruckTypeW from '../../assets/images/homepage/hover/truck-type-w.svg';
import imageTrustShipmentW from '../../assets/images/homepage/hover/trust-shipment-w.svg';

//PromotionContainer
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//privilege Container
import imagePrivilege1 from '../../assets/images/homepage/privilege/privilege01.jpg';
import imagePrivilege2 from '../../assets/images/homepage/privilege/privilege02.png';
import imagePrivilege3 from '../../assets/images/homepage/privilege/privilege03.png';
import imagePrivilege4 from '../../assets/images/homepage/privilege/privilege05.jpg';

//DeviceContainer
import iconApp from '../../assets/images/Icon-App.png';
import imageAppStore from '../../assets/images/app-store.svg';
import imagePlaystore from '../../assets/images/play-store.svg';
import imageMockup from '../../assets/images/Mock-Up-Application.png';
import imageMockupWebp from '../../assets/images/Mock-Up-Application.webp';

import './style.css';
import appUrl from './../../config/appUrl';
import moment from 'moment';

import { withNamespaces } from "react-i18next";

AOS.init({
	delay: 100
});

const Container = styled.div`
	
`
const TextContentHeader = styled.div`
	font-size: 36px;
	color: #000000;
	font-weight: 700;
`;

const TextContent = styled.div`
	font-size: 20px;
	color: #000000;
  	margin-top: 10px;
  	line-height: 1.5;

	&:last-child {
		margin-bottom: 10px;
	}
`;

const NewsContainer = styled.div`
	padding: 10px;
`;

const StepCheckPriceContainer = styled.div`
	text-align: center;
	margin-top: 100px;
	padding-left: 15px;

	& .box-container {
		height: 310px;
		display: flex;
		align-items: center;
		padding: 10px;
	}

	& .box {
		flex: 1;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		border: 1px solid #eeeeee50;
		padding: 35px 20px;
		background-color: #ffffff;
        box-sizing: border-box;
		border-radius: 5px;
		height: 280px;
		vertical-align: middle;

		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: all .3s;

		&:not(:last-child) {
			margin-right: 20px;
		}

		& .box-image {
			display: block;

			& img {
				width: 120px
				height: 120px;
			}

			&-hover {
				display: none;

				& img {
					width: 120px
					height: 120px;
				}
			}
		}

		& .box-title {
			margin-top: 30px;
			font-weight: 700;
			text-align: left;
			font-size: 30px;
			line-height: 1;

			&-subtitle {
				text-align: left;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

const PromotionContainer = styled.div`
	margin-top: 100px;

	.image-slider {
		border-radius: 6px;
		background-color: #eeeeee;

		& a {
			outline: none;
		}

		& img {
			width: 100%;
			border-radius: 6px;
		}
	}

	.slick-slide:nth-child(odd) {
		padding-right: 10px;
	}

	.slick-slide:nth-child(even) {
		padding-left: 10px;
	}
`;

const PrivilegeContainer = styled.div`
	font-size: 24px;
	margin-top: 50px;

	& .privilege-cover {
		border-radius: 6px;
		border: 1px solid #fafafafa;

		& img {
			width: 100%;
			border-radius: 6px;
		}
	}

	& .privilege-title {
		margin-top: 10px;
		font-weight: 700;
	}

	& .privilege-detail {
		color: #00000090;
		line-height: 1;
	}

	& .privilege-button-more {
		display: inline-block;
		margin-top: 20px;
		margin-bottom: 10px;
		cursor: pointer;
		border-bottom: 2px solid transparent;

		& a {
			text-decoration: none;
			color: #000000;
			line-height: 1;
		}

		&:hover {
			font-weight: 700;
			border-bottom: 2px solid #d90101;
		}
	}
`;

const PromotionsContainer = styled.div`
	font-size: 24px;
	margin-top: 100px;

	& .promotion-banner {
		& img {
			width: 100%;
		}
	}
`;

const customStylesReactModal = {
	content: {
		top: '40%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		backgroundColor: 'transparent',
		border: 0,
	},
	overlay: {
		zIndex: 1010,
		backgroundColor: 'rgba(0,0,0,0.45)',
	},
};

const PromotionContainerNew = styled.div`
	position: relative;
	width: 100%;
	max-width: 320px;

	& .close-modal {
		position: absolute;
		color: #000000;
		top: 0px;
		right: 0px;
		background-color: #ffffff;
		width: 30px;
		height: 30px;
		text-align: center;
		border-radius: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 20px;
	}

	& img {
		width: 100%;
	}

	& .close-modal {
		cursor: pointer;
	}
`;

const settingsSlider = {
	dots: true,
	arrows:false,
	infinite: false,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	centerMode: true,
	centerPadding: 30,
};

class AppMobile extends Component {
	state = {
		isTop: true,
		isOpenModalPromotion: false
	};

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 100;
			if (isTop !== this.state.isTop) {
				this.setState({ isTop });
			}
		});
	}

	checkShowPromotion(date) {
		const format = "YYYY-MM-DD HH:mm:ss";
    const current = moment();
    return current.isSameOrBefore(moment(`${date} 23:59:59`, format), null, '[]');
	}

	checkBetweenDateTime = (startDateTime, endDateTime) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    const current = moment();
    const startDate = moment(startDateTime, format);
    const endDate = moment(endDateTime, format);
    const range = current.isBetween(startDate, endDate, null, '[]');
    return range;
  };

	render() {
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
		return (
			<Container className="homeMobile backgroundCircle">
				<ModalEvent />
				<Helmet>
					<title>GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
				</Helmet>
				<HeaderMobile
					bgcolor={this.state.isTop ? 'transparent' : '#FFFFFF'}
					border={this.state.isTop ? false : true}
				/>

				<div className="container">

					<div className="cover-height">
						{/* บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย */}
						<CoverMobile/>
					</div>
					
					{this.checkBetweenDateTime(
						'2020-03-01 00:00:00',
						'2021-03-31 23:59:59'
					) && (
						<PrivilegeContainer>
							<div data-aos="fade-up" data-aos-duration="1000">
								<TextHeader
									style={{ marginBottom: 30 }}
									title={this.props.t('homepage:Privilegesfromsponsors')}
									subTitle={this.props.t('homepage:PrivilegesfromoursponsorsspecialforGIZTIXsCustomerthatsyoushouldntmiss')}
								/>
							</div>
							
							<div>
								<Row>
									<div style={{paddingLeft:10}}>
										<Slider {...settingsSlider}>
											<NewsContainer data-aos="fade-up" data-aos-duration="1300">
												<a target="_blank" rel="noopener noreferrer" href="https://www.skzicthailand.com/home/product_zic">
													<div className="privilege-cover">
														<LazyImage  src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/18-mar-2020/Banner-ZIC-Home-Page-536x200.jpg" alt="privilege" />
													</div>
												</a>
												<div className="privilege-title">
													<Dotdotdot clamp={1}>
														ความเหมือน..ที่แตกต่าง
													</Dotdotdot>
												</div>
												<div className="privilege-detail">
													<Dotdotdot clamp={2}>
														yubase ( Base Oil Group 3 ) 100% ทุกหยดจากประเทศเกาหลีใต้
													</Dotdotdot>
												</div>
												<div className="privilege-button-more"><a target="_blank" rel="noopener noreferrer" href="https://www.skzicthailand.com/home/product_zic">{this.props.t('homepage:Readmore')}</a></div>
											</NewsContainer>
											<NewsContainer data-aos="fade-up" data-aos-duration="1300">
												<a target="_blank" rel="noopener noreferrer" href={`${appUrl}/giztixbusiness`}>
													<div className="privilege-cover">
														<LazyImage src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-Firsttruck-536x200.png" alt="privilege" />
													</div>
												</a>
												<div className="privilege-title">
													<Dotdotdot clamp={1}>
														จองรถขนส่งครั้งแรก ลด 5% เมื่อจองครั้งแรกรับส่วนลดไปเลย!
													</Dotdotdot>
												</div>
												<div className="privilege-detail">
													<Dotdotdot clamp={2}>
														สามารถจองรถส่งสินค้าได้ทุกประเภททั้งรถกระบะตู้ทึบ/คอก,
														รถบรรทุก 6 ล้อตู้ทึบ / ลิฟท์ท้าย/เครน,รถบรรทุก 10 ล้อ ตู้ทึบ/คอก/ลิฟท์ท้าย,และรถเทรลเลอร์พื้นเรียบ
													</Dotdotdot>
												</div>
												<div className="privilege-button-more"><a target="_blank" rel="noopener noreferrer" href={`${appUrl}/giztixbusiness`}>{this.props.t('homepage:Readmore')}</a></div>
											</NewsContainer>
										</Slider>
									</div>
								</Row>
							</div>
						</PrivilegeContainer>
					)}

					<PromotionsContainer>
						<div data-aos="fade-up" data-aos-duration="1000">
							<TextHeader
								style={{ marginBottom: 30 }}
								title="โปรโมชั่นพิเศษ"
								subTitle="ใช้รหัสส่วนลดตอนนี้ก่อนสิทธิ์หมด หรือหมดเขตรับเท่านั้น จองตอนนี้เลย"
							/>
						</div>
						
						<div>
							<Row>
								<Col md={12}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<a target="_blank" rel="noopener noreferrer" href={`${appUrl}/giztixbusiness`}>
											<div className="promotion-banner">
												<LazyImage src="https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-BU-Register-536x120.png" alt="privilege" />
											</div>
										</a>
									</div>
								</Col>
							</Row>
						</div>
					</PromotionsContainer>
				</div>

				<div className="backgroundCircle2">
					<div className="container">
						{
							this.checkShowPromotion('2019-08-11') && (
								<PromotionContainer>
									<div data-aos="fade-up" data-aos-duration="1000">
										<TextHeader
											style={{ marginBottom: 30 }}
											title={this.props.t('homepage:SpecialPromotion')}
											subTitle={this.props.t('homepage:UsingpromotioncodesbeforepromotionexpiredBooknow')}
										/>
									</div>

									<div data-aos="fade-up" data-aos-duration="1300">
										{
											this.checkShowPromotion('2019-08-11') && (
												<div className="image-slider" style={{marginBottom : "15px"}}>
													<a target="_blank" rel="noopener noreferrer" href="http://bit.ly/GTX_GTXTRUCK"><LazyImage src={`${require('../../assets/images/homepage/promotion/GTXTRUCK.png')}`} alt="GTXTRUCK"/></a>
												</div>
											)
										}

										{
											this.checkShowPromotion('2019-08-11') && (
												<div className="image-slider" style={{marginBottom : "15px"}}>
													<a target="_blank" rel="noopener noreferrer" href="http://bit.ly/GTX_GTXTRL"><LazyImage src={`${require('../../assets/images/homepage/promotion/GTXTRL.png')}`} alt="GTXTRL"/></a>
												</div>
											)
										}

										{
											this.checkShowPromotion('2019-08-11') && (
												<div className="image-slider" style={{marginBottom : "15px"}}>
													<a target="_blank" rel="noopener noreferrer" href="http://bit.ly/GTX_AAN300"><LazyImage src={`${require('../../assets/images/homepage/promotion/AAN300.png')}`} alt="AAN300"/></a>
												</div>
											)
										}
									</div>
								</PromotionContainer>
							) 
						}

						{/* เราทำให้ธุรกิจคุณดีขึ้นได้อย่างไร */}
						<StepCheckPriceContainer>
							<div data-aos="fade-up" data-aos-duration="1000">
								<TextHeader
									style={{ marginBottom: 20 }}
									title={this.props.t('homepage:Howwemakeyourbusinessbetter')}
									subTitle={this.props.t('homepage:Checkpriceandbookingtruckinstantlyjust4steps')}
								/>
							</div>
							
							<Row>
								<Slider {...settingsSlider}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><LazyImage src={imageEasyBook} alt="box" /></div>
												<div className="box-image-hover"><LazyImage src={imageEasyBookW} alt="box" /></div>
												<div className="box-title">{this.props.t('homepage:EasyBooingGettrucksure')}</div>
												<div className="box-title-subtitle">{this.props.t('homepage:Gettruckthroughwebsiteapplication')}<br/>{this.props.t('homepage:everyday24hours')}</div>
											</div>
										</div>
									</div>
									<div data-aos="fade-up" data-aos-duration="1600">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><LazyImage src={imagePricing} alt="box" /></div>
												<div className="box-image-hover"><LazyImage src={imagePricingW} alt="box" /></div>
												<div className="box-title">{this.props.t('homepage:Priceasactualusing')}</div>
												<div className="box-title-subtitle">{this.props.t('homepage:Rateiscalculatedbydistance')}<br/>{this.props.t('homepage:nohiddenprice')}</div>
											</div>
										</div>
									</div>
									<div data-aos="fade-up" data-aos-duration="1900">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><LazyImage src={imageTruckType} alt="box" /></div>
												<div className="box-image-hover"><LazyImage src={imageTruckTypeW} alt="box" /></div>
												<div className="box-title">{this.props.t('homepage:Providingvarioustruckservices')}</div>
												<div className="box-title-subtitle">{this.props.t('homepage:Selecttruckserviceandmatching')}<br/>{this.props.t('homepage:tocargotypeandshipment')}</div>
											</div>
										</div>
									</div>
									<div data-aos="fade-up" data-aos-duration="2100">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><LazyImage src={imageTrustShipment} alt="box" /></div>
												<div className="box-image-hover"><LazyImage src={imageTrustShipmentW} alt="box" /></div>
												<div className="box-title">{this.props.t('homepage:Assuredeveryshipment')}</div>
												<div className="box-title-subtitle">{this.props.t('homepage:Trackingshipmentstatuseveryshipments')}<br/>{this.props.t('homepage:onapplicationandwebsite')}</div>
											</div>
										</div>
									</div>
								</Slider>
							</Row>
						</StepCheckPriceContainer>
					</div>
				</div>

				<div className="backgroundCirclenotiApp"> 
					<div className="container" style={{ paddingTop: 100 }}>
						<img src={iconApp} alt="App giztix" width="80px" />
						<Row style={{ marginTop: 30 }}>
							<Col xs={12}>
								<TextContentHeader>{this.props.t('homepage:fasterAndMoreConvenient')}</TextContentHeader>
								<TextContent>
								{this.props.t('homepage:checkPriceAndBookTruckInstantly')} <br/>
								{this.props.t('homepage:availableOnMobileAndTablet')} <br/>
								{this.props.t('homepage:makeYourBusinessEasier')} <br/>
								</TextContent>
								<TextContent>
									<b>{this.props.t('homepage:orDownloadApplicationhere')}</b>
								</TextContent>
							</Col>
						</Row>

						<Row style={{ marginTop: 20 }}>
							<Col xs={12}>
								<a href="https://play.google.com/store/apps/details?id=com.giztix.app" target="_blank"><img src={imagePlaystore} alt="giztix download on play Store" width="160px" /></a> 
							</Col>
							<Col xs={12} style={{ marginTop: 10 }}>
								<a href="https://itunes.apple.com/app/id1439755826" target="_blank"><img src={imageAppStore} alt="giztix download on App Store" width="160px" /></a> 
							</Col>
						</Row>

						<Row style={{ marginTop: 50 }}>
							<Col xs={12}>
							{!isChrome  ? ( <img src={imageMockup} alt="giztix apllication mockup" width="100%" /> ):(<img src={imageMockupWebp} alt="giztix apllication mockup" width="100%" />)}
							</Col>
						</Row>
					</div>
				</div>
				
				<ReactModal
					isOpen={this.state.isOpenModalPromotion}
					onRequestClose={() =>
						this.setState({ isOpenModalPromotion: false })
					}
					style={customStylesReactModal}
				>
					{this.checkBetweenDateTime('2022-04-01 00:00:00','2022-04-30 23:59:59') ?  
						<PromotionContainerNew style={{ maxWidth: 600 }}>
							<div
								className="close-modal"
								onClick={() =>
									this.setState({ isOpenModalPromotion: false })
								}
							>
								<i className="fal fa-times" />
							</div>
							<a
								target="_blank"
								href={'https://app.giztix.com/?promotion_code=GTXSKD22'}
							>
								<img
									style={{ width: '100%', marginTop: 50 }}
									src={require('../../assets/images/popup/popup_songkran_2022.jpg')}
									alt="โปรโมชั่นสงกรานต์"
								/>
							</a>
						</PromotionContainerNew>
						:
						<PromotionContainerNew style={{ maxWidth: 600 }}>
							<div
								className="close-modal"
								onClick={() =>
									this.setState({ isOpenModalPromotion: false })
								}
							>
								<i className="fal fa-times" />
							</div>
							<a
								target="_blank"
								href={'https://blog.giztix.com/6-มาตรการสำคัญ-เพื่อความปลอดภัยจาก-covid-19/'}
							>
								<img
									style={{ width: '100%', marginTop: 50 }}
									src={require('../../assets/images/Pop-Up-covid.png')}
									alt="covid"
								/>
							</a>
						</PromotionContainerNew>
				}
				</ReactModal>
				
				<FooterMobile />
			</Container>
		);
	}
}

export default withNamespaces()(AppMobile);
