import React, { Component } from "react";
import { Row, Button } from "react-bootstrap";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import { Header, BreadcrumbTitel, Footer } from "./../../components";

const Container = styled.div`
  margin: 145px auto 50px;
  text-align: left;
  min-height: 30vh;
`;

const ButtonSearch = styled(Button)`
  width: 90px;
`;

const TitelNumber = styled.ul`
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    padding-left: 0px;
    margin-top: 0px;
  }
`;
const Titel = styled.div`
  margin-bottom: 30px;
  font-size: 36px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;
const TextIndent = styled.p`
  margin-bottom: 15px;
  margin-left: 64px;
  line-height: 1;

  @media (max-width: 768px) {
    margin-left: 20px;
  }
`;

class Complain extends Component {
  render() {
    const menu = [
      {
        active: true,
        link: "/helpcenter",
        title: "ศูนย์ความช่วยเหลือ >",
      },
      {
        active: true,
        link: "/complain",
        title: "นโยบายการแก้ปัญหาข้อร้องเรียน ",
      },
    ];

    return (
      <div style={{ backgroundColor: "#ffffff" }}>
        <Helmet>
          <title>Complain | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
          <meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
        </Helmet>
        <Header active={3}></Header>
        <BreadcrumbTitel menu={menu}></BreadcrumbTitel>
        <div>
          <Container className="container">
            <Titel>นโยบายการแก้ปัญหาข้อร้องเรียน</Titel>
            <TitelNumber>
              <p>
                <strong>1.&nbsp;&nbsp;&nbsp;&nbsp;มาตรการคุ้มครองและรักษาความลับของผู้ร้องเรียน</strong>
              </p>
            </TitelNumber>
            <TextIndent>
              เพื่อเป็นการคุ้มครองสิทธิของผู้ร้องเรียนและผู้ให้ข้อมูลที่กระทำโดยเจตนาสุจริตทาง <a href="https://www.giztix.com">www.giztix.com</a> จะปกปิดชื่อ ที่อยู่ หรือข้อมูลใด ๆ
              ที่สามารถระบุตัวผู้ร้องเรียนหรือผู้ให้ข้อมูลได้ และเก็บรักษาข้อมูลของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ
              โดยจำกัดเฉพาะผู้รับผิดชอบในการดำเนินการตรวจสอบเรื่องร้องเรียนเท่านั้นที่จะเข้าถึงข้อมูลดังกล่าวได้
            </TextIndent>
            <TextIndent>
              ทั้งนี้ ผู้ได้รับข้อมูลจากการปฏิบัติหน้าที่ที่เกี่ยวข้องกับเรื่องร้องเรียน มีหน้าที่เก็บรักษาข้อมูล ข้อร้องเรียน และเอกสารหลักฐานของผู้ร้องเรียนและผู้ให้ข้อมูลไว้เป็นความลับ
              ห้ามเปิดเผยข้อมูลแก่บุคคลอื่นที่ไม่มีหน้าที่เกี่ยวข้อง เว้นแต่เป็นการเปิดเผยตามหน้าที่ที่กฎหมายกำหนด
            </TextIndent>

            <TitelNumber>
              <p>
                <strong>2.&nbsp;&nbsp;&nbsp;&nbsp;การดำเนินการเมื่อได้รับเรื่องร้องเรียน</strong>
              </p>
            </TitelNumber>
            <TextIndent>
              <a href="https://www.giztix.com">www.giztix.com</a> จะเป็นผู้พิจารณาเรื่องร้องเรียนที่ได้รับทันที และแจ้งให้หน่วยงานที่เกี่ยวข้องดำเนินการสอบสวนข้อเท็จจริงเกี่ยวกับเรื่องที่ได้รับ
              การร้องเรียนและดำเนินการแก้ไขอย่างเหมาะสม
              <br />
              <a href="https://www.giztix.com">www.giztix.com</a> จะติดตามผลความคืบหน้าเป็นระยะ ซึ่งหน่วยงานที่เกี่ยวข้องจะแจ้งผลการดำเนินงานภายในระยะเวลาที่เหมาะสมให้แก่ผู้ร้องเรียนทราบ
              โดยไม่มีค่าใช้จ่าย
            </TextIndent>

            <TitelNumber>
              <p>
                <strong>3.&nbsp;&nbsp;&nbsp;&nbsp;ช่องทางการรับเรื่องร้องเรียน</strong>
              </p>
            </TitelNumber>
            <TextIndent>ผู้ร้องเรียนจะต้องระบุรายละเอียดของเรื่องที่จะร้องเรียน พร้อมชื่อ ที่อยู่ และหมายเลขโทรศัพท์ที่สามารถติดต่อได้ และส่งมายัง[เว็บไซต์ของท่าน] ได้ 2 ช่องทาง คือ</TextIndent>
            <TextIndent style={{ marginBottom: 0 }}>ช่องทางที่ 1 จดหมายทางไปรษณีย์ ตามที่อยู่ในเมนูติดต่อจิซทิกซ์</TextIndent>
            <TextIndent style={{ marginBottom: 0 }}>
              {" "}
              ช่องทางที่ 2 จดหมายทาง E-mail address : <a href="mailto:service@giztix.com">service@giztix.com</a>
            </TextIndent>

            <TitelNumber>
              <p>
                <strong>4.&nbsp;&nbsp;&nbsp;&nbsp;ใช้สิทธิภายใต้ พ.ร.บ.คุ้มครองข้อมูลส่วนบุคคล</strong>
              </p>
            </TitelNumber>
            <TextIndent>ลูกค้าสามารถส่งคำขอด้านข้อมูลส่วนบุคคลไปยัง บริษัท จิซทิกซ์ จำกัด ผ่านฟอร์ม "หนังสือความยินยอมจากลูกค้า" ได้ 2 ช่องทาง คือ</TextIndent>
            <TextIndent style={{ marginBottom: 0 }}>ช่องทางที่ 1 จดหมายทางไปรษณีย์ ตามที่อยู่ในเมนูติดต่อจิซทิกซ์</TextIndent>
            <TextIndent>
              {" "}
              ช่องทางที่ 2 จดหมายทาง E-mail address : <a href="mailto:service@giztix.com">service@giztix.com</a>
            </TextIndent>
            <TextIndent style={{ marginBottom: 0 }}>ดาวน์โหลด "หนังสือความยินยอมจากลูกค้า" <a href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/Giztix_Consent_Request+_Form_for_Client.pdf" download>คลิกที่นี้</a></TextIndent>
            

            <TitelNumber>
              <p>
                <strong>5.&nbsp;&nbsp;&nbsp;&nbsp;ช่องทางการระงับข้อพิพาทโดยกลไกภายนอก</strong>
              </p>
            </TitelNumber>
            <TextIndent>หากกระบวนการแก้ไขข้อพิพาทโดยผู้ประกอบการไม่เป็นที่พอใจของลูกค้า ลูกค้าสามารถใช้ช่องทางภายนอกที่ลูกค้าสามารถดำเนินการระงับข้อพิพาทได้ โดยติดต่อมายัง</TextIndent>
            <TextIndent style={{ marginBottom: 0 }}>บริษัท จิซทิกซ์ จำกัด</TextIndent>
            <TextIndent style={{ marginBottom: 0 }}>777 อาคาร ดับบลิวเอชเอ ทาวเวอร์ ชั้น 15 หมู่ที่ 13 ถนนเทพรัตน(บางนา-ตราด)กม.7</TextIndent>
            <TextIndent style={{ marginBottom: 20 }}>ตำบลบางแก้ว อำเภอบางพลี จังหวัดสมุทรปราการ 10540</TextIndent>
          </Container>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Complain;
