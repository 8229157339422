
import React, { Component } from "react";
import { Row , Col , Button , FormGroup , ControlLabel , FormControl , InputGroup} from "react-bootstrap";
import styled from 'styled-components';

import './style.css';
import logo from './../../../../../../assets/images/headerSlide/slide01.png';
import appIos from './../../../../../../assets/images/app-store.svg';
import playStore from './../../../../../../assets/images/play-store.svg';

const HeaderContainer = styled(Row)`
    background-color: rgba(0, 0, 0,1);
    background-image: url(${logo});
    background-size:cover;
    width: 100%;
    height:600px;
    margin-right:0;
    margin-left:0;
    color:#fff;
`;

const HeaderDetail = styled(Row)`
    padding-top:150px;
    position: relative;
`;

const SearchPriceContainer  = styled(Row)`
    position: absolute;
    background-color: #fff;
    width: 100%;
    border-radius: 8px;
    bottom: -40px;
    -webkit-box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
    box-shadow: 0px 0px 10px 1px rgba(0,0,0,.3);
`;

const BtnContainer  = styled(Col)`
    padding-right:0;
    padding-left:0;
`;

const DeliveryContainer = styled(Col)`
    border-left: 1px solid #ddd;
`;

const PickupContainer = styled(Col)`
    
`;

export class SlideHeader extends Component {
    searchPrice(){
        window.location = "/newshipment";
    }
    render() {
        return (
            <HeaderContainer>
                <div className="container HeaderContainer">
                    <HeaderDetail className="headerSlider">
                            <h1>EASY SHIPPING EVERYDAY</h1>
                            <p>is simply dummy text of the printing and typesetting industry. <br/>
                            Lorem Ipsum has been.</p>

                            <h4>Download Our App</h4>
                            <div className="section-download-app" id="section-download-app">
                                <a data-href="https://play.google.com/store/apps/details?id=com.giztix.app" className="icon-app" mode="android">
                                    <img src={playStore} className="logo-app" alt="logo" />
                                </a>
                                <a data-href="https://itunes.apple.com/app/id1439755826" className="icon-app" mode="ios">
                                    <img src={appIos} className="logo-app" alt="logo" />
                                </a>
                            </div>
                    </HeaderDetail>  

                    <SearchPriceContainer className="SearchPriceContainer"> 
                        <form>
                            <PickupContainer className="pinMap" xs={12} >
                                <FormGroup>
                                    <ControlLabel>Pickup address</ControlLabel>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Select" />
                                        <InputGroup.Addon><i className="fas fa-map-marker-alt"></i></InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </PickupContainer>
                            <DeliveryContainer className="pinMap" xs={12} >
                                <FormGroup>
                                    <ControlLabel>Delivery address</ControlLabel>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Select"  />
                                        <InputGroup.Addon><i className="fas fa-map-marker-alt"></i></InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                            </DeliveryContainer>
                            <BtnContainer className="pinBtn" xs={12} >
                                <Button onClick={() => this.searchPrice()} className="btn-search" bsStyle="primary" block>Check price now <i className="far fa-arrow-right"></i></Button>
                            </BtnContainer>
                        </form>
                    </SearchPriceContainer>

                </div>
                        
            </HeaderContainer>
        );
    }
}

export default SlideHeader;
