import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import coverImage from '../../../../assets/images/homepage/cover/mainpicture_Giztixwebsite.png';
import coverImageWebP from '../../../../assets/images/homepage/cover/mainpicture_Giztixwebsite.webp';
import color from './../../../../config/color';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyImage } from '../../../../components';
import appUrl from './../../../../config/appUrl';

import { withNamespaces } from "react-i18next";

AOS.init({
	delay: 100
});

const CoverContainer = styled.div`
	padding-top: calc(45vh - 150px);

	& .symbol {
		display: inline-block;
		position: absolute;
		top: -10px;
		height: 14px;
		width: 14px;
		border-radius: 4px;
		background-color: ${color.Primary};
	}

	& .title-header {
		position: relative;
		font-weight: bold;
		line-height: 48pt;
		font-size: 66px;
	}
	
	.title-sub-header {
		font-size: 55px;
		font-weight: bold;
		line-height: 40pt;
	}
	
	.detail {
		margin-top: 10px;
		font-size: 26px;
		line-height: 1;
	}

	.check-button {
		height: 54px;
		width: 276px;
		font-size: 28px;
		border-radius: 6px;
		margin-top: 40px;
		//box-shadow: 0px 6px 16px #d9010120;
		background-color: #d90101;
		color: #FFFFFF;
		
		&:not(:last-child) {
			margin-right: 16px;
		}

		& i {
			font-size: 20px;
		}

		&:hover {
			background-color: #d90101;
			box-shadow: 0px 10px 20px #d9010140;
		}

		&--outline {
			background-color: #FFFFFF;
			color: #d90101;
			border: 1px solid #d90101;
			
			&:hover {
				background-color: #FFFFFF;
			}
		}
	}

	@media (min-width: 992px) {
		.cover-right {
			padding-left: 55px;
		}

		.img-cover {
			position: absolute;
			top: -100px;
			left: -70px;
			width: 145%;
		}

		.title-header {
			font-size: 62px;
		}

		.title-sub-header {
			font-size: 50px;
		}

		.detail {
			font-size: 25px;
			margin-top: 20px;
		}

		.check-button {
			height: 55px;
			width: 200px;
			font-size: 28px;

			& i {
				font-size: 20px;
			}
		}
	}

	@media (min-width: 1200px) {
		.img-cover {
			position: absolute;
			top: -100px;
			left: -70px;
			width: 145%;
		}

		.cover-right {
			padding-left: 95px;
		}
		
		.title-header {
			font-size: 66px;
		}

		.title-sub-header {
			font-size: 55px;
		}

		.detail {
			font-size: 30px;
			margin-top: 20px;
		}

		.check-button {
			height: 60px;
			width: 250px;
			font-size: 34px;

			& i {
				font-size: 26px;
			}
		}
	}

	@media (max-width: 992px) {
		padding-top: calc(45vh - 200px);
		.cover-right {
			padding-left: 30px;
		}
	}
`;

const Cover = (props) => {
	var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  return (
		<div>
			<CoverContainer>
				<Row>
					<Col xl={5} lg={5} md={5} smHidden xsHidden className="size">
						<div data-aos="fade-up" data-aos-duration="1500">
							{!isChrome  ? ( <LazyImage className="img-cover" src={coverImage} alt="cover"/>) : ( <LazyImage className="img-cover" src={coverImage} alt="cover"/>)}
						</div>
					</Col>

					<Col xl={7} lg={7} md={7} className="size">
						<div className="cover-right">
							<div data-aos="fade-up" data-aos-duration="1500">
								<div className="title-header">
								{props.t('homepage:FullTruckLoadService')} <span className="symbol" />
								</div>
								<div className="title-sub-header">{props.t('homepage:EasyBookingGetTruckNationwideSurely')}</div>
							</div>
							
							<div data-aos="fade-up" data-aos-duration="1800">
								<div className="detail">
								{props.t('homepage:SafetyEasyBookingTruckServiceMakeyourbusinessflow')}{' '}
									<br /> {props.t('homepage:withprecisetrackingshipmentstatusandidentity')}
								</div>
							</div>

							<div data-aos="fade-up" data-aos-duration="2100">
								<Button className="btn-book-now check-button" onClick={() => window.location = `${appUrl}`}>
									{props.t('homepage:btnBookNow')}
								</Button>
								<Button className="btn-check-price check-button check-button--outline" onClick={() => window.location = `${appUrl}`}>
									{props.t('homepage:FreeCheckPriceNow')}
								</Button>
							</div>
							{
								// <div data-aos="fade-up" data-aos-duration="2100" style={{marginTop: '30px', cursor: 'pointer'}} onClick={props.isShowModal} >
								// 	<img src={require('../../../../assets/images/Distance-01.png')} alt="Distance Challenge"  style={{width: '100%'}} />
								// </div>
							}
						</div>
					</Col>
				</Row>
			</CoverContainer>
		</div>
	);
};

export default withNamespaces()(Cover);
