import React from "react";
import {Row , Badge} from "react-bootstrap";
import styled from 'styled-components';
import PropTypes from 'prop-types';
import color from "./../../config/color";


const Container = styled(Row)`
    position: relative;
    margin-left:0;
    margin-right: 0;

    .card-header {
        margin-bottom: 10px;
    }
    
    .card-header.border {
        padding: 0 0 10px;
        border-bottom: 1px solid #e3e3e3;
    }
    
    .card-header .right-btn {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 24px;
        z-index: 9;
    }
    
    .card-title {
        font-size: 24px;
        font-weight: bold;
        color: #000000;
        line-height: 1.2;
    }
    
    .card-subtitle {
        font-size: 16px;
        color: #808080;
        line-height: 1;
    }
`;

const BadgeContainer = styled(Badge)`
    background-color: #fff;
    border: 1px solid #D90101;
    color: #D90101;
    font-size: 26px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    float: left;
    margin-right: 10px;
`;

const BackBtn = styled.a`
    float: left;
    padding-right: 10px;
    border-right: 1px solid #e3e3e3;
    margin-right: 10px;
    margin-top: 1px;
    color: ${color.Black};
    cursor: pointer;

    & i {
        font-size: 16px;
    }
`;

const Title = styled.div`
    display: inline-block;
`;

const Symbol = styled.div`
    width: 10px;
    height: 10px;
    background-color: ${color.Primary};
    display: inline-block;
    margin-left: 4px;
    margin-bottom: 10px;
`;

const ArrowBackContainer = styled.div`
    margin-bottom: 25px;

    & i {
        color: ${color.Primary}
        font-size: 20px;
        cursor: pointer;
    }
`;

const ActionContainer = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;

const CardHeader = (props) => (
    <Container>
        { (props.arrowBack) ? (
            <ArrowBackContainer>
                <i className="fal fa-arrow-left" onClick={props.onClickArrowBack}></i>
            </ArrowBackContainer>
        ) : null }
        <div className={props.border ? 'card-header border' : 'card-header'}>
            { props.backLink ? <BackBtn href={props.backLink}> <i className="fal fa-arrow-left" style={{color:"#D90101",marginRight:"10px"}}></i> กลับ</BackBtn> : null}
            { props.backFucn ? <BackBtn onClick={props.backFucn}> <i className="fal fa-arrow-left" style={{color:"#D90101",marginRight:"10px"}}></i> กลับ</BackBtn> : null}
            { props.number ? <BadgeContainer>{props.number}</BadgeContainer> : null}

            <div className="card-title-wrapper">
                <div className="card-title"><Title>{props.title}</Title>{props.symbol ? <Symbol /> : null}</div>
                { props.subTitle ? <div className="card-subtitle">{props.subTitle}</div> : null}
            </div>

            {props.children && <ActionContainer>{props.children}</ActionContainer> }
            
            {props.onPressClose ? <div className="right-btn" onClick={() => props.onPressClose()}><i className="fal fa-times"></i></div> : null}
        </div>
    </Container>
);

CardHeader.propTypes = {
    backLink: PropTypes.string,
    backFucn: PropTypes.func,
    number: PropTypes.string, 
    title: PropTypes.string,
    subTitle: PropTypes.string,
    border: PropTypes.bool,
    symbol: PropTypes.bool,
    arrowBack: PropTypes.bool,
};
  
CardHeader.defaultProps = {
    title : "",
    subTitle : "",
    border : false,
    symbol : false,
    arrowBack: false,
};

export default CardHeader;
