import tarot_01 from "../../assets/images/mutelu2022/tarot/1/Tarots_01_All-01.svg";
import tarot_02 from "../../assets/images/mutelu2022/tarot/1/Tarots_01_All-02.svg";
import tarot_03 from "../../assets/images/mutelu2022/tarot/1/Tarots_01_All-03.svg";
import tarot_04 from "../../assets/images/mutelu2022/tarot/1/Tarots_01_All-04.svg";
import tarot_05 from "../../assets/images/mutelu2022/tarot/2/Tarots_01_All-05.svg";
import tarot_06 from "../../assets/images/mutelu2022/tarot/2/Tarots_01_All-06.svg";
import tarot_07 from "../../assets/images/mutelu2022/tarot/2/Tarots_01_All-07.svg";
import tarot_08 from "../../assets/images/mutelu2022/tarot/2/Tarots_01_All-08.svg";
import tarot_09 from "../../assets/images/mutelu2022/tarot/3/Tarots_01_All-09.svg";
import tarot_10 from "../../assets/images/mutelu2022/tarot/3/Tarots_01_All-10.svg";
import tarot_11 from "../../assets/images/mutelu2022/tarot/3/Tarots_01_All-11.svg";
import tarot_12 from "../../assets/images/mutelu2022/tarot/3/Tarots_01_All-12.svg";
import tarot_13 from "../../assets/images/mutelu2022/tarot/4/Tarots_01_All-13.svg";
import tarot_14 from "../../assets/images/mutelu2022/tarot/4/Tarots_01_All-14.svg";
import tarot_15 from "../../assets/images/mutelu2022/tarot/4/Tarots_01_All-15.svg";
import tarot_16 from "../../assets/images/mutelu2022/tarot/4/Tarots_01_All-16.svg";
import tarot_17 from "../../assets/images/mutelu2022/tarot/5/Tarots_01_All-17.svg";
import tarot_18 from "../../assets/images/mutelu2022/tarot/5/Tarots_01_All-18.svg";
import tarot_19 from "../../assets/images/mutelu2022/tarot/5/Tarots_01_All-19.svg";
import tarot_20 from "../../assets/images/mutelu2022/tarot/5/Tarots_01_All-20.svg";
import tarot_21 from "../../assets/images/mutelu2022/tarot/6/Tarots_01_All-21.svg";
import tarot_22 from "../../assets/images/mutelu2022/tarot/6/Tarots_01_All-22.svg";
import tarot_23 from "../../assets/images/mutelu2022/tarot/6/Tarots_01_All-23.svg";
import tarot_24 from "../../assets/images/mutelu2022/tarot/6/Tarots_01_All-24.svg";
import tarot_25 from "../../assets/images/mutelu2022/tarot/7/Tarots_01_All-25.svg";
import tarot_26 from "../../assets/images/mutelu2022/tarot/7/Tarots_01_All-26.svg";
import tarot_27 from "../../assets/images/mutelu2022/tarot/7/Tarots_01_All-27.svg";
import tarot_28 from "../../assets/images/mutelu2022/tarot/7/Tarots_01_All-28.svg";
import tarot_29 from "../../assets/images/mutelu2022/tarot/8/Tarots_01_All-29.svg";
import tarot_30 from "../../assets/images/mutelu2022/tarot/8/Tarots_01_All-30.svg";
import tarot_31 from "../../assets/images/mutelu2022/tarot/8/Tarots_01_All-31.svg";
import tarot_32 from "../../assets/images/mutelu2022/tarot/8/Tarots_01_All-32.svg";
import tarot_33 from "../../assets/images/mutelu2022/tarot/9/Tarots_01_All-33.svg";
import tarot_34 from "../../assets/images/mutelu2022/tarot/9/Tarots_01_All-34.svg";
import tarot_35 from "../../assets/images/mutelu2022/tarot/9/Tarots_01_All-35.svg";
import tarot_36 from "../../assets/images/mutelu2022/tarot/9/Tarots_01_All-36.svg";
import tarot_37 from "../../assets/images/mutelu2022/tarot/10/Tarots_01_All-37.svg";
import tarot_38 from "../../assets/images/mutelu2022/tarot/10/Tarots_01_All-38.svg";
import tarot_39 from "../../assets/images/mutelu2022/tarot/10/Tarots_01_All-39.svg";
import tarot_40 from "../../assets/images/mutelu2022/tarot/10/Tarots_01_All-40.svg";
import tarot_41 from "../../assets/images/mutelu2022/tarot/11/Tarots_01_All-41.svg";
import tarot_42 from "../../assets/images/mutelu2022/tarot/11/Tarots_01_All-42.svg";
import tarot_43 from "../../assets/images/mutelu2022/tarot/11/Tarots_01_All-43.svg";
import tarot_44 from "../../assets/images/mutelu2022/tarot/11/Tarots_01_All-44.svg";
import tarot_45 from "../../assets/images/mutelu2022/tarot/12/Tarots_01_All-45.svg";
import tarot_46 from "../../assets/images/mutelu2022/tarot/12/Tarots_01_All-46.svg";
import tarot_47 from "../../assets/images/mutelu2022/tarot/12/Tarots_01_All-47.svg";
import tarot_48 from "../../assets/images/mutelu2022/tarot/12/Tarots_01_All-48.svg";

const data = [
  {
    linkId: "capricorn17441",
    bgColor: "#F2621B",
    zodiacName: "มังกร",
    tarotImage: [tarot_01, tarot_02, tarot_03, tarot_04],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีมังกร หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 15 มกราคม – 12 กุมภาพันธ์)",
    contentSubtitleHealth: "สุขภาพ Steadfastness ความมุ่งมั่น",
    contentHealth:
      "คุณอาจมีปัญหาหนักหน่วงเรื่องสุขภาพ แต่อย่าได้กังวลมากไป เช่นเดียวกับพระคเณศขี่ช้าง ช้างอาจใหญ่โตและทรงพลัง แต่ถ้าเราเข้าใจธรรมชาติของช้างเราก็จะควบคุมมันได้  คุณอาจต้องทุ่มเทศึกษาธรรมชาติของความเจ็บป่วย คุณอาจต้องมองไว้หลาย ๆ ทางเลือก แต่คุณจะผ่านปัญหาไปได้แม้ว่าจะใช้เวลามากสักหน่อย และหากคุณผ่านไปได้ จงแบ่งปันบทเรียนนี้กับผู้อื่น",
    contentSubtitleLove: "ความรัก Decision การตัดสินใจ",
    contentLove:
      "ถึงเวลาของการตัดสินใจแล้ว คนเรามักตัดสินใจจากร่องนิสัยเดิม ๆ แต่ที่จริงก็มีทางเลือกอื่น ๆ ที่เรามองไม่เห็น จงคิดอย่างรอบด้านสักนิด ช้าลงและเชื่อในปัญญาภายในของเรา พระคเณศกำลังเงื้อคทาบอกให้คุณเลิกลังเลเสียที จงกล้าหาญที่จะเลือกและยอมรับผล ถ้าเราตัดสินใจถูก รางวัลคือความสมหวัง แต่ถึงตัดสินใจพลาด คุณก็จะได้เรียนรู้ที่จะยอมรับความทุกข์ ซึ่งเป็นสิ่งที่ไม่อาจเลี่ยงได้ในชีวิตคน ขอกำลังใจจากพระองค์และเลือกด้วยหัวใจที่สง่างาม",
    contentSubTitleMoney: "การเงิน  Openness ความเปิดกว้าง",
    contentMoney:
      "นี่เป็นช่วงเวลาที่คุณจะได้ทบทวนสิ่งที่ทำ คุณอาจพบความผิดพลาดที่ไม่รู้มาก่อนด้านการเงิน หรืออาจพบความผิดปกติบางอย่าง และเมื่อทบทวนดีแล้ว คุณค่อยคิดว่าจะทำอย่างไรต่อ ลองรับฟังความคิดเห็นจากหลาย ๆ คนโดยไม่จำเป็นจะต้องเป็นผู้เชี่ยวชาญเท่านั้น แต่ลองฟังอย่างเปิดใจจากคนใกล้ตัวหรือคนที่ห่วงใย ก่อนที่คุณจะสรุปและแก้ไข",
    contentSubTitleJob: "การงาน Positive Outlook มุมมองทางบวก",
    contentJob:
      "มุมมองทางบวกไม่เท่ากับการมองโลกในแง่ดีชนิดหลอกตัวเอง สถานการณ์ต่าง ๆ ในชีวิตเป็นเพียงสิ่งที่เกิดขึ้น การพยายามมองทุกสิ่งในแง่ดีเป็นความประสาทแบบหนึ่ง ในขณะที่การมองทุกสิ่งในแง่ร้ายก็เป็นความประสาทอีกแบบ การมีมุมมองทางบวกหมายความว่า คุณเตรียมจิตใจและมุมมองอย่างเปิดกว้างต่อสิ่งที่เกิดขึ้น เรียนรู้และรู้จักที่จะขอบคุณเมื่อสิ่งนั้นได้ให้บทเรียนแก่คุณ ไม่ว่ามันจะดีหรือแย่ก็ตาม เช่นเดียวกับทุกสิ่งที่เกิดขึ้นในงาน จงตั้งรับด้วยการไม่คิดไปล่วงหน้าจนวิตกกังวล แต่ก็ไม่ประมาท",
  },
  {
    linkId: "aquarius90210",
    bgColor: "#5842A7",
    zodiacName: "กุมภ์",
    tarotImage: [tarot_05, tarot_06, tarot_07, tarot_08],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีกุมภ์ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 13 กุมภาพันธ์ – 14 มีนาคม)",
    contentSubtitleHealth:
      "สุขภาพ Energetic Movement ความเคลื่อนไหวอย่างมีพลัง",
    contentHealth:
      "พระคเณศอาจกำลังบอกคุณว่า ให้ยืดเส้นยืดสายเสียบ้าง เพื่อจะรักษาสุขภาพของคุณในตอนนี้ให้คงอยู่ไปอีกนาน ๆ นอกจากออกกำลังทางกาย อย่าลืมบริหารจิตใจด้วยเพราะสองอย่างนี้สัมพันธ์กัน สภาวะบ้านเมืองอาจทำให้คุณรู้สึกหดหู่และหมดอาลัยตายอยาก และนั่นย่อมส่งผลต่อสุขภาพโดยรวม ขอให้รักษาความหวัง ดูแลจิตใจให้ดี และส่งกำลังใจให้ผู้คน",
    contentSubtitleLove: "ความรัก Promise of The Future คำมั่นแห่งอนาคต",
    contentLove:
      "เช่นเดียวกับคนราศีพฤษภ นี่อาจเป็นช่วงเวลาเปลี่ยนผ่านที่สำคัญในชีวิต คุณกำลังจะได้เรียนรู้อะไรใหม่ ๆ นั่นทำให้คุณต้องมองไปข้างหน้า หากคุณมีคนรัก สิ่งที่คุณอยากทำหรือมีความฝันร่วมกัน คุณคงต้องกลับมาระลึกถึงมันใหม่อีกครั้ง หากคุณยังเป็นคนโสด อนาคตคือสิ่งที่คุณเลือกเอง ขอให้มองไปข้างหน้าอย่างที่องค์พระคเณศทำ",
    contentSubTitleMoney: "การเงิน Reflection ส่องสะท้อน",
    contentMoney:
      "คุณมาถึงจุดเปลี่ยนทางการเงินแล้ว นี่เป็นช่วงเวลาสำคัญที่คุณจะต้องตัดสินใจว่าจะเลือกทางไหน พระคเณศอาจช่วยกระซิบว่า “ลาภ” กับ “โลภ” ต่างกันแค่สระตัวเดียว ถ้าคุณเชื่อมั่นในปัญญาภายในและหัวใจที่ดีของคุณ การตัดสินใจก็จะไม่ผิดพลาด และสิ่งที่คุณเลือกจะสะท้อนทั้งความดีงามและความน่าเกลียดที่คุณมี ขอให้ใช้สติปัญญากับการเลือกครั้งนี้ให้เต็มที่",
    contentSubTitleJob: "การงาน Childlike Expression แสดงออกอย่างเด็ก ๆ ",
    contentJob:
      "บางครั้งคุณปกปิดตัวเองมากไป คุณต้องซ่อนเร้นความต้องการและอารมณ์ความรู้สึก เพื่อไม่ให้เห็นว่าคุณอ่อนแอ คุณกลัวที่จะโดนตัดสินจากเพื่อนร่วมงาน หรือคุณกลัวว่าความคิดเห็นของคุณอาจดูโง่ราวกับความคิดเด็ก ขอให้คุณกลับมาเชื่อมั่นในตนเอง หากคุณมีความจริงใจและปรารถนาดี จงแสดงความคิดเห็นอย่างซื่อตรงต่อเพื่อนร่วมงาน นั่นอาจเป็นประโยชน์ต่อส่วนรวมมากกว่าการนิ่งเงียบ แต่นั่นก็เรียกร้องความกล้าหาญซึ่งคุณมีอยู่แล้วภายใน",
  },
  {
    linkId: "pisces53889",
    bgColor: "#5842A7",
    zodiacName: "มีน",
    tarotImage: [tarot_09, tarot_10, tarot_11, tarot_12],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีมีน หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 15 มีนาคม – 12 เมษายน)",
    contentSubtitleHealth: "สุขภาพ Divine Support เทพเกื้อหนุน",
    contentHealth:
      "หากคุณป่วยคุณจะหายอย่างรวดเร็ว หากคุณแข็งแรงดีคุณจะได้รับการปกป้อง พรนี้มาจากพระคเณศหรือจักรวาล กระนั้นคุณควรกลับมาดูแลจิตใจเป็นครั้งคราว  อย่าละทิ้งการดูแลภายใน ร่างกายที่แข็งแรงควรมาพร้อมกับจิตวิญญาณที่แข็งแรงเช่นกัน จงใช้เวลาในแต่ละวันฝึกฝนที่จะหายใจช้า ๆ ฝึกโยคะหรือเพ่งเสียง “โอม” อย่างผ่อนคลาย ในเมื่อคุณได้รับพร คุณควรจะช่วยเหลือผู้อื่นที่มีปัญหาสุขภาพกายใจเท่าที่ทำได้",
    contentSubtitleLove: "ความรัก Wholeness ทั้งหมดทั้งปวง",
    contentLove:
      "การมองแบบแยกส่วนบางครั้งก็ปกปิดปัญหาจริง ๆ หรือทำให้เกิดปัญหาเพิ่ม แม้คุณอาจมีเรื่องหงุดหงิดเล็ก ๆ น้อย ๆ อยู่บ่อยครั้ง เขาหรือเธออาจทำอะไรงี่เง่าหรือขัดใจคุณ แต่ขอให้ย้อนกลับไปมองในภาพรวมของความสัมพันธ์ หากมันงดงามก็ขอให้ดื่มด่ำกับภาพนั้นแล้วรู้สึกสำนึกขอบคุณ หากมันแย่เสียยิ่งกว่าดี จงลองมองหาโครงสร้างบางอย่างที่ก่อให้เกิดปัญหา ถ้าคุณค้นพบและแก้ไขได้ ชีวิตรักของคุณก็จะยืนยาวอย่างที่ควรเป็น",
    contentSubTitleMoney: "การเงิน Openness ความเปิดกว้าง",
    contentMoney:
      "เช่นเดียวกับคนราศีมังกร นี่เป็นช่วงเวลาที่คุณจะได้ทบทวนสิ่งที่ทำ คุณอาจพบความผิดพลาดที่ไม่รู้มาก่อนด้านการเงิน หรืออาจพบความผิดปกติบางอย่าง และเมื่อทบทวนดีแล้ว คุณค่อยคิดว่าจะทำอย่างไรต่อ ลองรับฟังความคิดเห็นจากหลาย ๆ คนโดยไม่จำเป็นจะต้องเป็นผู้เชี่ยวชาญเท่านั้น แต่ลองฟังอย่างเปิดใจจากคนใกล้ตัวหรือคนที่ห่วงใย ก่อนที่คุณจะสรุปและแก้ไข",
    contentSubTitleJob: "การงาน Guidance ชี้แนะ",
    contentJob:
      "อย่าอายที่จะขอคำแนะนำจากใคร แม้ว่าคนนั้นจะเป็นคนที่อ่อนอาวุโสหรือไม่ได้มีสถานภาพเท่าคุณ ปัญญาและความเข้าใจเรื่องงานไม่ได้อยู่ที่ปริญญาเพียงอย่างเดียว ยิ่งช่วงนี้ดูเหมือนคุณต้องการคำแนะนำเพิ่มมากขึ้น  ที่สำคัญเมื่อได้รับคำแนะนำที่หลากหลายแล้ว คุณไม่ควรลืมที่จะขอคำแนะนำจากภายในของตัวเองด้วย ว่าคุณต้องการอะไร สิ่งใดคือคุณค่าในงานที่คุณแสวงหา",
  },
  {
    linkId: "aries45806",
    bgColor: "#BE0108",
    zodiacName: "เมษ",
    tarotImage: [tarot_13, tarot_14, tarot_15, tarot_16],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีเมษ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 13 เมษายน - 14 พฤษภาคม)",
    contentSubtitleHealth: "สุขภาพ Positive Outlook ทัศนคติเชิงบวก",
    contentHealth:
      "แม้ว่าในปีที่ผ่านมาทุกอย่างจะดูแย่และไม่มีทีท่าว่าจะดีขึ้น แต่อยากให้คุณจำไว้ว่า สิ่งต่าง ๆ ก็เป็นเช่นนั้นของมัน  เช่นเดียวกับใบหน้าจำนวนมากมายของพระคเณศ ซึ่งแสดงให้เราเห็นถึงอารมณ์ความรู้สึกของเราต่อสถานการณ์ต่าง ๆ สุขภาพของเราก็เช่นเดียวกัน เราอาจมีทั้งช่วงที่สุขภาพย่ำแย่ หรือดีสุด ๆ ในปีนี้ แต่ไม่ว่าอย่างไร มุมมองต่อเรื่องสุขภาพดูเหมือนจะเป็นสิ่งที่เราละเลยไป พระคเณศอาจกำลังกระซิบว่า คุณควรกลับมาใส่ใจต่อมุมมองที่คุณมีในเรื่องสุขภาพด้วย ไม่ว่าอะไรจะเกิดขึ้นก็ตาม",
    contentSubtitleLove: "ความรัก Energetic Movement ความเคลื่อนไหวอย่างมีพลัง",
    contentLove:
      "คุณอาจกำลังอยู่ในช่วงเวลาของความเปลี่ยนแปลงและพัฒนาความสัมพันธ์ นี่เป็นช่วงเวลาที่สำคัญมาก ๆ ไม่ว่าคุณเพิ่งเริ่มมีความรักหรือคบหากันมานาน บางครั้งคุณอาจหลงลืมไปว่าเป้าหมายสูงสุดในความรักและความสัมพันธ์นั้นคืออะไร ขอให้คุณกลับมาตั้งสติอีกครั้ง แน่นอนว่าการเปลี่ยนแปลงย่อมต้องการพลังและความมุ่งมั่น เช่นเดียวกับการร่ายรำอันทรงพลังของพระคเณศ ส่วนคนโสดอาจเป็นช่วงเวลาที่จะได้พบอะไรใหม่ ๆ ",
    contentSubTitleMoney: "การเงิน Fulfillment สมปรารถนา",
    contentMoney:
      "พระคเณศอาจกำลังแนะนำให้คุณศึกษาหาความรู้มากกว่านี้หากคุณต้องการจะประสบความสำเร็จด้านการเงิน เช่นเดียวกับพระองค์ที่มักอ่านคัมภีร์อยู่เสมอ อย่าเพิ่งรีบร้อน คุณจะประสบความสำเร็จทางการเงินอย่างแน่นอนหากคุณมีความรู้ที่สั่งสมไว้มากพอ และจงอย่าลืมว่า แม้แต่ตัวความรู้นั้นเองก็เป็นสินทรัพย์อันมีค่ามหาศาล อย่ามีเงินแต่โง่! เพราะเงินเหล่านั้นจะสูญสลายไปอย่างรวดเร็ว",
    contentSubTitleJob: "การงาน Healing การเยียวยา",
    contentJob:
      "พักสักนิดไหม ในสภาพเศรษฐกิจที่แย่ขนาดนี้ คุณอาจวุ่นอยู่กับการแก้ปัญหา นั่นอาจทำให้คุณเหนื่อยล้ามากกว่าครั้งไหน ๆ ในชีวิต ขอเพียงช่วงเวลาเล็ก ๆ ที่คุณจะเยียวยาหัวใจของคุณ คุณอาจอยู่นิ่ง ๆ แล้วกลับมาตระหนักถึงความรักและความอ่อนโยนที่คุณสามารถมอบให้ตัวคุณเอง พระคเณศทรงพักบนนกยูงซึ่งเป็นสัญลักษณ์ของความแช่มช้าทว่าสง่างาม และพระองค์อาจบอกให้คุณมาพักด้วยกันอยู่",
  },
  {
    linkId: "taurus36588",
    bgColor: "#1B492D",
    zodiacName: "พฤษภ",
    tarotImage: [tarot_17, tarot_18, tarot_19, tarot_20],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีพฤษภ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 15 พฤษภาคม – 14 มิถุนายน)",
    contentSubtitleHealth: "สุขภาพ Relaxation การผ่อนพัก",
    contentHealth:
      "ภารกิจที่มากมายในชีวิตทำให้คุณเกิดความเครียด ความเศร้า ท้อแท้ และนั่นย่อมจะส่งผลต่อสุขภาพอย่างแน่นอน ดูเหมือนพระคเณศอยากให้คุณพักนะ คุณต้องไม่คิดว่าการพักเป็นเรื่องเสียเวลาหรือไม่มีประโยชน์ มันควรถูกบรรจุลงในตารางชีวิตของคุณ แต่การพักไม่ได้เป็นแค่การนอนนิ่ง ๆ หรือหนีจากอะไร มันเป็นศิลปะของการไม่ตอบสนองต่ออารมณ์และสิ่งเร้าต่าง ๆ ที่จะพาคุณกลับไปสู่วังวนเดิม และถ้าคุณได้พัก สุขภาพกายใจของคุณจะดีขึ้นอย่างแน่นอน",
    contentSubtitleLove: "ความรัก Promise of The Future คำมั่นแห่งอนาคต",
    contentLove:
      "นี่อาจเป็นช่วงเวลาเปลี่ยนผ่านที่สำคัญในชีวิต คุณกำลังจะได้เรียนรู้อะไรใหม่ ๆ นั่นทำให้คุณต้องมองไปข้างหน้า หากคุณมีคนรัก อะไรคือสิ่งที่คุณอยากทำร่วมกันกับคนรัก อะไรคือฝันที่มีร่วมกัน คุณคงต้องกลับมาระลึกถึงมันใหม่อีกครั้ง หากคุณยังเป็นคนโสด อนาคตคือสิ่งที่คุณเลือกเอง ขอให้มองไปข้างหน้าอย่างที่องค์พระคเณศทำ",
    contentSubTitleMoney: "การเงิน Clear Your Energy สลายพลังของคุณ",
    contentMoney:
      "คุณอาจกำลังกลัวการเปลี่ยนแปลงและการออกจากคอมฟอร์ตโซน  แต่ความสำเร็จจะเกิดขึ้นเมื่อผ่านอุปสรรคไปแล้ว พระคเณศเป็นผู้ขจัดอุปสรรค ซึ่งแปลว่าพระองค์ต้องสลายพลังงานที่ไม่ดีออกไป คุณอาจจำเป็นต้องสลายพลังงานไม่ดีที่วนเวียนอยู่รอบ ๆ ตัวคุณเช่นกัน  แม้แต่ทัศนคติของคุณเองด้วย ลองปรับเปลี่ยนและจัดระเบียบทางการเงินใหม่อีกครั้ง อะไร ๆ ก็อาจค่อย ๆ ดีขึ้น",
    contentSubTitleJob: "การงาน Perseverance วิริยะอุตสาหะ",
    contentJob:
      "งาที่หักของพระคเณศหมายถึงการเสียสละตนเองและการทำงานหนัก ในคัมภีร์เล่าว่าพระองค์ทรงใช้งาที่หักนี้ในการจดจารตำนานมหาภารตะ หากคุณเป็นคนที่ทำงานหนักมาตลอด อีกไม่นานนี้คุณจะได้รับผลสำเร็จจากการทำงานหนักอย่างแน่นอน ไม่ว่าจะเป็นงานเพื่อตนเองหรือส่วนรวม ปัญหาเกี่ยวกับงานและเศรษฐกิจของคุณกำลังจะหมดไป ส่วนผู้เริ่มต้นทำงานดูเหมือนความสำเร็จยังอยู่อีกไกล แต่อย่าเพิ่งท้อแท้",
  },
  {
    linkId: "gemini56626",
    bgColor: "#810005",
    zodiacName: "เมถุน",
    tarotImage: [tarot_21, tarot_22, tarot_23, tarot_24],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีเมถุน หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 15 มิถุนายน – 14 กรกฎาคม)",
    contentSubtitleHealth: "สุขภาพ Innocence บริสุทธิ์ไร้เดียงสา",
    contentHealth:
      "ความเจ็บป่วยบางอย่างของเราดูเหมือนจะมาจากความเครียดและรูปแบบการใช้ชีวิตแบบ “ผู้ใหญ่” ของเราเอง พระคเณศอาจบอกให้คุณกลับมาสู่ความบริสุทธิ์ไร้เดียงสา ซึ่งคือความเปิดกว้างต่อความรักที่มีอยู่ในจักรวาลนี้ แม้คุณอาจไม่พบคนที่ห่วงใย แต่จักรวาลนี้โอบกอดคุณอยู่ตลอดเวลา หากคุณบริสุทธิ์และเปิดกว้างมากพอคุณก็จะพบความรักนี้และได้รับการเยียวยา",
    contentSubtitleLove: "ความรัก Relaxation การผ่อนพัก",
    contentLove:
      "การทะเลาะและไม่เข้าใจกันเกิดขึ้นได้เป็นปกติ แต่หากคุณโหมไฟด้วยการพยายามบอกว่าใครถูกใครผิด หรือทุ่มอารมณ์เข้าใส่กัน ดูเหมือนความสัมพันธ์จะไปได้ยากยิ่งกว่าเดิม ขอให้แต่ละฝ่ายเปิดพื้นที่ให้กันและกัน สร้างช่องว่างเล็ก ๆ เพื่อแต่ละคนจะได้พักสักครู่หนึ่ง และไม่ตัดสินกัน แต่นั่นย่อมต้องการความไว้วางใจมากพอสมควร ส่วนคนโสด คุณอาจยังไม่ต้องรีบคิดเรื่องนี้ เพราะจักรวาลอาจยังไม่ได้หมุนใครมาให้คุณในช่วงนี้",
    contentSubTitleMoney: "การเงิน Promise of The Future คำมั่นแห่งอนาคต",
    contentMoney:
      "คุณอาจขาดการวางแผนระยะยาว และนั่นอาจทำให้คุณมีปัญหาทางการเงินอย่างไม่รู้ตัว  คนที่มีเงินเก็บอาจยังไม่ต้องรีบร้อนลงทุนในช่วงนี้ ส่วนคนที่กำลังมองหาโอกาสใหม่ ๆ ขอให้มองไกลยิ่งกว่าเดิม และพระคเณศอาจกำลังบอกคุณว่า บางทีความร่ำรวยที่สมบูรณ์คือความร่ำรวยที่สังคมมีร่วมกัน เพราะทุกครั้งที่สถานการณ์ทางสังคมมีปัญหา เราทุกคนก็ได้รับผลไปด้วย อย่าร่ำรวยโดยทิ้งใครไว้ข้างหลัง",
    contentSubTitleJob: "การงาน Harmony สอดคล้องกลมกลืน",
    contentJob:
      "คุณอาจต้องหาความสมดุลระหว่างงาน ความรัก และชีวิตส่วนตัวให้เจอ และเมื่อนั้นทุกอย่างก็จะค่อย ๆ สอดคล้องกลมกลืน ชีวิตคุณจะสงบสุขยิ่งกว่าเดิม คุณอาจมีปัญหากับเพื่อร่วมงานหรือเจ้านาย แต่การพูดคุยที่เหมาะสมอาจช่วยแก้สถานการณ์นี้ได้ และหากมันยังไม่ดีขึ้นคุณก็ไม่ต้องเสียใจไป คุณเพียงแค่กลับมามองให้เห็นคุณค่าของตนเอง จักระที่พระคเณศแสดงให้เห็น คือจักระแห่งความสมดุล ทุกครั้งที่ว้าวุ่นหรือสับสน ลองกลับมาหายใจช้า ๆ นึกถึงบริเวณท้องน้อยดู",
  },
  {
    linkId: "cancer24407",
    bgColor: "#1C0041",
    zodiacName: "กรกฎ",
    tarotImage: [tarot_25, tarot_26, tarot_27, tarot_28],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีกรกฎ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 15 กรกฎาคม – 15 สิงหาคม)",
    contentSubtitleHealth: "สุขภาพ Wholeness ทั้งหมดทั้งปวง",
    contentHealth:
      "หากคุณเจ็บป่วยอยู่ ความเจ็บป่วยของคุณกำลังย้ำเตือนให้คุณเห็นความเชื่อมโยงของสรรพสิ่ง ยิ่งหากคุณตระหนักว่าคุณไม่ได้เจ็บป่วยและทุกข์คนเดียวในโลกนี้ เมื่อนั้น ความรักและความกรุณาของคุณจะเยียวยาความเจ็บป่วยของสรรพสัตว์และตัวคุณเอง พระคเณศยังอาจมีคำแนะนำว่า ลองมองสุขภาพแบบองค์รวมดูสิ อาจช่วยให้มีสุขภาพที่ดีขึ้น หรือเป็นแนวทางรักษาคุณในอีกแบบที่น่าจะประสบผลสำเร็จได้",
    contentSubtitleLove: "ความรัก Guidance ชี้แนะ",
    contentLove:
      "แม้ว่าความรักจะเป็นประสบการณ์ส่วนตัว แต่หากคุณลองหาใครสักคนที่ไว้ใจได้เพื่อปรึกษาดู คุณอาจผ่านช่วงเวลายากลำบากนี้ไปได้	ส่วนคนที่ไม่อยากจะบอกใครหรือยังมีบางสิ่งที่กำลังลังเล ขอให้คุณลองกลับไปฟังเสียงหัวใจตัวเอง บางทีเสียงหัวใจคุณอาจกำลังชี้แนะอะไรบางอย่าง หรือคุณอาจขอคำชี้แนะจากพระคเณศหรือสิ่งศักดิ์สิทธิ์ที่คุณเคารพก็ได้นะ ท่านอาจกำลังรอคุณอยู่",
    contentSubTitleMoney: "การเงิน Capacity to Love ศักยภาพที่จะรัก",
    contentMoney:
      "คุณอาจกำลังเจอสถานการณ์ทางการเงินที่เปลี่ยนแปลงตลอดเวลา เอาแน่เอานอนไม่ได้ แต่คุณไม่ต้องกังวลเกินไปนัก ถ้ามันออกมาดีก็ขอให้คุณพร้อมที่จะแบ่งปันความสำเร็จนั้นกับคนรอบตัว และถ้ามันจะพัง คุณก็มีศักยภาพพอที่จะรับมือกับมันได้ เช่นเดียวกับงวงของพระคเณศที่กวัดแกว่งไปมาและยืดหยุ่นอ่อนนุ่ม พร้อมรองรับทุกสิ่งด้วยความเปิดกว้างและความรัก",
    contentSubTitleJob: "การงาน Drive แรงผลักดัน",
    contentJob:
      "ช่วงนี้คุณอาจมีแรงบันดาลใจใหม่ ๆ หรือมีลู่ทางใหม่ ๆ ที่น่าสนใจ แต่อย่าเพิ่งรีบร้อน ค่อย ๆ พิจารณาให้ดี คุณอาจหาที่ปรึกษาหรือนำพาคนอื่น ๆ ไปกับแรงบันดาลใจนี้ด้วย ไม่จำเป็นต้องทำมันอย่างโดดเดี่ยว อย่าใช้เงินเยอะนักในช่วงนี้ แต่จงใช้อย่างรอบคอบ และหากแรงบันดาลใจดังกล่าวจะเกิดประโยชน์แก่คนอื่น ๆ จงลงมือทำเลย!",
  },
  {
    linkId: "leo84968",
    bgColor: "#FFD35C",
    zodiacName: "สิงห์",
    tarotImage: [tarot_29, tarot_30, tarot_31, tarot_32],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีสิงห์ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 16 สิงหาคม – 16 กันยายน)",
    contentSubtitleHealth: "สุขภาพ Joy ปีติยินดี",
    contentHealth:
      "ความปีติยินดีคือภาวะที่หัวใจและจิตวิญญาณสอดประสานกัน ซึ่งมาจากการยอมรับสิ่งต่าง ๆ อย่างที่เป็น กระนั้นก็ไม่ต้องโหยหาอาลัยหรือโศกเศร้า สิ่งที่กั้นขวางสุขภาวะทางกายและใจของคุณ คือการไม่ยอมรับสภาวะทางอารมณ์และกลไกของความคิดต่าง ๆ รวมถึงความเสื่อมสลายเปลี่ยนแปลง แค่คุณเริ่มต้นที่จะยอมรับมัน ความปีติยินดีจะค่อย ๆ เกิดขึ้นอย่างเป็นธรรมชาติ พระคเณศมีพวงมาลัยที่แสดงให้เห็นความรื่นรมย์ของการยอมรับความงามง่าย ๆ อย่างความงามของดอกไม้ แม้ว่าอีกไม่นานมันจะเหี่ยวเฉาไป มีคำสอนว่า ถ้าคุณสุขภาพแข็งแรง จงยินดี เพราะคุณจะใช้มันทำประโยชน์ต่อผู้อื่นได้อีก ถ้าสุขภาพคุณแย่ จงยินดี เพราะผลกรรมของคุณกำลังลดลง",
    contentSubtitleLove:
      "ความรัก Personal Transformation การเปลี่ยนแปลงในตัวเอง",
    contentLove:
      "ความรักอาจกำลังเกิดขึ้น ความรักจะนำความเปลี่ยนแปลงมาสู่ตัวคุณ หากคุณไม่ยอมแพ้ที่จะเรียนรู้มันคุณจะเติบโตมากขึ้นเรื่อย ๆ พระคเณศกำลังชี้ไปเบื้องบนเพื่อบอกว่า จักรวาลกำลังลุ้นและเอาใจช่วยคุณอยู่เสมอ ไม่ว่าผลจะเป็นอย่างไรก็ตาม ไม่ว่าจะสมหวังหรือผิดหวัง การเติบโตของคุณจะเป็นก้าวสำคัญ ปาดน้ำตา เงยหน้าเอาไว้ มองไปเบื้องบน และยิ้มให้กับทุกประสบการณ์",
    contentSubTitleMoney: "การเงิน Understanding ความเข้าใจ",
    contentMoney:
      "คุณอาจต้องเปลี่ยนมุมมองต่อเรื่องการเงินเสียใหม่ เพราะมุมมองเก่าที่มีอยู่อาจทำให้คุณพลาดโอกาสไปหลายอย่าง	 ที่สำคัญ ปัญหาบางอย่างอาจเป็นแค่เส้นผมบังภูเขา ฉะนั้น ให้เวลากับการทบทวนบ้าง ที่สำคัญ เงินเป็นเครื่องมือเข้าถึงความสุข ตัวมันไม่ใช่ความสุข เลขมาก ๆ ในบัญชีธนาคารอาจทำให้อุ่นใจ แต่คุณจะใช้มันอย่างไรต่างหากคือสิ่งสำคัญ!",
    contentSubTitleJob: "การงาน Generosity ความเอื้อเฟื้อ",
    contentJob:
      "ความเอื้อเฟื้อมิได้หมายความว่าคุณจะต้องยิ้มแย้มแจ่มใสและแบ่งปันของกินกับเพื่อนร่วมงาน หรือพาเขาไปเลี้ยงเหล้า แต่ความเอื้อเฟื้อยังหมายถึงความเปิดกว้างและยอมรับในข้อผิดพลาดของคนอื่น เพื่อนร่วมงานหรือลูกน้องของคุณอาจทำความผิดอยู่บ่อย ๆ ในช่วงนี้ หรือแม้แต่เจ้านายของคุณก็ตาม ให้อภัยกับข้อผิดพลาดของเขาบ้างถ้ามันไม่ถึงกับทำให้บริษัทหรือที่ทำงานวิบัติ การสื่อสารที่ดีจะช่วยให้ทุกคนผ่านปัญหาเรื่องงานในช่วงนี้ไปได้",
  },
  {
    linkId: "virgo34251",
    bgColor: "#1C0041",
    zodiacName: "กันย์",
    tarotImage: [tarot_33, tarot_34, tarot_35, tarot_36],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีกันย์ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 17 กันยายน – 16 ตุลาคม)",
    contentSubtitleHealth:
      "สุขภาพ Personal Transformation การเปลี่ยนแปลงในตัวเอง",
    contentHealth:
      "คุณอาจต้องยอมปรับเปลี่ยนแบบแผนพฤติกรรมของคุณ เพื่อให้สุขภาพของคุณดีขึ้น มิฉะนั้นความป่วยไข้จะมาเยือนเร็ว ๆ นี้ เพราะคุณโหมทำสิ่งต่าง ๆ โดยไม่ได้ดูแลตัวเองมานานแล้ว แต่การปรับเปลี่ยนพฤติกรรมเป็นเรื่องยาก คุณอาจต้องมองไปไกล ๆ ถึงอนาคต หรือไม่ก็อาจหาใครสักคนมาช่วยคุณ  แม้ว่าการเปลี่ยนแปลงนี้จะใช้เวลา แต่มันจะให้ผลที่คุ้มค่าแน่นอน",
    contentSubtitleLove: "ความรัก Generosity ความเอื้อเฟื้อ",
    contentLove:
      "หัวใจที่เปิดกว้างจะนำคุณไปพบรักแท้ และการมีทัศนคติเกี่ยวกับความรักสำคัญไม่น้อยกว่าการพบใครสักคน คุณอาจเฝ้าถามตัวเองว่าทำไมความรักของฉันพังไม่เป็นท่าทุกครั้ง ทำไมฉันยังโสดอยู่จนบัดนี้ เคล็ดลับคือคุณควรเปิดกว้างและรับฟัง นี่เป็นช่วงเวลาที่ดี  หากคุณมีคนรักอยู่แล้วและกำลังมีปัญหากัน มอบพื้นที่ให้เขา ถ้าเขาหรือเธอรู้สึกถึงความเปิดกว้างที่คุณมอบให้ การคืนดีกันก็จะเกิดขึ้นได้ไม่ยากนัก",
    contentSubTitleMoney: "การเงิน Divine Support เทพเกื้อหนุน",
    contentMoney:
      "ไม่ว่าคุณจะมีปัญหาทางการเงินอย่างไรก็มักมีมือที่มองไม่เห็นช่วยคุณไว้ คุณอาจมีช่วงเวลายากลำบาก แต่คุณจะผ่านมันไปได้เสมอ ทว่านั่นไม่ใช่เพราะคุณแค่มีโชคหรือดวงดี การที่จักรวาลหรือเทพที่ไม่ใช่ป๋าเทพหรือสุเทพช่วยเหลือคุณอยู่ เพราะคุณได้รักษาเจตจำนงที่ดีและความอ่อนโยนไว้ในใจ ตราบใดที่คุณยังคงรักษาสิ่งเหล่านี้ไว้ คุณจะไม่มีทางตกต่ำ ที่สำคัญลองกลับมาเชื่อมโยงกับความศักดิ์สิทธิ์ที่คุณหลงลืม หรือคุณจะลองภาวนามนต์ “โอม” สั้น ๆ ง่าย ๆ ให้บ่อย ซึ่งจะช่วยให้การสื่อสารกับพลังที่ช่วยเหลือคุณไม่ขาดตอน",
    contentSubTitleJob: "การงาน Promise of The Future คำมั่นแห่งอนาคต",
    contentJob:
      "คุณอาจกำลังจะเลื่อนตำแหน่งหรือได้รับผิดชอบงานที่สำคัญมาก ๆ นั่นคงทำให้คุณลนลานพอสมควร อย่าพึงกังวลหรือตกใจ หรือมัวโฟกัสอยู่แต่เรื่องตรงหน้า จงมองไปไกล ๆ มองไปถึงปลายทางของงาน ที่สำคัญ งานของคุณจะสำเร็จได้ด้วยความร่วมมือของผู้อื่น จงให้เกียรติเขาและพาเขาไปกับคุณสู่ความสำเร็จนั้นด้วย อย่าเป็นเจ้านายหรือเพื่อนร่วมงานแบบที่ทิ้งใครไว้ข้างหลัง ถ้าคิดได้แบบนี้ ซื้อแชมเปญแช่เย็นรอไว้เลย",
  },
  {
    linkId: "libra87202",
    bgColor: "#57D4C4",
    zodiacName: "ตุลย์",
    tarotImage: [tarot_37, tarot_38, tarot_39, tarot_40],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีตุลย์ หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 17 ตุลาคม – 15 พฤศจิกายน)",
    contentSubtitleHealth: "สุขภาพ Innocence บริสุทธิ์ไร้เดียงสา",
    contentHealth:
      "เช่นเดียวกับคนราศีเมถุน ความเจ็บป่วยบางอย่างของเราดูเหมือนจะมาจากความเครียดและรูปแบบการใช้ชีวิตแบบ “ผู้ใหญ่” ของเราเอง พระคเณศอาจบอกให้คุณกลับมาสู่ความบริสุทธิ์ไร้เดียงสา ซึ่งคือความเปิดกว้างต่อความรักที่มีอยู่ในจักรวาลนี้ แม้คุณอาจไม่พบคนที่ห่วงใย แต่จักรวาลนี้โอบกอดคุณอยู่ตลอดเวลา หากคุณบริสุทธิ์และเปิดกว้างมากพอคุณก็จะพบความรักนี้และได้รับการเยียวยา",
    contentSubtitleLove: "ความรัก Achievement บรรลุผล",
    contentLove:
      "คงจะพิลึกน่าดูหากทายว่าทุกคนในราศีนี้จะประสบความสำเร็จด้านความรัก ใช่สิ มันจะเป็นไปได้อย่างไร มันย่อมต้องมีทั้งคนที่สมหวังและผิดหวัง ขอแสดงความยินดีกับคนที่สมหวังในความรัก ซึ่งคุณได้รับจากความทุ่มเทของคุณเอง แต่คนที่ไม่ได้สมหวังก็ไม่ต้องเสียใจ เพราะคุณเองก็จะบรรลุถึงอะไรบางอย่างเช่นกัน ทั้งหมดนี้ขึ้นอยู่กับมุมมองของคุณ คุณอาจได้เรียนรู้บางสิ่ง ได้เติบโตขึ้น มีพลังงานที่ดีบางอย่างอยู่รอบ ๆ ภาพพระคเณศโอบชายาไว้ทั้งสององค์ ซึ่งอาจตีความได้ว่า เมื่อคุณโอบรับไว้ทั้งสุขและทุกข์ คุณย่อมบรรลุถึงอะไรบางอย่างเสมอ แต่แนวโน้มโดยรวมเป็นไปในทางดีนะ ขอให้ใช้เวลาแห่งความสุขอย่างเต็มที่",
    contentSubTitleMoney: "การเงิน Surrender ศิโรราบ",
    contentMoney:
      "ศิโรราบหมายถึงการที่คุณไม่ต่อสู้ขัดขืนกับบางสิ่งที่คุณไม่อาจกำหนดบังคับได้ ชีวิตก็เป็นแบบนี้ ปัจจัยทางการเงินของคุณไม่ใช่สิ่งที่คุณจะควบคุมได้ทั้งหมดเสียแล้ว คุณผูกมันไว้กับกลไกที่คุณเองก็ไม่สามารถไปชี้นำ อย่างแรกที่คุณต้องทำคือยอมรับเสียก่อน จากนั้นคุณอาจต้องพัก ซึ่งหมายความว่าคุณยังไม่ควรริเริ่มอะไรใหม่ ปล่อยความท้าทายไปเถิดถ้ามันเสี่ยงเกินไป อย่าอิจฉาหรือวิ่งตามใครมากไป พระคเณศเรียกร้องให้คุณพักร่วมกับพระองค์ เพื่อให้คุณมีพลังและรอจังหวะที่ดีในอนาคต",
    contentSubTitleJob: "การงาน Belonging ซึ่งกันและกัน",
    contentJob:
      "เราต่างก็ไม่สมบูรณ์ เราจึงต้องพึ่งพาอาศัยกัน บางครั้งปัญหาในงานของคุณอาจเริ่มต้นจาก “อัตตา”เล็ก ๆ ของคุณเอง แต่ในเวลานี้คุณต้องการความร่วมมือร่วมใจ จึงเป็นเวลาที่คุณอาจต้องยอมลดอัตตาลงบ้าง และตระหนักว่า ผลสำเร็จมาจากความร่วมมือของทุกคน และคุณเองก็อาจต้องการการสนับสนุนจากเพื่อนร่วมงานอย่างมากในเวลานี้",
  },
  {
    linkId: "scorpio66223",
    bgColor: "#FFD35C",
    zodiacName: "พิจิก",
    tarotImage: [tarot_41, tarot_42, tarot_43, tarot_44],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีพิจิก หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 16 พฤศจิกายน – 15 ธันวาคม)",
    contentSubtitleHealth: "สุขภาพ Belonging ซึ่งกันและกัน",
    contentHealth:
      "หนูที่พระคเณศขี่อยู่เป็นสัญลักษณ์ของความมืดและความเห็นแก่ตัว สิ่งเหล่านี้นำมาซึ่งความป่วยไข้ คุณต้องการแสงสว่าง ความโล่งโปร่ง และการดูแลเอาใจใส่ที่ดี ช่วงนี้สุขภาพของคุณอาจถดถอย หากคุณต้องการความช่วยเหลือก็จงขอความช่วยเหลือจากผู้อื่น อย่าคิดว่าตนเองเป็นภาระ และเมื่อคุณหายดีแล้ว ก็ขอให้ช่วยเหลือดูแลผู้อื่นเช่นกัน",
    contentSubtitleLove: "ความรัก Priorities ความสำคัญลำดับแรก",
    contentLove:
      "อะไรคือความสำคัญอันดับแรกในความรักของคุณ? ตัวคุณเอง ความสุขของคุณ หรือคนรักและความสุขของเขา หรือความสุขที่จะมีร่วมกัน คุณคงต้องถามเรื่องนี้ให้ดี	หากคุณต้องการจะไปต่อในความสัมพันธ์ แต่จงอย่าลืมว่า ไม่ว่าคุณจะตอบยังไง สิ่งนั้นจะชี้นำคุณต่อ พระคเณศยกดอกบัวมาพิจารณาอาจกำลังบอกใบ้คุณว่า ที่จริงทุกวันที่ตื่นมาหายใจ นั่นคือ “ความสำคัญอันดับแรก” คุณควรจะมองเห็นความงามในรายละเอียดเล็ก ๆ ของชีวิต และนั่นจะช่วยให้คุณรู้ว่า อะไรควรมาก่อน",
    contentSubTitleMoney: "การเงิน Embrace โอบกอด",
    contentMoney:
      "พระคเณศอาจกำลังบอกให้คุณรู้ว่า หากคุณไว้วางใจให้ใครช่วยจัดการเรื่องการเงินในช่วงนี้ คุณควรจะไว้วางใจเขามากขึ้น ฟังเขามากขึ้น และหากเขาทำพลาดคุณก็ควรจะให้อภัย สถานการณ์รอบ ๆ ตัวคุณดูเหมือนจะไม่ง่าย บางครั้งคนที่คุณต้องการให้เขาช่วยก็ต้องการการสนับสนุนจากคุณเช่นกัน การทำงานเป็นทีมและการส่งเสริมกันจะเป็นหัวใจสำคัญในห้วงเวลานี้",
    contentSubTitleJob: "การงาน Divine Support เทพเกื้อหนุน",
    contentJob:
      "คุณอาจได้รับข่าวดี เพราะงานของคุณกำลังมีใครสักคนสนับสนุน บางทีก็เป็นความช่วยเหลือที่คาดไม่ถึงหรือจากใครที่มองไม่เห็น แต่คุณจำต้องแยกแยะระหว่างระบบอุปถัมภ์ที่ฉ้อฉลกับความช่วยเหลือที่ถูกที่ควร เพราะมิฉะนั้น ผลร้ายจะย้อนกลับมาสู่คุณในภายหลัง ที่สำคัญ คุณควรช่วยเหลือส่งเสริมเพื่อนร่วมงานอย่างเงียบ ๆ บ้างถ้ามีโอกาส และนั่นจะช่วยให้องค์กรของคุณรุ่งเรือง",
  },
  {
    linkId: "sagittarius85408",
    bgColor: "#FF8434",
    zodiacName: "ธนู",
    tarotImage: [tarot_45, tarot_46, tarot_47, tarot_48],
    contentTitle:
      "(ผู้ที่ลัคนาสถิตราศีธนู หรือหากไม่รู้อนุโลมใช้กับผู้ที่เกิดในระหว่างวันที่ 16 ธันวาคม – 14 มกราคม)",
    contentSubtitleHealth: "สุขภาพ Nurture บำรุงหล่อเลี้ยง",
    contentHealth:
      "สุขภาพของคุณกำลังจะดีขึ้นราวกับได้รับการบำรุงเลี้ยงดูจากแม่ อย่าหวังว่าอาหารเสริมสรรพคุณมหัศจรรย์หรือทางลัดวิเศษจะช่วยคุณได้ คุณแค่ดูแลสุขภาพอย่างที่ควรเป็น และคุณควรยอมรับการดูแลจากคนอื่น ๆ ที่รักคุณด้วย เราต่างต้องการการดูแลกันและกัน หล่อเลี้ยงกันและกัน นอกจากการดูแลทางกาย อย่าลืมการดูแลด้านจิตใจด้วย",
    contentSubtitleLove: "ความรัก Capacity to Love ศักยภาพที่จะรัก",
    contentLove:
      "อย่ากลัวที่จะรักใครสักคนหรือบางสิ่ง คุณมีศักยภาพอย่างเต็มเปี่ยมที่จะรัก แม้ว่าคุณจะเคยมีประสบการณ์ที่ไม่ดีนักเกี่ยวกับความรัก แม้คุณจะรู้สึกว่าหัวใจคุณแห้งผากและแข็งกระด้าง แต่เชื่อเถอะว่ามันมีธรรมชาติที่อ่อนนุ่มและอ่อนไหวเหมือนงวงของพระคเณศ ที่ต้องทำคือลองเปิดใจดู หากมีใครสักคนเข้ามาในช่วงนี้ ยังไม่ต้องรีบตัดสินใจ ปล่อยให้ท่วงทำนองเพลงรักบรรเลงไปตามจังหวะของมัน ยินดีด้วยที่คุณจะได้สัมผัสความอ่อนโยนและอ่อนไหวของหัวใจอีกครั้ง",
    contentSubTitleMoney: "การเงิน Blessings อำนวยพร",
    contentMoney:
      "เพียงแค่คุณมีความชัดเจนว่าคุณต้องการอะไร พระคเณศหรือจักรวาลกำลังอวยพรคุณ ด้วยความมีโชค ความรุ่มรวยและความสำเร็จ แม้เป้าหมายอาจดูยากแต่ก็เป็นไปได้ เพียงแต่พรที่คุณได้รับไม่ใช่สิ่งที่ระเบิดตูมตามแล้วจางหายไป พระคเณศกำลังค่อย ๆ โปรยดอกไม้มาให้คุณอย่างต่อเนื่อง ช้า ๆ ทีละนิด นั่นคือคุณจะค่อย ๆ ได้รับผลที่ต้องการ และอาจทำให้คุณตระหนักถึงพรสูงสุดว่า อะไรคือเป้าหมายที่แท้จริงในชีวิตและจิตวิญญาณของคุณ",
    contentSubTitleJob: "การงาน Energetic Movement  ความเคลื่อนไหวอย่างมีพลัง",
    contentJob:
      "เริ่มได้เลยอย่ามัวรีรอ! งานหรือโปรเจคที่คุณคั่งค้างไว้ควรจัดการเสียที รวมถึงสิ่งใหม่ ๆ ที่วางแผนไว้แล้วด้วย สิ่งเหล่านี้จะประสบความสำเร็จ  พระคเณศร่ายรำด้วยพลังเช่นเดียวกับคุณที่ต้องร่ายรำกับสถานการณ์และความท้าทายต่าง ๆ ที่จะเกิดขึ้น แม้จะต้องโฟกัสเป้าหมาย แต่อย่าลืมที่จะทำงานด้วยความรื่นรมย์ คุณจะรำอย่างไรถ้าไม่มีวงดนตรี อย่าลืมสร้างทีมสนับสนุนที่ให้ใจกับคุณ และนั่นเกิดจากการที่คุณให้ใจกับเขาก่อน",
  },
];

export default data;
