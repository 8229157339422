import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from 'react-i18next';
import en from './config/i18n/lang/en.json';
import th from './config/i18n/lang/th.json';

const resources = {
  en: en,
  th: th,
};

const languageDetector = {
	type: 'languageDetector',
	async: true, // async detection
	detect: async cb => {
		const lang = await localStorage.getItem('lang');
		if (lang) {
			return cb(lang);
		} else {
			return cb('th');
		}

		// return cb(DeviceInfo.getDeviceLocale().replace('_', '-'));
	},
	init: () => {},
	cacheUserLanguage: () => {},
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(languageDetector)
  // pass the i18n instance to the react-i18next components.
  // Alternative use the I18nextProvider: https://react.i18next.com/components/i18nextprovider
  .use(reactI18nextModule)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'th',
    resources,
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    // special options for react-i18next
    // learn more: https://react.i18next.com/components/i18next-instance
    react: {
      wait: true,
    },
  });

export default i18n;
