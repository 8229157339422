import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
	CardImageHeader,
	BreadcrumbTitel,
	Input
} from './../../components';
import imageContactUs from '../../assets/images/contactus/contact-us.jpg';
import { Row, Col } from 'react-bootstrap';
import { FormControl, Button } from 'react-bootstrap';
import { Mutation } from 'react-apollo';
import contactUsMutation from './graphql/mutation/contactUs';
import color from '../../config/color';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";

import { withNamespaces } from "react-i18next";

window.recaptchaOptions = {
  lang: 'th',
};

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
	color: #000000;
	margin-top: 60px;
	
	@media(max-width: 768px) {
		font-size: 20px;
		margin-top: 20px;
	}
`;

const Container = styled(Row)`
	margin-top: 20px;

	& input {
		margin-bottom: 16px;
	}

	& button {
		height: 48px;
		margin-top: 20px;
		box-sizing: border-box;
	}
`;

const MessageContainer = styled.div`
	font-size: 18px;
	color: ${color.Primary};
	padding-bottom: 10px;

	& i {
		margin-right: 5px;
		font-size: 16px;
	}
`;

const Wrapper = styled.div`
	padding-top: 120px;
	padding-bottom: 60px;

	@media(max-width: 767px) {
		padding-top: 15px;
		padding-bottom: 20px;
	}
`;

class ContactUs extends React.Component {
	constructor(props, context) {
		super(props, context);
				
		this.state = {
			name: '',
			lastName: '',
			email: '',
			phone: '',
			msg: '',
			status: false,
			loading: false,
			
			//recaptcha
			callback: "not fired",
      valueRecaptCha: '',
      loadRecaptCha: false,
      expired: "false",
		};

		this._reCaptchaRef = React.createRef();
	}

	componentDidMount() {
    setTimeout(() => {
      this.setState({ loadRecaptCha: true });
    }, 1500);
    console.log("didMount - reCaptcha Ref-", this._reCaptchaRef);
	}
	
	handleChangeReCAPTCHA = value => {
    this.setState({ valueRecaptCha: value });
    // if value is null recaptcha expired
    if (value === null) this.setState({ expired: "true" });
	};
	
	asyncScriptOnLoad = () => {
    this.setState({ callback: "called!" });
    console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  };
	
	handleSendMsg(contactUs) {
		this.setState({status: false, loading: true}, () => contactUs())
	}

	checkButton() {
		if(!this.state.loading && this.state.name && this.state.lastName && this.state.email && this.state.phone && this.state.msg && this.state.valueRecaptCha) {
			return false;
		}

		return true;
	}
	
	render() {
		const menu = [
			{
				active: true,
				link: '#',
				title: this.props.t('header:contactUs'),
			},
		];

		return (
			<div>
				<Helmet>
					<title>Contact | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="สอบถามบริการขนส่ง และการใช้งานติดต่อเราได้ทุกเรื่อง" />
				</Helmet>

				<Header />
				<BreadcrumbTitel menu={menu} />
				<Wrapper>
					<CardImageHeader
						img={imageContactUs}
						title={this.props.t('header:contactUs')}
						subtitle={this.props.t('contactus:inquireForDeliveryServiceOrUsageContactUsAnytime')}
					/>
					<div className="container">
						<Row>
							<Col md={12} className="text-center">
								<Title>{this.props.t('contactus:messageUs')}</Title>
							</Col>
						</Row>

						<Row>
							<Col lg={8} lgOffset={2} md={8} mdOffset={2} sm={10} smOffset={1} xs={12}>
								<Container>
									{ this.state.status && (<Col md={12}><MessageContainer><i className="fas fa-check-circle"></i> {this.props.t('contactus:messageSent')}</MessageContainer></Col>) }
									<Col md={6} sm={6}><Input type="text" name="name" placeholder={this.props.t('contactus:name')} value={this.state.name} onChange={event => this.setState({name: event.target.value})}/></Col>
									<Col md={6} sm={6}><Input type="text" name="lastName" placeholder={this.props.t('contactus:surname')} value={this.state.lastName} onChange={event => this.setState({lastName: event.target.value})} /></Col>
									<Col md={6} sm={6}><Input type="text" name="email" placeholder={this.props.t('contactus:emailAddress')} value={this.state.email} onChange={event => this.setState({email: event.target.value})} /></Col>
									<Col md={6} sm={6}><Input type="text" name="phone" placeholder={this.props.t('contactus:mobileNo')} value={this.state.phone} onChange={event => this.setState({phone: event.target.value})} /></Col>
									<Col md={12} sm={12}><FormControl name="msg" componentClass="textarea" value={this.state.msg} onChange={event => this.setState({msg: event.target.value})} placeholder={this.props.t('contactus:subject')} style={{ height: 150 }} /></Col>

									{ this.state.loadRecaptCha && (
										<Col md={12} style={{textAlign: 'center', marginTop: 16}}>
											<ReCAPTCHA
												style={{ display: "inline-block" }}
												ref={this._reCaptchaRef}
												sitekey="6LfO6ZIUAAAAABT9fFlQhM7EyRGJhoR8i2I2SS-w"
												onChange={this.handleChangeReCAPTCHA}
												asyncScriptOnLoad={this.asyncScriptOnLoad}
											/>
										</Col>
									)}

									<Col lg={6} lgOffset={3} md={6} mdOffset={3} sm={6} smOffset={3} xs={12} style={{textAlign: 'center'}}>
										<Mutation mutation={contactUsMutation} 
											variables={{
												name: this.state.name,
												lastName: this.state.lastName,
												email: this.state.email,
												phone: this.state.phone,
												msg: this.state.msg,
											}}

											update={(cache, { data }) => {
												if(data.contactUs.succeed) {
													this.setState({status: true, loading: false}, () => {
														this.setState({name: '', lastName: '', email: '', phone: '', msg: ''})
														this._reCaptchaRef.current.reset();
													})
												}
											}}
										>
											{
												(contactUs) => {
													return (
														<Button
														disabled={this.checkButton()}
														block bsStyle="primary" onClick={() => this.handleSendMsg(contactUs)}>
															{
																this.state.loading ? this.props.t('contactus:message2') : this.props.t('contactus:message')	
															}
														</Button>
													)
												}
											}
										</Mutation>
									</Col>
								</Container>
							</Col>
						</Row>
					</div>
				</Wrapper>
        <Footer />
			</div>
		);
	}
}

export default withNamespaces()(ContactUs);
