import React from "react";
import { Route, Switch } from "react-router-dom";

import App from "./view/App";
import AppMobile from "./view/AppMobile";
import NotFound from "./view/NotFound";
import About from "./view/About";
import Policy from "./view/Policy";
import Complain from "./view/Complain";
import Standard from "./view/Standard";
import Termscondition from "./view/Termscondition";
import Help from "./view/Help";
import Pricing from "./view/Pricing";
import ContactUs from "./view/ContactUs";
import ApplicationDownload from "./view/ApplicationDownload";
import NotiDownloadApp from "./view/NotiDownloadApp";
import SpecialMichelin from "./view/SpecialMichelin";
import SpecialDealSCB from "./view/SpecialDealSCB";
import GiztixBusiness from "./view/GiztixBusiness";
import Insurance from "./view/Insurance";
import MuTeLu from "./view/MuTeLu";

// Career
import LandingCareer from "./view/Career/screens/landing";
import GiztixDepartment from "./view/Career/screens/giztixDepartment";
import GiztixDepartmentFA from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentFA";
import GiztixDepartmentCBD from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentCBD";
import GiztixDepartmentHR from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentHR";
import GiztixDepartmentMAX from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentMAX";
import GiztixDepartmentOM from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentOM";
import GiztixDepartmentSM from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentSM";
import GiztixDepartmentGM from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentGM";
import GiztixDepartmentDEV from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentDEV";
import GiztixDepartmentUXUI from "./view/Career/screens/giztixDepartment/screens/giztixDepartmentUXUI";

import Subscribe from "./view/Subscribe";

import client from "./config/client";
import CookiePolicy from "./view/CookiePolicy";
import PrivacyNotice from "./view/PrivacyNotice";
import PrivacyPolicy from "./view/PrivacyPolicy";

class Routes extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: props.width };
  }
  componentWillMount() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    if (this.state.width < 768) {
      return (
        <Switch>
          <Route path="/mutelu2022/:name" component={MuTeLu} />
          <Route exact path="/" component={AppMobile} />
          <Route path="/aboutus" component={About} />
          <Route path="/pricing" component={Pricing} />
          <Route path="/contactus" component={ContactUs} />
          <Route path="/helpcenter" component={Help} />

          <Route path="/privacy-notice" component={PrivacyNotice} />
          <Route path="/cookie-policy" component={CookiePolicy} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/policy" component={Policy} />
          <Route path="/policy-cookies" component={Policy} />
          <Route path="/complain" component={Complain} />
          <Route path="/standard" component={Standard} />
          <Route path="/termscondition" component={Termscondition} />
          <Route path="/insurance" component={Insurance} />

          <Route path="/career" component={LandingCareer} />
          <Route path="/giztixdepartment" component={GiztixDepartment} />
          <Route path="/giztixdepartmentFA" component={GiztixDepartmentFA} />
          <Route path="/giztixdepartmentCBD" component={GiztixDepartmentCBD} />
          <Route path="/giztixdepartmentHR" component={GiztixDepartmentHR} />
          <Route path="/giztixdepartmentMAX" component={GiztixDepartmentMAX} />
          <Route path="/giztixdepartmentOM" component={GiztixDepartmentOM} />
          <Route path="/giztixdepartmentSM" component={GiztixDepartmentSM} />
          <Route path="/giztixdepartmentGM" component={GiztixDepartmentGM} />
          <Route path="/giztixdepartmentDEV" component={GiztixDepartmentDEV} />
          <Route path="/giztixdepartmentUXUI" component={GiztixDepartmentUXUI} />
          <Route path="/subscribe" component={Subscribe} />

          <Route path="/notidownloadapp" component={NotiDownloadApp} />
          <Route
            path="/scgcbmlogin"
            render={() => {
              window.location.href = "https://cbm.giztix.com/scgcbmlogin";
              return null;
            }}
          />
          <Route
            path="/nichireilogin"
            render={() => {
              window.location.href = "https://nichirei.giztix.com/nichireilogin";
              return null;
            }}
          />
          <Route path="*" component={NotiDownloadApp} />
        </Switch>
      );
    }
    return (
      <Switch>
        <Route path="/mutelu2022/:name" component={MuTeLu} />
        <Route exact path="/" component={App} />
        <Route path="/aboutus" component={About} />
        <Route path="/policy" component={Policy} />
          <Route path="/policy-cookies" component={Policy} />
        <Route path="/privacy-notice" component={PrivacyNotice} />
        <Route path="/cookie-policy" component={CookiePolicy} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/complain" component={Complain} />
        <Route path="/standard" component={Standard} />
        <Route path="/termscondition" component={Termscondition} />
        <Route path="/helpcenter" component={Help} />
        <Route path="/pricing" component={Pricing} />
        <Route path="/contactus" component={ContactUs} />
        <Route path="/application" component={ApplicationDownload} />
        <Route path="/notidownloadapp" component={NotiDownloadApp} />
        <Route path="/specialdeal" component={SpecialMichelin} />
        <Route path="/specialdeal-scb" component={SpecialDealSCB} />
        <Route path="/giztixbusiness" component={GiztixBusiness} />
        <Route path="/insurance" component={Insurance} />
        <Route path="/career" component={LandingCareer} />
        <Route path="/giztixdepartment" component={GiztixDepartment} />
        <Route path="/giztixdepartmentFA" component={GiztixDepartmentFA} />
        <Route path="/giztixdepartmentCBD" component={GiztixDepartmentCBD} />
        <Route path="/giztixdepartmentHR" component={GiztixDepartmentHR} />
        <Route path="/giztixdepartmentMAX" component={GiztixDepartmentMAX} />
        <Route path="/giztixdepartmentOM" component={GiztixDepartmentOM} />
        <Route path="/giztixdepartmentSM" component={GiztixDepartmentSM} />
        <Route path="/giztixdepartmentGM" component={GiztixDepartmentGM} />
        <Route path="/giztixdepartmentDEV" component={GiztixDepartmentDEV} />
        <Route path="/giztixdepartmentUXUI" component={GiztixDepartmentUXUI} />

        <Route path="/subscribe" component={Subscribe} />

        <Route
          path="/scgcbmlogin"
          render={() => {
            window.location.href = "https://cbm.giztix.com/scgcbmlogin";
            return null;
          }}
        />
        <Route
          path="/nichireilogin"
          render={() => {
            window.location.href = "https://nichirei.giztix.com/nichireilogin";
            return null;
          }}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    );
  }
}
export default Routes;
