import React, { Component } from "react";
import { Header, Footer } from "./../../../../../../components";
import {TextHeader } from "./components";
import styled from "styled-components";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link as LinkScroll, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import "./style.css";

//ContactContainer
import bgContact from "../../../../../../assets/images/career/1366x455/Corporate.jpg";

import "./style.css";

import { withNamespaces } from "react-i18next";

AOS.init({
  delay: 100
});

const Container = styled.div``;

const ContactContainer = styled.div`
 background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        /* bottom, image */
        url(${bgContact});
        text-shadow: 0px 3px 6px #000;
  height: 460px;
  padding: 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  .container {
    text-align: center;
    color: #fff;
    padding: 120px;
    position: relative;
    height: 140px;

    .check-button {
      height: 40px;
      width: 140px;
      font-size: 24px;
    }
  }
  @media only screen and (max-width: 767px) {
    .container {
      text-align: center;
      color: #fff;
      padding:60px;
      position: relative;
      height: 140px;
    }
  }
`;

const DeviceContainer = styled.div`
  position: relative;
  margin-top: 30px;

  & .device-content {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #fafafafa;
    padding: 40px;

    & .download-container {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
    }

    & .download-image-grcode {
      & img {
        width: 120px;
      }
    }

    & .download-line-vertical {
      width: 1px;
      background-color: #e3e3e3;
      margin: 0 20px;
    }

    & .download-image-store-container {
      display: flex;
      flex-direction: column;

      & img {
        width: 100%;
        max-width: 175px;
        margin-bottom: 10px;
      }
    }
  }

  & .device-image {
    margin-top: 50px;
    width: 260%;
    position: absolute;
    right: 0;

    & img {
      width: 100%;
    }
  }

  & .text-download {
    margin-top: 20px;
  }

  @media (max-width: 992px) {
    .device-image {
      margin-top: 100px;
      width: 220%;
    }
  }
`;


class GiztixBusiness extends Component {
  state = {
    isTop: true,
    onOpenModal: false
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>
          Corporate Business Development | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
            รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
          </title>
          <meta
            name="description"
            content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
          />
        </Helmet>
        <Header
          bgcolor={this.state.isTop ? "transparent" : "#000000"}
          border={false}
          color="white"
        />

        <ContactContainer>
          <div className="container">
            <TextHeader
              className="title"
              style={{ textAlign: "center" }}
              styleTitle={{
                marginBottom: 10,
                textAlign: "center",
                fontSize: 60,
                fontWeight: "bold",
                color: "#fff"
              }}
              title="Corporate Business Development"
            />
						<p style={{ textAlign: "center" }}>
            ทำงานสนุก เผือกทุกแผนก เพื่อบรรลุเป้าหมายทุกคนและองค์กร
          </p>
           
          </div>
        </ContactContainer>


        <div className="container" style={{marginTop: -70}}>
				<Element name="stepCheckPriceContainer" />
				<TextHeader
									styleTitle={{marginTop: 120 ,fontSize:30}}
									title="ตำแหน่งงาน"
								/>
        </div>

        <div className="statusJob">
          <p>
            Available Jobs in Corporate Business Development
            <br/>There are no current openings, please check back soon.
          </p>
        </div>

        <DeviceContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.5404450240753!2d100.60889471482976!3d13.685684990390254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d672e8097f671%3A0x8b3d7495efdf67d5!2sGIZTIX+HQ!5e0!3m2!1sth!2sth!4v1562662978115!5m2!1sth!2sth"
            style={{
              width: "100%",
              height: "300px",
              border: 0,
              marginBottom: -10
            }}
            allowfullscreen
          />
        </DeviceContainer>

        <Footer />
      </Container>
    );
  }
}

export default withNamespaces()(GiztixBusiness);
