import React, { Component } from "react";
import { Header, Footer, LazyImage } from "./../../../../components";
import { Cover, TextHeader } from "./components";
import styled from "styled-components";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Dotdotdot from "react-dotdotdot";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link as LinkScroll, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import "./style.css";



//StepSupport
import imageSupport from "../../../../assets/images/gtxBusiness/Icon/Support.svg";
import fullTransportService from "../../../../assets/images/gtxBusiness/Icon/fullTransportService.svg";
import SecurityService from "../../../../assets/images/gtxBusiness/Icon/SecurityService.svg";

//InvoiceContainer
import PaymentService from "../../../../assets/images/gtxBusiness/Icon/PaymentService.svg";
import SavePrice from "../../../../assets/images/gtxBusiness/Icon/SavePrice.svg";

//PriceContainer
import SaveCost from "../../../../assets/images/gtxBusiness/Icon/SaveCost.svg";
import ManageShipment from "../../../../assets/images/gtxBusiness/Icon/ManageShipment.svg";

import bgGiztix from '../../../../assets/images/career/1366x455/Giztix.jpg';

//ContactContainer
import bgContact from "../../../../assets/images/gtxBusiness/Picture/bgContact.png";

import color from "../../../../config/color";
import "./style.css";
import appUrl from "./../../../../config/appUrl";

//privilege Container
import imagePrivilege1 from "../../../../assets/images/career/378x213/Accouning.jpg";
import imagePrivilege2 from "../../../../assets/images/career/378x213/Corporate.jpg";
import imagePrivilege3 from "../../../../assets/images/career/378x213/HR.jpg";
import imagePrivilege4 from "../../../../assets/images/career/378x213/Max.jpg";
import imagePrivilege5 from "../../../../assets/images/career/378x213/Operation.jpg";
import imagePrivilege6 from "../../../../assets/images/career/378x213/Sale.jpg";
import imagePrivilege7 from "../../../../assets/images/career/378x213/Marketing.jpg";
import imagePrivilege8 from "../../../../assets/images/career/378x213/Developer.jpg";
import imagePrivilege9 from "../../../../assets/images/career/378x213/UxUi.jpg";


//StepCheckPriceContainer
import imageEasyBook from '../../../../assets/images/homepage/hover/easy-book.svg';
import imagePricing from '../../../../assets/images/homepage/hover/pricing.svg';
import imageTruckType from '../../../../assets/images/homepage/hover/truck-type.svg';
import imageTrustShipment from '../../../../assets/images/homepage/hover/trust-shipment.svg';

import imageEasyBookW from '../../../../assets/images/homepage/hover/easy-book-w.svg';
import imagePricingW from '../../../../assets/images/homepage/hover/pricing-w.svg';
import imageTruckTypeW from '../../../../assets/images/homepage/hover/truck-type-w.svg';
import imageTrustShipmentW from '../../../../assets/images/homepage/hover/trust-shipment-w.svg';

import { withNamespaces } from "react-i18next";

AOS.init({
  delay: 100
});

const Container = styled.div``;

const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -100px;
  width: 100%;

  & .text-button-more {
    color: #ffffff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    text-decoration: none;

    & i {
      font-size: 40px;
    }
  }

  @media (max-width: 992px) {
    bottom: 0px;
  }
`;

const StepSupportContainer = styled.div`
  text-align: center;
  margin-top: 30px;

  & .box-container {
    height: 310px;
    display: flex;
    align-items: center;
  }

  & .box {
    padding: 35px 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 280px;
    vertical-align: middle;
    display: block;
    transition: all 0.3s;
    margin: 0 auto;

    &:not(:last-child) {
      margin-right: 20px;
    }

    & .box-image {
      display: block;

      & img {
        width: 120px;
        height: 120px;
			}
			& i {
        font-size: 100px;
      }
    }

    & .box-title {
      margin-top: 30px;
      font-weight: 700;
      text-align: center;
      font-size: 26px;
      line-height: 1;

      &-subtitle {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        line-height: 1;
      }
    }
  }
`;

const InvoiceContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 40px;

  & .box-container {
    height: auto;
    margin-bottom: 40px;
    display: flex;
    align-items: top;
  }

  & .box-image {
    display: block;

    & img {
      width: 100px;
      height: 100px;
    }
  }

  & .box {
    padding: 0;
    padding-top: 10px;
    text-align: right;
    height: auto;
    display: block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0 auto;

    &:not(:last-child) {
      margin-right: 40px;
    }

    & .box-title {
      margin-top: 0;
      font-weight: 700;
      text-align: right;
      font-size: 26px;
      line-height: 1;

      &-subtitle {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        line-height: 1;
      }
    }
  }
`;

const PriceContainer = styled.div`
	margin-top: 0px;
	margin-bottom: 40px;

	& .box-container {    
		height: auto;
		margin-bottom: 40px;
		display: flex;
		align-items: top;
	}

	& .box-image {
		display: block;

		& img {
			width: 100px;
			height: 100px;
		}
	}

	& .box {
		padding: 0;
		padding-top: 10px;
		text-align: left;
		height: auto;
		display: block;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin: 0 auto;
		margin-left: 40px;

		&:not(:last-child) {
			margin-right: 40px;
		}

		& .box-title {
			margin-top: 0;
			font-weight: 700;
			text-align: left;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;


const GiztixContainer = styled.div`

background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        /* bottom, image */
        url(${bgGiztix});
  /* background-image: url(${bgGiztix}); */
  height: 455px;
  padding: 40px 0;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
	text-shadow: 0px 3px 6px #000;
	background-color: rgba(00,00,00,10);

  .container {
    text-align: center;
    color: #fff;
    padding: 120px;
    position: relative;
    height: 140px;

    .check-button {
      height: 40px;
      width: 140px;
      font-size: 24px;
    }
  }
`;

const ContactContainer = styled.div`
  background-image: url(${bgContact});
  height: 460px;
  padding: 40px 0;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  .container {
    text-align: center;
    color: #fff;
    padding: 120px;
    position: relative;
    height: 140px;

    .check-button {
      height: 40px;
      width: 140px;
      font-size: 24px;
    }
  }
	@media only screen and (max-width: 767px) {
    .container {
      text-align: center;
      color: #fff;
      padding:60px;
      position: relative;
      height: 140px;
    }
  }
`;

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
`;

const StepInvoiveContainer = styled.div`
  margin-top: 30px;
`;

const ColStepLeft = styled(Col)`
  padding-top: 50px;
  padding-right: 60px;
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-right: 30px;
  }
`;
const ColStepRight = styled(Col)`
  padding-left: 60px;
  padding-top: 50px;
  padding-bottom: 40px;
  border-left: 1px dashed #ddd;
  @media (max-width: 992px) {
    padding-left: 30px;
  }
`;

const PrivilegeContainer = styled.div`
  font-size: 24px;
  margin-top: 120px;

  & .privilege-cover {
    border-radius: 6px;
    border: 1px solid #fafafafa;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 16%);
		
    & img {
      width: 100%;
			border-radius: 6px 6px 0 0;
    }
  }
	& a {
      text-decoration: none;
      color: #000000;
      line-height: 2;
    }

  & .privilege-title {
    margin-top: 10px;
    font-weight: 700;
		text-align: center;
  }

  & .privilege-detail {
    color: #00000090;
    line-height: 1;
		text-align: center;
		padding-bottom: 20px;
  }

  & .privilege-button-more {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    & a {
      text-decoration: none;
      color: #000000;
      line-height: 1;
    }

    &:hover {
      font-weight: 700;
      border-bottom: 2px solid #d90101;
    }
  }
`;

const DeviceContainer = styled.div`
  position: relative;
  margin-top: 30px;

  & .device-content {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #fafafafa;
    padding: 40px;

    & .download-container {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
    }

    & .download-image-grcode {
      & img {
        width: 120px;
      }
    }

    & .download-line-vertical {
      width: 1px;
      background-color: #e3e3e3;
      margin: 0 20px;
    }

    & .download-image-store-container {
      display: flex;
      flex-direction: column;

      & img {
        width: 100%;
        max-width: 175px;
        margin-bottom: 10px;
      }
    }
  }

  & .device-image {
    margin-top: 50px;
    width: 260%;
    position: absolute;
    right: 0;

    & img {
      width: 100%;
    }
  }

  & .text-download {
    margin-top: 20px;
  }

  @media (max-width: 992px) {
    .device-image {
      margin-top: 100px;
      width: 220%;
    }
  }
`;

const Symbol = styled.div`
  display: inline-block;
  position: absolute;
  top: -10px;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: ${color.Primary};
`;

const StepCheckPriceContainer = styled.div`
	text-align: center;

	& .box-container {
		height: 310px;
		display: flex;
		align-items: center;
	}

	& .box {
		flex: 1;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		border: 1px solid #eeeeee50;
		padding: 35px 20px;
		background-color: #ffffff;
    box-sizing: border-box;
		border-radius: 5px;
		height: 280px;
		vertical-align: middle;

		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: all .3s;

    &:hover {
			height: 310px;
      background-image: linear-gradient(to bottom right, ${
				color.Primary
			}, #9D0000);
			box-shadow: 0px 5px 10px rgba(217, 1, 1, 0.2);
			color: #FFFFFF;
			line-height: 1;

			& .box-title-subtitle {
				display: block;
			}

			& .box-image {
				display: none;

				&-hover {
					display: block;
				}
			}
    }

		&:not(:last-child) {
			margin-right: 20px;
		}

		& .box-image {
			display: block;

			& img {
				width: 120px;
				height: 120px;
			}
			& i {
        font-size: 80px;
      }

			&-hover {
				display: none;

				& img {
					width: 120px;
					height: 120px;
				}
				& i {
        font-size: 100px;
      }
			}
		}

		& .box-title {
			margin-top: 30px;
			font-weight: 700;
			text-align: center;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: none;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

class GiztixBusiness extends Component {
  state = {
    isTop: true,
    onOpenModal: false
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>
            Giztix Department | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
            รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
          </title>
          <meta
            name="description"
            content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
          />
        </Helmet>
        <Header
          bgcolor={this.state.isTop ? "transparent" : "#000000"}
          border={false}
          color="white"
        />

        <GiztixContainer>
          <div className="container">
            <TextHeader
              className="title"
              style={{ textAlign: "center" }}
              styleTitle={{
                marginBottom: 10,
                textAlign: "center",
                fontSize: 60,
                fontWeight: "bold",
                color: "#fff"
              }}
              title="GIZTIX"
            />
						<p style={{ textAlign: "center" }}>
            เราพร้อมที่จะต้อนรับสมาชิกใหม่<br />
            ที่เปี่ยมไปด้วยความมุ่งมั่น ความคิดสร้างสรรค์ และพร้อมที่จะก้าวไปด้วยกันกับเรา
          </p>
           
          </div>
        </GiztixContainer>


        <div className="container" style={{marginTop: -70}}>
				<Element name="stepCheckPriceContainer" />
					<PrivilegeContainer>
						<div data-aos="fade-up" data-aos-duration="1000">
								<p style={{ fontSize: 30, fontWeight: "bold", marginBottom: 40, textAlign: "center"}}>
									ค้นหากลุ่มงานที่คุณสนใจ
								</p>
						</div>
						
						<Row>
								<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentFA">
											<div className="privilege-cover middle">
												<LazyImage src={imagePrivilege1} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Financing & Accounting
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											วางบิลดั่งพายุ จ่ายเร็วเหมือนสายฟ้า
											<br />
											ทวงหนี้ดุจฟ้าผ่า รับเงินราวแสงแดด
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>

								<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="1600">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentCBD">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege2} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Corporate Business Development
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											ทำงานสนุก เผือกทุกแผนก
											<br />
											เพื่อบรรลุเป้าหมายทุกคนและองค์กร
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>

									<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="1900">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentHR">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege3} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Human Resources
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											ตามหาคนที่ “ใช่”
											<br />
											รับคน พัฒนาคน พนักงานอยู่ดี มีความสุข
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>
								<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="2100">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentMAX">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege4} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											MAX (Product Project)
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											โปรเจ็คผลิตภัณฑ์ที่ช่วยติดตาม
											<br />
											ควบคุมด้าน Transportation ให้เป็นเรื่องง่าย
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>

								<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="2300">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentOM">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege5} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Operation Marketplace
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											บริการลูกค้า ประสานงานรถขนส่ง
											<br />
											ทำงานยากให้เป็นเรื่องง่าย คลายกังวลผู้ใช้
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>

									<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="2600">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentSM">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege6} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Sale Marketplace
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											ติดต่อลูกค้า ดูแล After sales
											<br />
											บริการดี ลูกค้าจองขนส่งสบาย...ได้ที่ Giztix
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>
								<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentGM">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege7} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Graphic & Marketing
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											วางแผน & ผลิตสื่อในรูปแบบต่างๆ
											<br />
											เพื่อให้ผู้คนรู้จัก Giztix ได้อย่างชัดเจนและมากขึ้น
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>

								<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentDEV">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege8} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											Developer
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											แท็กทีมเพื่อสร้างนวัตกรรมใหม่ๆ
											<br />
											พัฒนาโปรแกรม และแอปพลิเคชั่นให้ใช้งานได้จริง
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>

									<Col md={4} lg={4} xs={12} sm={6} style={{marginBottom:"30px"}}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<a target="_blank" rel="noopener noreferrer" href="/giztixdepartmentUXUI">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege9} alt="privilege" />
											
										<div className="privilege-title">
											<Dotdotdot clamp={1}>
											UX/UI Designer
											</Dotdotdot>
										</div>
										<div className="privilege-detail">
											<Dotdotdot clamp={2}>
											ออกแบบและพัฒนา สร้างสรรค์สิ่งใหม่ๆ 
											<br />
											เรียนรู้และเข้าใจ User Experience 
											</Dotdotdot>
										</div>
										</div>
										</a>
									</div>
								</Col>
							</Row>

					<p style={{ textAlign: "left", fontSize: 20, marginTop: 50}}>
					สำหรับตำแหน่งที่ยังไม่เปิดรับ ผู้ที่สนใจสมัครงาน สามารถส่งประวัติ รูปถ่าย สําเนาวุฒิการศึกษา พร้อมระบุตําแหน่งที่สมัครที่หัวข้อ Email มาที่ people@giztix.com  ผู้ที่ได้รับการคัดเลือก
					<br/>
					จะได้รับการติดต่อกลับ สอบถามข้อมูลเพิมเติมที่ ฝ่ายทรัพยากรบุคคล  โทร 02-026-6895 
          </p>

					<hr />
					</PrivilegeContainer>
        </div>

				<div className="container" >
        <Element name="stepCheckPriceContainer" />
        <StepCheckPriceContainer>
							<div data-aos="fade-up" data-aos-duration="1000">
								<TextHeader
									style={{ marginBottom: 40 , marginTop: 40 }}
									title="สวัสดิการ"
								/>
							</div>
							
							<Row>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><i className="far fa-calendar-alt"></i></div>
												<div className="box-image-hover"><i className="far fa-calendar-alt"></i></div>
												<div className="box-title">ทำงาน <br/>สัปดาห์ละ 5 วัน</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="1600">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><i class="far fa-clock"></i></div>
												<div className="box-image-hover"><i class="far fa-clock"></i></div>
												<div className="box-title">มีเวลา <br/>การทำงานที่ยืดหยุ่น</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="1900">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><i class="far fa-ambulance"></i></div>
												<div className="box-image-hover"><i class="far fa-ambulance"></i></div>
												<div className="box-title">มีประกันอุบัติเหตุ</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="2100">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><i class="far fa-lightbulb"></i></div>
												<div className="box-image-hover"><i class="far fa-lightbulb"></i></div>
												<div className="box-title">มีค่าพัฒนา <br/>และฝึกอบรมพนักงาน</div>
											</div>
										</div>
									</div>
								</Col>
							</Row>

							<br/>
							<Row>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="1300">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><i class="far fa-piggy-bank"></i></div>
												<div className="box-image-hover"><i class="far fa-piggy-bank"></i></div>
												<div className="box-title">กองทุนสำรองเลี้ยงชีพ</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="1600">
										<div className="box-container">
											<div className="box">
												<div className="box-image"><i class="far fa-bus"></i></div>
												<div className="box-image-hover"><i class="far fa-bus"></i></div>
												<div className="box-title">เที่ยวและงานเลี้ยง<br/>ประจำปี</div>
											</div>
										</div>
									</div>
								</Col>
								<Col md={3} sm={6}>
									<div data-aos="fade-up" data-aos-duration="2100">
										<div className="box-container">
											<div className="box">
                                            <div className="box-image"><i class="far fa-user"></i></div>
                                            <div className="box-image-hover"><i class="far fa-user"></i></div>
												<div className="box-title">มีชุดยูนิฟอร์ม</div>
											</div>
										</div>
									</div>
								</Col>
								
							</Row>
						</StepCheckPriceContainer>
        </div>
				
        <DeviceContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.5404450240753!2d100.60889471482976!3d13.685684990390254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d672e8097f671%3A0x8b3d7495efdf67d5!2sGIZTIX+HQ!5e0!3m2!1sth!2sth!4v1562662978115!5m2!1sth!2sth"
            style={{
              width: "100%",
              height: "300px",
              border: 0,
              marginBottom: -10
            }}
            allowfullscreen
          />
        </DeviceContainer>

        <Footer />
      </Container>
    );
  }
}

export default withNamespaces()(GiztixBusiness);
