import React from "react";
import { Helmet } from "react-helmet";
import { withNamespaces } from "react-i18next";

function HeaderHelmet(props) {
  const { title } = props;
  return (
    <Helmet>
      <title>{title} | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
      <meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
    </Helmet>
  );
}

export default withNamespaces()(HeaderHelmet);
