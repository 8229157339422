import React from 'react';
import PropTypes from 'prop-types';
import 'rc-drawer/assets/index.css';


import HeaderDesktop from './../HeaderDesktop';
import HeaderMobile from './../HeaderMobile';


class Header extends React.Component {
	constructor(props) {
		super(props);
		this.state = {width: props.width};
	}
	componentWillMount() {
		this.setState({width: window.innerWidth});
	}

	render(props) {
		if(this.state.width < 768){
			return (<HeaderMobile {...this.props} />);
		}
		return (<HeaderDesktop  {...this.props} />);
	}
}

Header.propTypes = {
	active: PropTypes.number,
	full: PropTypes.bool,
	bgcolor: PropTypes.string,
	border: PropTypes.bool,
	color: PropTypes.string,
};

Header.defaultProps = {
	active: null,
	full: false,
	border: true,
	color: 'black',
};

export default Header;
