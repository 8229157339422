import gql from 'graphql-tag';

export default gql`
mutation Update(
    $email: String
){
    emailSubscribeAdd(
      email:$email
    ){
      _id
    }
  }
`;
