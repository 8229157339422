import React from 'react';
import PropTypes from 'prop-types';
import { Row , Col } from 'react-bootstrap';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import logo from './../../assets/images/logo_giztix.png';
import appIos from './../../assets/images/app-store.svg';
import playStore from './../../assets/images/play-store.svg';

import version from '../../config/version';
import MediaQuery from 'react-responsive';

import { withNamespaces } from "react-i18next";

const FooterContainer = styled.div`
	background-color: #000;
	color: rgba(255,255,255,.6);
	padding: 30px 0 20px;
	text-align: center;
`;

const MenuContainer = styled(Row)`
	padding-bottom: 10px;
`;

const DownloadContainer = styled(Row)`
	padding-top: 10px;
	padding-bottom: 30px;
`;

const DBDContainer = styled(Row)`
	padding-bottom: 30px;
`;

const CopyRightContainer = styled(Row)`
	padding-top: 20px;
	border-top: 1px solid rgba(255,255,255,0.2);
`;

const Title = styled.div`
	color: rgba(255,255,255,1);
	margin-bottom:10px;
`;

const SocailLinkContainer = styled.div`
	width: 100%;
	float: left;
`;

const SocailLink = styled.a`
	display: inline-block;
	margin-right: 20px;
	font-size: 27px;
	line-height: 30px;
	color: rgba(255,255,255,.6);
`;

const TopContainer = styled.div`
	font-size: 18px;
	color: #000000;
	background-color: #F9F9F9;
	padding: 15px 0 30px;
	line-height: 1;
	text-align: center;
	cursor: pointer;
	width: 100%;
	display: block;

  & span {
	width: 100%;
	float: left;
  }
`;

class FooterMobile extends React.Component {
	
	
	componentDidMount () {
		const script = document.createElement("script");

		script.src = "https://www.trustmarkthai.com/callbackData/initialize.js?t=ac54f3-22-5-c2eebed8b4423b9f63231bdf5fe34ef38a451cc";
		script.id = 'dbd-init';
		script.async = true;
		document.body.appendChild(script);
	}

	render() {
		return (
			<div>
				<MediaQuery maxWidth={767}>
					<TopContainer onClick={() => window.scrollTo(0, 0)}>
						<i className="fal fa-chevron-up"></i>
						<span>{this.props.t('header:backToTop')}</span>
					</TopContainer>
				</MediaQuery>
				<FooterContainer>
					<div className="container">
						<MenuContainer>
							<Col xs={12}>
								<Title>{this.props.t('header:contactCustomerService')}</Title>
								<p>
							{this.props.t('header:GIZTIXCompanyName')}<br/>
							{this.props.t('header:AddressGIZTIX')}<br/>
							{this.props.t('header:AddressGIZTIX2')}
							</p>
							<p>
							{this.props.t('header:emailGiztix')}<a href="mailto:service@giztix.com" style={{color: "#ccc"}}>service@giztix.com</a><br/>
							{this.props.t('header:contactGiztix')} 02-026-6895<br/>
								{this.props.t('header:giztixOpenhours')}
							</p>
							</Col>
						</MenuContainer>
						<DownloadContainer>
							<Col sm={12}>
								<Title>{this.props.t('header:SubscribeHere')}</Title>
								<SocailLinkContainer>
									<SocailLink href="https://www.facebook.com/giztix/" target="_blank"><i className="fab fa-facebook-square"></i></SocailLink>
									<SocailLink href="https://line.me/R/ti/p/@giztix" target="_blank"><i className="fab fa-line"></i></SocailLink>
									<SocailLink href="https://www.instagram.com/giztix_official/" target="_blank"><i className="fab fa-instagram"></i></SocailLink>
									<SocailLink href="https://www.youtube.com/channel/UCDoz39PN2yzoQB5d0-xcgJg" target="_blank"><i className="fab fa-youtube"></i></SocailLink>
								</SocailLinkContainer>
							</Col>
						</DownloadContainer>
						<DBDContainer>
							<Col sm={12} ><div id="Certificate-banners"></div></Col>
						</DBDContainer>
						<CopyRightContainer>
							<Col xs={12}>
								<span>{this.props.t('header:CopyRightGiztix')} {version})</span>
							</Col>
						</CopyRightContainer>
					</div>
				</FooterContainer>
			</div>
		);
	}
}

export default withNamespaces()(FooterMobile);
