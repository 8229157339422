import React, { Component } from 'react';
import { Row ,Breadcrumb,FormGroup,InputGroup,Button,FormControl} from "react-bootstrap";
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import {
    Header,
    BreadcrumbTitel,
    Footer
} from "./../../components";


const Container = styled.div`
    margin: 145px auto 50px;
    text-align: left;
    min-height: 30vh;
`;

const TitelNumber = styled.ul`
    margin-top: 30px;
    margin-bottom: 10px;

    @media(max-width: 768px) {
        padding-left: 0px;
        margin-top: 0px;
    }
`;
const Titel = styled.div`
    margin-bottom:30px;
    font-size: 36px;

    @media(max-width: 768px) {
        margin-bottom: 20px;
        font-size: 24px;
    }
`;
const TextIndent = styled.p`
    margin-bottom:15px;
    margin-left: 64px;
    line-height: 1;

    @media(max-width: 768px) {
        margin-left: 20px;
    }
`;

class Standard extends Component {

    render() {
        const menu = [ 

            {
                "active": true,
                "link": "/helpcenter",
                "title": "ศูนย์ความช่วยเหลือ >"
            },
            {
                "active": true,
                "link": "/standard",
                "title": "มาตราฐานการคัดกรองผู้ให้บริการ "
            }
        ];

        return (
            <div style={{ backgroundColor: "#ffffff" }}>
                <Helmet>
					<title>Standard | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย" />
				</Helmet>
                <Header active={3}></Header>
                <BreadcrumbTitel menu={menu}></BreadcrumbTitel>
                <div>
                    <Container className="container">
                        <Titel>มาตราฐานการคัดกรองผู้ให้บริการ</Titel>
                        <TextIndent>จิซทิกซ์ “เรา” ได้คำนึงถึงความสำคัญของสินค้าที่เป็นของลูกค้าทุกท่าน เพื่อสร้างความมั่นใจให้กับลูกค้าที่ใช้บริการขนส่งสินค้ากับเรา เราจึงขอแจ้งมาตรฐานการคัดกรอกผู้ให้บริการที่จะให้บริการผ่านทางเว็บไซต์ของเราดังนี้</TextIndent>

                        <TitelNumber><p><strong>ผู้ประกอบการขนส่งในรูปแบบ บริษัท หรือ ห้างหุ้นส่วนจำกัด</strong></p></TitelNumber>
                        <TextIndent>ผู้ประกอบการขนส่งที่จดทะเบียนในนามบริษัท หรือห้างหุ้นส่วนจำกัดทุกรายที่มีรายชื่ออยู่บนเว็บไซต์ www.giztix.com และ/หรือ Giztix แอปพลิเคชัน ต้องผ่านมาตรฐานดังนี้</TextIndent>
                        <TextIndent>1.	มีเอกสารรับรองการจัดตั้งบริษัทหรือห้างหุ้นส่วนจำกัด เพื่อประกอบธุรกิจการให้บริการขนส่งสินค้า</TextIndent>
                        <TextIndent>2.	บริษัทหรือห้างหุ้นส่วนจำกัด ต้องมีสำนักงานเป็นหลักแหล่ง และมีชื่อปรากฎอยู่ในรายชื่อขององค์กรรัฐที่เกี่ยวข้อง</TextIndent>
                        <TextIndent>3.	ต้องสามารถสืบค้นข้อมูลจากแหล่งที่น่าเชื่อถือได้</TextIndent>
                        <TextIndent>4.	ต้องผ่านมาตรฐานการให้บริการขนส่งที่ทางกรมขนส่งทางบกกำหนด</TextIndent>
                        <TextIndent>5.	ต้องสามารถออกเอกสารที่เกี่ยวข้องกับการให้บริการได้ตามมาตรฐานที่คนทั่วไปยอมรับ</TextIndent>
                        <TextIndent>6.	มีกฎเกณฑ์การให้บริการ และ/หรือ ขอบเขตความรับผิดที่แน่ชัด</TextIndent>
                        <TextIndent>ในกรณีที่บริษัทหรือห้างหุ้นส่วนจำกัดใดที่ไม่ผ่านมาตรฐานดังกล่าว เราจะไม่อนุญาตให้ผู้ประกอบการเหล่านั้นแสดงอยู่บนเว็บไซต์ และ/หรือ Giztix แอปพลิเคชันโดยเด็ดขาด เนื่องจากอาจก่อให้เกิดความเสี่ยง ความเสียหายต่อลูกค้าที่ต้องการ ใช้บริการ</TextIndent>
                        
                        <TitelNumber><p><strong>ผู้ให้บริการขนส่งในรูปแบบบุคคล และ/หรือ รถร่วมให้บริการ</strong></p></TitelNumber>
                        <TextIndent>ผู้ให้บริการขนส่งสินค้าในรูปแบบบุคคล และ/หรือ รถร่วมให้บริการทุกรายที่เราคัดเลือก เพื่อเตรียมให้บริการ กับลูกค้า ผ่านช่องทางเว็บไซต์ www.giztix.com และ/หรือ Giztix แอปพลิเคชันจำเป็นต้องผ่านมาตรฐานดังนี้</TextIndent>
                        <TextIndent>1.	มีบัตรประจำตัวประชาชนของราชอาณาจักรไทย</TextIndent>
                        <TextIndent>2.	มีใบอนุญาตขับขี่รถยนต์ตามประเภทรถที่ให้บริการ</TextIndent>
                        <TextIndent>3.	มีที่อยู่เป็นหลักแหล่ง</TextIndent>
                        <TextIndent>4.	ไม่มีประวัติอาชญากรรมร้ายแรง หรือคดีร้ายแรง เช่น เกี่ยวข้องกับยาเสพติด, ขับรถโดยประมาททำให้ผู้อื่นถึงแก่ความตาย, ทะเลาะวิวาท, ทำร้ายร่างกาย, ลักทรัพย์ หรือดื่มสุราขณะขับขับขี่ยานพาหนะเป็นต้น</TextIndent>
                        <TextIndent>5.	ผ่านการอบรมมารยามการให้บริการและการแต่งกาย</TextIndent>
                        <TextIndent>6.	มีเอกสารยืนยันการเป็นเจ้าของยานพาหนะที่ใช้ในการให้บริการ

</TextIndent>
                        <TextIndent>7.	เอกสารกรมทัณฑ์ประกันภัยสำหรับรถยนต์ที่ใช้ในการให้บริการ

</TextIndent>
                        <TextIndent>ในกรณีที่บุคลคใดที่ไม่ผ่านมาตรฐานดังกล่าว เราจะไม่อนุญาตให้บุคลเหล่านั้นแสดงอยู่บนเว็บไซต์ และ/หรือ Giztix แอปพลิเคชัน รวมถึงการคัดเลือกเพื่อให้บริการโดยเด็ดขาด เนื่องจากอาจก่อให้เกิดความเสี่ยง ความเสียหายต่อลูกค้า ที่ต้องการใช้บริการ</TextIndent>

                        <TitelNumber><p><strong>มาตรฐานรถที่ให้บริการ</strong></p></TitelNumber>
                        <TextIndent>เราทราบดีว่าสินค้าของท่านมีความหลากหลาย เพื่อให้สอดคล้องกับสินค้าของท่าน เราจึงจัดเตรียมรถที่คอยให้บริการซึ่งแบ่งประเภทดังนี้ รถกระบะ, รถหกล้อ, รถสิบล้อ, รถเทรลเลอร์ลากจูง เนื่องจากประเภทรถเหล่านี้ถูกออกแบบ เพื่อใช้งานที่ไม่เหมือนกัน เราจึงกำหนดเงื่อนไขและสร้างมาตรฐานเพื่อให้ท่านเข้าใจและรู้สึกปลอดภัยเมื่อใช้บริการ ซึ่งมีเงื่อนไขดังต่อไปนี้</TextIndent>

                        <table className="table">
                            <thead>
                                <tr>
                                    <th className="text-center"></th>
                                    <th className="text-center">รถกระบะ</th>
                                    <th className="text-center">รถหกล้อ</th>
                                    <th className="text-center">รถสิบล้อ</th>
                                    <th className="text-center">รถเทรลเลอร์</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>รถอยู่ในสภาพพร้อมใช้งาน
                                    </td><th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th> 
                                </tr>
                                <tr>
                                    <td>หากเป็นชนิดตู้ทึบต้อง ไม่รั่วซึม, มีสลักล็อคตู้ทุกบาน
                                    </td><th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center"></th> 
                                </tr>
                                <tr>
                                    <td>มีอุปกรณ์ยึดสินค้า
                                    </td><th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th> 
                                </tr>
                                <tr>
                                    <td>มีใบรับรองวิศกรรมในกรณีต่อเติม และ/หรือ ดัดแปลงตัวรถ
                                    </td><th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th> 
                                </tr>
                                <tr>
                                    <td>พื้นที่บรรจุสินค้าอยู่ในสภาพพร้อมใช้งาน
                                    </td><th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th> 
                                </tr>
                                <tr>
                                    <td>อุปกรณ์ที่ใช้เตือนผู้ใช้ถนนในกรณีรถเสีย
                                    </td><th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th> 
                                </tr>
                                <tr>
                                    <td>อุปกรณ์เสริมเพื่อห้ามล้อ
                                    </td><th className="text-center"></th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th>
                                    <th className="text-center">X</th> 
                                </tr>
                            </tbody>
                        </table>

                        <TextIndent>มาตรฐานการคัดเลือกรถที่ให้บริการเหล่านี้เป็นมาตรฐานพื้นฐานเบื้องต้นเท่านั้น เรายังมีเกณฑ์การคัดกรองอีกมากมายที่แตกต่างกันออกไปตามประเภทของรถ ดังนั้นท่านจึงมั่นใจได้ว่าสินค้าของท่านจะมีความปลอดภัยระหว่างขนส่ง และถึงจุดหมายปลายทางได้อย่างที่ท่านคาดหวัง</TextIndent>
                        
                    </Container>
                    <Footer />
                </div>


            </div>
        )
    }
}

export default Standard;