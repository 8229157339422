import React, { Component } from 'react';
import { Header, Footer, LazyImage } from './../../components';
import { Cover, TextHeader } from './components';
import styled from 'styled-components';
import { Row, Col, Button , Modal } from 'react-bootstrap';
import Dotdotdot from 'react-dotdotdot';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link as LinkScroll, Element } from 'react-scroll';
import { Helmet } from 'react-helmet';
import './style.css';

//StepSupport
import imageSupport from '../../assets/images/gtxBusiness/Icon/Support.svg';
import fullTransportService from '../../assets/images/gtxBusiness/Icon/fullTransportService.svg';
import SecurityService from '../../assets/images/gtxBusiness/Icon/SecurityService.svg';

//InvoiceContainer
import PaymentService from '../../assets/images/gtxBusiness/Icon/PaymentService.svg';
import SavePrice from '../../assets/images/gtxBusiness/Icon/SavePrice.svg';

//PriceContainer
import SaveCost from '../../assets/images/gtxBusiness/Icon/SaveCost.svg';
import ManageShipment from '../../assets/images/gtxBusiness/Icon/ManageShipment.svg';

//ContactContainer
import bgContact from '../../assets/images/gtxBusiness/Picture/bgContact.png';


import color from '../../config/color';
import './style.css';
import appUrl from './../../config/appUrl';

AOS.init({
	delay: 100
});

const Container = styled.div`
	
`;

const MoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position:absolute;
	bottom:-25px;
	width:100%;

	& .text-button-more {
		color: #ffffff;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);	
		cursor: pointer;
		text-decoration: none;

		& i {
			font-size: 40px;
		}
	}

	@media (max-width: 992px) {
		bottom:0px;
	}
`;

const StepSupportContainer = styled.div`
	text-align: center;
	margin-top: 30px;

	& .box-container {
		height: 310px;
		display: flex;
		align-items: center;
	}

	& .box {
		padding: 35px 20px;
		background-color: #ffffff;
    	box-sizing: border-box;
		border-radius: 5px;
		height: 280px;
		vertical-align: middle;
		display: block;
		transition: all .3s;
		margin: 0 auto;

		&:not(:last-child) {
			margin-right: 20px;
		}

		& .box-image {
			display: block;

			& img {
				width: 120px
				height: 120px;
			}
		}

		& .box-title {
			margin-top: 30px;
			font-weight: 700;
			text-align: center;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

const InvoiceContainer = styled.div`
	margin-top: 0px;
	margin-bottom: 40px;
	
	& .box-container {   
		height: auto;
		margin-bottom: 40px;
		display: flex;
		align-items: top;
	}

	& .box-image {
		display: block;

		& img {
			width: 100px
			height: 100px;
		}
	}

	& .box {
		padding: 0;
		padding-top: 10px;
		text-align: right;
		height: auto;
		display: block;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin: 0 auto;

		&:not(:last-child) {
			margin-right: 40px;
		}

		& .box-title {
			margin-top: 0;
			font-weight: 700;
			text-align: right;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
`;

const PriceContainer = styled.div`
	margin-top: 0px;
	margin-bottom: 40px;

	& .box-container {    
		height: auto;
		margin-bottom: 40px;
		display: flex;
		align-items: top;
	}

	& .box-image {
		display: block;

		& img {
			width: 100px
			height: 100px;
		}
	}

	& .box {
		padding: 0;
		padding-top: 10px;
		text-align: left;
		height: auto;
		display: block;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin: 0 auto;
		margin-left: 40px;

		&:not(:last-child) {
			margin-right: 40px;
		}

		& .box-title {
			margin-top: 0;
			font-weight: 700;
			text-align: left;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
`;

const ContactContainer = styled.div`
	background-image: url(${bgContact});	
	margin-top: 120px;
	height: 280px;
	padding: 80px 0;
	background-repeat: no-repeat;
    background-size: cover;

	.container{
		background-color: #ffffff;
		padding: 40px;
		border-radius: 10px;
		box-shadow: 1px 2px 8px #000;
		position: relative;
		height: 140px;

		.check-button{
			height: 48px;
			width: 260px;
			font-size: 26px;
			position: absolute;
			right: 50px;
			top: 47px;
		}
	}
`;



const LineHorizontal = styled.div`
	width: 100%;
	height: 1px;
	background-color: #E3E3E3;
`;

const StepInvoiveContainer = styled.div`
	margin-top:30px;
`;

const ColStepLeft = styled(Col)`
	padding-top: 50px;
	padding-right:60px;
	padding-bottom: 40px;
	@media (max-width: 992px) {
		padding-right:30px;
	}
`;
const ColStepRight = styled(Col)`
	padding-left:60px;
	padding-top: 50px;
	padding-bottom: 40px;
	border-left : 1px dashed #ddd;
	@media (max-width: 992px) {
		padding-left:30px;
	}
`;


class GiztixBusiness extends Component {
	state = {
		isTop: true,
		onOpenModal: false
	};

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 100;
			if (isTop !== this.state.isTop) {
				this.setState({ isTop });
			}
		});
	}

	render() {
		return (
			<Container>
				<Helmet>
					<title>Business | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
				</Helmet>
				<Header
					bgcolor={this.state.isTop ? 'transparent' : '#00000090'}
          border={false}
          color="white"
				/>
				
				<div className="cover-height">
					{/* บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย */}
					<Cover/>
					
					{/* ดูเนื้อหาเพิ่มเติม */}
					<MoreContainer>
						<div style={{ textAlign: 'center' }}>
							<LinkScroll className="text-button-more" to="StepSupportContainer" smooth={true} duration={1500}>
								<div>
									<i className="fas fa-circle" />
								</div>
							</LinkScroll>
						</div>
					</MoreContainer>
				</div>

				<div className="container">
					

					{/* สะดวกสบายกับรูปแบบ */}
					<Element name="StepSupportContainer" />
					<StepInvoiveContainer>
						<Row>
							<ColStepLeft sm={6}>
								<div data-aos="fade-up" data-aos-duration="1000">
									<TextHeader
										style={{ marginBottom: 60,textAlign:"right"  }}
										title="การชำระเงินแบบวางบิล"
										subTitle="สะดวกสบายกับรูปแบบ"
										detail="บันทึกค่าขนส่งอัตโนมัติ รับเอกสารใบวางบิล และใบเสร็จทันที"
									/>

								</div>

								<InvoiceContainer>
									<Row>
										<Col sm={12}>
											<div data-aos="fade-up" data-aos-duration="1600">
												<div className="box-container">
													<div className="box">
														<div className="box-title">บริการชำระค่าบริการขนส่ง</div>
														<div className="box-title-subtitle">
															จัดการเอกสารบัญชีรายจ่ายสำหรับธุรกิจที่ชำระค่าบริการ <br/>
															หลายงานขนส่ง ได้ที่ GIZTIX ที่เดียว</div>
													</div>
													<div className="box-image"><LazyImage src={PaymentService} alt="box" /></div>
												</div>
											</div>
										</Col>
										<Col sm={12}>
											<div data-aos="fade-up" data-aos-duration="2100">
												<div className="box-container">
													<div className="box">
														<div className="box-title">มีโอกาสได้ราคาพิเศษ</div>
														<div className="box-title-subtitle">
															มีโอกาสได้การลดราคาพิเศษจาก <br/>
															การวางบิลของทาง GIZTIX
														</div>
													</div>
													<div className="box-image"><LazyImage src={SavePrice} alt="box" /></div>
												</div>
											</div>
										</Col>
									</Row>
								</InvoiceContainer>

								<LineHorizontal />
							</ColStepLeft>
							<ColStepRight sm={6}>
								<div data-aos="fade-up" data-aos-duration="1000">
									<TextHeader
										style={{ marginBottom: 60,textAlign:"left"  }}
										title="ค่าบริการขนส่งราคาพิเศษ*"
										subTitle="ลดต้นทุนขององค์กรด้วย"
										detail="ราคาขนส่งพิเศษสำหรับลูกค้าระดับองค์กร/ธุรกิจ ยิ่งจองมากราคายิ่งถูกลงมากขึ้น"
									/>
								</div>

								<PriceContainer>
									<Row>
										<Col sm={12}>
											<div data-aos="fade-up" data-aos-duration="1600">
												<div className="box-container">
													<div className="box-image"><LazyImage src={SaveCost} alt="box" /></div>
													<div className="box">
														<div className="box-title">ลดต้นทุนค่าขนส่ง</div>
														<div className="box-title-subtitle">
															ค่าบริการขนส่งพิเศษไม่มีราคาแอบแฝง ช่วยจัดการต้นทุน <br/>
															การจัดส่งสินค้าให้ธุรกิจคุณแม่นยำมากยิ่งขึ้น
														</div>
													</div>
												</div>
											</div>
										</Col>
										<Col sm={12}>
											<div data-aos="fade-up" data-aos-duration="2100">
												<div className="box-container">
													<div className="box-image"><LazyImage src={ManageShipment} alt="box" /></div>
													<div className="box">
														<div className="box-title">จัดการขนส่งสินค้าอย่างมีประสิทธิภาพ</div>
														<div className="box-title-subtitle">
															บริหารงานขนส่งสินค้า <br/>
															ด้วยระบบออนไลน์รูปแบบใหม่
														</div>
													</div>
												</div>
											</div>
										</Col>
									</Row>
								</PriceContainer>

								<LineHorizontal />
							</ColStepRight>
						</Row>
						
						
					</StepInvoiveContainer>


					<StepSupportContainer>
						<div data-aos="fade-up" data-aos-duration="1000">
							<TextHeader
								style={{ marginBottom: 60,textAlign:"center" }}
								title="ระดับองค์กรตลอด 24 ชม."
								subTitle="สบายใจด้วยการดูแล"
								detail="ไม่ว่าจะจองรถขนส่งเร่งด่วนขนาดไหน เราพร้อมดูแลคุณทุกการขนส่ง"
							/>
						</div>
						
						<Row>
							<Col sm={4}>
								<div data-aos="fade-up" data-aos-duration="1600">
									<div className="box-container">
										<div className="box">
											<div className="box-image"><LazyImage src={imageSupport} alt="box" /></div>
											<div className="box-title">มีเจ้าหน้าที่ผู้เชี่ยวชาญ</div>
											<div className="box-title-subtitle">ให้คำปรึกษาและดูแลคุณตลอด 24 ชม.</div>
										</div>
									</div>
								</div>
							</Col>
							<Col sm={4}>
								<div data-aos="fade-up" data-aos-duration="1900">
									<div className="box-container">
										<div className="box">
											<div className="box-image"><LazyImage src={fullTransportService} alt="box" /></div>
											<div className="box-title">บริการขนส่งสินค้าสำหรับ <br/> องค์กร/ธุรกิจ เต็มรูปแบบ</div>
											<div className="box-title-subtitle">
												รองรับบริการขนส่งในประเทศ <br/>
												นำเข้า ส่งออก และพิธีการศุลกากร<br/>
												แบบ One Stop Service
											</div>
										</div>
									</div>
								</div>
							</Col>
							<Col sm={4}>
								<div data-aos="fade-up" data-aos-duration="2100">
									<div className="box-container">
										<div className="box">
											<div className="box-image"><LazyImage src={SecurityService} alt="box" /></div>
											<div className="box-title">ปลอดภัยทุกการขนส่ง</div>
											<div className="box-title-subtitle">
												การันตีคุณภาพการส่งสินค้า <br/>
												ตลอดการขนส่งจนถึงปลายทาง
											</div>
										</div>
									</div>
								</div>
							</Col>
						</Row>
					</StepSupportContainer>


				</div>

				<ContactContainer>
					<div data-aos="fade-up" data-aos-duration="1000">
						<div className="container"> 
							<TextHeader
								className="title"
								style={{ marginBottom: 60,textAlign:"left"}}
								title="จิซทิกซ์สำหรับลูกค้าองค์กร/ธุรกิจ"
								detail="ตอบสนองความต้องการใช้บริการขนส่งในระดับองค์กร"
							>
							</TextHeader>

							<Button bsStyle="primary" className="check-button" onClick={() => window.location = `${appUrl}/register-business`}>
								ลงทะเบียน
							</Button>
						</div>
					</div>
				</ContactContainer>

				<Footer />
			</Container>
		);
	}
}

export default GiztixBusiness;
