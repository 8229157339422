import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import client from './config/client';
import Routes from './routes';
import './index.css';
import PDPA from './components/PDPA';

import ReactGA from 'react-ga';
import gaCode from './config/gaCode';

ReactGA.initialize(gaCode);
ReactGA.pageview(window.location.pathname);

export default () => (
  <ApolloProvider client={client}>
    <BrowserRouter>
      <PDPA />
      <Routes />
    </BrowserRouter>
  </ApolloProvider>
);
