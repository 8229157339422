import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Header, BreadcrumbTitel, Footer } from "../../components";
import { withNamespaces } from "react-i18next";
import file from "./PrivacyNotice.pdf";

const Container = styled.div`
  margin: 145px auto 50px;
  text-align: left;
  min-height: 30vh;
`;
const TitelNumber = styled.ul`
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;
const Titel = styled.div`
  margin-bottom: 30px;
  font-size: 36px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;
const TextIndent2 = styled.p`
  margin-bottom: 15px;
  text-indent: 64px;

  @media (max-width: 768px) {
    text-indent: 20px;
  }
`;

class PrivacyNotice extends React.Component {
  render() {
    const menu = [
      {
        active: true,
        link: "/helpcenter",
        title: "ศูนย์ความช่วยเหลือ >",
      },
      {
        active: true,
        link: "/privacy-notice",
        title: "ประกาศความเป็นส่วนตัวสำหรับลูกค้า",
      },
    ];

    return (
      <div style={{ backgroundColor: "#ffffff" }}>
        <Helmet>
          <title>Privacy Notice | GIZTIX</title>
          <meta name="description" content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย" />
        </Helmet>

        <Header active={3}></Header>
        <BreadcrumbTitel menu={menu}></BreadcrumbTitel>

        <div>
          <Container className="container">
            <Titel>{this.props.t("header:PrivacyNotice")}</Titel>
            <TextIndent2>
              บริษัท จิซทิกซ์ จำกัด (“บริษัท”, “Giztix” หรือ “จิซทิกซ์”) ซึ่งเป็นบริษัทจดทะเบียนในประเทศไทย คือ บริษัทผู้เป็นเจ้าของและดำเนินการ โดยมุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของผู้ใช้บริการ
              คู่ค้า พันธมิตรทางธุรกิจ (เรียกรวมกันว่า “ท่าน” หรือ “เจ้าของข้อมูล”) บนนโยบายความเป็นส่วนตัวต่อไปนี้ ไม่ว่าจะเป็นการเก็บรวบรวม ใช้ และ/หรือเปิดเผย (เรียกรวมกันว่า การประมวลผลข้อมูล)
              และดำเนินการเกี่ยวกับข้อมูลที่สามารถระบุตัวตนของท่านได้ (ข้อมูลส่วนบุคคล รวมถึงนิติบุคคล) ซึ่งมีความเกี่ยวข้องกับการให้บริการของบริษัทผ่านทางเว็บไซต์ www.giztix.com ("Site","เว็บไซต์")
              แอปพลิเคชั่น (Application) หรือซอฟต์แวร์ระบบ (System Software) ทีมีการอ้างอิงถึงการให้บริการของบริษัทดังกล่าวบนนโยบายความเป็นส่วนตัวฉบับนี้ เมื่อท่านใช้บริการของจิซทิกซ์นั้น
              ท่านได้ยินยอมให้เรารวบรวม ใช้ เปิดเผย และดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่านตามที่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
              2562 จึงออกประกาศฉบับนี้ขึ้น เพื่อแจ้งข้อมูลให้แก่ผู้ใช้บริการอันเกี่ยวกับ สิทธิและหน้าที่ ตลอดจนเงื่อนไขต่างๆ ในการเก็บ รวบรวม ใช้ และ เปิดเผย ข้อมูลส่วนบุคคล{" "}
            </TextIndent2>

            <TextIndent2>เมื่อท่านใช้บริการของ Giztix ย่อมแสดงว่าท่านยินยอมที่จะให้เราใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบุไว้ในนโยบายนี้</TextIndent2>
            <iframe style={{ width: "563px", height: "666px" }} src={file} type="application/pdf" title="title"></iframe>
          </Container>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withNamespaces()(PrivacyNotice);
