import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import FooterDesktop from './../FooterDesktop';
import FooterMobile from './../FooterMobile';
import MediaQuery from 'react-responsive';

class Footer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {width: props.width};
	}
	componentWillMount() {
		this.setState({width: window.innerWidth});
	}

	render(props) {
		return (
			<div>
				<MediaQuery minWidth={768}>
					<FooterDesktop  {...props} />
				</MediaQuery>
				<MediaQuery maxWidth={767}>
					<FooterMobile {...props} />
				</MediaQuery>
			</div>
		);
	}
}

Footer.propTypes = {
	active: PropTypes.number,
};

Footer.defaultProps = {
	active: null,
};

export default Footer;
