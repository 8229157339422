import React from 'react';
import styled from 'styled-components';
import color from '../../config/color';
import MediaQuery from 'react-responsive';

const ContainerDesktop = styled.div`
  position: relative;
  width: 100%;
  background-color: #E3E3E3;
  background-image: url(${props => props.img});
  border-radius: 10px;
  background-size: cover;
  background-repeat: no-repeat;


  & .card-content {
    padding: 60px 90px;

    &-title {
      position: relative;
      font-weight: 700;
      font-size: 60px;
      color: #FFFFFF;
      line-height: 1;
    }

    &-subtitle {
      font-size: 24px;
      color: #FFFFFF;
      line-height: 1;
    }
  }

  
	@media (max-width: 767px) {
    & .card-content {
      padding: 30px;
  
      &-title {
        position: relative;
        font-weight: 700;
        font-size: 46px;
        color: #FFFFFF;
        line-height: 1;
      }
  
      &-subtitle {
        font-size: 20px;
        color: #FFFFFF;
        line-height: 1;
      }
    }
  }
`;

const ContainerMobile = styled.div`
  position: relative;
  width: 100%;
  background-color: #E3E3E3;
  background-image: url(${props => props.img});
  background-size: cover;
  background-repeat: no-repeat;
  

  & .card-content {
    padding: 40px 25px;
    text-align: center;
    box-sizing: border-box;

    &-title {
      position: relative;
      font-weight: 700;
      font-size: 24px;
      color: #FFFFFF;
      line-height: 1.5;
    }

    &-subtitle {
      font-size: 20px;
      color: #FFFFFF;
      line-height: 1.2;
    }
  }
`;

const SymbolDesktop = styled.div`
	display: inline-block;
	position: absolute;
	top: -5px;
	height: 14px;
	width: 14px;
	border-radius: 4px;
  background-color: ${color.Primary};
`;

const SymbolMobile = styled.div`
  display: inline-block;
  position: absolute;
  top: 0px;
  height: 8px;
  width: 8px;
  border-radius: 1px;
  background-color: ${color.Primary};
`;


const CardImageHeader = (props) => {
  return (
    <div>
      <MediaQuery minWidth={768}>
        <div className="container">
          <ContainerDesktop img={props.img}>
            <div className="card-content">
              <div className="card-content-title">{props.title}<SymbolDesktop /></div>
              <div className="card-content-subtitle">{props.subtitle.split("\n").map(item => {
                return (<div>{item}</div>)
              })}</div>
            </div>
          </ContainerDesktop>
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={767}>
        <ContainerMobile img={props.img}>
          <div className="card-content">
            <div className="card-content-title">{props.title}<SymbolMobile /></div>
            <div className="card-content-subtitle">{props.subtitle.split("\n").map(item => {
              return (<div>{item}</div>)
            })}</div>
          </div>
        </ContainerMobile>
      </MediaQuery>
    </div>
    
  )
}

export default CardImageHeader;