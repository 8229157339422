import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Button,Modal } from 'react-bootstrap';
import coverImage from '../../../../../../../../assets/images/gtxBusiness/Picture/wed1.jpg';
import coverHuman from '../../../../../../../../assets/images/gtxBusiness/Picture/tem.png';
import color from './../../../../../../../../config/color';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyImage , CardHeader} from '../../../../components';
import appUrl from './../../../../../../../../config/appUrl';

AOS.init({
	delay: 100
});

const CoverContainer = styled.div`
	padding-top: calc(45vh - 210px);
	background-image: url(${coverImage});	
	background-repeat: no-repeat;
	background-size:cover;    
	background-position: center;
	width: 100%;    
    height: calc(100vh);

	& .symbol {
		display: inline-block;
		position: absolute;
		top: -10px;
		height: 14px;
		width: 14px;
		border-radius: 4px;
		background-color: ${color.Primary};
	}

	& .title-header {
		color: #FFFFFF; 
		position: relative;
		font-weight: bold;
		line-height: 40pt;
		font-size: 60px;
	}

	.title-sub-header {
		color: #FFFFFF; 
		font-size: 30px;    
		font-weight: normal;
	}

	.detail {
		font-size: 24px;
		margin-top: 20px;
	}

	.detail a{
		color: rgba(255, 255, 255, 0.6);    
		width: 100%;
		float: left;
		cursor:pointer;
	}

	.detail a:hover{
		color: rgba(255, 255, 255, 1);    
		text-decoration: none;
	}


	.check-button {
		height: 54px;
		width: 276px;
		font-size: 28px;
		border-radius: 6px;
		margin-top: 20px;
		box-shadow: 0px 6px 16px #d9010120;
		background-color: #d90101;

		& i {
			font-size: 20px;
		}

		&:hover {
			background-color: #d90101;
			box-shadow: 0px 10px 20px #d9010140;
		}
	}

	@media (min-width: 992px) {
		.cover-right {    
			padding-top: 150px;
		}

		.img-cover {    
			position: absolute;
			width: 200%;
			right: 0;
		}

		.title-header {
			font-size: 60px;
		}

		.title-sub-header {
			font-size: 30px;    
			font-weight: normal;
		}

		.check-button {
			height: 55px;
			width: 270px;
			font-size: 28px;

			& i {
				font-size: 20px;
			}
		}
	}

	@media (min-width: 1200px) {
		.img-cover {    
			position: absolute;
			width: 200%;
			right: 0;
		}

		.cover-right {    
			padding-top: 150px;
		}
		
		.title-header {
			font-size: 60px;
		}

		.title-sub-header {
			font-size: 30px;    
			font-weight: normal;
		}

		.check-button {
			height: 60px;
			width: 300px;
			font-size: 34px;

			& i {
				font-size: 26px;
			}
		}
	}

	@media (max-width: 992px) {
		padding-top: calc(45vh - 200px);
		.cover-right {
			padding-top: 200px;
		}
	}
`;

const InnerContainer = styled.div`
	padding: 15px;
	height: calc(100vh - 223px);
	overflow: auto;
	border: 1px solid #ddd;
	border-radius: 10px;

	ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
	  }
	  
	  ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	  }
	  
	  ol > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 0.6em;    
	  }
	  
	  li ol > li {
		margin: 0;
	  }
	  
	  li ol > li:before {
		content: counters(item, ".") " ";
	  }
`;

class Cover extends Component {
	state = {
		isTop: true,
		onOpenModal: false
	};

	render() {
	return (
		<div>
			<CoverContainer>
				<div className="container">	
					<Row>
						<Col xl={7} lg={7} md={7} className="size">
							<div className="cover-right">
								<div data-aos="fade-up" data-aos-duration="1500">
									<div className="title-header" style={{fontSize: 100}}>
									โลกใหม่ของโลจิซติกซ์
									</div>
									<div className="title-sub-header" style={{fontSize: 24, marginTop: 10}}>GIZTIX EXPRESS พัฒนาความฝันที่สร้างขึ้นมา จนเป็นที่รู้จัก และมีเทคโนโลยีที่ตอบโจทย์<br />
การส่งสินค้าทุกธุรกิจให้มี ความสะดวก รวดเร็ว ปลอดภัย<br />
ชีวิตธุรกิจของคุณจะดีขึ้นในทุกที่ทุกเวลา...</div>
								</div>

								<div data-aos="fade-up" data-aos-duration="1800">
									<Button bsStyle="primary" className="check-button" onClick={() => window.location = `${appUrl}/register-business`}>
										ร่วมงานกับเรา
									</Button>
								</div>
							</div>
						</Col>
					</Row>
				</div>
				
			</CoverContainer>

		</div>
	);
	}
};

export default Cover;
