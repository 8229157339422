import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Header, BreadcrumbTitel, Footer } from "../../components";
import { withNamespaces } from "react-i18next";
import HeaderHelmet from "../../components/HeaderHelmet";

const Container = styled.div`
  margin: 145px auto 50px;
  text-align: left;
  min-height: 30vh;
`;
const TitelNumber = styled.ul`
  margin-top: 30px;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    padding-left: 0px;
  }
`;
const Titel = styled.div`
  margin-bottom: 30px;
  font-size: 36px;

  @media (max-width: 768px) {
    margin-bottom: 20px;
    font-size: 24px;
  }
`;
const TextIndent2 = styled.p`
  margin-bottom: 15px;
  text-indent: 64px;

  @media (max-width: 768px) {
    text-indent: 20px;
  }
`;

class PrivacyPolicy extends React.Component {
  render() {
    const { t } = this.props;
    const menu = [
      {
        active: true,
        link: "/helpcenter",
        title: "ศูนย์ความช่วยเหลือ >",
      },
      {
        active: true,
        link: "/privacy-policy",
        title: "นโยบายความเป็นส่วนตัว",
      },
    ];

    return (
      <div style={{ backgroundColor: "#ffffff" }}>
        <HeaderHelmet title="Privacy Policy" />
        <Header active={3}></Header>
        <BreadcrumbTitel
          menu={[
            {
              active: true,
              link: "/helpcenter",
              title: `${t("header:helpCenter")} >`,
            },
            {
              active: true,
              link: "/privacy-policy",
              title: t("header:PrivacyPolicy"),
            },
          ]}
        ></BreadcrumbTitel>

        <div>
          <Container className="container">
            <Titel>{t("header:PrivacyPolicy")}</Titel>
            <TextIndent2>
              บริษัท จิซทิกซ์ จำกัด (“บริษัท”, “Giztix” หรือ “จิซทิกซ์”) ซึ่งเป็นบริษัทจดทะเบียนในประเทศไทย คือ บริษัทผู้เป็นเจ้าของและดำเนินการ โดยมุ่งมั่นที่จะปกป้องความเป็นส่วนตัวของผู้ใช้บริการ
              คู่ค้า พันธมิตรทางธุรกิจ (เรียกรวมกันว่า “ท่าน” หรือ “เจ้าของข้อมูล”) บนนโยบายความเป็นส่วนตัวต่อไปนี้ ไม่ว่าจะเป็นการเก็บรวบรวม ใช้ และ/หรือเปิดเผย (เรียกรวมกันว่า การประมวลผลข้อมูล)
              และดำเนินการเกี่ยวกับข้อมูลที่สามารถระบุตัวตนของท่านได้ (ข้อมูลส่วนบุคคล รวมถึงนิติบุคคล) ซึ่งมีความเกี่ยวข้องกับการให้บริการของบริษัทผ่านทางเว็บไซต์ www.giztix.com ("Site","เว็บไซต์")
              แอปพลิเคชั่น (Application) หรือซอฟต์แวร์ระบบ (System Software) ทีมีการอ้างอิงถึงการให้บริการของบริษัทดังกล่าวบนนโยบายความเป็นส่วนตัวฉบับนี้ เมื่อท่านใช้บริการของจิซทิกซ์นั้น
              ท่านได้ยินยอมให้เรารวบรวม ใช้ เปิดเผย และดำเนินการเกี่ยวกับข้อมูลส่วนบุคคลของท่านตามที่ได้ระบุไว้ในนโยบายความเป็นส่วนตัวนี้ เพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.
              2562 จึงออกประกาศฉบับนี้ขึ้น เพื่อแจ้งข้อมูลให้แก่ผู้ใช้บริการอันเกี่ยวกับ สิทธิและหน้าที่ ตลอดจนเงื่อนไขต่างๆ ในการเก็บ รวบรวม ใช้ และ เปิดเผย ข้อมูลส่วนบุคคล{" "}
            </TextIndent2>

            <TextIndent2>เมื่อท่านใช้บริการของ Giztix ย่อมแสดงว่าท่านยินยอมที่จะให้เราใช้ข้อมูลส่วนบุคคลของท่านตามที่ระบุไว้ในนโยบายนี้</TextIndent2>

            <TextIndent2>
              บริษัทจะทำการปรับปรุงนโยบายความเป็นส่วนตัวนี้เมื่อถึงเวลาที่สมควร เพื่อสะท้อนให้เห็นถึงกฎหมายที่เปลี่ยนแปลงไป ขั้นตอนการรวบรวมข้อมูลส่วนบุคคลและแนวทางการนำข้อมูลดังกล่าวไปใช้
              จุดเด่นของเว็บไซต์ หรือความก้าวหน้าทางเทคโนโลยี หากเราเปลี่ยนแปลงวิธีการรวบรวมหรือวิธีการใช้ข้อมูลส่วนบุคคล เราจะแจ้งการเปลี่ยนแปลงนั้นให้ท่านทราบในนโยบายความเป็นส่วนตัวนี้
              โดยจะระบุวันที่เริ่มมีผลบังคับใช้ไว้ที่ด้านบนของนโยบายความเป็นส่วนตัว ดังนั้น ท่านจึงควรตรวจดูนโยบายความเป็นส่วนตัวนี้เป็นประจำ เพื่อให้ทราบถึงนโยบายและแนวทางการปฏิบัติล่าสุดของเรา
              นอกจากนี้ จิซทิกซ์ยังจะแจ้งการเปลี่ยนแปลงดังกล่าวให้ท่านทราบก่อนที่จะเริ่มมีการนำมาใช้ โปรดหยุดใช้บริการของบริษัท หากท่านไม่เห็นด้วยกับการเปลี่ยนแปลงหรือปรับปรุงใดๆ
              ในนโยบายความเป็นส่วนตัว การเข้าใช้บริการหลังวันที่ที่การปรับปรุงนโยบายความเป็นส่วนตัวมีผลบังคับใช้นั้นถือว่าท่านได้ยอมรับการปรับปรุงดังกล่าวแล้ว
            </TextIndent2>

            <TextIndent2>“ข้อมูลส่วนบุคคล” หมายถึง ข้อมูลเกี่ยวกับบุคคลซึ่งทำให้สามารถระบุตัวบุคคลนั้นได้ ไม่ว่าทางตรงหรือทางอ้อม แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรมโดยเฉพาะ</TextIndent2>

            <TextIndent2>
              “ข้อมูลที่มีความอ่อนไหว” หมายถึง ข้อมูลส่วนบุคคลเกี่ยวกับเชื้อชาติ เผ่าพันธ์ุ ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ ประวัติอาชญากรรม ข้อมูลสุขภาพ
              ความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ เช่น ข้อมูลจำลองใบหน้า ข้อมูลจำลองม่านตา หรือข้อมูลจำลองลายนิ้วมือ
            </TextIndent2>

            <TitelNumber>
              <p>
                <strong>1. วิธีการพื้นฐานในการจัดการกับข้อมูลส่วนบุคคล</strong>
              </p>
            </TitelNumber>
            <TextIndent2>
              บริษัท จิซทิกซ์ จำกัด ได้กำหนดนโยบายการปกป้องข้อมูลส่วนบุคคลและจัดทำระบบบริหารจัดการการปกป้องข้อมูลส่วนบุคคลของบริษัทขึ้นเพื่อให้แน่ใจว่าจะมีการบังคับใช้นโยบายเหล่านั้นอย่างเคร่งครัด
              บริษัทมีการดำเนินการอย่างต่อเนื่องเพื่อปรับปรุงและดูแลรักษาระบบของเรา พร้อมการสื่อสารนโยบายการปกป้องข้อมูลส่วนบุคคลของบริษัทไปยังบุคลากรทุกคนของบริษัท (ซึ่งรวมถึงคณะกรรมการของบริษัท
              พนักงานประจำ พนักงานพาร์ทไทม์ พนักงานโครงการระยะสั้น และพนักงานชั่วคราว) ตลอดจนฝ่ายต่างๆ ที่เกี่ยวข้อง
            </TextIndent2>

            <TitelNumber>
              <p>
                <strong>2. การเก็บรวบรวมข้อมูลส่วนบุคคล</strong>
              </p>
            </TitelNumber>
            <ol>
              <li>บริษัทจะเก็บรวบรวมข้อมูลส่วนบุคคลด้วยวิธีการที่ถูกกฎหมายและยุติธรรมเท่านั้น</li>
              <li>
                เมื่อเก็บรวบรวมข้อมูลส่วนบุคคลจากท่านเป็นลายลักษณ์อักษรโดยตรง เราจะระบุวัตถุประสงค์ที่เราต้องการใช้งานข้อมูล ซึ่งประกอบด้วยข้อมูลติดต่อ และขอการยินยอมจากท่าน
                เมื่อเราเก็บรวบรวมข้อมูลส่วนบุคคลโดยใช้วิธีการอื่น เราจะประกาศ
              </li>
              <li>วัตถุประสงค์ที่เราต้องการใช้งานข้อมูลนั้นบนเว็บไซต์ พร้อมรายละเอียดในการติดต่อ</li>
              <li>ท่านสามารถเลือกว่าจะให้ข้อมูลส่วนบุคคลแก่จิซทิกซ์หรือไม่ก็ได้ อย่างไรก็ตาม โปรดทราบว่า อาจมีบางบริการที่เราไม่สามารถมอบให้แก่ท่านได้หากปราศจากข้อมูลส่วนบุคคลของท่าน</li>
              <li>
                เมื่อท่านมอบข้อมูลส่วนบุคคลให้แก่จิซทิกซ์จะเป็นการแสดงว่าท่านยอมรับในข้อกำหนดและเงื่อนไขที่ระบุไว้ในหัวข้อนี้เกี่ยวกับนโยบายความเป็นส่วนตัว
                หากท่านไม่ยอมรับในข้อกำหนดและเงื่อนไขที่ระบุในที่นี้ โปรดทราบว่าเราอาจไม่สามารถให้บริการบางบริการที่เรามีให้ (ซึ่งรวมถึงการตอบกลับข้อสอบถามของท่าน แก่ท่านได้
              </li>
            </ol>

            <TitelNumber>
              <p>
                <strong>3. ข้อมูลส่วนบุคคลที่เราเก็บรวบรวม</strong>
              </p>
            </TitelNumber>
            <ol>
              <li>บริษัทจะใช้ข้อมูลส่วนบุคคลที่เราได้รับจาก่ทานเพื่อดำเนินการตามข้อสัญญาและธุรกรรมที่ตกลงไว้ระหว่างท่านและบริษัทฯ</li>
              <li>
                บริษัทอาจได้รับมอบหมายจากบริษัทลูกค้าให้ดำเนินการกับข้อมูลส่วนบุคคล ซึ่งในกรณีดังกล่าว เราจะใช้ประโยชน์จากข้อมูลส่วนบุคคลของท่านตามที่ได้รับคำสั่งจากบริษัทลูกค้า
                และจะเก็บรักษาข้อมูลนั้นไว้จนกว่าจะส่งคืนข้อมูลนั้นกลับไปยังบริษัทลูกค้าหรือลบออกจากระบบของเรา
              </li>
              <li>บางครั้งเราอาจได้รับรายชื่อหรือรายนามผ่านการจัดหาเชิงธุรกิจหรือทำเนียบนาม</li>
              <li>บริษัทจะไม่เก็บรวบรวม ใช้งาน หรือมอบข้อมูลส่วนบุคคลใดๆ ดังเช่นข้อมูลต่อไปนี้แก่บุคคลที่สามโดยปราศจากการยินยอมจากท่าน</li>
            </ol>

            <TextIndent2>ข้อมูลดังกล่าว ได้แก่:</TextIndent2>
            <ul>
              <li>ปรัชญา ความเชื่อ หรือศาสนาของบุคคล</li>
              <li>เชื้อชาติ ชาติพันธุ์ ความพิการทางร่างกาย/จิตใจ ประวัติอาชญากรรม หรือข้อมูลอื่นใด ที่จะนำไปสู่การเลือกปฏิบัติทางสังคม</li>
              <li>สิทธิของแรงงานในการจัดระเบียบ การร่วมเจรจาต่อรอง หรือกิจกรรมที่กระทำร่วมกัน</li>
              <li>การเข้าร่วมในการประท้วง การร้องเรียน หรือการใช้สิทธิ์ทางการเมืองอื่นๆ</li>
              <li>การรับการรักษาทางการแพทย์หรือพฤติกรรมการมีเพศสัมพันธ์</li>
            </ul>

            <TextIndent2>
              บริษัทรวบรวมข้อมูลส่วนบุคคลของท่านซึ่งท่านมอบให้แก่เราเมื่อใช้บริการเว็บไซต์ ข้อมูลส่วนบุคคลดังกล่าวรวมถึงชื่อ ที่อยู่ หมายเลขโทรศัพท์ ที่อยู่อีเมล ชื่อบริษัท ที่ตั้งบริษัท
              และ/หรือราคาค่าบริการด้านโลจิสติกส์ นอกเหนือจากนั้น เรายังรวบรวมข้อมูลที่ไม่ระบุตัวตนของท่านซึ่งอาจเชื่อมโยงไปสู่ข้อมูลส่วนตัวของท่านได้ โดยข้อมูลดังกล่าวได้แก่ชื่อล็อกอิน
              รหัสตัวเลขประจำเครื่องคอมพิวเตอร์ (IP Address) ข้อมูลทางภูมิศาสตร์ประเภทของระบบปฏิบัติการ สัญชาติ และรหัสผ่าน
              รวมไปจนถึงการตั้งค่าค้นหาที่เกี่ยวข้องกับการค้นหาสิ่งใดสิ่งหนึ่งโดยเฉพาะและข้อมูลที่เกี่ยวข้องกับการใช้งานอินเทอร์เน็ตทั่วไป
            </TextIndent2>

            <TextIndent2>
              เรายังเก็บรวบรวมข้อมูลที่ท่านมอบให้เมื่อ่ทานเชื่อมต่อกับบริการของ Giztix จากแอปพลิเคชัน เว็บไซต์เครือข่ายสังคมของบุคคลที่สาม (เช่น Facebook) หรือเว็บไซต์อื่นๆ (เช่น
              หุ้นส่วนที่ดูแลเรื่องการส่งเสริมการขาย)
            </TextIndent2>

            <TextIndent2>
              สำหรับพนักงานขับรถที่ใช้งาน โมบายแอปพลิเคชัน เพื่อทำการขนส่ง ทางแอปพลิเคชันจะขอการเข้าถึงตำแหน่งของท่าน รูปภาพ และกล้องถ่ายรูปเพื่อใช้สำหรับสร้างหลักฐานการขนส่งเพียงเท่านั้น
            </TextIndent2>

            <TitelNumber>
              <p>
                <strong>4. เราใช้ข้อมูลส่วนบุคคลของท่านอย่างไร</strong>
              </p>
            </TitelNumber>
            <TextIndent2>บริษัทจะใช้ข้อมูลส่วนบุคคลที่เราได้รับจากท่านเพื่อดำเนินการตามข้อสัญญาและธุรกรรมที่ตกลงไว้ระหว่างท่านและบริษัทฯ</TextIndent2>
            <TextIndent2>
              บริษัทจะใช้ข้อมูลส่วนบุคคลของท่านเพื่อนำเสนอข้อมูลที่เกี่ยวข้องซึ่งอยู่ในความสนใจของท่าน เช่น ข้อมูลเกี่ยวกับผลิตภัณฑ์ โซลูชัน และบริการที่เกี่ยวเนื่องของเรา บางครั้ง
              จิซทิกซ์อาจได้รับมอบหมายจากบริษัทลูกค้าให้ประมวลผลข้อมูลหรือดำเนินกิจกรรมลักษณะอื่นๆ ในนามของบริษัทลูกค้า ข้อมูลส่วนบุคคลที่เราได้รับโดยเป็นผลมาจากกิจกรรมข้างต้น
              จะถูกใช้สำหรับกิจกรรมที่เราได้รับมอบหมายให้ดำเนินการเท่านั้น ในกรณีดังกล่าว จิซทิกซ์ และบริษัทลูกค้าจะมีข้อผูกพันภายใต้ข้อตกลงในการรักษาความลับ
              โดยจะไม่เปิดเผยชื่อของบริษัทลูกค้าหรือรายละเอียดของกิจกรรมนั้นๆ
            </TextIndent2>
            <TextIndent2>
              จิซทิกซ์จะไม่ใช้ข้อมูลส่วนบุคคลที่ได้รับจากท่านเพื่อวัตถุประสงค์อื่นใดนอกเหนือจากที่ระบุไว้ขณะเก็บรวบรวมข้อมูลดังกล่าว อย่างไรก็ตาม เราอาจใช้ข้อมูลนั้น (โดยไม่มีการระบุเป็นรายบุคคล)
              เพื่อรวบรวมข้อมูลเชิงสถิติเกี่ยวกับจำนวนผู้เยี่ยมชมเว็บไซต์ ปริมาณการขาย และข้อมูลเกี่ยวกับลักษณะการนาวิเกตของผู้เยี่ยมชมในเว็บไซต์ของเรา
            </TextIndent2>
            <TextIndent2>การสอบถามข้อมูลเกี่ยวกับลักษณะที่เราใช้รายชื่อผู้ถือหุ้น</TextIndent2>
            <TextIndent2>
              เราใช้ข้อมูลส่วนบุคคลที่เก็บรวบรวมผ่านโปรแกรมการตรวจสอบความปลอดภัยในการใช้งานผลิตภัณฑ์ระยะยาว เพื่อแจ้งให้ลูกค้าทราบถึงการตรวจสอบ และให้ข้อมูลเกี่ยวกับการตรวจสอบนั้น
              ตลอดจนการเรียกคืนผลิตภัณฑ์
            </TextIndent2>

            <TitelNumber>
              <p>
                <strong>5. เราจะเปิดเผยหรือมอบข้อมูลส่วนบุคคลของท่านแก่บุคคลที่สามเมื่อใด</strong>
              </p>
            </TitelNumber>
            <TextIndent2>ตามกฎระเบียบนั้นจิซทิกซ์จะต้องไม่เปิดเผยหรือให้ข้อมูลส่วนบุคคลที่เราได้รับจากท่านแก่บุคคลที่สาม ยกเว้นในกรณีต่อไปนี้:</TextIndent2>
            <ol>
              <li>เมื่อเราได้รับการยินยอมจากท่าน</li>
              <li>เมื่อเราว่าจ้างบุคคลที่สามเพื่อจัดการกับข้อมูลส่วนบุคคลบางส่วนหรือทั้งหมดที่เราเก็บรวบรวมได้ เพื่อให้บริการที่จำเป็นต่อการบรรลุวัตถุประสงค์ของเรา</li>
              <li>เมื่อข้อมูลส่วนบุคคลนั้นได้รับมาโดยเป็นผลสืบเนื่องมาจากการควบรวมกิจการหรือการถ่ายโอนอื่นๆ ทางธุรกิจ</li>
              <li>เมื่อมีการใช้งานข้อมูลส่วนบุคคลนั้นร่วมกับบุคคลที่ระบุไว้ (โปรดดูที่ข้อ 7. การใช้ข้อมูลส่วนบุคคลร่วมกัน)</li>
              <li>เมื่อเราได้รับคำสั่งตามกฎหมายให้เปิดเผยข้อมูลดังกล่าว</li>
              <li>เมื่อเราจำเป็นต้องดำเนินการดังกล่าวเพื่อปกป้องชีวิต ร่างกาย หรือทรัพย์สินของบุคคล และการได้มาซึ่งการยินยอมจากท่านเป็นไปด้วยความยากลำบาก</li>
              <li>เมื่อมีความจำเป็นต้องดำเนินการดังกล่าวเพื่อปรับปรุงระบบสาธารณสุขหรือส่งเสริมสวัสดิภาพหรือความเป็นอยู่ที่ดีของเด็ก และการได้มาซึ่งการยินยอมจากท่านเป็นไปด้วยความยากลำบาก</li>
              <li>
                เมื่อจำเป็นต้องร่วมมือกับหน่วยงานภาครัฐในระดับประเทศหรือระดับท้องถิ่น หรือองค์กรที่ภาครัฐมอบหมายให้ดำเนินกิจการตามที่กฎหมายกำหนด
                แต่การได้มาซึ่งการยินยอมจากท่านอาจเป็นการขัดขวางการดำเนินกิจการดังกล่าว
              </li>
              <li>
                เพื่อยุติการให้ข้อมูลส่วนบุคคลแก่บุคคลที่สามโดยปราศจากการยินยอมจากลูกค้า: ในบางครั้งจิซทิกซ์อาจจำเป็นต้องมอบข้อมูลส่วนบุคคลที่ได้รับจากลูกค้าแก่บุคคลที่สาม เช่น
                บริษัทในเครือของจิซทิกซ์และผู้จัดจำหน่าย เพื่อรับทราบข้อมูลเพิ่มเติมเกี่ยวกับข้อมูลส่วนบุคคลที่เราให้แก่บุคคลที่สาม ตลอดจนวิธีการให้ข้อมูล และวิธีขอให้ยุติการให้ข้อมูลดังกล่าว
              </li>
            </ol>

            <TitelNumber>
              <p>
                <strong>6. เราปกป้องข้อมูลส่วนบุคคลของท่านอย่างไร</strong>
              </p>
            </TitelNumber>
            <TextIndent2>จิซทิกซ์จะใช้การป้องกันในเชิงบริหารจัดการ ทางเทคนิค ส่วนบุคคล และทางกายภาพเพื่อปกป้องข้อมูลส่วนบุคคลของท่าน</TextIndent2>
            <ol>
              <li>
                จิซทิกซ์จะใช้มาตรการรักษาความปลอดภัยที่จำเป็น และดำเนินการแก้ไขตามความจำเป็นเพื่อปกป้องข้อมูลส่วนบุคคลของท่านจากการเข้าถึงโดยไม่ได้รับอนุญาต การสูญหาย การทำลาย การดัดแปลงแก้ไข หรือ
                การรั่วไหลของข้อมูล อย่างไรก็ตาม โปรดทราบว่า ไม่มีเทคโนโลยีใดที่สามารถป้องกันการเข้าถึงข้อมูลส่วนบุคคลอย่างผิดกฎหมายได้โดยสมบูรณ์
              </li>
              <li>พนักงานของ จิซทิกซ์จะได้รับความรู้เกี่ยวกับการปกป้องข้อมูลส่วนบุคคลอย่างสม่ำเสมอ</li>
              <li>
                ในกรณีที่ จิซทิกซ์ว่าจ้างบริษัทต่างๆ เพื่อให้บริการในนามของบริษัทฯ และมอบหมายให้บริษัทเหล่านั้นรับผิดชอบในการจัดการกับข้อมูลส่วนบุคคล
                เราจะเลือกผู้รับเหมาช่วงที่เหมาะสมและกำหนดให้บริษัทดังกล่าวลงนามในข้อตกลงทั้งหมดที่จำเป็นเกี่ยวกับการจัดการข้อมูลส่วนบุคคล นอกจากนี้
                เรายังจะใช้มาตรการความปลอดภัยทั้งหมดตามที่กฎหมายกำหนดไว้
              </li>
              <li>การเก็บรวบรวมข้อมูลส่วนบุคคลผ่านเว็บไซต์ของเรา:</li>
            </ol>

            <TextIndent2>- การเข้ารหัส</TextIndent2>
            <TextIndent2>
              เมื่อเราเก็บรวบรวมข้อมูลส่วนบุคคลผ่านทางเว็บไซต์ของเรา เราจะใช้การเข้ารหัสแบบ Secure Sockets Layer (SSL) เพื่อให้แน่ใจได้ว่าจะมีการส่งผ่านข้อมูลส่วนบุคคลของท่านอย่างปลอดภัย
            </TextIndent2>

            <TextIndent2>- คุกกี้</TextIndent2>
            <TextIndent2>
              เว็บไซต์ของ จิซทิกซ์ ใช้คุกกี้ในบางกรณี คุกกี้ คือไฟล์ข้อมูลขนาดเล็กที่จัดเก็บข้อมูลซึ่งแลกเปลี่ยนระหว่างคอมพิวเตอร์ของท่านและเว็บไซต์ของเรา
              เราใช้คุกกี้เฉพาะเพื่อการจัดเก็บข้อมูลที่อาจเป็นประโยชน์ต่อท่านในครั้งถัดไปที่ท่านกลับมาเยี่ยมชมเว็บไซต์ของเรา และอำนวยความสะดวกมากขึ้นในแต่ละครั้งที่ท่านมีการโต้ตอบกับเราเท่านั้น
              หากท่านไม่ต้องการได้รับคุกกี้ ท่านสามารถปิดการใช้งานคุกกี้โดยเปลี่ยนแปลงการตั้งค่าบนเว็บเบราเซอร์ของท่าน อย่างไรก็ตาม โปรดทราบว่า บริการบางอย่างอาจไม่สามารถให้บริการแก่ท่าน
              หากปิดใช้งานคุกกี้
            </TextIndent2>

            <TextIndent2>- เว็บบีคอน (Web beacons)</TextIndent2>
            <TextIndent2>
              เว็บไซต์ของจิซทิกซ์จะใช้เว็บบีคอนในบางกรณี เว็บบีคอน คือเทคโนโลยีที่ใช้กันโดยทั่วไปโดยทำงานร่วมกับคุกกี้
              การใช้งานเว็บบีคอนทำให้เราสามารถรับทราบจำนวนครั้งที่ท่านเยี่ยมชมเว็บไซต์ของเราจากคอมพิวเตอร์ของท่านและรวบรวมข้อมูลสถิติเกี่ยวกับการใช้งานเว็บไซต์ของเราโดยตัวท่าน
              โดยส่วนใหญ่เราจะใช้เว็บบีคอนเพื่อช่วยให้เราปรับปรุงผลิตภัณฑ์และบริการสนับสนุนที่เรามี เราไม่ได้ใช้เว็บบีคอนเพื่อเก็บรวบรวมข้อมูลส่วนบุคคลจากท่าน แต่การปฏิเสธการรับคุกกี้
              จะทำให้ท่านสามารถปฏิเสธเว็บบีคอนด้วย
            </TextIndent2>

            <TextIndent2>- ลิงก์ไปยังเว็บไซต์อื่น</TextIndent2>
            <TextIndent2>เว็บไซต์ จิซทิกซ์อาจมีลิงก์ไปยังเว็บไซต์อื่นๆ โปรดทราบว่าเราจะไม่รับผิดชอบต่อวิธีการปฏิบัติด้านความเป็นส่วนตัวที่ใช้โดยเว็บไซต์อื่นใด นอกเหนือจากของเราเอง</TextIndent2>

            <TitelNumber>
              <p>
                <strong>7. การใช้ข้อมูลส่วนบุคคลร่วมกัน</strong>
              </p>
            </TitelNumber>
            <TextIndent2>
              จิซทิกซ์อาจแบ่งปันข้อมูลส่วนบุคคลของท่านกับผู้จัดหา เช่น รอยัลตี้โปรแกรมบุคคลที่สาม ซึ่งเราแต่งตั้งมาเพื่อให้บริการในฐานะตัวแทนของเรา เช่นเว็บโฮสติงและวิเคราะห์ข้อมูลเพื่อการตลาด
              (รวมถึงบนเว็บไซต์ของบุคคลที่สามเหล่านั้น)เพื่ออำนวยลูกค้าสัมพันธ์ เพื่อการดูแลบริการด้านโลจิสติกส์ และเพื่อการบริการอื่นๆ ที่เกี่ยวข้อง
              บุคคลที่สามดังกล่าวจะอยู่ภายใต้ข้อบังคับในการรักษาความปลอดภัยและความลับของข้อมูลส่วนบุคคล และจะใช้ข้อมูลส่วนบุคคลตามคำสั่งของเราเท่านั้น
            </TextIndent2>
            <TextIndent2>
              เราอาจมีการให้บริการอื่นๆ (เช่น บริการ"เพิ่มเติม") เพื่อช่วยให้ท่านวางแผนการขนส่งหรือรับประโยชน์ หรือสำหรับสิ่งอื่นใดที่ท่านสนใจ บริการนี้มาจากผู้สนับสนุนบุคคลที่สาม อย่างที่กล่าวข้างต้น
              อาจมีการแบ่งปันข้อมูลที่เกี่ยวข้องกับบุคคลที่สามเพื่อการบริการที่สะดวกและเหมาะสมยิ่งขึ้น เราจะไม่เปิดเผยข้อมูลส่วนบุคคลของท่านต่อบุคคลที่สามโดยไม่ได้รับการยินยอมจากท่าน
              เว้นเสียแต่เป็นการเปิดเผยข้อมูลดังที่มีระบุในนโยบายความเป็นส่วนตัวนี้ หรือการเปิดเผยข้อมูลตามการอนุญาตทางกฎหมายของเขตอำนาจศาลใดๆ ที่เกี่ยวข้องดังจะกล่าวถึงด้านล่าง
              รวมถึงการเปิดเผยข้อมูลส่วนบุคคลและข้อมูลที่จำเป็นสำหรับดำเนินการที่จองบริการด้านโลจิสติกส์ที่ท่านเลือกให้เสร็จสมบูรณ์ ตลอดจนการแบ่งปันข้อมูลส่วนบุคคลเฉพาะรายการกับพันธมิตรที่เกี่ยวข้อง
              (รายละเอียดเกี่ยวกับท่านจะถูกนำไปใช้เพื่อจุดประสงค์ด้านการรายงานและวิเคราะห์เท่านั้น) อย่างไรก็ตาม เราขอสงวนสิทธิที่จะเปิดเผยข้อมูลส่วนบุคคลของท่านต่อบริษัทหรือกลุ่มบริษัทพันธมิตรของเรา
              (ทั้งในและนอกประเทศของท่าน) ซึ่งรวมถึงพนักงานของบริษัทหรือกลุ่มบริษัทพันธมิตร เอเย่นต์ และผู้แทนที่ไว้วางใจได้ของเราที่สามารถเข้าถึงข้อมูลนี้ได้โดยได้รับอนุญาตจากเรา
              และจำเป็นต้องทราบหรือเข้าถึงข้อมูลนี้เพื่อดำเนินการให้บริการตามคำขอของท่าน (รวมถึงงานบริการลูกค้าสัมพันธ์) และเพื่อการตรวจสอบภายในองค์กร
              (การตรวจสอบบัญชีหรือการตรวจสอบการปฏิบัติตามข้อกำหนด)
            </TextIndent2>
            <TextIndent2>
              เราอาจเปิดเผยข้อมูลส่วนบุคคลโดยชอบด้วยกฎหมายที่เกี่ยวข้องเพื่อเป็นการป้องกันตัวเองจากความรับผิดชอบ เพื่อปฏิบัติตามหมายศาล ตามกระบวนการยุติธรรม ตามหนังสือรับรองหรือเทียบเท่า
              ตามคำร้องขอจากเจ้าหน้าที่ผู้รักษากฎหมาย หรือเจ้าหน้าที่ผู้มีอำนาจเพื่อสืบค้นการทุจริตหรือการกระทำที่ผิด หรือตามความจำเป็นอื่นๆ เพื่อให้สอดคล้องกับกฎหมายที่บังคับใช้
              และเพื่อปกป้องสิทธิประโยชน์ทางกฎหมายของเรา รวมถึงเปิดเผยข้อมูลส่วนบุคคลให้แก่ผู้ซื้อในเรื่องที่เกี่ยวกับยอดขาย การมอบหมายหน้าที่ และการโอนถ่ายอื่นๆ
              ของส่วนหนึ่งหรือทั้งหมดของธุรกิจหรือบริษัทของเรา นอกจากนี้
              เรายังอาจเปิดเผยข้อมูลส่วนบุคคลโดยชอบด้วยกฎหมายที่เกี่ยวข้องเพื่อเป็นการบังคับใช้ข้อกำหนดและเงื่อนไขที่เกี่ยวข้องกับบริการของเรา หรือเพื่อปกป้องสิทธิ ทรัพย์สิน
              หรือความปลอดภัยของเราผู้ใช้ และผู้อื่น ในสถานการณ์เช่น ที่กล่าวมาข้างต้นผู้รับข้อมูลส่วนบุคคลจากเราอาจตั้งอยู่ในประเทศที่อาจจะมีระดับการปกป้องข้อมูลตามมาตรฐานที่แตกต่าง
              เมื่อเปรียบเทียบกับกฎหมายซึ่งบังคับใช้ในประเทศของท่าน
            </TextIndent2>

            <TitelNumber>
              <p>
                <strong>8. คำถามที่เกี่ยวข้องกับข้อมูลส่วนบุคคล</strong>
              </p>
            </TitelNumber>
            <ol>
              <li>ท่านสามารถขอให้เราเปิดเผยข้อมูลส่วนบุคคลของท่านที่เรามีในครอบครองได้ นอกจากนี้ ท่านยังสามารถสอบถามเกี่ยวกับผลของคำขอนั้นและขอให้มีการแก้ไขข้อมูลได้ตามความจำเป็น</li>
              <li>ท่านสามารถส่งคำถามหรือแจ้งความคิดเห็นเกี่ยวกับการจัดการข้อมูลส่วนบุคคลของท่านให้เราทราบได้</li>
              <li>จิซทิกซ์จะตอบกลับคำขอดังกล่าวอย่างเหมาะสมและสมเหตุสมผล</li>
            </ol>

            <TextIndent2>โปรดทราบว่า คำขอที่ดำเนินการทางโทรศัพท์อาจมีการบันทึกการสนทนา เพื่อให้แน่ใจได้ว่าจะมีการตอบสนองอย่างเหมาะสมต่อคำขอของท่าน</TextIndent2>
            <p>สำหรับการสอบถามเกี่ยวกับข้อมูลส่วนบุคคล โปรดติดต่อ:</p>
            <p>บริษัท จิซทิกซ์ จำกัด</p>
            <p>จำกัดสถานที่ติดต่อ:</p>
            <p>777 อาคาร ดับบลิวเอชเอ ทาวเวอร์ ชั้น 15 หมู่ที่ 13 ถนนเทพรัตน (บางนา-ตราด) กม.7 ตำบลบางแก้ว อำเภอบางพลี จังหวัดสมุทรปราการ 10540</p>
            <p>ช่องทางการติดต่อ: 02-026-6895</p>
            <p>อีเมล: service@giztix.com</p>
            <p>เวลาทำการ: วันจันทร์ - ศุกร์ ตั้งแต่เวลา 8.00 - 17.00 น.</p>

            <TitelNumber>
              <p>
                <strong>9. ความเป็นส่วนตัวของเด็ก</strong>
              </p>
            </TitelNumber>
            <TextIndent2>
              จิซทิกซ์จะใช้ความระมัดระวังขั้นสูงสุดในการรักษาความปลอดภัยของข้อมูลส่วนบุคคลของเด็กที่มีอายุไม่เกิน 15 ปี เรามีข้อกำหนดให้เด็กที่มีอายุไม่เกิน 15 ปี
              ต้องแจ้งข้อมูลส่วนบุคคลโดยได้รับความยินยอมจากพ่อแม่และผู้ปกครองเท่านั้น
            </TextIndent2>
          </Container>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withNamespaces()(PrivacyPolicy);
