import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  & .help-box {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    border: 1px solid #E3E3E3;
    border-radius: 6px;
    padding: 0 20px;
    padding-top: 15px;

    @media(max-width: 768px) {
      padding: 10px 15px 0px 15px;
    }

    &-content {
      flex: 1;
      display: flex;
      flex-direction: column;

      &-header {
        cursor: pointer;
        font-size: 24px;
        text-align: left;
        padding-bottom: 12px;

        @media(max-width: 768px) {
          font-size: 20px;
        }

        &-open {
          font-weight: 700;
        }
      }

      &-detail {
        text-align: left;
        padding-bottom: 15px;
        font-size: 20px;
        line-height: 1;
      }
    }

    &-icon {
      cursor: pointer;
      font-size: 18px;
      color: #808080;
      margin-left: 30px;

      @media(max-width: 768px) {
        font-size: 16px;
      }
    }
  }
`;

class Accordion extends React.Component {
  render() {
    return (
      <Container>
        <div className="help-box" style={this.props.style}>
          <div className="help-box-content">
            <div className={`help-box-content-header ${this.props.isOpen && 'help-box-content-header-open'}`} onClick={() => this.props.onClick(this.props.title)}>{ this.props.title }</div>
            { this.props.isOpen ? (
              <div className="help-box-content-detail">
                {this.props.children}
              </div>
            ) : null }
          </div>

          <div className="help-box-icon" onClick={() => this.props.onClick(this.props.title)}>
            { this.props.isOpen ? (
              <i className="far fa-minus"></i>
            ) : (
              <i className="far fa-plus"></i>
            ) }
          </div>
        </div>
      </Container>
    )
  }
}

export default Accordion;