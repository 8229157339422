import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
	CardImageHeader,
	BreadcrumbTitel,
    Accordion,
} from './../../components';
import { Helmet } from 'react-helmet';
import imageDesktop from '../../assets/images/insurance/insurance-01.jpg';
import imageMobile from '../../assets/images/insurance/insurance-02.png';
import imageInsurance from '../../assets/images/insurance/icon.png';
//image tab
import image10WheelsCabinet from '../../assets/images/pricing/newModel/01-10WHEELS_CABINET.png';
import image10WheelsStall from '../../assets/images/pricing/03-10WHEELS_STALL.png';
import image6WheelsCabinet from '../../assets/images/pricing/newModel/01-6WHEELS_CABINET.png';
import image6WheelsCrane from '../../assets/images/pricing/04-6WHEELS_CRAN.png';
import imagePickupStall from '../../assets/images/pricing/03-PICKUP_STALL.png';
import imagePickupCabinet from '../../assets/images/pricing/newModel/01-PICKUP-CABINET.png';
import image6WheelsStall from '../../assets/images/pricing/03-6WHEELS_STALL.png';
import imageTrailer20FT from '../../assets/images/pricing/newModel/02-TRAILER_20FT.png';
import image6WheelsLift from '../../assets/images/pricing/6WHEELS_LIFT.png';
import image10WheelsLift from '../../assets/images/pricing/10WHEELS_LIFT.png';
import imageTrailer from '../../assets/images/pricing/06-TRAILER.png';
import imageTrailer20 from '../../assets/images/pricing/container20.png';
import imageTrailer40 from '../../assets/images/pricing/container40.png';
import { Row, Col } from 'react-bootstrap';
import color from '../../config/color';
import { withNamespaces } from "react-i18next";
import ReactHTMLParser from 'react-html-parser';

const Container = styled.div`
	& ul{
		padding-left: 15px;
	}
	& li{
		list-style: none;
	}
	@media(max-width: 767px) {
		& .price-container > div {
			
			margin-top: 120px;
			&:first-child{
				margin-top: 0px;
			}
			&:last-child{
				margin-top:50px;
			}
		}
	}
`;

const PriceContainerd = styled(Row)`
	margin-top:130px;
	@media(max-width: 992px) {
		margin-top:30px;
	}
`;

const TabContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow-x: auto;

	@media(max-width: 768px) {
		justify-content: flex-start;
	}

	&::-webkit-scrollbar {
    display: none;
	}

	& .active {
		border-bottom: 2px solid ${color.Primary};
		font-weight: 700;
	}

	& .tab {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 95px;

			@media(max-width: 768px) {
				margin-right: 60px;
			}
    }
    
    &-disabled {
      opacity: 0.5;
    }

		&-image {
			& img {
				height: 60px;
			}
		}

		&-title {
			font-size: 24px;
			color: #000000;
			margin-top: 5px;
			white-space: nowrap;

			@media(max-width: 768px) {
				font-size: 20px;
			}

			&:hover {
				color: rgba(0, 0, 0, 0.8);
      }
		}
	}
`;

const Title = styled.div`
	font-size: 36px;
	font-weight: 700;
	color: #000000;
	line-height: 1;
`;

const SubTitle = styled.div`
	font-size: 24px;
`;

const PriceContainer = styled.div`
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;

	@media(max-width: 992px) {
		margin-top: 80px;
	}

	& .image-truck {
		align-self: center;
		position: absolute;
		top: -65px;

		& img {
			height: 140px;
		}

		&-trailer {
			top: -90px;

			& img {
				height: 200px;
			}
		}
	}

	& .title {
		text-align: center;
		margin-top: 90px;
		font-size: 30px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 10px;
	}

	& .detail {
		font-size: 24px;
		text-align: center;
		margin-bottom: 15px;
		line-height: 1;
	}

	& .box {
		border-radius: 6px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 10px;
		margin-bottom: 10px;

		&-title {
			flex: 1;
			font-size: 24px;
			font-weight: 700;
			line-height: 1;
			padding: 10px;
		}

		&-number {
			text-align: right;
			font-size: 36px;
			font-weight: 700;
			white-space: nowrap;

			&-unit {
				text-align: left;
				display: inline-block;
				width: 25px;
				font-size: 20px;
			}
		}

		&-service-per-trip {
			background-color: ${color.Primary};
			color: #ffffff;
		}

		&-service-minimum-price {
			background-color: ${color.White};
			color: ${color.Primary};
			border: 1px solid ${color.Primary};
		}

		&-discount {
			background-color: ${color.White};
			color: ${color.Black};
			border: 1px solid ${color.Black};
		}
	}

	& .price-list {
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;

		&-header {
			font-weight: 700;
		}

		&-left {
			flex: 1;
		}

		&-right {
			font-weight: 700;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #e3e3e3;
		}
  }
  
  .hide {
    display: none;
  }
`;

const BoxPriceService = styled.div`
	position: relative;
	border-radius: 6px;
	padding: 10px;
	margin-bottom: 10px;
	line-height: 1;
	font-size: 18px;
	background-color: ${color.White};
	color: ${color.Black};
	border: 1px solid ${color.Black};
	text-align: center;
	margin-left: 15px;
	padding-left: 38px;

	& .price {
		font-size: 28px;
		font-weight: bold;
		margin: 0px 5px;
	}

	&.text-bold {
		font-weight: bold;
	}

	& img{
		position: absolute;
		left: -20px;
		top: -4px;
	}

	&.bg-red {
		background-color: ${color.White};
		color: ${color.Primary};
		border: 1px solid ${color.Primary};
	}
`;

const Wrapper = styled.div`
	padding-top: 120px;
	padding-bottom: 100px;

	@media(max-width: 767px) {
		padding-top: 15px;
		padding-bottom: 20px;
	}
`;

class Pricing extends React.Component {
	state = {
		selected: 0,
		openSections:'สินค้าที่รับประกัน'
	}

	onClick = title => {
        const {
          state: { openSections },
        } = this;
    
        const isOpen = !!openSections[title];
    
        this.setState({
          openSections: {
            [title]: !isOpen
          }
        });
    };

	handleSelectTab(tabIndex) {
		this.setState({ selected: tabIndex });
	}

	renderTranslate(text) {
		return (
			<div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: text}} />
		)
	}

	render() {
		const menu = [
			{
				active: true,
				link: '#',
				title: 'การคุ้มครองสินค้าของ GIZTIX EXPRESS',
			},
		];

		return (
			<Container>
				<Helmet>
					<title>การคุ้มครองสินค้าของ GIZTIX EXPRESS</title>
					<meta name="description" content="นโยบายการคุ้มครองสินค้าจาก GIZTIX EXPRESS" />
				</Helmet>
				<Header />
				<BreadcrumbTitel menu={menu} />
				
				<Wrapper>
					<CardImageHeader
						img={imageDesktop}
						imgMobile={imageMobile}
						title="นโยบายการคุ้มครองสินค้าจาก GIZTIX EXPRESS"
						subtitle={`GIZTIX EXPRESS มีนโยบายการคุ้มครองสินค้าระว่างการขนส่ง วงเงินสูงสุด 1,000,000 บาท \n โดยแยกตามประเภทรถขนส่งสินค้าของ GIZTIX EXPRESS ให้ลูกค้ามั่นใจว่า สินค้าของคุณจะถูกเราดูแลอย่างดี`}
					/>

					<div className="container">
						<Row style={{ marginTop: 60 }}>
							<Col md={12} lg={12} className="text-center">								
								<Title>วงเงินการคุ้มครองสินค้าของ GIZTIX EXPRESS</Title>
								<SubTitle>ตามประเภทรถขนส่งสินค้า</SubTitle>
							</Col>
						</Row>

						<PriceContainerd className={`price-containerd`}>
							<Col md={3} lg={3} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={imagePickupCabinet} alt="imagePickupCabinet" />
									</div>
									<div className="title">วงเงินการคุ้มครองสินค้า</div>
									<div className="detail">
										ประเภทรถ 4 ล้อ 
									</div>
									
									<BoxPriceService className="text-bold bg-red">
										<img src={imageInsurance}  alt="icon insurance" />
										<span>สูงสุด</span> <span className="price">300,000</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={3} lg={3} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image6WheelsCabinet} alt="image6WheelsCabinet" />
									</div>
									<div className="title">วงเงินการคุ้มครองสินค้า</div>
									<div className="detail">
										ประเภทรถ 6 ล้อ 
									</div>
									<BoxPriceService className="text-bold bg-red">
										<img src={imageInsurance}  alt="icon insurance" />
										<span>สูงสุด</span> <span className="price">500,000</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={3} lg={3} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image10WheelsCabinet} alt="image10WheelsCabinet" />
									</div>
									<div className="title">วงเงินการคุ้มครองสินค้า</div>
									<div className="detail">
										ประเภทรถ 10 ล้อ 
									</div>
									<BoxPriceService className="text-bold bg-red">
										<img src={imageInsurance}  alt="icon insurance" />
										<span>สูงสุด</span> <span className="price">500,000</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={3} lg={3} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={imageTrailer20FT} alt="imageTrailer20FT" />
									</div>
									<div className="title">วงเงินการคุ้มครองสินค้า</div>
									<div className="detail">
										ประเภทรถหัวลาก
									</div>
									<BoxPriceService className="text-bold bg-red">
										<img src={imageInsurance}  alt="icon insurance" />
										<span>สูงสุด</span> <span className="price">1,000,000</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>
						</PriceContainerd>

						<Row style={{ marginTop: 60 }}>
							<Col md={12} lg={12} className="text-center">								
								<Title>เงื่อนไขการคุ้มครอง</Title>
								<SubTitle>รายละเอียดและคำอธิบายการคุ้มครองสินค้าสำหรับลูกค้าองค์กร/ธุรกิจ</SubTitle>
								<SubTitle><a href='https://app.giztix.com/giztixbusiness' target='_blank' >สมัครจิซทิกซ์สำหรับองค์กร/ธุรกิจ >></a></SubTitle>
							</Col>
						</Row>

						<Row  style={{marginBottom:"40px"}}>
							<Col sm={12}>
								<Accordion style={{marginBottom: 10, marginTop: 10}} title="สินค้าที่รับประกัน" isOpen={!!this.state.openSections['สินค้าที่รับประกัน']} onClick={title => this.onClick(title)}>
									สินค้าทั่วไป
								</Accordion>

								<Accordion title="สินค้าที่ยกเว้นการรับประกัน" isOpen={!!this.state.openSections['สินค้าที่ยกเว้นการรับประกัน']} onClick={title => this.onClick(title)}>
									<ul>
										<li>A. สิ่งมีชีวิต, ต้นไม้, อาหารสด, สินค้าที่ต้องควบคุมอุณหภูมิ</li>
										<li>B. เหล้า, บุหรี่, เครื่องดื่มทุกชนิด</li>
										<li>C. สินค้าอิเล็กทรอนิกส์, คอมพิวเตอร์, โน๊ตบุค, โทรศัพท์มือถือ, แท็บเล็ต</li>
										<li>D. รถยนต์, รถจักรยานยนต์, เครื่องจักรขนาดใหญ่</li>
										<li>E. สิ่งของชำรุด, สิ่งของส่งซ่อม</li>
										<li>F. เวชภัณฑ์, ยารักษาโรค, เครื่องมือแพทย์, อุปกรณ์ทางการแพทย์</li>
										<li>G. สินค้าผิดกฎหมายทุกชนิด</li>
										<li>H. สินค้าที่ไม่มีใบอนุญาติขนส่ง</li>
									</ul>
								</Accordion>

								<Accordion style={{marginBottom: 10, marginTop: 10}} title="วงเงินจำกัดการเอาประกันภัย" isOpen={!!this.state.openSections['วงเงินจำกัดการเอาประกันภัย']} onClick={title => this.onClick(title)}>
									<ul>
										<li>A. การลักทรัพย์ ยกเว้นการลักทรัพย์การลักทรัพย์ที่เป็นผลมาจากอุบัติเหตุของยานพาหนะที่ขนส่ง วงเงินจำกัด ไม่เกิน 20,000 บาทต่อครั้ง</li>
										<li>B. ความสูญเสีย หรือเสียหายจากการเปียกน้ำ หรือเสียหายจากการเปียกน้ำที่เป็นผลมาจากอุบัติเหตุของยานพาหนะที่ขนส่ง วงเงินจำกัด ไม่เกิน 20,000 บาทต่อครั้ง</li>
										<li>C. ค่าใช้จ่ายต่างๆ ที่จำเป็นและมีเหตุผลภายหลังการเกิดอุบัติเหตุ เช่น ค่าเสียเวลา, ค่าล่วงเวลา, ฯลฯ ที่เป็นผลมาจากอุบัติเหตุของยานพาหนะที่ขนส่ง วงเงินจำกัด ไม่เกิน 5,000 บาทต่อครั้ง</li>
									</ul>
								</Accordion>

								<Accordion title="เงื่อนไขการเครม" isOpen={!!this.state.openSections['เงื่อนไขการเครม']} onClick={title => this.onClick(title)}>
									<ul>
										<li>A. ผู้เครมประกันภัยต้องส่งมอบซากหรือสินค้าที่เสียหายให้แก่บริษัทประกัน มิเช่นนั้นบริษัทประกันจะชดใช้ค่าสินไหมทดแทนภายใต้เงื่อนไขของกรมธรรม์โดย หักค่าซากที่ประเมินโดยผู้ประเมินวินาศภัย</li>
										<li>B. บริษัทประกันจะชดใช้ตามความเสียหายที่แท้จริง แต่ไม่รวมภาษีมูลค่าเพิ่ม หรือตามราคาของสินค้า (Invoice) ทั้งนี้ไม่เกินมูลค่าของจำนวนเงินจำกัด ความรับผิดสูงสุดต่ออุบัติต่อครั้ง</li>
										<li>C. กรณีผู้เอาประกันมีใบเสนอราคาของสินค้า (Invoice) ผู้เอาประกันภัยต้องสำแดงใบเสนอราคาของสินค้า ของเจ้าของสินค้าเพื่อพิจารณาค่าสินไหมทดแทน</li>
										<li>D. กรณีผู้เอาประกันไม่มีใบเสนอราคาของสินค้า (Invoice) บริษัทประกัน จะพิจารณาชดใช้ค่าเสียหายโดยใช้ราคาตลาดของสินค้าประเภทนั้นๆ ณ เวลาที่เกิดเหตุ</li>
										<li>E. ผู้เอาประกันต้องส่งมอบเอกสารตามที่บริษัทประกันเรียกร้อง ภายใน 7 วันนับจากวันที่ยานพาหนะที่ขนส่งเกิดอุบัติเหตุ</li>
									</ul>
								</Accordion>

								<Accordion style={{marginBottom: 10, marginTop: 10}} title="เงื่อนไขเพิ่มเติม" isOpen={!!this.state.openSections['เงื่อนไขเพิ่มเติม']} onClick={title => this.onClick(title)}>
									<ul>
										<li>A. เงื่อนไขว่าด้วยข้อยกเว้นภัยสงครามและการก่อการร้าย</li>
										<li>B. เงื่อนไขว่าด้วยข้อยกเว้นการจดจำวันที่ทางอิเล็กทรอนิกส์</li>
										<li>C. เงื่อนไขว่าด้วยข้อกำหนดสถาบันสำหรับยกเว้นความคุ้มครองภัยจากสารเคมี สารทางชีวภาพ สารเคมีชีวภาพ อาวุธที่ใช้คลื่นแม่เหล็กไฟฟ้า และการโจมตีทางคอมพิวเตอร์</li>
										<li>D. เงื่อนไขว่าด้วยข้อกำหนดสำหรับยกเว้นความคุ้มครองภัยจากการปนเปื้อนสารกัมมันตภาพรังสี สารเคมี สารทางชีวภาพ และอาวุธที่ใช้คลื่นแม่เหล็กไฟฟ้า  </li>
									</ul>
								</Accordion>
							</Col>
						</Row>
					</div>
				</Wrapper>
				<Footer />
			</Container>
		);
	}
}

export default withNamespaces()(Pricing);
