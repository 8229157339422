import baseUrl from './baseUrl';

let appUrl = '';
if (baseUrl === 'https://api.giztix.com') {
    appUrl = 'https://app.giztix.com';
}

if (baseUrl === 'https://demoapi.giztix.com') {
    if(window.location.hostname === "localhost")
    {
        appUrl = 'http://localhost:3000';
    }else{
        appUrl = 'https://giztix-web-app.netlify.com';
    }
    
}

export default appUrl;
