import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
	CardImageHeader,
  BreadcrumbTitel,
  Accordion
} from './../../components';
import imageHelpCenter from '../../assets/images/helpcenter/help-center.jpg';
import { Row, Col } from 'react-bootstrap';
import color from '../../config/color';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Helmet } from 'react-helmet';
import appUrl from './../../config/appUrl';
import { withNamespaces } from "react-i18next";
import ReactHTMLParser from 'react-html-parser';

const Title = styled.div`
  font-size: 36px;
  font-weight: 700;
  color: #000000;
  margin-top: 60px;

  @media(max-width: 768px) {
    font-size: 24px;
    line-height: 1;
    margin-top: 20px;
  }
`;

const SubTitle = styled.div`
  font-size: 24px;

  @media(max-width: 768px) {
    font-size: 20px;
    line-height: 1;
  }
`;

const HelpContainer = styled.div`
  margin-top: 20px;

  & ol, ul {
    padding-left: 25px;
    margin: 0;
  }
`;

const Wrapper = styled.div`
	padding-top: 120px;

	@media(max-width: 767px) {
		padding-top: 15px;
	}
`;

class Help extends React.Component {
  state = {
    section: 0,
    openSections: {},
  }

  onClick = title => {
    const {
      state: { openSections },
    } = this;

    const isOpen = !!openSections[title];

    this.setState({
      openSections: {
        [title]: !isOpen
      }
    });
  };
  
  render() {
    const menu = [
			{
				active: true,
				link: '#',
				title: this.props.t('help:customerCallCenterService'),
			},
    ];
    
    return (
      <div>
        <Helmet>
					<title>Help Center | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
				</Helmet>
				<Header />
				<BreadcrumbTitel menu={menu} />
        <Wrapper>
          <CardImageHeader
            img={imageHelpCenter}
            title={this.props.t('help:customerCallCenterService')}
            subtitle={this.props.t('help:customerCallCenterServiceDetails')}
          />
          
          <div className="container">
            <Row>
              <Col md={12} className="text-center">
                <Title>{this.props.t('help:mostFrequentQuestions')}</Title>
                <SubTitle>{this.props.t('help:mostFrequentQuestionsDetails')}</SubTitle>

                <HelpContainer>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q1')} isOpen={!!this.state.openSections[this.props.t('help:Q1')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A1')}
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q2')} isOpen={!!this.state.openSections[this.props.t('help:Q2')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A2')}
                    <ol>
                      <li>{this.props.t('help:A2_1')}</li>
                      <li>{this.props.t('help:A2_2')}</li>
                      <li>{this.props.t('help:A2_3')}</li>
                      <li>{this.props.t('help:A2_4')}</li>
                    </ol>
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q3')} isOpen={!!this.state.openSections[this.props.t('help:Q3')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A3')}
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q4')} isOpen={!!this.state.openSections[this.props.t('help:Q4')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A4')}
                    <ul>
                      <li>{this.props.t('help:A4_1')} <a href="/pricing" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:learnMore')}</a></li>
                      <li>{this.props.t('help:A4_2')} <a href="/pricing" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:learnMore')}</a></li>
                      <li>{this.props.t('help:A4_3')} <a href="/pricing" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:learnMore')}</a></li>
                      <li>{this.props.t('help:A4_4')} <a href="/pricing" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:learnMore')}</a></li>
                    </ul>
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q5')} isOpen={!!this.state.openSections[this.props.t('help:Q5')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A5')}
                    <ol>
                      <li><strong>{this.props.t('help:A5_1')}</strong>{this.props.t('help:A5_1_1')}</li>
                      <li><strong>{this.props.t('help:A5_2')}</strong>{this.props.t('help:A5_2_1')}</li>
                    </ol>
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q6')} isOpen={!!this.state.openSections[this.props.t('help:Q6')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A6')}
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q7')} isOpen={!!this.state.openSections[this.props.t('help:Q7')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A7')}
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q8')} isOpen={!!this.state.openSections[this.props.t('help:Q8')]} onClick={title => this.onClick(title)}>
                    {ReactHTMLParser(this.props.t('help:A8'))}
                  </Accordion>

                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:Q9')} isOpen={!!this.state.openSections[this.props.t('help:Q9')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A9')} <a href={`${appUrl}/tracking`} target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:here')}</a>
                  </Accordion>

                  <Accordion title={this.props.t('help:Q10')} isOpen={!!this.state.openSections[this.props.t('help:Q10')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:A10')}
                  </Accordion>
                </HelpContainer>
              </Col>
            </Row>

            <Row>
              <Col md={12} className="text-center" style={{marginTop: 60}}>
                <Title>{this.props.t('help:generalQuestions')}</Title>

                <HelpContainer>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ1')} isOpen={!!this.state.openSections[this.props.t('help:gQ1')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA1')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ2')} isOpen={!!this.state.openSections[this.props.t('help:gQ2')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA2')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ3')} isOpen={!!this.state.openSections[this.props.t('help:gQ3')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA3')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ4')} isOpen={!!this.state.openSections[this.props.t('help:gQ4')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA4')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ5')} isOpen={!!this.state.openSections[this.props.t('help:gQ5')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA5')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ6')} isOpen={!!this.state.openSections[this.props.t('help:gQ6')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA6')} <a href="/contactus" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:here')} </a>
                    <br/> {this.props.t('help:duringWorking')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ7')} isOpen={!!this.state.openSections[this.props.t('help:gQ7')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA7')}
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ8')} isOpen={!!this.state.openSections[this.props.t('help:gQ8')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA8')}<a href="/tracking" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:here')}</a>
                  </Accordion>
                  <Accordion style={{marginBottom: 10}} title={this.props.t('help:gQ9')} isOpen={!!this.state.openSections[this.props.t('help:gQ9')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA9')} <a href="/contactus" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:here')}</a>
                    <br/> {this.props.t('help:duringWorking')}
                  </Accordion>
                  <Accordion style={{marginBottom: 100}} title={this.props.t('help:gQ10')} isOpen={!!this.state.openSections[this.props.t('help:gQ10')]} onClick={title => this.onClick(title)}>
                  {this.props.t('help:gA10')} <a href="/contactus" target="_blank" style={{color: `${color.Primary}`}}>{this.props.t('help:here')}</a> <br/>{this.props.t('help:duringWorking')}
                  </Accordion>
                </HelpContainer>
              </Col>
            </Row>
          </div>
        </Wrapper>
        <Footer />
      </div>
    )
  }
}

export default withNamespaces()(Help);