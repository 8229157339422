import React from 'react';
import styled from 'styled-components';
import { Row, Col, Button } from 'react-bootstrap';
import coverImage from '../../../../assets/images/homepage/cover/mainpicture_Giztixwebsite_m.png';
import coverImageWebP from '../../../../assets/images/homepage/cover/mainpicture_Giztixwebsite_m.webp';
import color from './../../../../config/color';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyImage } from '../../../../components';
import appUrl from './../../../../config/appUrl';

import { withNamespaces } from "react-i18next";

AOS.init({
	delay: 100 
});

const CoverContainer = styled.div`
	padding-top: 80px !important;
		text-align : center;

	& .symbol {
		display: inline-block;
		position: absolute;
		top: -10px;
		height: 14px;
		width: 14px;
		border-radius: 4px;
		background-color: ${color.Primary};
	}

	& .title-header {
		margint-top:20px;
		position: relative;
		font-weight: bold;
		line-height: 36px;
		font-size: 36px;
	}
	
	.title-sub-header {
		font-size: 26px;
		font-weight: bold;
		line-height: 26px;
	}
	
	.detail {
		margin-top: 10px;
		font-size: 20px;
		line-height: 1.2;
	}

	.check-button {
		height: 54px;
		width: 90%;
		font-size: 28px;
		border-radius: 6px;
		margin-left: auto;
		margin-right: auto;
		//box-shadow: 0px 6px 16px #d9010120;
		background-color: #d90101;
		color: #FFFFFF;

		& i {
			font-size: 20px;
		}

		&:hover {
			background-color: #d90101;
			box-shadow: 0px 10px 20px #d9010140;
		}

		&--outline {
			background-color: #FFFFFF;
			color: #d90101;
			border: 1px solid #d90101;
			
			&:hover {
				background-color: #FFFFFF;
			}
		}
	}
`;

const Cover = (props) => {
	const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
	return (
		<div>
			<CoverContainer>
				<Row>
					<Col sm={12} className="size">
						<div data-aos="fade-up" data-aos-duration="1500">
						{!isChrome  ? ( <LazyImage className="img-cover" src={coverImage} alt="cover"/>) : ( <LazyImage className="img-cover" src={coverImage} alt="cover"/>)}
						</div>
					</Col>

					<Col xl={7} lg={7} md={7} className="size">
						<div className="cover-right">
							<div data-aos="fade-up" data-aos-duration="1500">
								<div className="title-header">
								{props.t('homepage:FullTruckLoadService')} <span className="symbol" />
								</div>
								<div className="title-sub-header">{props.t('homepage:EasyBookingGetTruckNationwideSurely')}</div>
							</div>
							
							<div data-aos="fade-up" data-aos-duration="1800">
								<div className="detail">
								{props.t('homepage:coverDetails_mobile1')}<br/>{props.t('homepage:EasyBookingGetTruckNationwideSurely')}<br/>{props.t('homepage:coverDetails_mobile3')}
								</div>
							</div>

							<div data-aos="fade-up" data-aos-duration="2100">
								<Row>
									<Col xs={12} sm={6} style={{marginTop: 30}}>
										<Button className="btn-book-now check-button" onClick={() => window.location = `${appUrl}`}>
											{props.t('homepage:btnBookNow')}
										</Button>
									</Col>
									<Col xs={12} sm={6} style={{marginTop: 30, marginBottom: 20}}>
										<Button className="btn-check-price check-button check-button--outline" onClick={() => window.location = `${appUrl}`}>
											{props.t('homepage:FreeCheckPriceNow')}
										</Button>
									</Col>
								</Row>
							</div>
						</div>
					</Col>
				</Row>
			</CoverContainer>
		</div>
	);
};

export default withNamespaces()(Cover);
