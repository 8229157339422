import React from 'react';
import styled from 'styled-components';
import { FormControl } from 'react-bootstrap';
import PropTypes from 'prop-types';
import color from './../../config/color'

const InputBox = styled(FormControl)`
	color: #000000;
	padding-left: ${props => (props.iconleft ? 36 : 10)}px;
	padding-right: ${props => {
		if(props.iconright && props.status !== '') return 65;
		if(props.iconright) return 36;
		return 10;
	}}px;
`;

const InputContainer = styled.div`
	position: relative;
`;

const IconLeft = styled.i`
	font-size: 15px;
	color: ${props => props.color || '#b3b3b3'};
	position: absolute;
	left: 10px;
	top: 10px;
`;

const IconRight = styled.i`
	font-size: 15px;
	color: ${props => props.color || '#b3b3b3'};
	position: absolute;
	right: ${props => {
		if (props.status === 'valid' || props.status === 'invalid') return 36;
		return 10;
	}}px;
	top: 10px;

	cursor: ${props => props.onClick ? 'pointer' : ''};
`;

const AvailableIcon = styled.i`
	font-size: 15px;
	color: ${color.Primary}
	position: absolute;
	right: 10px;
	top: 10px;
`;

const ErrorIcon = styled.i`
	font-size: 15px;
	color: ${color.SoftGray}
	position: absolute;
	right: 10px;
	top: 10px;
`;

const InputLabel = styled.div`
	font-size: 20px;
`;

const Input = props => {
	const { iconLeft, iconRight, iconLeftColor, iconRightColor, onPressIconRight, status, ...rest } = props;

	return (
		<div>
			{ props.inputLabel ? (<InputLabel>{props.inputLabel}</InputLabel>) : null }
			<InputContainer>
				{props.iconLeft ? (
					<IconLeft
						className={props.iconLeft}
						color={props.iconLeftColor}
					/>
				) : null}
				<InputBox
					autoFocus={props.autoFocus}
					iconleft={props.iconLeft}
					iconright={props.iconRight}
					status={props.status}
					placeholder={props.placeholder}
					{...rest}
				/>
				{props.iconRight ? (
					<IconRight
						status={props.status}
						onClick={props.onPressIconRight}
						className={props.iconRight}
						color={props.iconRightColor}
					>
					</IconRight>
				) : null}
				{props.status === 'valid' ? (
					<AvailableIcon
						className="fas fa-check-circle"
					/>
				) : null}
				{props.status === 'invalid' ? (
					<ErrorIcon
						className="fas fa-times-circle"
					/>
				) : null}
			</InputContainer>
		</div>
	);
};

Input.propTypes = {
	iconLeft: PropTypes.string,
	iconRight: PropTypes.string,
	iconLeftColor: PropTypes.string,
	iconRightColor: PropTypes.string,
	onPressIconRight: PropTypes.func,
	status: PropTypes.oneOf(['', 'valid', 'invalid']),
	autoFocus : PropTypes.bool,
};

Input.defaultProps = {
	status: '',
	autoFocus: false
};

export default Input;