import React, { Component } from 'react';
import { Row ,Breadcrumb,FormGroup,InputGroup,Button,FormControl, Col ,Modal} from "react-bootstrap";
import styled from 'styled-components';
import './style.css';
import { Helmet } from 'react-helmet';
import {Mutation} from "react-apollo";


import { withNamespaces } from "react-i18next";


import emailSubscribeAdd from "./graphql/mutation/emailSubscribeAdd"

import {
    Header,
    BreadcrumbTitel,
    Footer
} from "./../../components";

const InputContainer = styled.div`
    // width: 500px;
    margin: 150px auto;
    text-align: center;
    min-height:40vh;
`;

const ButtonSearch = styled(Button)`
    width: 120px;
`;

const Titel = styled.h2`
    margin-bottom:30px;
    word-break: break-word;
`;

class Tracking extends Component {
    state = {
        value : "",
        openModal : false,
        emailValid: false,
        emailMessageError : null
    };

    checkEmail = email => {
		const validateEmail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/gi;
		if (validateEmail.test(email)) {
			return true;
		}

		return false;
	};

    handleChange(e){
        this.setState({ value: e.target.value });
    }

    handleKeyPress(e,sendEmailSubscribeAdd){
        if (e.key === 'Enter') {
            if(this.state.value !== "" && this.state.value !== undefined){
                if (this.checkEmail(this.state.value)) {
					sendEmailSubscribeAdd();
				} else {
					this.setState({ emailValid : true , emailMessageError: this.props.t('subscribe:inputEmailFormat') })
				}
            }
            else{
                alert(this.props.t('subscribe:PlsFillYourEmail'));
            }
        }
    }

    onClicksubscribe(sendEmailSubscribeAdd){
        if(this.state.value !== "" && this.state.value !== undefined){
            if (this.checkEmail(this.state.value)) {
                sendEmailSubscribeAdd();
            } else {
                this.setState({emailValid : true , emailMessageError: this.props.t('subscribe:inputEmailFormat') })
            }
        }
        else{
            alert(this.props.t('subscribe:PlsFillYourEmail'));
        }
    }

    render() {
        const menu = [ 
            {
                "active": true,
                "link": "#",
                "title": this.props.t('subscribe:SubscribeToUpdateNewPromotionHere')
            }
        ];

        return (
            <div className="backgroundTracking">
                <Helmet>
					<title>{this.props.t('subscribe:SubscribeToUpdateNewPromotionHere')} | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
				</Helmet>
                <Header active={3}></Header>
                <Mutation
                    mutation={emailSubscribeAdd}
                    variables={{
                        email: this.state.value
                    }}
                    update={() => {
                        this.setState({
                            value : "",
                            openModal: true,
                            emailValid : false , 
                            emailMessageError:null
                        });
                        
                    }}
                    onError={(error) => {
                        this.setState({
                            emailValid : true , 
                            emailMessageError: this.props.t('subscribe:inputEmailUsed')
                        });
                    }}
                >
                    {sendEmailSubscribeAdd => (
                        <Row>
                            <BreadcrumbTitel menu={menu}></BreadcrumbTitel>
                            <InputContainer>
                                <Row>
                                    <Col xs={10} xsOffset={1}>
                                        <Titel>{this.props.t('subscribe:SubscribeToUpdateNewPromotionHere')}</Titel>
                                    </Col>
                                    
                                    <Col xs={12} sm={6} smOffset={3} md={4} mdOffset={4} xsHidden>
                                        <FormGroup>
                                            <InputGroup>
                                            <FormControl 
                                                type="email"
                                                value={this.state.value}
                                                placeholder={this.props.t('subscribe:FillYourEmail')}
                                                onChange={event =>
                                                    this.handleChange(event)
                                                } 
                                                onKeyPress={event =>
                                                    this.handleKeyPress(event ,sendEmailSubscribeAdd)
                                                } 
                                            />
                                            <InputGroup.Button>
                                                <ButtonSearch bsStyle="primary" onClick={() => this.onClicksubscribe(sendEmailSubscribeAdd)}>{this.props.t('subscribe:SubscribeNow')}</ButtonSearch>
                                            </InputGroup.Button>
                                            </InputGroup>
                                        </FormGroup>
                                        <div style={{color:"#d90301"}}>{this.state.emailMessageError}</div>
                                    </Col>
                                    <Col xs={10} xsOffset={1} smOffset={3} smHidden mdHidden lgHidden>
                                        <div>
                                            <FormControl 
                                                type="email"
                                                value={this.state.value}
                                                placeholder={this.props.t('subscribe:FillYourEmail')}
                                                onChange={event =>
                                                    this.handleChange(event)
                                                } 
                                                onKeyPress={event =>
                                                    this.handleKeyPress(event ,sendEmailSubscribeAdd)
                                                } 
                                            />
                                            <div style={{color:"#d90301"}}>{this.state.emailMessageError}</div>
                                            <Button style={{marginTop: 20, width: 200}} bsStyle="primary" bsSize="lg" onClick={() => this.onClicksubscribe(sendEmailSubscribeAdd)}>{this.props.t('subscribe:SubscribeNow')}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </InputContainer>
                        </Row>
                        )
                    }
                </Mutation>  

                <Modal show={this.state.openModal} onHide={() => this.setState({ openModal: false })}>
                    <Modal.Body>
                        <Row style={{ marginBottom: "10px" }}>
                            <Col md={12}> 
                                <h3>{this.props.t('subscribe:ThankYouforsubscribing')}</h3>
                                <p>{this.props.t('subscribe:YouGetUpdateInYourEmail')}</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button bsStyle="defalut"  style={{width:"120px"}} onClick={() => this.setState({ openModal: false })}>{this.props.t('subscribe:ok')}</Button>
                    </Modal.Footer>
                </Modal>


                <Footer />
            </div>
        )
    }
}

export default withNamespaces()(Tracking);