import React from 'react';
import styled from 'styled-components';
import {
	FooterMobile
} from './../../components';
import { Helmet } from 'react-helmet';
import iconApp from '../../assets/images/Icon-App.png';
import imageAppStore from '../../assets/images/app-store.svg';
import imagePlaystore from '../../assets/images/play-store.svg';
import imageMockup from '../../assets/images/Mock-Up-Application.png';
import { Row, Col } from 'react-bootstrap';
import './style.css';

import { withNamespaces } from "react-i18next";

const TextHeader = styled.div`
	font-size: 46px;
	color: #000000;
	font-weight: 700;
`;

const TextContent = styled.div`
	font-size: 22px;
	color: #000000;
  margin-top: 10px;
  line-height: 1.5;

	&:last-child {
		margin-bottom: 10px;
	}
`;

class NotiDownloadApp extends React.Component {
	render() {
		return (
			<div className="backgroundCirclenotiApp"> 
				<Helmet>
					<title>Download App | GIZTIX</title>
					<meta name="description" content="สามารถเช็คราคาและจองบริการขนส่ง ทั้งแอปพลิเคชันบนมือถือ แทบเล็ต ให้ธุรกิจคุณสะดวกขึ้น" />
				</Helmet>
				<div className="container" style={{ paddingTop: 80 }}>
					
          		<img src={iconApp} alt="App giztix" width="80px" />

					<Row style={{ marginTop: 60 }}>
						<Col xs={12}>
							<TextHeader>{this.props.t('homepage:fasterAndMoreConvenient')}</TextHeader>
							<TextContent>
              {this.props.t('homepage:checkPriceAndBookTruckInstantly')} <br/>
								{this.props.t('homepage:availableOnMobileAndTablet')} <br/>
								{this.props.t('homepage:makeYourBusinessEasier')} <br/>
							</TextContent>
							<TextContent>
							<b>{this.props.t('homepage:orDownloadApplicationhere')}</b>
							</TextContent>
						</Col>
					</Row>

					<Row style={{ marginTop: 20 }}>
						<Col xs={12}>
              <a href="https://play.google.com/store/apps/details?id=com.giztix.app" target="_blank"><img src={imagePlaystore} alt="play Store" width="160px" /></a> 
						</Col>
						<Col xs={12} style={{ marginTop: 10 }}>
              <a href="https://itunes.apple.com/app/id1439755826" target="_blank"><img src={imageAppStore} alt="App Store" width="160px" /></a> 
						</Col>
					</Row>

					<Row style={{ marginTop: 50 }}>
						<Col xs={12}>
						<img src={imageMockup} alt="App Store" width="100%" />
            </Col>
					</Row>
				</div>
        
        <FooterMobile />
			</div>
		);
	}
}

export default withNamespaces()(NotiDownloadApp);
