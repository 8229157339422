import React from 'react';
import ReactDOM from 'react-dom';
import './i18n'
import App from './App';
import './index.css';

const renderApp = () => {
	ReactDOM.render(<App />, document.getElementById('root'));
};

renderApp();

if (module.hot) {
	module.hot.accept(['./App'], () => {
		renderApp();
	});
}
