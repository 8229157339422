import React from 'react';
import { Title, Container, BannerContainer, ImageBanner } from './Style';
import moment from 'moment';

class BannerSponsor extends React.Component {
  checkBetweenDateTime = (startDateTime, endDateTime) => {
    const format = 'YYYY-MM-DD HH:mm:ss';
    const current = moment();
    const startDate = moment(startDateTime, format);
    const endDate = moment(endDateTime, format);
    const range = current.isBetween(startDate, endDate, null, '[]');
    return range;
  };

  render() {
    const bannerZiic_536_120 =
      'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/18-mar-2020/Banner-ZIC-BU-536x120.jpg';
    const bannerFirstBook_536_120 =
      'https://s3-ap-southeast-1.amazonaws.com/appmedia.giztix.com/upload/banner/Banner-Firsttruck-536x120.png';

    let bannerListPricing = [];
    if (this.props.page === 'pricing') {
      bannerListPricing = [
        {
          src: bannerZiic_536_120,
          link:
            'https://www.skzicthailand.com/home/product_zic'
        },
        {
          src: bannerFirstBook_536_120,
          link: 'https://app.giztix.com'
        }
      ];
    }

    const imageBanner = bannerListPricing.map((item, index) => (
      <ImageBanner key={index} target="_blank" href={item.link}>
        <img src={item.src} alt="banner" />
      </ImageBanner>
    ));

    return this.checkBetweenDateTime(
      '2020-03-01 00:00:00',
      '2021-03-31 23:59:59'
    ) ? (
      <Container>
        <Title>ผู้สนับสนุน</Title>
        <BannerContainer>{imageBanner}</BannerContainer>
      </Container>
    ) : null;
  }
}

export default BannerSponsor;
