import styled from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const Container = styled.div``;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media(max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageBanner = styled.a`
  width: 50%;
  cursor: pointer;
  padding: 0 10px;
  overflow: hidden;
  
  & img {
    border-radius: 6px;
    width: 100%;
    height: auto;
  }
  
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }

  @media(max-width: 767px) {
    width: 100%;
    padding: 0 0 10px;
  }
`;
