import React from 'react';
import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

const Header = styled.div`
  text-align: center;

  & p {
    margin: 0;
    padding: 0;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 1;
  }

  & i {
    display: block;
    font-size: 30px;
    margin-bottom: 10px;
  }
`;

const Body = styled.div`
  font-size: 20px;
  text-align: center;
  line-height: 1;
`;
class ModalAlert extends React.Component {
  render() {
    return(
      <div>
        <Modal style={{minWidth:'300px'}} show={this.props.show} onHide={() => this.props.onCloseModal()}>
          <Modal.Body>
            <Header>
              {
                this.props.icon && (<i className={this.props.icon}></i>)
              }
              
              <p>{this.props.title}</p>
            </Header>

            <Body>
              {this.props.children}
            </Body>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default ModalAlert;