import React from 'react';
import styled from 'styled-components';
import {
	Header,
  Footer,
  ModalAlert,
  Input
} from './../../components';
import { Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import './style.css';
import URI from 'urijs';
import baseUrl from './../../config/baseUrl';
import './style.css';
import axios from 'axios';

const Container = styled.div`
  & .detail p {
    margin: 20px 0;
    padding: 0;
  }

  & .color-white {
    background-color: #FFFFFF;
  }

  .form-control {
    font-size: 18px;
  }

  & h3 {
    margin: 0;
    font-size: 28px;

    & i {
      font-size: 20px;
    }
  }
`;

const SCBDealContainer = styled.div`
  background-color: rgb(76, 40, 128);

  & .partnercontent-scb .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/scb/H1.png')});
    width: 100%;
    height: 330px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #441e8a;
  }

  @media only screen and (max-width : 992px) {
    & .partnercontent-scb .header-wrapper {
        height: 290px;
    }
  }

  @media only screen and (max-width : 768px) {
    & .partnercontent-scb .header-wrapper {
        height: 230px;
    }
  }

  @media only screen and (max-width : 480px) {
    & .partnercontent-scb .header-wrapper {
      position: relative;
      background-image: url(${require('../../assets/images/partnercontent/scb/Mobile/m1.png')});
      width: 100%;
      height: 275px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      padding-top: 55px;
      background-color: #441e8a;
    }
  }

  & .box-shadow img{
    -webkit-box-shadow: 0px 0px 11px 2px rgba(0,0,0,.3);
    -moz-box-shadow: 0px 0px 11px 2px rgba(0,0,0,.3);
    box-shadow: 0px 0px 11px 2px rgba(0,0,0,.3);
  }
`;

const SCBContentOneContainer = styled.div`
  & .partnercontent-scb .content-wrapper .title::after {
    background-color: transparent;
  }

  & .gradient-purple{
    padding: 90px 0;
  }

  @media only screen and (max-width : 480px) {
    & .gradient-purple{
      padding: 30px 0;
    }
  }
`;

const SCBContentTwoContainer = styled.div`
  & .partnercontent-scb .header-wrapper {
    background-image: url(${require('../../assets/images/partnercontent/scb/H3.png')});
  }

  & .partnercontent-scb .content-wrapper .title::after {
    background-color: transparent;
  }
`;

const SCBContentThreeContainer = styled.div`
  & .partnercontent-scb .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/scb/H5.png')});
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #ffffff;
  }

  & .partnercontent-scb .content-wrapper .title::after {
    background-color: transparent;
  }

  @media only screen and (max-width : 992px) {
    & .partnercontent-scb .header-wrapper {
        height: 290px;
    }
  }

  @media only screen and (max-width : 768px) {
    & .partnercontent-scb .header-wrapper {
        height: 230px;
    }

    & .partnercontent-scb .content-wrapper.list-view {
        padding: 20px 0;
        text-align: left;
    }
  }

  @media only screen and (max-width : 480px) {
    & .partnercontent-scb .header-wrapper {
      position: relative;
      background-image: url(${require('../../assets/images/partnercontent/scb/H5.png')});
      width: 100%;
      height: 330px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      padding-top: 55px;
      background-color: #441e8a;
    }
  }
`;

const SCBContentFourContainer = styled.div`
  & .partnercontent-scb .content-wrapper .title::after {
    background-color: transparent;
  }

  & .partnercontent-scb .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/scb/H4.png')});
    width: 100%;
    height: 380px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #3b2069;
  }

  @media only screen and (max-width : 992px) {
    & .partnercontent-scb .header-wrapper {
      height: 290px;
    }
  }

  @media only screen and (max-width : 768px) {
    & .partnercontent-scb .header-wrapper {
        height: 230px;
    }
  }

  @media only screen and (max-width : 480px) {
    & .partnercontent-scb .header-wrapper {
      position: relative;
      background-image: url(${require('../../assets/images/partnercontent/scb/Mobile/m4.png')});
      width: 100%;
      height: 330px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      padding-top: 55px;
      background-color: #3b2069;
    }
  }
`;

class SpecialDealSCB extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      guestName: '',
      guestEmail: '',
      guestPhone: '',
      productName: '',
      other_productName: '',
      branchName: '',
      openModal: false,
      iconAlert: '',
      titleAlert: '',
      messageAlert: '',
      guestNameValidate: '',
      guestEmailValidate: '',
      guestPhoneValidate: '',
      other_productNameValidate: '',
    };
  }

  redirect() {
    window.location = '/specialdeal-scb?content=scbdeal';
    return null;
  }

  sendRegister = () => {
    const obj = {
      "guestName": this.state.guestName,
      "guestEmail": this.state.guestEmail,
      "guestPhone": this.state.guestPhone,
      "productName": this.state.productName,
      "other_productName": this.state.other_productName,
      "branchName": this.state.branchName,
    }

    var data = JSON.stringify(obj);
    var bodyFormData = new FormData();
    bodyFormData.set('data', data);

    axios({
      method: 'post',
      url: 'http://kaikin.co/specialdeal/setscbsmeGuest/',
      data: bodyFormData,
      config: { headers: {'Content-Type': 'multipart/form-data' }}})
    .then((res) => {
      if(res.data.error === null) {
        this.setState({messageAlert: 'บันทึกเรียบร้อยแล้ว', iconAlert: 'fal fa-check-circle', titleAlert: 'สำเร็จ', openModal: true});
      } else {
        this.setState({messageAlert: 'ขออภัยคุณได้ลงทะเบียนรับสิทธ์ไปแล้ว', iconAlert: 'fal fa-exclamation-triangle', titleAlert: 'แจ้งเตือน', openModal: true});
      }
    })
    .catch((res) => {
      this.setState({messageAlert: `เกิดข้อผิดพลาด: ${res}`, iconAlert: 'fal fa-exclamation-triangle', titleAlert: 'แจ้งเตือน', openModal: true});
    });
  }

  checkEmail = email => {
		const validateEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (validateEmail.test(email)) {
			return true;
		}

		return false;
  };
  
  checkPhoneNumber = phoneNumber => {
		const validatePhoneNumber = /^[0]\d{9}$/; //ต้องเริ่มต้นด้วยเลข 0 และต่อด้วยตัวเลข 9 ตัว
		if (validatePhoneNumber.test(phoneNumber)) {
			return true;
		}

		return false;
  };
  
  handleDisabledRegisterButton = () => {
    if(this.state.guestNameValidate && this.state.guestEmailValidate && this.state.guestPhoneValidate && this.state.productName && this.state.branchName) {
      if(this.state.productName !== 'other') {
        return false
      }

      if(this.state.productName === 'other' && this.state.other_productNameValidate) {
        return false;
      }
    }

    return true;
  }

  render() {
    const uri = new URI(`${baseUrl}/${this.props.location.search}`);
    const params = uri.search(true);
    const paramsContent = params.content;

    return (
      <Container>
        <ModalAlert show={this.state.openModal} icon={this.state.iconAlert} title={this.state.titleAlert} onCloseModal={() => this.setState({openModal: false})}>
          <div className="text-center">{this.state.messageAlert}</div>
          <hr />
          <Button bsStyle="primary" block style={{marginTop: 10}} onClick={() => this.setState({openModal: false})}>ตกลง</Button>
        </ModalAlert>
        {
          paramsContent === 'scbdeal' ? (
            <SCBDealContainer>
              <Helmet>
                <title>SCB Business center เปิดให้บริการแล้ว!</title>
                <meta name="description" content="นับเป็นศูนย์ให้คำปรึกษา SME ครบวงจรแห่งแรกพรั่งพร้อมไปด้วยทุกบริการที่ SME ต้องการ ไม่ว่าจะเป็นทีมกูรู ผู้เชี่ยวชาญในด้านต่างๆ ผู้เชี่ยวชาญทั้งด้านการสร้างแบรนด์ การทำการตลาดยุคดิจิทัล การขนส่ง และธุรกิจแฟรนไชส์ โดยSMEสามารถขอรับคำปรึกษาได้ทุกช่วงธุรกิจ ไม่ว่าคุณกำลังเริ่มต้น ขยายกิจการหรือกำลังประสบปัญหา เราพร้อมช่วยคุณอย่างตรงจุด นอกจากนี้ยังมีกิจกรรมและงานสัมมนาที่ช่วยจุดไฟ เติมฝัน ให้คุณก้าวสู่ความสำเร็จอย่างมั่นคง" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60, textAlign: 'center'}} className="partnercontent-scb">
                <section className="header-wrapper" />
                <div className="content-wrapper" style={{color: '#FFFFFF'}}>
                  <div className="row">
                    <div className="col-md-12" style={{marginBottom: 20}}>
                      <a href="/specialdeal-scb?content=scbcontent1" className="box-shadow">
                        <img src={require('../../assets/images/partnercontent/scb/sb11.png')} alt="" width="100%"/>
                      </a>
                    </div>

                    <div className="col-md-12" style={{marginBottom: 20}}>
                      <a href="/specialdeal-scb?content=scbcontent2" className="box-shadow">
                        <img src={require('../../assets/images/partnercontent/scb/sb12.png')} alt="" width="100%"/>
                      </a>
                    </div>

                    <div className="col-md-12" style={{marginBottom: 20}}>
                      <a href="/specialdeal-scb?content=scbcontent3" className="box-shadow">
                        <img src={require('../../assets/images/partnercontent/scb/sb13.png')} alt="" width="100%"/>
                      </a>
                    </div>

                    <div className="col-md-12">
                      <a href="/specialdeal-scb?content=scbcontent4" className="box-shadow">
                        <img src={require('../../assets/images/partnercontent/scb/sb14.png')} alt="" width="100%"/>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </SCBDealContainer>
          ) : paramsContent === 'scbcontent1' ? (
            <SCBContentOneContainer>
              <Helmet>
                <title>SCB Business center เปิดให้บริการแล้ว!</title>
                <meta name="description" content="นับเป็นศูนย์ให้คำปรึกษา SME ครบวงจรแห่งแรกพรั่งพร้อมไปด้วยทุกบริการที่ SME ต้องการ ไม่ว่าจะเป็นทีมกูรู ผู้เชี่ยวชาญในด้านต่างๆ ผู้เชี่ยวชาญทั้งด้านการสร้างแบรนด์ การทำการตลาดยุคดิจิทัล การขนส่ง และธุรกิจแฟรนไชส์ โดยSMEสามารถขอรับคำปรึกษาได้ทุกช่วงธุรกิจ ไม่ว่าคุณกำลังเริ่มต้น ขยายกิจการหรือกำลังประสบปัญหา เราพร้อมช่วยคุณอย่างตรงจุด นอกจากนี้ยังมีกิจกรรมและงานสัมมนาที่ช่วยจุดไฟ เติมฝัน ให้คุณก้าวสู่ความสำเร็จอย่างมั่นคง" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent-scb">
                <section className="header-wrapper">
                  <div className="content-wrapper">
                    <div className="title">
                      <span className="text-purple">SCB Business Center</span>
                      <span>ศูนย์ธุรกิจ SME รูปแบบใหม่</span>
                    </div>
                    <div className="detail desktop-and-tablet">
                      <p style={{color:'#58595B'}}>
                        ที่ตอบโจทย์ครบทุกความต้องการทางธุรกิจ เป็นทั้งสถานที่สร้างเครือข่ายธุรกิจ การแบ่งปันความรู้ กิจกรรมฝึกอบรม
                        เพื่อพัฒนาทักษะ ของผู้ประกอบการ การจับคู่ธุรกิจ ที่จะช่วยให้ธุรกิจของคุณเติบโตได้อย่างต่อเนื่อง
                        รวมถึงการให้คำปรึกษาด้านธุรกิจทุกด้าน
                      </p>
                    </div>
                    <div className="line-box">
                      <ul>
                        <li className="bg-yellow"></li>
                        <li style={{"width":"80px"}}></li>
                      </ul>
                    </div>
                    <div className="title sub">
                      <span>“ตอบโจทย์ธุรกิจ </span>
                      <span className="text-yellow">SME</span>
                      <span> ด้านโลจิสติกส์”</span>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper bg-white mobile">
                  <div className="content-wrapper">
                    <div className="detail text-center">
                      <p>
                        ที่ตอบโจทย์ครบทุกความต้องการทางธุรกิจ เป็นทั้งสถานที่สร้างเครือข่ายธุรกิจ การแบ่งปันความรู้ กิจกรรมฝึกอบรม
                        เพื่อพัฒนาทักษะ ของผู้ประกอบการ การจับคู่ธุรกิจ ที่จะช่วยให้ธุรกิจของคุณเติบโตได้อย่างต่อเนื่อง
                        รวมถึงการให้คำปรึกษาด้านธุรกิจทุกด้าน
                      </p>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper bg-purple">
                  <div className="content-wrapper PT110">
                    <div className="row">
                      <div className="col-md-6">
                        <img src={require('../../assets/images/partnercontent/scb/sb1.png')} alt=""/>
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-white">SCB</span>
                          <span className="text-yellow">SME</span>
                          <span className="text-white">ร่วมกับ GIZTIX</span>
                        </div>
                        <div className="detail">
                          <p className="text-white">
                            ตลาดขนส่งออนไลน์ มอบ Promotion Code ส่วนลดค่าขนส่ง 50%
                            ให้แก่ลูกค้าธุรกิจ SME บริการขนส่งสินค้ารูปแบบใหม่สำหรับธุรกิจ SME ด้านการขนส่งสินค้าทุกรูปแบบทั้งขนส่งภายใน และระหว่างประเทศ ให้ของธุรกิจ สามารถเช็คราคา, จองบริการขนส่ง, และเช็คสถานการขนส่งสินค้ากับบริษัท 
                            ขนส่งกว่า 500 บริษัทออนไลน์ด้วยระบบการขนส่ง E-Logistics รูปแบบใหม่ ที่ทำให้การขนส่งง่าย และมีประสิทธิภาพมากขึ้น
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper bg-white">
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="title">
                          <span className="text-yellow">รายละเอียด</span>
                          <span>และเงื่อนไขส่วนลดค่าบริการขนส่ง</span>
                        </div>
                        <div className="detail" style={{color:'#58595B'}}>
                          <ul className="number-list">
                            <li> <i> 1 </i> <span>ส่วนลดค่าบริการขนส่ง 50% สูงสุด 1,000 บาท สำหรับลูกค้า SCB Business Center 20 ท่านแรกของแต่ละสาขา</span></li>
                            <li> <i> 2 </i> <span>ส่วนลดค่าบริการขนส่ง 5% สูงสุด 1,000 บาท สำหรับลูกค้า SCB Business Center ท่านที่ 21 เป็นต้นไปของแต่ละสาขา</span></li>
                            <li> <i> 3 </i> <span>Promotion Code ส่วนลดสามารถสำหรับการจองบริการขนส่งผ่าน GIZTIX เท่านั้น</span></li>
                            <li> <i> 4 </i> <span>Promotion Code สามารถใช้บริการได้ 1 ท่าน ต่อ 1 สิทธิ์ เท่านั้น</span></li>
                            <li> <i> 5 </i> <span>Promotion Code ส่วนลดสามารถใช้ได้ตั้งแต่วันนี้ ถึงวันที่ 31 ธันวาคม 2561 เท่านั้น</span></li>
                            <li> <i> 6 </i> <span>ไม่สามารถนำส่วนลดแลกเป็นเงินสดได้</span></li>
                            <li> <i> 7 </i> <span>สงวนสิทธิในการเปลี่ยนแปลงข้อกำหนดเงื่อนไข และระยะเวลาโดยไม่ต้องแจ้งให้ทราบล่วงหน้า</span></li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper gradient-purple">
                  <div className="content-wrapper radius bg-gray mb110 HPB">
                    <div className="row">
                      <div className="col-md-7 pl55">
                        <div className="title">
                          <span className="text-white">ขั้นตอนการรับสิทธิ์</span>
                        </div>
                        <div className="detail">
                          <ul className="number-list yellow text-white">
                            <li> <i> 1 </i> <span>กรอกข้อมูล: ชื่อ-นามสกุล, อีเมล์, เบอร์โทรศัพท์, เลือกสาขา, และบริการของ SCB</span></li>
                            <li> <i> 2 </i> <span>คลิก “ลงทะเบียนรับสิทธิ์”</span></li>
                            <li> <i> 3 </i> <span>นำ SMS ยืนยัน ให้เจ้าหน้าที่ ณ ศูนย์ Business Center สาขาที่กำหนด</span></li>
                            <li> <i> 4 </i> <span>สมัครผลิตภัณฑ์ของ SCB SME ครบ 1 รายการ <br/> (SCB Easy APP, QR Payment, SCB Connect)</span></li>
                            <li> <i> 5 </i> <span>รับ Promotion Code ส่วนลดค่าบริการขนส่งกับเจ้าหน้าที่ ณ ศูนย์ Business Center</span></li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-5">
                        <div className="content-box" style={{"top":"-95px"}}>
                          <div className="content-header blod" style={{"color":"#000"}}>
                            กรอกข้อมูลเพื่อรับสิทธิ์
                          </div>
                          <div className="content-body">
                            <div  id="get_promo_code_form" noValidate="noValidate" style={{"marginBottom":"0"}}>
                              <div className="form-group">
                                <label className="text_black">ชื่อ - นามสกุล:</label>
                                {/* <input value={this.state.guestName} onChange={(event) => this.setState({guestName: event.target.value})} className="form-control" type="text" name="guestName" placeholder="ชื่อ - นามสกุล" required=""/> */}
                                <Input
                                  type="text"
                                  placeholder="ชื่อ - นามสกุล"
                                  status={this.state.guestNameValidate === true ? 'valid' : this.state.guestNameValidate === false ? 'invalid' : ''}
                                  value={this.state.guestName}
                                  onChange={event => {
                                    if (event.target.value) {
                                      this.setState({
                                        guestName: event.target.value,
                                        guestNameValidate: true,
                                      });
                                    } else {
                                      this.setState({
                                        guestName: event.target.value,
                                        guestNameValidate: false,
                                      });
                                    }
                                  }}
                                  name="username"
                                  autoFocus
                                />
                              </div>
                              <div className="form-group">
                                <label className="text_black">อีเมล:</label>
                                {/* <input value={this.state.guestEmail} onChange={(event) => this.setState({guestEmail: event.target.value})} className="form-control" type="email" name="guestEmail" placeholder="อีเมล" required=""/> */}
                                <Input
                                  type="text"
                                  placeholder="อีเมล"
                                  status={this.state.guestEmailValidate === true ? 'valid' : this.state.guestEmailValidate === false ? 'invalid' : ''}
                                  value={this.state.guestEmail}
                                  onChange={event => {
                                    if (this.checkEmail(event.target.value)) {
                                      this.setState({
                                        guestEmail: event.target.value,
                                        guestEmailValidate: true,
                                      });
                                    } else {
                                      this.setState({
                                        guestEmail: event.target.value,
                                        guestEmailValidate: false,
                                      });
                                    }
                                  }}
                                  name="email"
                                />
                              </div>
                              <div className="form-group">
                                <label className="text_black">เบอร์โทรศัพท์:</label>
                                {/* <input value={this.state.guestPhone} onChange={(event) => this.setState({guestPhone: event.target.value})} className="form-control" type="number" name="guestPhone" placeholder="เบอร์โทรศัพท์" required="" minLength="10" maxLength="10" /> */}
                                <Input
                                  type="text"
                                  placeholder="เบอร์โทรศัพท์"
                                  status={this.state.guestPhoneValidate === true ? 'valid' : this.state.guestPhoneValidate === false ? 'invalid' : ''}
                                  value={this.state.guestPhone}
                                  onChange={event => {
                                    if (this.checkPhoneNumber(event.target.value)) {
                                      this.setState({
                                        guestPhone: event.target.value,
                                        guestPhoneValidate: true,
                                      });
                                    } else {
                                      this.setState({
                                        guestPhone: event.target.value,
                                        guestPhoneValidate: false,
                                      });
                                    }
                                  }}
                                  name="phoneNumber"
                                />
                              </div>
                              <div className="form-group">
                                <label className="text_black">Product:</label>
                                <select value={this.state.productName} onChange={(event) => this.setState({productName: event.target.value})} className="form-control" data-plugin="select2" name="productName" data-placeholder="-- ระบุ --" required="">
                                  <option value="">-- ระบุ --</option>
                                  <option value="QR Payment">QR Payment</option>
                                  <option value="SCB Easy App">SCB Easy App</option>
                                  <option value="SCB Connect">SCB Connect</option>
                                  <option value="สินเชื่อเพื่อธุรกิจ">สินเชื่อเพื่อธุรกิจ</option>
                                  <option value="other">อื่นๆ</option>
                                </select>
                              </div>

                              {
                                (this.state.productName === 'other') ? (
                                  <div className="form-group">
                                    <div className="other-box">
                                      {/* <input value={this.state.other_productName} onChange={(event) => this.setState({other_productName: event.target.value})} className="form-control" type="text" name="other_productName" placeholder="โปรดระบุ" /> */}
                                      <Input
                                        type="text"
                                        placeholder="โปรดระบุ"
                                        status={this.state.other_productNameValidate === true ? 'valid' : this.state.other_productNameValidate === false ? 'invalid' : ''}
                                        value={this.state.other_productName}
                                        onChange={event => {
                                          if (event.target.value) {
                                            this.setState({
                                              other_productName: event.target.value,
                                              other_productNameValidate: true,
                                            });
                                          } else {
                                            this.setState({
                                              other_productName: event.target.value,
                                              other_productNameValidate: false,
                                            });
                                          }
                                        }}
                                        name="otherProductName"
                                      />
                                    </div>
                                  </div>
                                ) : null
                              }
                              
                              <div className="form-group">
                                <label className="text_black">Branch:</label>
                                <select value={this.state.branchName} onChange={(event) => this.setState({branchName: event.target.value})} className="form-control" name="branchName" data-plugin="select2" data-placeholder="-- ระบุ --" required="">
                                  <option value="">-- ระบุ --</option>
                                  <option value="Central World ชั้น 5 (ฝั่งอิเซตัน)">Central World ชั้น 5 (ฝั่งอิเซตัน)</option>
                                  <option value="Siam Square ซอย 1">Siam Square ซอย 1</option>
                                  <option value="Central East Vile ชั้น 3">Central East Vile ชั้น 3</option>
                                  <option value="สำเพ็ง">สำเพ็ง</option>
                                  <option value="ตลาดหนองมน จ.ชลบุรี">ตลาดหนองมน จ.ชลบุรี</option>
                                </select>
                              </div>

                              <button disabled={this.handleDisabledRegisterButton()} onClick={() => this.sendRegister()} className="btn color-purple btn-block btn-lg" style={{"marginTop":"25px"}}>ลงทะเบียนรับสิทธิ์</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">	
                      </div>
                      <div className="col-md-6">
                        <a href="/specialdeal-scb?content=scbcontent2" className="btn btn-link btn-lg text-purple viewpage">	
                          <span className="arrow"><i className="fal fa-chevron-right"></i></span>		
                          <span className="title text-purple">SCB Business center<br/> เปิดให้บริการแล้ว!</span>
                        </a>	
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{"backgroundColor":"#4c2880","color":"#fff"}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white">
                        <h3>ติดต่อสอบถามเพิ่มเติม:</h3>
                        <ul className="number-list text-white">
                          <li> <i className="fas fa-phone bg-white text-purple" style={{"paddingTop":"6px", fontSize: 16}}></i> <span>Giztix :  02-026-6895<br/>
                            Business Center Central world: 02-2556270-1, Business Center Siam square ซอย1 <br/>
                            Business Center Central East Ville 02-5536246-7, Business Center  สำเพ็ง 02-2262834-5 </span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </SCBContentOneContainer>
          ) : paramsContent === 'scbcontent2' ? (
            <SCBContentTwoContainer>
              <Helmet>
                <title>SCB Business Center ศูนย์ธุรกิจ SME รูปแบบใหม่</title>
                <meta name="description" content="ที่ตอบโจทย์ครบทุกความต้องการทางธุรกิจ เป็นทั้งสถานที่สร้างเครือข่ายธุรกิจ การแบ่งปันความรู้ กิจกรรมฝึกอบรม เพื่อพัฒนาทักษะ ของผู้ประกอบการ การจับคู่ธุรกิจ ที่จะช่วยให้ธุรกิจของคุณเติบโตได้อย่างต่อเนื่อง รวมถึงการให้คำปรึกษาด้านธุรกิจทุกด้าน" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent-scb">
                <section className="header-wrapper">
                  <div className="content-wrapper">
                    <div className="title">
                      <span className="text-purple">SCB Business center  </span>
                    </div>
                    <div className="detail desktop-and-tablet">
                      <p className="text-black">
                        นับเป็นศูนย์ให้คำปรึกษา SME ครบวงจรแห่งแรกพรั่งพร้อมไปด้วยทุกบริการที่ SME ต้องการ
                        ไม่ว่าจะเป็นทีมกูรู ผู้เชี่ยวชาญในด้านต่างๆ ผู้เชี่ยวชาญทั้งด้านการสร้างแบรนด์ การทำการตลาดยุคดิจิทัล 
                        การขนส่ง และธุรกิจแฟรนไชส์ โดยSMEสามารถขอรับคำปรึกษาได้ทุกช่วงธุรกิจ ไม่ว่าคุณกำลังเริ่มต้น 
                        ขยายกิจการหรือกำลังประสบปัญหา เราพร้อมช่วยคุณอย่างตรงจุด นอกจากนี้ยังมีกิจกรรมและงานสัมมนาที่ช่วยจุดไฟ 
                        เติมฝัน ให้คุณก้าวสู่ความสำเร็จอย่างมั่นคง
                      </p>
                    </div>
                    <div className="line-box">
                      <ul>
                        <li className="bg-yellow"></li>
                        <li style={{width: 80}}></li>
                      </ul>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper bg-white mobile">
                  <div className="content-wrapper">
                    <div className="detail text-center">
                      <p>
                        นับเป็นศูนย์ให้คำปรึกษา SME ครบวงจรแห่งแรกพรั่งพร้อมไปด้วยทุกบริการที่ SME ต้องการ
                        ไม่ว่าจะเป็นทีมกูรู ผู้เชี่ยวชาญในด้านต่างๆ ผู้เชี่ยวชาญทั้งด้านการสร้างแบรนด์ การทำการตลาดยุคดิจิทัล 
                        การขนส่ง และธุรกิจแฟรนไชส์ โดยSMEสามารถขอรับคำปรึกษาได้ทุกช่วงธุรกิจ ไม่ว่าคุณกำลังเริ่มต้น 
                        ขยายกิจการหรือกำลังประสบปัญหา เราพร้อมช่วยคุณอย่างตรงจุด นอกจากนี้ยังมีกิจกรรมและงานสัมมนาที่ช่วยจุดไฟ 
                        เติมฝัน ให้คุณก้าวสู่ความสำเร็จอย่างมั่นคง
                      </p>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper bg-purple">
                  <div className="content-wrapper PT60">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="detail">
                          <p className="text-white text-center">
                            SME  ใดต้องการคำปรึกษาดีๆมาหาเราได้ที่ Central world ชั้น 5 (ฝั่งอิเซตัน), Siam square ซอย1,Central East Ville ชั้น 3 โซนหน้าโรงภาพยนตร์ สำเพ็ง และล่าสุดคือ หนองมน   โดยมีแผนจะขยายอีกหลายศูนย์ เพื่อรองรับความต้องการของ SME ให้ได้มากที่สุด เพราะเรารู้ว่า “เวลา” ของ SME  เป็นสิ่งสำคัญจึงรวมทุกบริการที่ SME ต้องการไว้ในที่เดียว มาปุ๊บ จบทุกปัญหา

                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper bg-white">
                  <div className="content-wrapper list-view">
                    <div className="row mb30">
                      <div className="col-md-6 col-sm-4 col-xs-12">
                        <img src={require('../../assets/images/partnercontent/scb/sb2.png')} alt="" />
                      </div>
                      <div className="col-md-6 col-sm-8 col-xs-12">
                        <div className="title">
                          <span className="text-yellow">SME</span>
                          <span>Business Coach</span>
                        </div>
                        <div className="detail">
                          <p className="text-black">พร้อมให้คำแนะนำและสนับสนุนทุก SME อย่างตรงจุด <br/> เพื่อให้คุณเติบโตได้อย่างมั่นคง</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6 col-sm-4 col-xs-12">
                        <img src={require('../../assets/images/partnercontent/scb/sb3.png')} alt="" />
                      </div>
                      <div className="col-md-6 col-sm-8 col-xs-12">
                        <div className="title">
                          <span className="text-yellow">SME </span>
                          <span>Guru</span>
                        </div>
                        <div className="detail">
                          <p className="text-black">พร้อมด้วยทีมกูรู ผู้เชี่ยวชาญทั้งด้านการสร้างแบรนด์  <br/> 
                          การทำการตลาดยุคดิจิทัล การขนส่ง และธุรกิจแฟรนไชส์  <br/> 
                          ที่จะเผยทุกเคล็ดลับ สอนทุกเทคนิคให้ธุรกิจคุณ <br/> 
                          เติบโตอย่างก้าวกระโดด</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6 col-sm-4 col-xs-12">
                        <img src={require('../../assets/images/partnercontent/scb/sb4.png')} alt="" />
                      </div>
                      <div className="col-md-6 col-sm-8 col-xs-12">
                        <div className="title">
                          <span className="text-yellow">CO</span>
                          <span>-Working</span>
                        </div>
                        <div className="detail">
                          <p className="text-black">เปิดพื้นที่ให้ SME นั่งทำงานหรือหาแรงบันดาลใจได้โดย<br/> 
                          ไม่จำกัดเวลา พร้อมมีสิ่งอำนวยความสะดวก<br/> 
                          ครบครัน</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6 col-sm-4 col-xs-12">
                        <img src={require('../../assets/images/partnercontent/scb/sb5.png')} alt=""/>
                      </div>
                      <div className="col-md-6 col-sm-8 col-xs-12">
                        <div className="title">
                          <span className="text-yellow">Meeting </span>room
                        </div>
                        <div className="detail">
                          <p className="text-black">หาก SME ต้องการห้องประชุมแบบส่วนตัว และเดินทาง<br/> 
                          มาประชุมได้สะดวก ก็สามารถจองห้องประชุมได้<br/> 
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">
                        <a className="btn btn-default" style={{padding: '5px 45px'}} href="https://businesscenter.scb.co.th/th/" target="_blank">
                          <h3>
                            <span className="text-yellow">อ่านเพิ่มเติม <i className="fas fa-arrow-circle-right"></i></span>
                          </h3>
                        </a>
                        <div className="detail" style={{display: 'none'}}>
                          <a href="https://businesscenter.scb.co.th/th/" className="btn btn-link text_black" target="_blank"> https://businesscenter.scb.co.th/th/</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="section-wrapper gradient-purple">
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <a className="box-shadow" href="#">
                          <img src={require('../../assets/images/partnercontent/scb/sb10.png')} className="desktop-and-tablet" alt=""/>
                          <img src={require('../../assets/images/partnercontent/scb/Mobile/m15.png')} className="mobile" alt=""/>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">						
                        <a href="/specialdeal-scb?content=scbcontent1" className="btn btn-link btn-lg text-purple viewpage left">	
                          <span className="arrow"><i className="fal fa-chevron-left"></i></span>	
                          <span className="title text-purple">SCB Business center<br/> ศูนย์ธุรกิจ SME รูปแบบใหม่</span>	
                        </a>	
                      </div>
                      <div className="col-md-6">
                        <a href="/specialdeal-scb?content=scbcontent3" className="btn btn-link btn-lg text-purple viewpage pull-right">	
                          <span className="arrow"><i className="fal fa-chevron-right"></i></span>		
                          <span className="title text-purple">สินเชื่อเพื่อธุรกิจ<br/> ขนส่ง</span>
                        </a>	
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{backgroundColor: '#4c2880',color: '#fff'}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white">
                        <h3>ติดต่อสอบถามเพิ่มเติม:</h3>
                        <ul className="number-list text-white">
                          <li> <i className="fas fa-phone bg-white text-purple" style={{paddingTop: 6}}></i> <span>Giztix :  02-026-6895<br/>
                            Business Center Central world: 02-2556270-1, Business Center Siam square ซอย1 <br/>
                            Business Center Central East Ville 02-5536246-7, Business Center  สำเพ็ง 02-2262834-5 </span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </SCBContentTwoContainer>
          ) : paramsContent === 'scbcontent3' ? (
            <SCBContentThreeContainer>
              <Helmet>
                <title>ผลิตภัณฑ์ SCB Center ที่ช่วยสนับสนุนผู้ประกอบการ Logistics</title>
                <meta name="description" content="ผลิตภัณฑ์ SCB Center ที่ช่วยสนับสนุนผู้ประกอบการ Logistics" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent-scb">
                <section className="header-wrapper"/>
                <section className="section-wrapper bg-purple">
                  <div className="content-wrapper  list-view">
                    <div className="text-center" style={{"marginBottom":"30px"}}>
                      <div className="title">
                        <span className="text-white">สินเชื่อเพื่อธุรกิจการขนส่ง</span>
                      </div>
                      <div className="line-box">
                        <ul>
                          <li className="bg-yellow"></li>
                          <li style={{"width":"80px","backgroundColor":"#33185f"}}></li>
                        </ul>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-12">
                        <div className="box-titel bg-yellow text-white bold">
                          วงเงินสินเชื่อเช่าซื้อรถกระบะและเช่าซื้อรถบรรทุกครอบคลุมอุปกรณ์ต่อเติม
                        </div>
                        <ul className="dot-list">
                          <li>ดาวน์ขั้นต่ำเพียง 0%</li>
                          <li>ให้บริการสินเชื่อครอบคลุมทั้งตัวรถ อุปกรณ์และส่วนประกอบร่วมกับตัวรถ</li>
                          <li>ผ่อนนาน 60 งวด</li>
                          <li> วงเงินกู้เพื่อชำระค่าเบี้ยประกันวินาศภัยประเภท 1, ประเภท 3 (Motor Insurance), พรบ. และค่าเบี้ยประกันภัยสำหรับกรมธรรม์ประกันภัย</li>
                        </ul>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-12">
                        <div className="box-titel  bg-yellow text-white bold">
                          วงเงิน O/D (ไม่ใช้หลักประกัน)
                        </div>
                        <ul className="dot-list">
                          <li>วงเงิน O/D สำหรับหมุนเวียนในกิจการ</li>
                          <li>ไม่ต้องใช้หลักประกัน สูงสุด 30 ลบ.</li>
                        </ul>
                        <p className="text-yellow">*** หมายเหตุ : สามารถเบิกใช้วงเงิน O/D ได้ เมื่อมีการตั้งวงเงินวงสินเชื่อเช่าซื้อรถบรรทุก เรียบร้อยแล้ว เท่านั้น</p>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-12">
                        <div className="box-titel bg-yellow text-white bold">
                          วงเงินสินเชื่อเพื่อการนำเข้า-ส่งออกวงเงิน Forward Contract
                        </div>
                        <ul className="dot-list">
                          <li>วงเงินสินเชื่อเพื่อการนำเข้า-ส่งออกระหว่างประเทสทุกประเภทสูงสุด 100 ลบ.</li>
                          <li>วงเงิน Forward Contract สูงสุด100 ลบ</li>
                          <li>ไม่ต้องใช้หลักประกันหรือหลักประกันต่ำ</li>
                        </ul>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-12">
                        <div className="box-titel bg-yellow text-white bold">
                          วงเงินหมุนเวียน วงเงินสินเชื่อเพื่อสร้างที่จอดรถ วงเงินสินเชื่อเพื่อสร้างคลังสินค้า
                        </div>
                        <ul className="dot-list">
                          <li>วงเงินสินเชื่อครบวงจร (OD,PN,LG ทุกประเภท และ Term Loan)</li>
                        </ul>
                        <p className="text-yellow">เงื่อนไข : เป็นไปตามที่ธนาคารกำหนด</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">
                        
                        <a className="btn btn-default" style={{"padding":"5px 45px"}} href="https://businesscenter.scb.co.th/th/" target="_blank">
                          <h3>
                            <span className="text-yellow">อ่านเพิ่มเติม <i className="fas fa-arrow-circle-right"></i></span>
                          </h3>
                        </a>
                        <div className="detail" style={{"display":"none"}}>
                          <a href="https://businesscenter.scb.co.th/th/" className="btn btn-link text_white" target="_blank"> https://businesscenter.scb.co.th/th/</a>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">						
                        <a href="/specialdeal-scb?content=scbcontent2" className="btn btn-link btn-lg text-purple viewpage left">
                          <span className="arrow"><i className="fal fa-chevron-left"></i></span>	
                          <span className="title text-purple">SCB Business center<br/> ศูนย์ธุรกิจ SME รูปแบบใหม่</span>	
                        </a>	
                      </div>
                      <div className="col-md-6">
                        <a href="/specialdeal-scb?content=scbcontent4" className="btn btn-link btn-lg text-purple viewpage pull-right">	
                          <span className="arrow"><i className="fal fa-chevron-right"></i></span>		
                          <span className="title text-purple">ผลิตภัณฑ์ที่ช่วยสนับสนุน<br/> ผู้ประกอบการ Logistics</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </SCBContentThreeContainer>
          ) : paramsContent === 'scbcontent4' ? (
            <SCBContentFourContainer>
              <Helmet>
                <title>ผลิตภัณฑ์ SCB Center ที่ช่วยสนับสนุนผู้ประกอบการ Logistics</title>
                <meta name="description" content="ผลิตภัณฑ์ SCB Center ที่ช่วยสนับสนุนผู้ประกอบการ Logistics" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent-scb">
                <section className="header-wrapper"/>
                <section className="section-wrapper bg-white">
                  <div className="content-wrapper  list-view">
                    <div className="text-center" style={{"marginBottom":"30px"}}>
                      <div className="title">
                        <span className="text-yellow">ผลิตภัณฑ์ </span><span>ที่ช่วยสนับสนุนผู้ประกอบการ Logistics</span>
                      </div>
                      <div className="line-box">
                        <ul>
                          <li className="bg-yellow"></li>
                          <li style={{"width":"80px","backgroundColor":"#33185f"}}></li>
                        </ul>
                      </div>
                    </div>

                    <div className="box-titel bg-purple text-white bold">
                      บริการทางการเงินอื่นๆ
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6">
                        <img src={require('../../assets/images/partnercontent/scb/sb6.png')} style={{"marginBottom":"20px","float":"left"}} alt="" />
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-purple">QR </span>
                          <span>Payment</span>
                        </div>
                        <div className="detail">
                          <p className="text-black">พร้อมให้คำแนะนำและสนับสนุนทุก SME อย่างตรงจุด <br/> เพื่อให้คุณเติบโตได้อย่างมั่นคง</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6">
                        <img src={require('../../assets/images/partnercontent/scb/sb7.png')} style={{"marginBottom":"20px","float":"left"}} alt="" />
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-purple">SCB  </span>
                          <span>Easy App</span>
                        </div>
                        <div className="detail">
                          <p className="text-black">พร้อมด้วยทีมกูรู ผู้เชี่ยวชาญทั้งด้านการสร้างแบรนด์  <br/> 
                          การทำการตลาดยุคดิจิทัล การขนส่ง และธุรกิจแฟรนไชส์  <br/> 
                          ที่จะเผยทุกเคล็ดลับ สอนทุกเทคนิคให้ธุรกิจคุณ <br/> 
                          เติบโตอย่างก้าวกระโดด</p>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6">
                        <img src={require('../../assets/images/partnercontent/scb/sb8.png')} style={{"marginBottom":"20px","float":"left"}} alt="" />
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-purple">SCB </span>
                          <span>Connect</span>
                        </div>
                        <div className="detail">
                          <p>ทุกรายรับ รายจ่าย SCB ไลน์บอก ฟรี!</p>
                          <ul className="dot-list">
                            <li className="text-black">เตือนฟรี</li>
                            <li className="text-black">เตือนตั้งแต่บาทแรก</li>
                            <li className="text-black">เตือนทันที เมื่อมีความเคลื่อนไหวบัญชี</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6">
                        <img src={require('../../assets/images/partnercontent/scb/sb9.png')} style={{"marginBottom":"20px","float":"left"}} />
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-purple">SCB </span>
                          <span className="text-yellow">SME</span>
                          <span>x</span>
                          <span>Google My Business</span>
                        </div>
                        <div className="detail">
                          <p className="text-black">ปักหมุดร้านผ่าน SCB SME ค้นง่าย ขายดี <br/>ขึ้นทันทีแบบเรียลไทม์</p>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-center">						
                        <a className="btn btn-default" style={{"padding":"5px 45px"}} href="https://businesscenter.scb.co.th/th/" target="_blank">
                          <h3>
                            <span className="text-yellow">อ่านเพิ่มเติม <i className="fas fa-arrow-circle-right"></i></span>
                          </h3>
                        </a>
                        <div className="detail" style={{"display":"none"}}>
                          <a href="https://businesscenter.scb.co.th/th/" className="btn btn-link text_black" target="_blank"> https://businesscenter.scb.co.th/th/</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">
                        
                        <a href="/specialdeal-scb?content=scbcontent3" className="btn btn-link btn-lg text-purple viewpage left">
                          <span className="arrow"><i className="fal fa-chevron-left"></i></span>	
                          <span className="title text-purple">สินเชื่อเพื่อธุรกิจ<br/>การขนส่ง</span>	
                        </a>	
                      </div>
                      <div className="col-md-6">
                          
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{"backgroundColor":"#4c2880","color":"#fff"}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white">
                        <h3>ติดต่อสอบถามเพิ่มเติม:</h3>
                        <ul className="number-list text-white">
                          <li> <i className="fas fa-phone bg-white text-purple" style={{"paddingTop":"6px"}}></i> <span>Giztix :  02-026-6895<br/>
                            Business Center Central world: 02-2556270-1, Business Center Siam square ซอย1 <br/>
                            Business Center Central East Ville 02-5536246-7, Business Center  สำเพ็ง 02-2262834-5 </span></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </SCBContentFourContainer>
          ) : this.redirect()
        }
      </Container>
    )
  }
}

export default SpecialDealSCB;