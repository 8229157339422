import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
	CardImageHeader,
  BreadcrumbTitel,
  LazyImage
} from './../../components';
import imageAboutUs from '../../assets/images/aboutus/about-us.jpg';
import imageWhyGiztix from '../../assets/images/aboutus/why-giztix.png';
import imageHistory from '../../assets/images/aboutus/history.png';
import imageQuotes from '../../assets/images/aboutus/quotes.png';
import { Row, Col } from 'react-bootstrap';
import color from '../../config/color';
import './style.css';
import { Helmet } from 'react-helmet';
import MediaQuery from 'react-responsive';

import { withNamespaces } from "react-i18next";

const TextHeader = styled.div`
	font-size: 48px;
	color: #000000;
  font-weight: 700;

  @media (max-width: 767px) {
    font-size: 28px;
  }
`;

const TextContent = styled.div`
	color: #000000;
  margin-top: 20px;
  line-height: 24pt;

  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 22pt;
  }

	&:last-child {
		margin-bottom: 20px;
  }
`;

const Quotes = styled.div`
  position: relative;
  padding: 40px 80px;
  box-sizing: border-box;

  @media (max-width: 767px) {
    padding: 10px;
  }
`;

const BorderQuotesLeft = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 75px;
  border-top: 1px solid #707070;
  border-left: 1px solid #707070;

  @media (max-width: 767px) {
    width: 40px;
    height: 30px;
  }
`;

const BorderQuotesRight = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 60px;
  height: 75px;
  border-bottom: 1px solid #707070;
  border-right: 1px solid #707070;

  @media (max-width: 767px) {
    width: 40px;
    height: 30px;
  }
`;

const TimelineContainer = styled.div`
  padding-top: 60px;

  @media(max-width: 767px) {
    padding-top: 10px;
  }
  
  & .timeline-center {
    position: relative;
    text-align: center;
    color: #000000;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 25px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 25px;
      background-color: #E3E3E3;
    }
  }

  & .timeline-left {
    text-align: right;
    position: relative;
    color: #000000;
    margin-right: 50%;

    @media(max-width: 767px) {
      text-align: center;
      margin-right: 0;
      padding-top: 30px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -8px;
      background-color: ${color.Primary};
      width: 16px;
      height: 16px;
      border-radius: 50px;

      @media(max-width: 767px) {
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    & .timeline-text {
      padding-right: 40px;
      padding-bottom: 40px;
      padding-left: 20%;

      @media(max-width: 767px) {
        padding: 15px 20px;
      }

      &-line::after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        background-color: #E3E3E3;
        width: 1px;
        height: 100%;

        @media(max-width: 767px) {
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          height: 50px;
        }
      }
    }
  }

  & .timeline-right {
    position: relative;
    color: #000000;
    margin-left: 50%;

    @media(max-width: 767px) {
      text-align: center;
      margin-left: 0;
      padding-top: 30px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -8px;
      background-color: ${color.Primary};
      width: 16px;
      height: 16px;
      border-radius: 50px;

      @media(max-width: 767px) {
        left: 50%;
        transform: translateX(-50%);
      }
    }

    & .timeline-text {
      padding-left: 40px;
      padding-bottom: 40px;
      padding-right: 20%;

      @media(max-width: 767px) {
        padding: 15px 20px;

        &::after {
          content: '';
          position: absolute;
          top: -10px;
          left: 50%;
          background-color: #E3E3E3;
          width: 1px;
          height: 50px;
        }
      }

      &-line::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0px;
        background-color: #E3E3E3;
        width: 1px;
        height: 100%;

        @media(max-width: 767px) {
          top: -10px;
          left: 50%;
          transform: translateX(-50%);
          height: 50px;
        }
      }
    }
    
    @media (max-width: 768px) {
      padding-top: 30px;
    }
  }
`;

const QuotesContainer = styled.div`
  font-size: 24px;
`;

const ContentContainer = styled(Row)`
    margin-top: 80px;

    @media (max-width: 767px) {
      margin-top: 40px;
    }
`;

const Wrapper = styled.div`
	padding-top: 120px;

	@media(max-width: 767px) {
		padding-top: 15px;
	}
`;

class About extends React.Component {

	render() {
		const menu = [
			{
				active: true,
				link: '#',
				title: this.props.t('header:aboutUs'),
			},
		];

		return (
			<div>
        <Helmet>
					<title>About us | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="เกี่ยวกับ GIZTIX - บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
          <meta property="og:description" content="เกี่ยวกับ GIZTIX - บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
          <meta property="og:url" content={window.location.href} />
				</Helmet>
        
				<Header />
				<BreadcrumbTitel menu={menu} />

        <Wrapper>
          <CardImageHeader
            img={imageAboutUs}
            title={this.props.t('header:aboutUs')}
            subtitle={this.props.t('about:becauseOfTheInconvenienceAndLongLogisticsProcessItMadeUs')}
          />

          <div className="container">
            <ContentContainer>
              <Col md={6} lg={6}>
                <TextHeader>{this.props.t('header:WhyGIZTIX')}</TextHeader>
                <TextContent>
                {this.props.t('about:whyGiztixDetails')}
                </TextContent>
                <TextContent>
                {this.props.t('about:whyGiztixDetails2')}
                </TextContent>
              </Col>
              <Col md={6} lg={6}>
                <LazyImage src={imageWhyGiztix} alt="why giztix" width="100%" />
              </Col>
            </ContentContainer>

            <ContentContainer>
              <Col md={10} mdOffset={1} lg={10} lgOffset={1} xs={12} sm={12}>
                <TextContent style={{ textAlign: 'center' }}>
                {this.props.t('about:whyGiztixDetails3')}
                </TextContent>
              </Col>
            </ContentContainer>

            <ContentContainer >
              <Col md={10} mdOffset={1} lg={8} lgOffset={2} xs={12} sm={12}>
                <Quotes>
                  <TextContent style={{ textAlign: 'center' }}>
                  {this.props.t('about:whyGiztixDetails4').split('\n').map(item => (<div>{item}</div>))}
                  </TextContent>

                  <BorderQuotesLeft />
                  <BorderQuotesRight />
                </Quotes>
              </Col>
            </ContentContainer>
          </div>

          <div style={{backgroundColor: '#F9F9F9', marginTop: 75}}>
            <div className="container"  style={{ paddingTop: 50, paddingBottom: 50 }}>
              <Row>
                <Col md={6} lg={5} xs={12}>
                  <LazyImage src={imageHistory} alt="history" width="100%" />
                </Col>
                <Col lg={1}></Col>
                <Col md={6} lg={6} xs={12}>
                  <TextHeader>{this.props.t('about:companyBackground')}</TextHeader>
                  <TextContent>
                  {this.props.t('about:companyBackgroundDetails')}
                  </TextContent>
                  <TextContent>
                  {this.props.t('about:companyBackgroundDetails2')}
                  </TextContent>
                </Col>
              </Row>
            </div>
          </div>

          <div className="container">
              <MediaQuery minWidth={768}>
                <ContentContainer>
                  <Col md={6} lg={6} sm={6}>
                    <QuotesContainer>
                      <TextContent style={{ textAlign: 'center', lineHeight: '29pt' }}>
                      {this.props.t('about:companyBackgroundDetails3')}
                      </TextContent>
                      <TextContent style={{ textAlign: 'center', marginTop: 40, lineHeight: '29pt' }}>
                      {this.props.t('about:companyBackgroundDetails4').split('\n').map(item => (<div>{item}</div>))}
                      </TextContent>
                      <TextContent style={{ textAlign: 'center', marginTop: 60, fontSize: 22 }}>
                        <div><strong>{this.props.t('about:sittisakWongsomnuk')}</strong></div>
                        <div>{this.props.t('about:ceoAndCoFounder')}</div>
                      </TextContent>
                    </QuotesContainer>
                  </Col>
                  <Col lg={1}></Col>
                  <Col md={6} lg={5} sm={6}>
                    <LazyImage className="image-quotes" src={imageQuotes} alt="history" />
                  </Col>
                </ContentContainer>
              </MediaQuery>

              <MediaQuery maxWidth={767}>
                <ContentContainer style={{textAlign: 'center'}}>
                  <Col xs={12}>
                    <LazyImage style={{width: '85%'}} src={imageQuotes} alt="history" />
                  </Col>
                  <Col xs={12}>
                    <QuotesContainer>
                      <TextContent style={{ textAlign: 'center', marginTop: 20, fontSize: 20 }}>
                        <div><strong>{this.props.t('about:sittisakWongsomnuk')}</strong></div>
                        <div>{this.props.t('about:ceoAndCoFounder')}</div>
                      </TextContent>
                      <TextContent style={{ textAlign: 'center', marginTop: 20, lineHeight: 1.5 }}>
                      {this.props.t('about:companyBackgroundDetails3')}
                      </TextContent>
                      <TextContent style={{ textAlign: 'center', marginTop: 20, lineHeight: 1.5 }}>
                      {this.props.t('about:companyBackgroundDetails4').split('\n').map(item => (<div>{item}</div>))}
                      </TextContent>
                    </QuotesContainer>
                  </Col>
                </ContentContainer>
              </MediaQuery>
          </div>

          <div className="container">
            <ContentContainer>
              <Col md={12}>
                <TextHeader style={{textAlign: 'center'}}>{this.props.t('about:historyAndPastAwards')}</TextHeader>
                <TimelineContainer>
                  <div className="timeline-center">
                    <div className="timeline-text">2018</div>
                  </div>

                  <div className="timeline-left">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2018')}</div>
                  </div>

                  <div className="timeline-center">
                    <div className="timeline-text">2017</div>
                  </div>

                  <div className="timeline-right">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2017_1')}</div>
                  </div>

                  <div className="timeline-left">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2017_2')}</div>
                  </div>

                  <div className="timeline-center">
                    <div className="timeline-text">2016</div>
                  </div>

                  <div className="timeline-right">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2016')}</div>
                  </div>

                  <div className="timeline-center">
                    <div className="timeline-text">2015</div>
                  </div>

                  <div className="timeline-left">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2015_1')}</div>
                  </div>

                  <div className="timeline-right">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2015_2')}</div>
                  </div>

                  <div className="timeline-left">
                    <div className="timeline-text timeline-text-line">{this.props.t('about:content2015_3')}</div>
                  </div>

                  <div className="timeline-right">
                    <div className="timeline-text">{this.props.t('about:content2015_4')}</div>
                  </div>
                </TimelineContainer>
              </Col>
            </ContentContainer>
          </div>
        </Wrapper>
				<Footer />
			</div>
		);
	}
}

export default withNamespaces()(About);
