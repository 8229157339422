import React, { useState, useEffect } from "react";
import {
  Modal,
  InputGroup,
  FormControl,
  Row,
  Col,
  FormGroup,
  Button,
} from "react-bootstrap";
import {
  FacebookShareButton,
  GooglePlusShareButton,
  TwitterShareButton,
  LineShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  GooglePlusIcon,
  LineIcon,
  EmailIcon,
} from "react-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useLocation, useHistory } from "react-router-dom";
import appUrl from "../../../../src/config/appUrl";

const ModalShare = (props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const pathName = `${location.pathname}`;
  const [copy, setCopy] = useState(false);

  return (
    <Modal show={open} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 24,
              color: "#000",
            }}
          >
            แชร์คำทำนาย
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: "center" }}>
        <Row
          style={{
            padding: 20,
            fontWeight: "normal",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            // marginLeft: 5,
            // margin: 5,
          }}
        >
          <Col xs={2}>
            <LineShareButton
              url={`www.giztix.com${pathName}`}
              title='แชร์คำทำนาย '
            >
              <LineIcon size={32} round={true} />
            </LineShareButton>
          </Col>
          <Col xs={2}>
            <FacebookShareButton
              url={`www.giztix.com${pathName}`}
              title='แชร์คำทำนาย '
            >
              <FacebookIcon size={32} round={true} />
            </FacebookShareButton>
          </Col>
          <Col xs={2}>
            <TwitterShareButton
              url={`www.giztix.com${pathName}`}
              title='แชร์คำทำนาย '
            >
              <TwitterIcon size={32} round={true} />
            </TwitterShareButton>
          </Col>
          <Col xs={2}>
            <EmailShareButton
              url={`www.giztix.com${pathName}`}
              title='แชร์คำทำนาย '
            >
              <EmailIcon size={32} round={true} />
            </EmailShareButton>
          </Col>
        </Row>
        <Row style={{ paddingLeft: 20, paddingRight: 20 }}>
          <Col>
            <FormGroup>
              <InputGroup>
                <FormControl
                  style={{ borderRight: "none" }}
                  id='sharetracking_input'
                  type='text'
                  readOnly
                  value={`www.giztix.com${pathName}`}
                />
                <InputGroup.Button>
                  <CopyToClipboard
                    text={`www.giztix.com${pathName}`}
                    onCopy={() => {
                      setCopy(true);
                    }}
                  >
                    <Button
                      text={pathName}
                      style={{
                        width: 120,
                        color: "#1683ff",
                        backgroundColor: "#eee",
                        border: "1px solid #ccc",
                        borderLeft: "none",
                        // paddingLeft: 0,
                        textAlign: "center",
                      }}
                    >
                      {copy ? "คัดลอกลิ้งก์แล้ว" : "คัดลอกลิ้งก์"}
                    </Button>
                  </CopyToClipboard>
                </InputGroup.Button>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default ModalShare;
