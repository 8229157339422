import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import color from '../../../../config/color';

const Container = styled.div`
	text-align: left;
	width: 60vw;

`;

const Title = styled.div`
  position: relative;
  font-size: 36px;
  line-height: 1;
  font-weight: 700;
`;

const SubTitle = styled.div`
	font-size: 20px;
	line-height: 1;
`;

const TextHeader = (props) => {
	return (
		<Container style={props.style}>
			<Title>{props.title}</Title>
			<SubTitle>{props.subTitle}</SubTitle>
		</Container>
	);
};

export default TextHeader;