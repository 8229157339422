export default {
	Primary: '#D90101',
	Green: '#39B54A',
	Blue: '#29ABE2',
	Orange: '#F7931E',
	Gray: '#666666',
	SoftGray: '#B3B3B3',
	Black: '#000000',
	White: '#FFFFFF',
	PrimaryGradient: ['#FF0000', '#DB0000'],
	WhiteGradient: ['#FFFFFF', '#E6E6E6'],
};
