import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';
import MediaQuery from 'react-responsive';
import appUrl from '../../../../config/appUrl';

const ModalContainer = styled(Modal)`
  .text-primary {
    color: #D90101; 
  }

  & .modal-dialog {
    width: 90%;
    margin: 50px auto;
    
    @media(min-width: 768px) {
      top: 50px;
      transform: translateY(-50%);
      width: 750px;
    }
  }
  
  & .modal-body {
    background-color: #FFFFFF !important;
    padding: 0px;
    border-radius: 4px;
  }

  & .bg {
    border-radius: 4px;
    width: 100%;
  }
`;

const ButtonClose = styled.div`
  position: absolute;
  right: 6px;
  top: 6px;
  color: #FFFFFF;
  cursor: pointer;
  width: 26px
  height: 26px;
  text-align: center;
  font-size: 18px;

  @media(min-width: 768px) {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;

const PromotionContainer = styled.div``;

const ImageDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;

  & img {
    max-width: 100%;
    cursor: pointer;
  }

  & a:first-child img {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  & a:last-child img {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

const ImageMobileContainer = styled.div`
  display: flex;
  flex-direction: column;

  & img {
    width: 100%;
  }

  & a:first-child img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  & a:last-child img {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

class Promotion extends React.Component {
  constructor(props) {
		super(props);

    this.state = {
      isCopy: false,
    }
  }

  handleCopyCodePromotion() {
    this.setState({ isCopy: true }, () => {
      setTimeout(() => {
        window.location = appUrl;
      }, 2000);
    });
  }

  render() {
    return (
      <ModalContainer show={this.props.show} onHide={this.props.onHide}>
        <ModalContainer.Body>
          <ButtonClose onClick={this.props.onClose}><i className="far fa-times"></i></ButtonClose>
          <MediaQuery minWidth={768}>
            <PromotionContainer>
              <ImageDesktopContainer>
                <a href={`https://giztix.page.link/USE_AAN300`}>
                  <img src={`${require('./images/desktop/promotion01.jpg')}`} alt="promotion"/>
                </a>
                <a href={`https://giztix.page.link/USE_GTXTRUCK`}>
                  <img src={`${require('./images/desktop/promotion02.jpg')}`} alt="promotion"/>
                </a>
                <a href={`https://giztix.page.link/USE_GTXTRL`}>
                  <img src={`${require('./images/desktop/promotion03.jpg')}`} alt="promotion"/>
                </a>
                <a href={`https://giztix.page.link/USE_BACKHAUL`}>
                  <img src={`${require('./images/desktop/promotion04.jpg')}`} alt="promotion"/>
                </a>
              </ImageDesktopContainer>
            </PromotionContainer>
          </MediaQuery>

          <MediaQuery maxWidth={767}>
            <PromotionContainer>
              <ImageMobileContainer>
                <a href={`https://giztix.page.link/USE_AAN300`}>
                  <img src={`${require('./images/mobile/promotion01.jpg')}`} alt="promotion"/>
                </a>
                <a href={`https://giztix.page.link/USE_GTXTRUCK`}>
                  <img src={`${require('./images/mobile/promotion02.jpg')}`} alt="promotion"/>
                </a>
                <a href={`https://giztix.page.link/USE_GTXTRL`}>
                  <img src={`${require('./images/mobile/promotion03.jpg')}`} alt="promotion"/>
                </a>
                <a href={`https://giztix.page.link/USE_BACKHAUL`}>
                  <img src={`${require('./images/mobile/promotion04.jpg')}`} alt="promotion"/>
                </a>
              </ImageMobileContainer>
            </PromotionContainer>
          </MediaQuery>
        </ModalContainer.Body>
      </ModalContainer>
    );
  }
}

export default Promotion;