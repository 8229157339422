import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
} from './../../components';
import { Helmet } from 'react-helmet';
import './style.css';
import URI from 'urijs';
import Slider from "react-slick";
import baseUrl from './../../config/baseUrl';

const settingSlider = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

const Container = styled.div`
  & .detail p {
    margin: 20px 0;
    padding: 0;
    font-size: 24px;
    line-height: 1.2;
  }

  & .color-white {
    background-color: #FFFFFF;
  }
`;

const MichelinDealContainer = styled.div`
  .partnercontent .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/michelin/Web/p0.jpg')});
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #182848;
  }

  @media only screen and (max-width: 992px) {
    .partnercontent .header-wrapper {
      height: 310px;
    }
  }

  @media only screen and (max-width: 768px) {
    .partnercontent .header-wrapper {
      height: 300px;
    }
  }

  @media only screen and (max-width: 480px) {
    .partnercontent .header-wrapper {
      position: relative;
      background-image: url(${require('../../assets/images/partnercontent/michelin/Mobile/m0.jpg')});
      width: 100%;
      height: 290px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      padding-top: 55px;
      background-color: #182848;
    }
  }
`;

const MichelinContentOneContainer = styled.div`
  & .partnercontent .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/michelin/Web/p1.jpg')});
    width: 100%;
    height: 410px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #182848;
  }

  @media only screen and (max-width: 992px) {
    & .partnercontent .header-wrapper {
      height: 310px;
    }
  }

  @media only screen and (max-width: 768px) {
    & .partnercontent .header-wrapper {
      display: none;
    }
  }
`;

const MichelinContentTwoContainer = styled.div`
  & .partnercontent .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/michelin/Web/p2.jpg')});
    width: 100%;
    height: 410px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #182848;
  }

  @media only screen and (max-width: 992px) {
    & .partnercontent .header-wrapper {
      height: 310px;
    }
  }

  @media only screen and (max-width: 768px) {
    & .partnercontent .header-wrapper {
      display: none;
    }
  }
`;

const MichelinContentThreeContainer = styled.div`
  & .partnercontent .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/michelin/Web/p3.jpg')});
    width: 100%;
    height: 410px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #182848;
  }

  @media only screen and (max-width: 992px) {
    & .partnercontent .header-wrapper {
      height: 310px;
    }
  }

  @media only screen and (max-width: 768px) {
    & .partnercontent .header-wrapper {
      display: none;
    }
  }
`;

const MichelinContentFourContainer = styled.div`
  & .partnercontent .header-wrapper {
    position: relative;
    background-image: url(${require('../../assets/images/partnercontent/michelin/Web/p44.jpg')});
    width: 100%;
    height: 410px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    padding-top: 55px;
    background-color: #182848;
  }

  @media only screen and (max-width: 992px) {
    & .partnercontent .header-wrapper {
      height: 310px;
    }
  }

  @media only screen and (max-width: 768px) {
    & .partnercontent .header-wrapper {
      display: none;
    }
  }
`;

class SpecialMichelin extends React.Component {
  redirect() {
    window.location = '/specialdeal?content=michelindeal';
    return null;
  }

  render() {
    const uri = new URI(`${baseUrl}/${this.props.location.search}`);
    const params = uri.search(true);
    const paramsContent = params.content;

    return (
      <Container>
        {
          paramsContent === 'michelindeal' ? (
            <MichelinDealContainer>
              <Helmet>
                <title>ให้ มิชลิน ดูแลทุกการขนส่ง</title>
                <meta name="description" content="สิทธิประโยชน์ 3 ต่อสำหรับผู้ใช้บริการ GIZTIX เพียงสมัครเป็นสมาชิก MICHELIN CLUB กับมิชลิน" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent">
                <section className="header-wrapper" />
                <section className="section-wrapper gradient-blue">
                  <div className="content-wrapper list-view">
                    <div className="row mb30">
                      <div className="col-md-6 keep-right">
                        <img src={require('../../assets/images/partnercontent/michelin/1.png')} style={{width: '100%',marginBottom: 20,float: 'left'}} alt=""/>
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-yellow">Welcome point</span>
                          <span> พิเศษ 50 แต้มมูลค่า 3000 บาท</span>
                        </div>
                        <div className="detail">
                          <p>พร้อมสะสมคะแนนทุกครั้งเมื่อซื้อยางรถบรรทุกมิชลินทุกเส้น แต้มสะสมสามารถใช้แลก
                          ของรางวัลพิเศษมากมายจากมิชลิน อาทิ เช่น ยางรถบรรทุกมิชลิน คูปองเติมน้ำมัน 
                          คูปองรับบริการที่ศูนย์ หรือทริปท่องเที่ยวต่างประเทศ ….</p>

                          <a href="/specialdeal?content=michelincontent1" className="btn color-yellow marginTop15">อ่านเพิ่มเติม</a>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6">
                        <img src={require('../../assets/images/partnercontent/michelin/2.jpg')} style={{width: '100%',marginBottom: 20,float: 'left'}} alt=""/>
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span className="text-yellow">รับประกัน แตก/ตำ/บาด/บวม</span>
                          <span>Road hazard warranty</span>
                        </div>
                        <div className="detail">
                          <p>รับประกันแตก ตำ บาด บวม สำหรับยางรถบรรทุกมิชลินทุกเส้น
                          ตั้งแต่ วันนี้ -31 ธันวาคม 
                          เมื่อเป็นสมาชิก MICHELIN CLUB…..</p>
                          <a href="/specialdeal?content=michelincontent2" className="btn color-yellow marginTop15">อ่านเพิ่มเติม</a>
                        </div>
                      </div>
                    </div>

                    <div className="row mb30">
                      <div className="col-md-6 keep-right">
                        <img src={require('../../assets/images/partnercontent/michelin/3.jpg')} style={{width: '100%',marginBottom: 20,float: 'left'}} alt=""/>
                      </div>
                      <div className="col-md-6">
                        <div className="title">
                          <span>
                            โปรโมชั่นพิเศษสำหรับยางรถบรรทุกขนาด 9.5R17.5 
                            ชนิดไม่ใช้ยางใน 
                          </span>
                          <span className="text-yellow">ช่วยประหยัดค่ายางใน ยางรอง 
                            ในระยะยาว พร้อมความปลอดภัยที่มากขึ้น </span>
                          <span>ซื้อยางพร้อมกระทะล้อในราคาพิเศษ</span>
                        </div>
                        <div className="detail">
                          <ul className="dot-list" style={{marginTop: 20}}>
                            <li>ยางมิชลิน X MULTI Z 9.5R17.5 กระทะล้อ ชุดละ 8,000 บาท</li>
                            <li>Download ข้อมูลผลิตภัณฑ์ MICHELIN X MULTI Z 9.5R17.5 ที่นี่</li>
                          </ul>

                          <a href="/specialdeal?content=michelincontent3" className="btn color-yellow marginTop15">อ่านเพิ่มเติม</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{backgroundColor: '#095A98',color: '#ffffff'}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white text-center">
                        <div className="desktop-and-tablet">
                          <a href="https://truckloyalty.com/index.php/user/event_register_ex" className=" btn color-white marginTop15 btn-michelin" style={{padding: '0 15px'}}>
                            <h3 style={{margin: 0}}>
                              <img src={require('../../assets/images/partnercontent/michelin/logo_her.png')} alt="" />
                              <span className="text">คลิกเพื่อสมัคร <span className="text-blue">MICHELIN CLUB</span> และรับสิทธิประโยชน์ </span>
                            </h3>
                          </a>
                        </div>

                        <div className="mobile">
                          <img src={require('../../assets/images/partnercontent/michelin/logo_ver.png')} alt="" />
                          <strong>คลิกเพื่อสมัคร <span className="text-yellow">MICHELIN CLUB </span>และรับสิทธิประโยชน์</strong>
                        </div>

                        <br/>

                        <a href="https://truckloyalty.com/index.php/user/event_register_ex" className="btn color-yellow marginTop15">คลิกเลย</a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <Footer />
            </MichelinDealContainer>
          ) : paramsContent === 'michelincontent1' ? (
            <MichelinContentOneContainer>
              <Helmet>
                <title>MICHELIN LOYALTY PROGRAM</title>
                <meta name="description" content="สะสมคะแนนมาก...ยิ่งมีสิิทธิ์มาก เพียงสมัครสมาชิก รับฟรี+ Welcome point 50 คะแนน สามารถแลกรับของรางวัลมากมายจากมิชลิน อาทิ เช่น ยางใหม่ตุ๊กตามิชลินประดับรถ คูปองน้ำมัน ทริปท่องเที่ยวต่างประเทศ" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent">
              <section className="header-wrapper"/>
                <div className="mobile" style={{backgroundColor: '#182848'}}>
                  <img src={require('../../assets/images/partnercontent/michelin/Mobile/c1.jpg')} alt="" />
                  <img src={require('../../assets/images/partnercontent/michelin/Mobile/m1.jpg')} alt="" />
                </div>

                <section className="section-wrapper gradient-blue">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <div className="content-wrapper" id="pdf-view" src="/assets/global/media/partnercontent/michelin/Pdf/Content1.pdf">
                        <Slider {...settingSlider}>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/01/Pic-01.png')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/01/Pic-02.png')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/01/Pic-03.png')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/01/Pic-04.png')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/01/Pic-05.png')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/01/Pic-06.png')} alt=""/>
                          </div>
                        </Slider>
                      </div>
                      <a style={{marginBottom: 50, marginRight: 0}} href={require('../../assets/images/partnercontent/michelin/Pdf/Content1.pdf')} target="_blank" rel="noopener noreferrer" className="btn color-yellow marginTop15">Download รายละเอียดเพิ่มเติม คลิก</a>
                    </div>
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">	
                      </div>
                      <div className="col-md-6">						
                        <a href="/specialdeal?content=michelincontent2" className="btn btn-link btn-lg text-purple viewpage right">
                          <span className="arrow"><i className="fal fa-chevron-right"></i></span>	
                          <span className="title text-purple">Road Hazard <br/> Warranty</span>	
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{backgroundColor: '#095A98',color: '#fff'}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white text-center">
                        <div className="desktop-and-tablet">
                          <a href="https://truckloyalty.com/index.php/user/event_register_ex" className=" btn color-white marginTop15 btn-michelin" style={{padding: '0 15px'}}>
                            <h3 style={{margin: 0}}>
                              <img src={require('../../assets/images/partnercontent/michelin/logo_her.png')} alt="" />
                              <span className="text">คลิกเพื่อสมัคร <span className="text-blue">MICHELIN CLUB</span> และรับสิทธิประโยชน์ </span>
                            </h3>
                          </a>
                        </div>

                        <div className="mobile">
                          <img src={require('../../assets/images/partnercontent/michelin/logo_ver.png')} alt="" />
                          <strong>คลิกเพื่อสมัคร <span className="text-yellow">MICHELIN CLUB </span>และรับสิทธิประโยชน์</strong>
                        </div>
                        
                        <br/>

                        <a href="https://truckloyalty.com/index.php/user/event_register_ex" className="btn color-yellow marginTop15">คลิกเลย</a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </MichelinContentOneContainer>
          ) : paramsContent === 'michelincontent2' ? (
            <MichelinContentTwoContainer>
              <Helmet>
                <title>MICHELIN - มั่นใจยิ่งกว่า ด้วยรับประกัน</title>
                <meta name="description" content="มั่นใจยิ่งกว่า ด้วยรับประกัน แตก/ตำ/บาด/บวม สำหรับยางรถบรรทุกในกลุ่มมิชลิน เฉพาะสมาชิกมิชลิน อียู ลอยัลตี้ ตั้งแต่วันนี้-ธันวาคม 2562" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent">
              <section className="header-wrapper"/>
                <div className="mobile" style={{backgroundColor: '#182848'}}>
                  <img src={require('../../assets/images/partnercontent/michelin/Mobile/c2.jpg')} alt="" />
                  <img src={require('../../assets/images/partnercontent/michelin/Mobile/m2.jpg')} alt="" />
                </div>

                <section className="section-wrapper gradient-blue">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <div className="content-wrapper" id="pdf-view" src="/assets/global/media/partnercontent/michelin/Pdf/Content1.pdf">
                        <Slider {...settingSlider}>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/02/Pic-01.jpg')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img style={{maxWidth: 595}} src={require('../../assets/images/partnercontent/michelin/content/02/Pic-02.jpg')} alt=""/>
                          </div>
                        </Slider>
                      </div>
                      <a style={{marginBottom: 50, marginRight: 0}} href={require('../../assets/images/partnercontent/michelin/Pdf/Content2-RoadHazardWarrantyLeaflet_A5_2019_.pdf')} target="_blank" rel="noopener noreferrer" className="btn color-yellow marginTop15">Download รายละเอียดเพิ่มเติม คลิก</a>
                    </div>
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">
                        <a href="/specialdeal?content=michelincontent1" className="btn btn-link btn-lg text-purple viewpage left">
                          <span className="arrow"><i className="fal fa-chevron-left"></i></span>	
                          <span className="title text-purple">Welcome point พิเศษ 50 แต้ม <br/> มูลค่า 3000 บาท</span>	
                        </a>	
                      </div>
                      <div className="col-md-6">						
                        <a href="/specialdeal?content=michelincontent3" className="btn btn-link btn-lg text-purple viewpage right">
                          <span className="arrow"><i className="fal fa-chevron-right"></i></span>	
                          <span className="title text-purple">โปรโมชั่นพิเศษสำหรับยาง<br/> รถบรรทุกขนาด 9.5R17.5</span>	
                        </a>
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{backgroundColor: '#095A98',color: '#fff'}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white text-center">
                        <div className="desktop-and-tablet">
                          <a href="https://truckloyalty.com/index.php/user/event_register_ex" className=" btn color-white marginTop15 btn-michelin" style={{padding: '0 15px'}}>
                            <h3 style={{margin: 0}}>
                              <img src={require('../../assets/images/partnercontent/michelin/logo_her.png')} alt="" />
                              <span className="text">คลิกเพื่อสมัคร <span className="text-blue">MICHELIN CLUB</span> และรับสิทธิประโยชน์ </span>
                            </h3>
                          </a>
                        </div>

                        <div className="mobile">
                          <img src={require('../../assets/images/partnercontent/michelin/logo_ver.png')} alt="" />
                          <strong>คลิกเพื่อสมัคร <span className="text-yellow">MICHELIN CLUB </span>และรับสิทธิประโยชน์</strong>
                        </div>
                        
                        <br/>

                        <a href="https://truckloyalty.com/index.php/user/event_register_ex" className="btn color-yellow marginTop15">คลิกเลย</a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </MichelinContentTwoContainer>
          ) : paramsContent === 'michelincontent3' ? (
            <MichelinContentThreeContainer>
              <Helmet>
                <title>MICHELIN - โปรโมชั่นพิเศษสำหรับยาง รถบรรทุกขนาด 9.5R17.5</title>
                <meta name="description" content="โปรโมชั่นพิเศษสำหรับยาง รถบรรทุกขนาด 9.5R17.5 ชนิดไม่ใช้ยางใน ช่วยประหยัดค่ายางใน ยางรอง ในระยะยาว พร้อมความปลอดภัยที่มากขึ้น" />
              </Helmet>
              <Header />
              <div style={{paddingTop: 60}} className="partnercontent">
              <section className="header-wrapper"/>
                <div className="mobile" style={{backgroundColor: '#182848'}}>
                  <img src={require('../../assets/images/partnercontent/michelin/Mobile/c3.jpg')} alt="" />
                  <img src={require('../../assets/images/partnercontent/michelin/Mobile/m3.jpg')} alt="" />
                </div>

                <section className="section-wrapper gradient-blue">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <div className="content-wrapper" id="pdf-view" src="/assets/global/media/partnercontent/michelin/Pdf/Content1.pdf">
                        <Slider {...settingSlider}>
                          <div className="image-slider">
                            <img src={require('../../assets/images/partnercontent/michelin/content/03/Pic-01.png')} alt=""/>
                          </div>
                          <div className="image-slider">
                            <img src={require('../../assets/images/partnercontent/michelin/content/03/Pic-02.png')} alt=""/>
                          </div>
                        </Slider>
                      </div>
                      <a style={{marginBottom: 50, marginRight: 0}} href={require('../../assets/images/partnercontent/michelin/Pdf/Content3.pdf')} target="_blank" rel="noopener noreferrer" className="btn color-yellow marginTop15">Download รายละเอียดเพิ่มเติม คลิก</a>
                    </div>
                  </div>
                </section>

                <section className="nav-wrapper">
                  <div className="content-wrapper bg-white">
                    <div className="row">
                      <div className="col-md-6">
                        <a href="/specialdeal?content=michelincontent2" className="btn btn-link btn-lg text-purple viewpage left">
                          <span className="arrow"><i className="fal fa-chevron-left"></i></span>	
                          <span className="title text-purple">Road Hazard <br/> Warranty</span>	
                        </a>	
                      </div>
                    </div>
                  </div>
                </section>

                <section className="footer-wrapper" style={{backgroundColor: '#095A98',color: '#fff'}}>
                  <div className="content-wrapper">
                    <div className="row">
                      <div className="col-md-12 text-white text-center">
                        <div className="desktop-and-tablet">
                          <a href="https://truckloyalty.com/index.php/user/event_register_ex" className=" btn color-white marginTop15 btn-michelin" style={{padding: '0 15px'}}>
                            <h3 style={{margin: 0}}>
                              <img src={require('../../assets/images/partnercontent/michelin/logo_her.png')} alt="" />
                              <span className="text">คลิกเพื่อสมัคร <span className="text-blue">MICHELIN CLUB</span> และรับสิทธิประโยชน์ </span>
                            </h3>
                          </a>
                        </div>

                        <div className="mobile">
                          <img src={require('../../assets/images/partnercontent/michelin/logo_ver.png')} alt="" />
                          <strong>คลิกเพื่อสมัคร <span className="text-yellow">MICHELIN CLUB </span>และรับสิทธิประโยชน์</strong>
                        </div>
                        
                        <br/>

                        <a href="https://truckloyalty.com/index.php/user/event_register_ex" className="btn color-yellow marginTop15">คลิกเลย</a>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <Footer />
            </MichelinContentThreeContainer>
          ) : this.redirect()
        }
      </Container>
    )
  }
}

export default SpecialMichelin;