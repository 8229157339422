import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import color from '../../../../config/color';

const Container = styled.div`
	text-align: center;
`;

const Title = styled.div`
  position: relative;
  font-size: 40px;
  font-weight: 700;
`;

const SubTitle = styled.div`
	font-size: 24px;
	line-height: 1;
`;

const Symbol = styled.div`
	display: inline-block;
	position: absolute;
	top: -10px;
	height: 14px;
	width: 14px;
	border-radius: 4px;
	background-color: ${color.Primary};
`;

const TextHeader = (props) => {
	return (
		<Container style={props.style}>
			<Title>{props.title} <Symbol/></Title>
			<SubTitle>{props.subTitle}</SubTitle>
		</Container>
	);
};

export default TextHeader;