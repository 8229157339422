import React, { Component } from 'react';
import styled from 'styled-components';
import { Row, Col, Button,Modal } from 'react-bootstrap';
import coverImage from '../../../../assets/images/gtxBusiness/Picture/wed1.jpg';
import coverHuman from '../../../../assets/images/gtxBusiness/Picture/tem.png';
import color from './../../../../config/color';
import { Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { LazyImage , CardHeader} from '../../../../components';
import appUrl from './../../../../config/appUrl';

AOS.init({
	delay: 100
});

const CoverContainer = styled.div`
	padding-top: calc(45vh - 150px);
	background-image: url(${coverImage});	
	background-repeat: no-repeat;
	background-size:cover;    
	background-position: center;
	width: 100%;    
    height: calc(100vh - 100px);

	& .symbol {
		display: inline-block;
		position: absolute;
		top: -10px;
		height: 14px;
		width: 14px;
		border-radius: 4px;
		background-color: ${color.Primary};
	}

	& .title-header {
		color: #FFFFFF; 
		position: relative;
		font-weight: bold;
		line-height: 40pt;
		font-size: 60px;
	}

	.title-sub-header {
		color: #FFFFFF; 
		font-size: 30px;    
		font-weight: normal;
	}

	.detail {
		font-size: 24px;
		margin-top: 20px;
	}

	.detail a{
		color: rgba(255, 255, 255, 0.6);    
		width: 100%;
		float: left;
		cursor:pointer;
	}

	.detail a:hover{
		color: rgba(255, 255, 255, 1);    
		text-decoration: none;
	}


	.check-button {
		height: 54px;
		width: 276px;
		font-size: 28px;
		border-radius: 6px;
		margin-top: 20px;
		box-shadow: 0px 6px 16px #d9010120;
		background-color: #d90101;

		& i {
			font-size: 20px;
		}

		&:hover {
			background-color: #d90101;
			box-shadow: 0px 10px 20px #d9010140;
		}
	}

	@media (min-width: 992px) {
		.cover-right {    
			padding-top: 150px;
		}

		.img-cover {    
			position: absolute;
			width: 200%;
			right: 0;
		}

		.title-header {
			font-size: 60px;
		}

		.title-sub-header {
			font-size: 30px;    
			font-weight: normal;
		}

		.check-button {
			height: 55px;
			width: 270px;
			font-size: 28px;

			& i {
				font-size: 20px;
			}
		}
	}

	@media (min-width: 1200px) {
		.img-cover {    
			position: absolute;
			width: 200%;
			right: 0;
		}

		.cover-right {    
			padding-top: 150px;
		}
		
		.title-header {
			font-size: 60px;
		}

		.title-sub-header {
			font-size: 30px;    
			font-weight: normal;
		}

		.check-button {
			height: 60px;
			width: 300px;
			font-size: 34px;

			& i {
				font-size: 26px;
			}
		}
	}

	@media (max-width: 992px) {
		padding-top: calc(45vh - 200px);
		.cover-right {
			padding-top: 200px;
		}
	}
`;

const InnerContainer = styled.div`
	padding: 15px;
	height: calc(100vh - 223px);
	overflow: auto;
	border: 1px solid #ddd;
	border-radius: 10px;

	ol {
		list-style-type: none;
		counter-reset: item;
		margin: 0;
		padding: 0;
	  }
	  
	  ol > li {
		display: table;
		counter-increment: item;
		margin-bottom: 0.6em;
	  }
	  
	  ol > li:before {
		content: counters(item, ".") ". ";
		display: table-cell;
		padding-right: 0.6em;    
	  }
	  
	  li ol > li {
		margin: 0;
	  }
	  
	  li ol > li:before {
		content: counters(item, ".") " ";
	  }
`;

class Cover extends Component {
	state = {
		isTop: true,
		onOpenModal: false
	};

	render() {
	return (
		<div>
			<CoverContainer>
				<div className="container">	
					<Row>
						<Col xl={7} lg={7} md={7} className="size">
							<div className="cover-right">
								<div data-aos="fade-up" data-aos-duration="1500">
									<div className="title-header">
									สำหรับลูกค้าองค์กร/ธุรกิจ <span className="symbol" />
									</div>
									<div className="title-sub-header">ตอบสนองความต้องการใช้บริการขนส่งในระดับองค์กร</div>
								</div>

								<div data-aos="fade-up" data-aos-duration="1800">
									<Button bsStyle="primary" className="check-button" onClick={() => window.location = `${appUrl}/register-business`}>
										ลงทะเบียน
									</Button>
								</div>
								
								<div data-aos="fade-up" data-aos-duration="2100">
									<div className="detail">
										<a onClick={() => this.setState({ onOpenModal : true }) }>อ่านเงื่อนไขและข้อกำหนด > </a>
										<a href="/contactus">สอบถามรายละเอียดเพิ่มเติม ></a>
									</div>
								</div>
							</div>
						</Col>

						<Col xl={5} lg={5} md={5} smHidden xsHidden className="size">
							
						</Col>
					</Row>
				</div>
				
			</CoverContainer>

				<Modal show={this.state.onOpenModal} size="lg">
					<Modal.Header>
						<Modal.Title>
							<CardHeader
								title="เงื่อนไขและข้อกำหนดการให้บริการ Giztix Business"
								onPressClose={() =>
									this.setState({ onOpenModal : false })
								}
							/>
						</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InnerContainer>
							<p>	
								คำนิยามต่อไปนี้ใช้บังคับกับเงื่อนไขข้อกำหนดการให้บริการ Giztix Business ที่ระบุด้านล่างนี้  ซึ่งใช้บังคับในเงื่อนไขการให้บริการ
								“Giztix Business” หมายถึงผลิตภัณฑ์ สำหรับให้บริการขนส่งโดย ยินยอมให้ผู้ใช้บริการชำระค่าบริการตามรอบบิล โดยผลิตภัณฑ์นี้เป็นกรรมสิทธิ์ของบริษัท
								“ท่าน”,“ผู้ยื่นสมัคร”,”ผู้ยื่นคำร้อง”,”ผู้สมัคร”,”ผู้ใช้บริการ” หมายถึง บุคคล หรือ บริษัท ที่มีความประสงค์สมัครใช้บริการ Giztix Business
								“เรา”,”บริษัท” หมายถึง บริษัท จิซทิกซ์ จำกัด
							</p>
							<p>	
									เมื่อท่านทำการสมัครการใช้บริการ Giztix Business เราถือว่าท่านยอมรับเงื่อนไขและข้อกำหนดของเราที่ระบุอยู่ด้านล่าง
							</p>
							<p>			
								<ol>
									<li>
										ข้อมูลที่ท่านมอบให้เรา
										<ol>
											<li>
												ข้อมูลที่ท่านมอบให้เรามีความถูกต้องแม่นยำ ในที่นี้หมายถึง ชื่อบุคคลที่เกี่ยวข้อง, ชื่อบริษัท, เลขประจำตัวผู้เสียภาษีอากร, ที่ตั้งบริษัท, และ/หรือ ข้อมูลอื่นใดที่ท่านมอบให้เราล้วนมาจากความสมัครใจของท่านทั้งสิ้น 
											</li>
											<li>
												ท่านยินยอมให้เราใช้ข้อมูลดังกล่าวเพื่อสมัครสมาชิก Giztix Business เท่านั้น
											</li>
											<li>
												ท่านทราบดีว่าข้อมูลที่ท่านมอบให้เราเป็นความลับทางธุรกิจระหว่างท่านกับเรา
											</li>
											<li>
												ท่านยอมรับว่าข้อมูลที่มอบให้เรามีความถูกต้อง ครบถ้วน และท่านอนุญาติให้เราใช้ข้อมูลเท่านั้นเพื่อการสมัคร Giztix Business และ/หรือ การออกใบแจ้งหนี้, ใบเสร็จรับเงิน, ใบกำกับภาษี
											</li>
											<li>
												ทางเราสงวนสิทธิ์ในการรับผิดชอบในความเสียหายอันเกิดมาจากความผิดพลาดของข้อมูลทุกประการ
											</li>
										</ol>
									</li>

									<li>
										การให้เครดิตและการชำระ
										<ol>
											<li>
												ท่านได้อ่านและยอมรับเงื่อนไขการให้เครดิตชำระค่าจ้างจากเรา โดยกำหนดให้เครดิตนับตั้งแต่วันที่ 1 ถึงวันสุดท้ายของเดือน
											</li>
											<li>
												ท่านได้อ่านและยอมรับเงื่อนไขการชำระค่าจ้าง โดยกำหนดให้ท่านทำการชำระภายในวันที่ 7 ของเดือนถัดไป
											</li>
											<li>
												ท่านได้อ่านและยอมรับเงื่อนไขการสงวนสิทธิ์การให้บริการในกรณีท่านไม่ชำระค่าจ้างข้างต้นในเวลาที่กำหนด
											</li>
											<li>
												การชำระค่าจ้างด้วยเงินสด(COD) หรือ บัตรเครดิต จะถือว่าเราไม่ได้ให้เครดิตค่าจ้างกับท่าน และงานที่จ้างนั้นจะไม่ถูกนำไปบันทึกในยอดค้างชำระ และ/หรือ ถูกบันทึกในใบแจ้งหนี้, ใบกำกับภาษี, ใบเสร็จรับเงิน, เอกสารหัก ณ ที่จ่าย
											</li>
											<li>
												เอกสารใบแจ้งหนี้, ใบเสร็จรับเงิน, ใบกำกับภาษี จะถูกส่งไปยังท่านด้วยระบบอิเล็กทรอนิกส์ผ่านช่องทางอีเมลที่ท่านแจ้งเท่านั้น
											</li>
											<li>
												ในกรณีท่านต้องการทำหัก ณ ที่จ่าย ท่านได้ให้คำมั่นกับเราว่าจะส่งเอกสารใบหัก ณ ที่จ่ายต้นฉบับมายังสำนักงานของเราทุกครั้ง
											</li>
											<li>
												ท่านยินยอมที่จะชำระค่าจ้างตามที่แจ้งในใบแจ้งหนี้ผ่านทางช่องการชำระที่เรากำหนดไว้เท่านั้น
											</li>
										</ol>
									</li>
								</ol>
							</p>
						</InnerContainer>
					</Modal.Body>

					<Modal.Footer>
						<Button  onClick={() => window.location = `${appUrl}/register-business`} bsStyle="primary" block>ลงทะเบียน</Button>
					</Modal.Footer>
				</Modal>
			
		</div>
	);
	}
};

export default Cover;
