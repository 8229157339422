import React from 'react';
import ModalPromotion from './modals/Promotion';
import moment from 'moment';

class ModalEvent extends React.Component {
  state = {
    isOpenModal: false,
  }

  componentDidMount() {
    if(this.checkModalClose()) {
      this.setState(this.setState({ isOpenModal: true }));
    }
  }

  checkModalClose() {
    const format = "YYYY-MM-DD HH:mm:ss";
    const current = moment();
    const startDate = moment("2019-05-23 00:00:00", format);
    const endDate   = moment("2019-06-30 23:59:59", format);
    const range = current.isBetween(startDate, endDate, null, '[]');

    return range;
  }

  render() {
    return (
      <div>        
        <ModalPromotion 
          show={this.state.isOpenModal} 
          onPressButton={() => this.setState({ isOpenModal: false})} 
          onClose={() => this.setState({ isOpenModal: false})} 
          onHide={() => this.setState({ isOpenModal: false})}
        />
      </div>
    )
  }
}

export default ModalEvent;