import React, { Component } from 'react';
import { Header, Footer, LazyImage } from './../../../../components';
import { Cover, TextHeader } from './components';
import styled from 'styled-components';
import { Row, Col, Button , Modal } from 'react-bootstrap';
import Dotdotdot from 'react-dotdotdot';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link as LinkScroll, Element } from 'react-scroll';
import { Helmet } from 'react-helmet';
import './style.css';

//StepSupport
import imageSupport from '../../../../assets/images/gtxBusiness/Icon/Support.svg';
import fullTransportService from '../../../../assets/images/gtxBusiness/Icon/fullTransportService.svg';
import SecurityService from '../../../../assets/images/gtxBusiness/Icon/SecurityService.svg';

//InvoiceContainer
import PaymentService from '../../../../assets/images/gtxBusiness/Icon/PaymentService.svg';
import SavePrice from '../../../../assets/images/gtxBusiness/Icon/SavePrice.svg';

//PriceContainer
import SaveCost from '../../../../assets/images/gtxBusiness/Icon/SaveCost.svg';
import ManageShipment from '../../../../assets/images/gtxBusiness/Icon/ManageShipment.svg';

//FunnyContainer
import bgFunny from '../../../../assets/images/career/Full pic/career.jpg';

//JoinContainer
import bgJoin from '../../../../assets/images/career/mainpiconHomepage.jpg';



import color from '../../../../config/color';
import './style.css';
import appUrl from './../../../../config/appUrl';

//privilege Container

import imagePrivilege1 from '../../../../assets/images/career/Full pic/career1.3.jpg';
import imagePrivilege2 from '../../../../assets/images/career/Full pic/career1.1.jpg';
import imagePrivilege3 from '../../../../assets/images/career/Full pic/career1.2.jpg';
import imagePrivilege4 from '../../../../assets/images/career/664.jpg';
import imagePrivilege5 from '../../../../assets/images/career/403405-PCSCAQ-741.jpg';


import { withNamespaces } from "react-i18next";

AOS.init({
	delay: 100
});

const Container = styled.div`
	
`;

const MoreContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position:absolute;
	bottom:0px;
	width:100%;

	& .text-button-more {
		color: #ffffff;
		text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);	
		cursor: pointer;
		text-decoration: none;

		& i {
			font-size: 40px;
		}
	}

	@media (max-width: 992px) {
		bottom:0px;
	}
`;

const StepSupportContainer = styled.div`
	text-align: center;
	margin-top: 30px;

	& .box-container {
		height: 310px;
		display: flex;
		align-items: center;
	}

	& .box {
		padding: 35px 20px;
		background-color: #ffffff;
    	box-sizing: border-box;
		border-radius: 5px;
		height: 280px;
		vertical-align: middle;
		display: block;
		transition: all .3s;
		margin: 0 auto;

		&:not(:last-child) {
			margin-right: 20px;
		}

		& .box-image {
			display: block;

			& img {
				width: 120px;
				height: 120px;
			}
		}

		& .box-title {
			margin-top: 30px;
			font-weight: 700;
			text-align: center;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;


const InvoiceContainer = styled.div`
	margin-top: 0px;
	margin-bottom: 40px;
	
	& .box-container {   
		height: auto;
		margin-bottom: 40px;
		display: flex;
		align-items: top;
	}

	& .box-image {
		display: block;

		& img {
			width: 100px;
			height: 100px;
		}
	}

	& .box {
		padding: 0;
		padding-top: 10px;
		text-align: right;
		height: auto;
		display: block;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin: 0 auto;

		&:not(:last-child) {
			margin-right: 40px;
		}

		& .box-title {
			margin-top: 0;
			font-weight: 700;
			text-align: right;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

const PriceContainer = styled.div`
	margin-top: 0px;
	margin-bottom: 40px;

	& .box-container {    
		height: auto;
		margin-bottom: 40px;
		display: flex;
		align-items: top;
	}

	& .box-image {
		display: block;

		& img {
			width: 100px
			height: 100px;
		}
	}

	& .box {
		padding: 0;
		padding-top: 10px;
		text-align: left;
		height: auto;
		display: block;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin: 0 auto;
		margin-left: 40px;

		&:not(:last-child) {
			margin-right: 40px;
		}

		& .box-title {
			margin-top: 0;
			font-weight: 700;
			text-align: left;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

const FunnyContainer = styled.div`
	background-image: url(${bgFunny});	
	height: 384px;
	padding: 40px 0;
	background-repeat: no-repeat;
		background-size: cover;
		text-align: center;

	.container{
		text-align: center;
		color: #fff;
		padding: 40px;
		position: relative;
		height: 140px;

		.check-button{
			height: 40px;
			width: 140px;
			font-size: 24px;
		}
	}
`;

const JoinContainer = styled.div`

background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        /* bottom, image */
        url(${bgJoin});
	/* background-image: url(${bgJoin});	 */
	height: 300px;
	padding: 40px 0;
	background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		text-shadow: 0px 3px 6px #000;

	.container{
		text-align: center;
		color: #fff;
		padding: 40px;
		position: relative;
		height: 140px;

		.check-button{
			height: 40px;
			width: 140px;
			font-size: 24px;
		}
	}
`;



const LineHorizontal = styled.div`
	width: 100%;
	height: 1px;
	background-color: #E3E3E3;
`;

const StepInvoiveContainer = styled.div`
	margin-top:30px;
`;

const ColStepLeft = styled(Col)`
	padding-top: 50px;
	padding-right:60px;
	padding-bottom: 40px;
	@media (max-width: 992px) {
		padding-right:30px;
	}
`;
const ColStepRight = styled(Col)`
	padding-left:60px;
	padding-top: 50px;
	padding-bottom: 40px;
	border-left : 1px dashed #ddd;
	@media (max-width: 992px) {
		padding-left:30px;
	}
`;

const PrivilegeContainer = styled.div`
	font-size: 24px;
	margin-top: -100px;

	& .privilege-cover {
		border-radius: 6px;
		/* border: 1px solid #fafafafa; */

		& img {
			width: 100%;
			border-radius: 6px;
		}
	}

	& .privilege-title {
		margin-top: 10px;
		font-weight: 700;
	}

	& .privilege-detail {
		color: #00000090;
		line-height: 1;
	}

	& .privilege-button-more {
		display: inline-block;
		margin-top: 20px;
		margin-bottom: 10px;
		cursor: pointer;
		border-bottom: 2px solid transparent;

		& a {
			text-decoration: none;
			color: #000000;
			line-height: 1;
		}

		&:hover {
			font-weight: 700;
			border-bottom: 2px solid #d90101;
		}
	}
`;

const DeviceContainer = styled.div`
	position: relative;
	margin-top: 80px;

	@media (max-width: 992px) {
		margin-top: -30px;
		margin-bottom: 0px;
	}


	& .device-content {
		box-shadow: 0px 10px 20px rgba(0,0,0,0.1);
    background-color: #FFFFFF;
    border-radius: 6px;
    border: 1px solid #fafafafa;
    padding: 100px 60px;
    position: absolute;
    bottom: 0;
    right: calc(100vh - 725px);
    width: 536px;

		& .download-container {
			display: flex;
			flex-direction: row;
			margin-top: 16px;
		}

		& .download-image-grcode {
			& img {
				width: 120px;
			}
		}

		& .download-line-vertical {
			width: 1px;
			background-color: #E3E3E3;
			margin: 0 20px;
		}

		& .download-image-store-container {
			display: flex;
			flex-direction: column;

			& img {
				width: 100%;
				max-width: 175px;
				margin-bottom: 10px;
			}
		}
	}

	& .device-image {
		width: calc(70%);
		height: auto;

		& img {
			width: 100%;
		}
	}

	& .text-download {
		margin-top: 20px;
	}

	@media (max-width: 992px) {
		.device-image {
    	width:100%;
		}
		.device-content {
			bottom: 50px;
			right: 50px;
		}
	}
`;

const Symbol = styled.div`
	display: inline-block;
	position: absolute;
	top: -10px;
	height: 14px;
	width: 14px;
	border-radius: 4px;
	background-color: ${color.Primary};
`;


class GiztixBusiness extends Component {
	state = {
		isTop: true,
		onOpenModal: false
	}

	componentDidMount() {
		document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 100;
			if (isTop !== this.state.isTop) {
				this.setState({ isTop });
			}
		});
	}

	render() {
		return (
			<Container>
				<Helmet>
					<title>Career | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
					<meta name="description" content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา" />
				</Helmet>
				<Header
					bgcolor={this.state.isTop ? 'transparent' : '#000000'}
          border={false}
          color="white"
				/>
				
				<div className="cover-height-career">
					{/* บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย */}
					<Cover/>
					
					{/* ดูเนื้อหาเพิ่มเติม */}
					<MoreContainer>
						<div style={{ textAlign: 'center' }}>
							<LinkScroll className="text-button-more" to="StepSupportContainer" smooth={true} duration={1500}>
							ดูเนื้อหาเพิ่มเติม
								<div>
									<i className="fal fa-angle-double-down" />
								</div>
							</LinkScroll>
						</div>
					</MoreContainer>
				</div>

					{/* สะดวกสบายกับรูปแบบ */}
					<Element name="StepSupportContainer" />
					
					<StepInvoiveContainer>
					<div data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
						<DeviceContainer style={{marginBottom:80}}>
								<div className="device-image">
									<LazyImage src={imagePrivilege4} alt="privilege" />
								</div>
									<div className="device-content">
											<TextHeader
												className="title"
												style={{ marginTop: 20,textAlign:"left"}}
												styleTitle={{marginBottom: 20, fontSize:60, fontWeight:"bold", color:"#000", marginTop: 30}}
												title="มุ่งมั่นเพื่อองค์กร"
												detail="มีความมุ่งมั่นตั้งใจปฏิบัติงาน ให้บังเกิดผลลัพธ์
												ที่เป็นรูปธรรม เพื่อสอดคล้องกับเป้าหมาย
												ของหน่วยงานและองค์กร มีความพยายามทุ่มเท
												ที่จะส่งมอบงาน ที่มีคุณภาพ และได้มาตรฐาน
												ตามเวลาที่กำหนด ใส่ใจในผลลัพธ์สุดท้ายขององค์กร"
											>
											</TextHeader>
									</div>
					</DeviceContainer>
					</div>
					<Row>
					<FunnyContainer>
					<div data-aos="fade-up" data-aos-duration="1000">
						<div className="container" > 
							<TextHeader
								className="title"
								styleTitle={{textAlign:"center", fontSize:60, fontWeight:"bold", color:"#fff", marginTop: 30}}
								title="สร้างความสนุกสนานในทีมงาน"
								detail="มุ่งมั่นที่จะสร้างประสบการณ์และความสนุกสนาน เพื่อเสริมพลังและสร้างแรงบันดาลใจให้กับทีมงาน"
							>
							</TextHeader>
						</div>
					</div>
				</FunnyContainer>
						</Row>
					</StepInvoiveContainer>

				
					
					<div className="container">

					<PrivilegeContainer>
					
					<div>
							<Row>
								<Col md={4} lg={4} xs={4} sm={4}>
									<div data-aos="fade-up" data-aos-duration="1300">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege1} alt="privilege" />
											</div>
									</div>
								</Col>
					
								<Col md={4} lg={4} xs={4} sm={4}>
									<div data-aos="fade-up" data-aos-duration="1300">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege2} alt="privilege" />
											</div>
									</div>
								</Col>

								<Col md={4} lg={4} xs={4} sm={4}>
									<div data-aos="fade-up" data-aos-duration="1300">
											<div className="privilege-cover">
												<LazyImage src={imagePrivilege3} alt="privilege" />
											</div>
									</div>
								</Col>
							</Row>
						</div>
					</PrivilegeContainer>
				</div>

				<div data-aos="fade-up" data-aos-duration="1500" style={{marginTop: 80, marginBottom: 80}}>
				<TextHeader
								className="title"
								styleTitle={{marginBottom: 10, textAlign:"center", fontSize:60, fontWeight:"bold", color:"#000"}}
								title="ให้ความร่วมมือในทีม"
							>
				</TextHeader>
					<p style={{textAlign:"center"}}>มีความยินดีที่จะร่วมงานกับผู้อื่น เข้าใจในบทบาท หน้าที่ของตนเอง หรือการแก้ไขปัญหากับทีม ให้ความร่วมมือช่วยเหลือเพื่อนร่วมงาน <br/>
					มีน้ำใจ ให้เกียรติผู้อื่น โดยปราศจากอคติ เพื่อมุ่งที่จะทำให้เป้าหมายของทีม และองค์กรสำเร็จลุล่วงลง</p>
				</div>

				<DeviceContainer>
							<Row>
							<Col md={6} sm={6} style={{paddingLeft: 0, paddingRight: 0}}>
									<div data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
										<Col style={{width: "100%", height:768, backgroundColor: "#F9F9F9", display:"flex", justifyContent:"center", alignItems:"center"}}>
											<div style={{height:768, display:"flex", justifyContent:"center", alignItems:"center", flexDirection: "column"}}>
												<div style={{width: 150, backgroundColor:"#D90101", height: 45, alignSelf:"flex-start"}}></div>
												<img src={imagePrivilege5} alt="privilege" style={{width: "auto", height:"250px"}} />
												<div style={{width: 150, backgroundColor:"#318DE8", height: 45, alignSelf:"flex-end"}}></div>
											</div>
										</Col>
									</div>
								</Col>
								<Col md={6} sm={6} style={{paddingLeft: 0, paddingRight: 0}}>
									<div data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
										<Col style={{width: "100%", height:768, backgroundColor: "#000"}}>
										
										<Col style={{padding: "300px 100px "}}>
												<TextHeader
													className="title"
													styleTitle={{marginBottom: 10, textAlign:"left", fontSize:60, fontWeight:"bold", color:"#fff"}}
													title="คิดต่างเพื่ออนาคต"
												>
												</TextHeader>
												<p style={{textAlign:"left", fontSize:24, color:"#fff"}}>แสดงออกซึ่งการขับเคลื่อนการปฏิบัติงานด้วยความคิดสร้างสรรค์ <br/>
												ปรับปรุงงาน หรือคิดค้นนวัตกรรม ที่ก่อให้เกิดประสิทธิภาพ<br/>
												และประสิทธิผลต่อองค์กร</p>
										</Col>
										</Col>
									</div>
								</Col>
							</Row>

							<JoinContainer>
						<div className="container"> 
							<TextHeader
								className="title"
								style={{ marginBottom: 30,textAlign:"center"}}
								styleTitle={{marginBottom: 10, textAlign:"center", fontSize:36, fontWeight:"bold", color:"#fff"}}
								title="คุณพร้อมที่จะเป็นส่วนหนึ่งของ GIZTIX EXPRESS แล้วหรือยัง?"
								detail="ชีวิตธุรกิจของคุณจะดีขึ้นในทุกที่ทุกเวลา"
							>
							</TextHeader>
							<Button bsStyle="primary" className="check-button" onClick={() => window.location = `/giztixdepartment`}>
								ร่วมงานกับเรา
							</Button>
						</div>
				</JoinContainer>

				<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.5404450240753!2d100.60889471482976!3d13.685684990390254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d672e8097f671%3A0x8b3d7495efdf67d5!2sGIZTIX+HQ!5e0!3m2!1sth!2sth!4v1562662978115!5m2!1sth!2sth" style={{width:"100%", height:"300px" ,border: 0, marginBottom: -10}} allowfullscreen></iframe>

						</DeviceContainer>

				
				<Footer />
			</Container>
		);
	}
}

export default withNamespaces()(GiztixBusiness);
