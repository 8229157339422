import React from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { Query } from "react-apollo";
import styled from "styled-components";
import logo from "./../../assets/images/logo_giztix.png";
import appIos from "./../../assets/images/app-store.svg";
import playStore from "./../../assets/images/play-store.svg";

import version from "./../../config/version";
import appUrl from "./../../config/appUrl";

import { withNamespaces } from "react-i18next";

const FooterContainer = styled.div`
  background-color: #000;
  color: rgba(255, 255, 255, 0.6);
  padding: 80px 0 60px;

  & p {
    line-height: 1;
  }
`;
const MenuContainer = styled(Row)`
  padding-bottom: 40px;
`;
const DownloadContainer = styled(Row)`
  padding-top: 40px;
  padding-bottom: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;
const LoadAppContainer = styled.div``;
const CopyRightContainer = styled(Row)`
  padding-top: 40px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
`;
const Title = styled.div`
  color: rgba(255, 255, 255, 1);
  margin-bottom: 10px;
`;
const LinkImg = styled.a`
  margin-right: 10px;
`;
const Img = styled.img`
  height: 40px;
  border-radius: 4px;
  border: none;
`;
const Link = styled.a`
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 5px;
  width: 100%;
  float: left;
`;
const SocailLinkContainer = styled.div`
  width: 100%;
  float: left;
`;
const SocailLink = styled.a`
  display: inline-block;
  margin-right: 20px;
  font-size: 27px;
  line-height: 30px;
  color: rgba(255, 255, 255, 0.6);
`;

class Footer extends React.Component {
  state = {
    openMenu: false,
  };

  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://www.trustmarkthai.com/callbackData/initialize.js?t=ac54f3-22-5-c2eebed8b4423b9f63231bdf5fe34ef38a451cc";
    script.id = "dbd-init";
    script.async = true;
    document.body.appendChild(script);
  }

  render() {
    return (
      <FooterContainer>
        <div className="container">
          <MenuContainer>
            <Col md={6} sm={12}>
              <Title>{this.props.t("header:contactCustomerService")}</Title>
              <p>
                {this.props.t("header:GIZTIXCompanyName")}
                <br />
                {this.props.t("header:AddressGIZTIX")}
                <br />
                {this.props.t("header:AddressGIZTIX2")}
              </p>
              <p>
                {this.props.t("header:emailGiztix")}
                <a href="mailto:service@giztix.com" style={{ color: "#ccc" }}>
                  service@giztix.com
                </a>
                <br />
                {this.props.t("header:contactGiztix")} 02-026-6895
                <br />
                {this.props.t("header:giztixOpenhours")}
              </p>
            </Col>
            <Col md={2} sm={4}>
              <Title>{this.props.t("header:MainMenu")}</Title>
              <Link href={`${appUrl}/tracking`}>{this.props.t("header:shipmentTracking")}</Link>
              <Link href="/pricing">{this.props.t("header:transportationRate")}</Link>
              <Link href="https://blog.giztix.com/">{this.props.t("header:newsPromotion")}</Link>
              <Link href="/helpcenter">{this.props.t("header:helpCenter")}</Link>
            </Col>
            <Col md={2} sm={4}>
              <Title>{this.props.t("header:aboutUs")}</Title>
              <Link href="/aboutus">{this.props.t("header:WhyGIZTIX")}</Link>
              <Link href="/contactus">{this.props.t("header:contactUs")}</Link>
              <Link href="/termscondition">{this.props.t("header:TermsConditions")}</Link>
              <Link href="/complain">{this.props.t("header:SolvingRequestPolicy")}</Link>
            </Col>
            <Col md={2} sm={4}>
              <Title>{this.props.t("header:OtherService")}</Title>
              <Link href="http://thumbweb.maninnovation.com/">ThumbWeb</Link>
              <Link href="/subscribe">{this.props.t("subscribe:menu")}</Link>
              
              {/*<Link href="https://parcel.giztix.com/">{this.props.t('header:parcelMenu')}</Link>*/}
            </Col>
          </MenuContainer>
          <DownloadContainer>
            <Col md={6} sm={6}>
              <Title>{this.props.t("header:DownloadApplicationat")}</Title>
              <LoadAppContainer>
                <LinkImg className="img" href="https://itunes.apple.com/app/id1439755826" target="_blank">
                  <Img src={appIos} className="logo" alt="logo" />
                </LinkImg>
                <LinkImg className="img" href="https://play.google.com/store/apps/details?id=com.giztix.app" target="_blank">
                  <Img src={playStore} className="logo" alt="logo" />
                </LinkImg>
              </LoadAppContainer>
            </Col>
            <Col md={4} sm={6}>
              <Title>{this.props.t("header:SubscribeHere")}</Title>
              <SocailLinkContainer>
                <SocailLink href="https://www.facebook.com/giztix/" target="_blank">
                  <i className="fab fa-facebook-square"></i>
                </SocailLink>
                <SocailLink href="https://line.me/R/ti/p/@giztix" target="_blank">
                  <i className="fab fa-line"></i>
                </SocailLink>
                <SocailLink href="https://www.instagram.com/giztix_official/" target="_blank">
                  <i className="fab fa-instagram"></i>
                </SocailLink>
                <SocailLink href="https://www.youtube.com/channel/UCDoz39PN2yzoQB5d0-xcgJg" target="_blank">
                  <i className="fab fa-youtube"></i>
                </SocailLink>
              </SocailLinkContainer>
            </Col>
            <Col md={2} sm={12} style={{ textAlign: "right" }}>
              <div id="Certificate-banners"></div>
            </Col>
          </DownloadContainer>
          <CopyRightContainer>
            <Col md={6} sm={12}>
              <span>
                {this.props.t("header:CopyRightGiztix")} {version})
              </span>
            </Col>
            <Col md={2} sm={4}>
              <Link href="https://s3.ap-southeast-1.amazonaws.com/appmedia.giztix.com/template/Privacy_Notice.pdf" target="_blank">
                {this.props.t("header:PrivacyNotice")}
              </Link>
            </Col>
            <Col md={2} sm={4}>
              <Link href="/privacy-policy">{this.props.t("header:PrivacyPolicy")}</Link>
            </Col>
            <Col md={2} sm={4}>
              <Link href="/standard">{this.props.t("header:ServiceStandard")}</Link>
            </Col>
          </CopyRightContainer>
        </div>
      </FooterContainer>
    );
  }
}

Footer.propTypes = {
  active: PropTypes.number,
};

Footer.defaultProps = {
  active: null,
};

export default withNamespaces()(Footer);
