import gql from 'graphql-tag';

export default gql`
	mutation ContactUs(
		$name: String
		$lastName: String
		$email: String
		$phone: String
		$msg: String
	) {
		contactUs(
			name: $name
			lastName: $lastName
			email: $email
			phone: $phone
			msg: $msg
		) {
			succeed
		}
	}
`;
