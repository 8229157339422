import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
	CardImageHeader,
	BreadcrumbTitel,
} from './../../components';
import BannerSponsor from '../../containers/BannerSponsor';
import { Helmet } from 'react-helmet';
import imagePricing from '../../assets/images/pricing/pricing.jpg';
//image tab
import image10WheelsCabinet from '../../assets/images/pricing/newModel/01-10WHEELS_CABINET.png';
import image10WheelsStall from '../../assets/images/pricing/newModel/03-10WHEELS_STALL.png';
import image6WheelsCabinet from '../../assets/images/pricing/newModel/01-6WHEELS_CABINET.png';
import image6WheelsCrane from '../../assets/images/pricing/newModel/04-6WHEELS_CRAN.png';
import imagePickupStall from '../../assets/images/pricing/newModel/04-PICKUP_STALL.png';
import imagePickupCabinet from '../../assets/images/pricing/newModel/01-PICKUP-CABINET.png'; 
import image6WheelsStall from '../../assets/images/pricing/newModel/03-6WHEELS_STALL.png';
import imageTrailer20FT from '../../assets/images/pricing/newModel/02-TRAILER_20FT.png';
import image6WheelsLift from '../../assets/images/pricing/newModel/6WHEELS_LIFT.png';
import image10WheelsLift from '../../assets/images/pricing/newModel/10WHEELS_LIFT.png';
import imageTrailer from '../../assets/images/pricing/newModel/06-TRAILER.png';
import imageTrailer20 from '../../assets/images/pricing/newModel/container20.png';
import imageTrailer40 from '../../assets/images/pricing/newModel/container40.png';
import { Row, Col } from 'react-bootstrap';
import color from '../../config/color';
import { withNamespaces } from "react-i18next";
import ReactHTMLParser from 'react-html-parser';

const Container = styled.div`
	@media(max-width: 767px) {
		& .price-container > div {
			
			margin-top: 120px;
			&:first-child{
				margin-top: 0px;
			}
			&:last-child{
				margin-top:50px;
			}
		}
	}
`;

const TabContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	overflow-x: auto;

	@media(max-width: 768px) {
		justify-content: flex-start;
	}

	&::-webkit-scrollbar {
    display: none;
	}

	& .active {
		border-bottom: 2px solid ${color.Primary};
		font-weight: 700;
	}

	& .tab {
		display: flex;
		flex-direction: column;
		cursor: pointer;

		&:not(:last-child) {
			margin-right: 95px;

			@media(max-width: 768px) {
				margin-right: 60px;
			}
    }
    
    &-disabled {
      opacity: 0.5;
    }

		&-image {
			& img {
				height: 60px;
			}
		}

		&-title {
			font-size: 24px;
			color: #000000;
			margin-top: 5px;
			white-space: nowrap;

			@media(max-width: 768px) {
				font-size: 20px;
			}

			&:hover {
				color: rgba(0, 0, 0, 0.8);
      }
		}
	}
`;

const Title = styled.div`
	font-size: 36px;
	font-weight: 700;
	color: #000000;
	line-height: 1;
`;

const SubTitle = styled.div`
	font-size: 24px;
`;

const PriceContainer = styled.div`
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	padding: 20px;
	margin-bottom: 20px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	& .image-truck {
		align-self: center;
		position: absolute;
		top: -65px;

		& img {
			height: 140px;
		}

		&-trailer {
			top: -90px;

			& img {
				height: 200px;
			}
		}
	}

	& .title {
		text-align: center;
		margin-top: 90px;
		font-size: 30px;
		font-weight: 700;
		line-height: 1;
		margin-bottom: 10px;
	}

	& .detail {
		font-size: 24px;
		text-align: center;
		margin-bottom: 15px;
		line-height: 1;
		min-height: 100px;
	}

	& .box {
		border-radius: 6px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 10px;
		margin-bottom: 10px;

		&-title {
			flex: 1;
			font-size: 24px;
			font-weight: 700;
			line-height: 1;
			padding: 10px;
		}

		&-number {
			text-align: right;
			font-size: 36px;
			font-weight: 700;
			white-space: nowrap;

			&-unit {
				text-align: left;
				display: inline-block;
				width: 25px;
				font-size: 20px;
			}
		}

		&-service-per-trip {
			background-color: ${color.Primary};
			color: #ffffff;
		}

		&-service-minimum-price {
			background-color: ${color.White};
			color: ${color.Primary};
			border: 1px solid ${color.Primary};
		}

		&-discount {
			background-color: ${color.White};
			color: ${color.Black};
			border: 1px solid ${color.Black};
		}
	}

	& .price-list {
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;

		&-header {
			font-weight: 700;
		}

		&-left {
			flex: 1;
		}

		&-right {
			font-weight: 700;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #e3e3e3;
		}
  }
  
  .hide {
    display: none;
  }
`;

const BoxPriceService = styled.div`
	border-radius: 6px;
	padding: 10px;
	margin-bottom: 10px;
	line-height: 1;
	font-size: 24px;
	background-color: ${color.White};
	color: ${color.Black};
	border: 1px solid ${color.Black};
	text-align: center;

	& .price {
		font-size: 36px;
		font-weight: bold;
		margin: 0px 5px;
	}

	&.text-bold {
		font-weight: bold;
	}

	&.bg-red {
		background-color: ${color.White};
		color: ${color.Primary};
		border: 1px solid ${color.Primary};
	}
`;

const Wrapper = styled.div`
	padding-top: 120px;
	padding-bottom: 100px;

	@media(max-width: 767px) {
		padding-top: 15px;
		padding-bottom: 20px;
	}
`;

class Pricing extends React.Component {
	state = {
		selected: 0,
	}

	handleSelectTab(tabIndex) {
		this.setState({ selected: tabIndex });
	}

	renderTranslate(text) {
		return (
			<div style={{whiteSpace: 'pre-wrap'}} dangerouslySetInnerHTML={{__html: text}} />
		)
	}

	render() {
		const menu = [
			{
				active: true,
				link: '#',
				title: this.props.t('pricing:transportationServiceRate'),
			},
		];

		return (
			<Container>
				<Helmet>
					<title>Pricing | GIZTIX</title>
					<meta name="description" content="อัตราค่าบริการรถขนส่งเหมาคัน ส่งสินค้าทั่วประเทศไทย" />
				</Helmet>
				<Header />
				<BreadcrumbTitel menu={menu} />
				
				<Wrapper>
					<CardImageHeader
						img={imagePricing}
						title={this.props.t('pricing:transportationServiceRate')}
						subtitle={this.props.t('pricing:nationwideFullTruckLoadTransportationRateCalculateByDistance')}
					/>

					<div className="container">
						<Row>
							<Col md={12} className="text-center" style={{ marginTop: 45 }}>
								<TabContainer>
									<div className={`tab ${this.state.selected === 0 && 'active'}`} onClick={() => this.handleSelectTab(0)}>
										<div className="tab-image">
											<img src={imagePickupCabinet} alt="imagePickupCabinet" />
										</div>
										<div className="tab-title">{this.props.t('pricing:wheelsTruck4')}</div>
									</div>

									<div className={`tab ${this.state.selected === 1 && 'active'}`} onClick={() => this.handleSelectTab(1)}>
										<div className="tab-image">
											<img src={image6WheelsCabinet} alt="image6WheelsCabinet" />
										</div>
										<div className="tab-title">{this.props.t('pricing:wheelsTruck6')}</div>
									</div>

									<div className={`tab ${this.state.selected === 2 && 'active'}`} onClick={() => this.handleSelectTab(2)}>
										<div className="tab-image">
											<img
												src={image10WheelsCabinet}
												alt="image10WheelsCabinet"
											/>
										</div>
										<div className="tab-title">{this.props.t('pricing:wheelsTruck10')}</div>
									</div>

									<div className={`tab ${this.state.selected === 3 && 'active'}`} onClick={() => this.handleSelectTab(3)}>
										<div className="tab-image">
											<img
												src={imageTrailer20FT}
												alt="imageTrailer20FT"
											/>
										</div>
										<div className="tab-title">{this.props.t('pricing:trailer')}</div>
									</div>
								</TabContainer>
							</Col>
						</Row>

						<Row style={{ marginTop: 60 }}>
							<Col md={12} lg={12} className="text-center">								
								{ this.state.selected === 0 && (<Title>{this.props.t('pricing:wheelsTruckServiceRate4')}</Title>) }
								{ this.state.selected === 1 && (<Title>{this.props.t('pricing:wheelsTruckServiceRate6')}</Title>) }
								{ this.state.selected === 2 && (<Title>{this.props.t('pricing:wheelsTruckServiceRate10')}</Title>) }
								{ this.state.selected === 3 && (<Title>{this.props.t('pricing:trailerServiceRate')}</Title>) }
								<SubTitle>{this.props.t('pricing:theMostReasonablePriceForYourBusiness')}</SubTitle>
							</Col>
						</Row>

						<Row style={{ marginTop: 130 }} className={`price-container ${this.state.selected !== 0 && 'hide'}`}>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={imagePickupStall} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsStallTruck4')}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsStallTruck4Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">500</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">11</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={imagePickupCabinet} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsCabinetTruck4')}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsCabinetTruck4Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">400</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">10</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<div>
								<Col md={4} sm={12} xs={12}>
									<div style={{ fontSize: 28, fontWeight: 700 }}>{this.props.t('pricing:termsServiceConditions')}</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:rateConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.renderTranslate(this.props.t('pricing:rateConditionsList.4wheel'))}
									</div>
								</Col>
							</div>
						</Row>

						<Row style={{ marginTop: 130 }} className={`price-container ${this.state.selected !== 1 && 'hide'}`}>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image6WheelsStall} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsStallTruck6').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsStallTruck6Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">2,200</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">16</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image6WheelsCabinet} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsCabinetTruck6').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsCabinetTruck6Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">2,200</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">16</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image6WheelsLift} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsCabinetTruck6LiftTail').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsCabinetTruck6LiftTailDetail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">2,700</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">19.50</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>
							
							<div>
								<Col md={12} sm={12} xs={12}>
									<div style={{ fontSize: 28, fontWeight: 700 }}>{this.props.t('pricing:termsServiceConditions')}</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:timeConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.props.t('pricing:detailTimeCondition6Wheel').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:rateConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.renderTranslate(this.props.t('pricing:rateConditionsList.6wheel'))}
									</div>
								</Col>
							</div>
						</Row>

						<Row style={{ marginTop: 100 }} className={`price-container ${this.state.selected !== 1 && 'hide'}`}>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image6WheelsCrane} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsCabinetTruck6Crane').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsCabinetTruck6CraneDetail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">3,600</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">29</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>
							
							<div >
								<Col md={12} sm={12} xs={12}>
									<div style={{ fontSize: 28, fontWeight: 700 }}>{this.props.t('pricing:termsServiceConditions')}</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:timeConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.props.t('pricing:detailTimeCondition6WheelCrane').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:rateConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.renderTranslate(this.props.t('pricing:rateConditionsList.6wheelCrane'))}
									</div>
								</Col>
							</div>
						</Row>

						<Row style={{ marginTop: 80 }} className={`price-container ${this.state.selected !== 2 && 'hide'}`}>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image10WheelsStall} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsStallTruck10').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsStallTruck10Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">3,800</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">22.50</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image10WheelsCabinet} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsCabinetTruck10').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsCabinetTruck10Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">3,800</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">22.50</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck">
										<img src={image10WheelsLift} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:wheelsCabinetTruck10LiftTail').split('\n').map(item => (<div>{item}</div>))}</div>
									<div className="detail">
										{this.props.t('pricing:wheelsCabinetTruck10LiftTailDetail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">4,000</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">24</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>

							<div>
								<Col md={12} sm={12} xs={12}>
									<div style={{ fontSize: 28, fontWeight: 700 }}>{this.props.t('pricing:termsServiceConditions')}</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:timeConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.props.t('pricing:detailTimeCondition10Wheel').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:rateConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.renderTranslate(this.props.t('pricing:rateConditionsList.10wheel'))}
									</div>
								</Col>
							</div>
						</Row>

						<Row style={{ marginTop: 130 }} className={`price-container ${this.state.selected !== 3 && 'hide'}`}>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck image-truck-trailer">
										<img src={imageTrailer} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:trailerFlatBed')}</div>
									<div className="detail">
										{this.props.t('pricing:trailerFlatBedDetail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">4,600</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">38</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck image-truck-trailer">
										<img src={imageTrailer20} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:trailer20')}</div>
									<div className="detail">
										{this.props.t('pricing:trailer20Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">4,000</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">38</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>
							<Col md={4} lg={4} sm={6}>
								<PriceContainer>
									<div className="image-truck image-truck-trailer">
										<img src={imageTrailer40} alt="truck" />
									</div>
									<div className="title">{this.props.t('pricing:trailer40')}</div>
									<div className="detail">
										{this.props.t('pricing:trailer40Detail').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<BoxPriceService className="text-bold bg-red">
										<span>{this.props.t('pricing:minimumRate')}</span> <span className="price">4,600</span> <span>{this.props.t('pricing:baht')}</span>
									</BoxPriceService>
									<BoxPriceService>
										<span>{this.props.t('pricing:estimatePrice')}</span> <span className="price">38</span> <span>{this.props.t('pricing:baht')}/{this.props.t('pricing:km')}</span>
									</BoxPriceService>
								</PriceContainer>
							</Col>
							<div>
								<Col md={12} sm={12} xs={12}>
									<div style={{ fontSize: 28, fontWeight: 700 }}>{this.props.t('pricing:termsServiceConditions')}</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:timeConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.props.t('pricing:detailTimeConditionTrailer').split('\n').map(item => (<div>{item}</div>))}
									</div>
									<div style={{ fontSize: 24, fontWeight: 700, marginTop: 20 }}>{this.props.t('pricing:rateConditions')}</div>
									<div style={{ fontSize: 20, marginTop: 5, lineHeight: 1 }}>
										{this.renderTranslate(this.props.t('pricing:rateConditionsList.trailer'))}
									</div>
								</Col>
							</div>
						</Row>
					</div>

					<div className="container" style={{marginTop: 40}}>
						<BannerSponsor page="pricing" />
					</div>
				</Wrapper>
				<Footer />
			</Container>
		);
	}
}

export default withNamespaces()(Pricing);
