import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { EffectCoverflow, Pagination, Navigation, Autoplay } from "swiper";
import NotFound from "../NotFound";
import logoGiztix from "../../assets/images/mutelu2022/logogiztix.svg";
import logoGiztixExpress from "../../assets/images/mutelu2022/logogiztixexpress.svg";
import ads from "../../assets/images/mutelu2022/ads.svg";
import ads3kb from "../../assets/images/mutelu2022/ads3kb.png";
import bgWhite from "../../assets/images/mutelu2022/bg-white.svg";
import appUrl from "../../config/appUrl";
import "swiper/swiper.min.css";
import "swiper/modules/effect-fade/effect-fade.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/navigation/navigation.min.css";
import "swiper/modules/autoplay/autoplay.min.css";
import "swiper/modules/effect-coverflow/effect-coverflow.min.css";
import "./style.css";
import data from "./data";
import ModalShare from "./ModalShare";

const MuTeLu = () => {
  const { name } = useParams();
  const history = useHistory();

  const [isOpenModalShare, setIsOpenModalShare] = useState(false);

  let content = <div />;

  const dataContent = data.find((item) => item.linkId === name);

  if (dataContent && dataContent.linkId === name) {
    const {
      bgColor,
      zodiacName,
      tarotImage,
      contentTitle,
      contentSubtitleHealth,
      contentHealth,
      contentSubtitleLove,
      contentLove,
      contentSubTitleMoney,
      contentMoney,
      contentSubTitleJob,
      contentJob,
    } = dataContent;

    content = (
      <>
        <div
          style={{
            backgroundColor: bgColor,
            backgroundImage: `url(${bgWhite})`,
            minWidth: "100%",
            minHeight: "100vh",
          }}
        >
          <div className='logo-giztix-container'>
            <img className='image-logo-giztix' src={logoGiztix} alt='' />
          </div>
          <div className='zodiac-container'>
            <div className='bg-zodiac'>
              <div className='title-container'>
                <img
                  className='image-logo-giztix-express'
                  src={logoGiztixExpress}
                  alt=''
                />
                <div className='title-x'>X</div>
                <div className='title-name'>อาจารย์ตุล คมกฤช อุ่ยเต็กเค่ง</div>
              </div>
              <Swiper
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={"auto"}
                // autoplay={{
                //   delay: 1500,
                //   disableOnInteraction: false,
                // }}
                coverflowEffect={{
                  rotate: 0,
                  stretch: 0,
                  depth: 100,
                  modifier: 1,
                  slideShadows: false,
                }}
                spaceBetween={30}
                pagination={false}
                initialSlide={2}
                modules={[EffectCoverflow, Pagination]}
                className='my-swiper'
              >
                {tarotImage.map((itemTarot) => (
                  <SwiperSlide>
                    <img src={itemTarot} alt='' />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='sub-title-container'>
                <div className='sub-title-name'>ดวงประจำปี 2565</div>
                <div className='sub-title-zodiac'>ของลัคนาราศี{zodiacName}</div>
              </div>
            </div>
          </div>
          <a href={appUrl}>
            <img src={ads} className='promotion-container' alt='' />
          </a>
          {/* <div className='promotion-container'>
          <Button className='button-container'>&nbsp;จองเลย!</Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className='promotion-title'>โปรโมรชั่นส่วนลด 300 บาท</div>
            <div className='promotion-code-container'>
              <div className='promotion-code-text'>เพียงใส่โค้ด</div>
              <div className='promotion-code'>ETV300</div>
            </div>
          </div>
          <Button
            className='button-container'
            style={{ backgroundColor: "#fff" }}
          >
            &nbsp;จองเลย!
          </Button>
        </div> */}
          <div className='content-container'>
            <div className='content-header'>
              คำทำนายภาพรวมด้วยไพ่ Oracle : The whispers of ganesha โดย
              อาจารย์ตุลย์
            </div>
            <div className='content-title'>{contentTitle}</div>
            <div className='content-subtitle'>{contentSubtitleHealth}</div>
            <div className='content'>{contentHealth}</div>
            <div className='content-subtitle'>{contentSubtitleLove}</div>
            <div className='content'>{contentLove}</div>
            <div className='content-subtitle'>{contentSubTitleMoney}</div>
            <div className='content'>{contentMoney}</div>
            <div className='content-subtitle'>{contentSubTitleJob}</div>
            <div className='content'>{contentJob}</div>
          </div>
          <div style={{ marginBottom: 20 }}>
            <a href={`${appUrl}/register-business`}>
              <img src={ads3kb} className='promotion-container' alt='' />
            </a>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Button
              className='button-container'
              style={{ backgroundColor: "#fff", marginRight: 5 }}
              onClick={() => history.push("/")}
            >
              กลับสู่หน้าหลัก
            </Button>
            <Button
              className='button-container'
              style={{
                backgroundColor: "#D90101",
                color: "#fff",
                marginLeft: 5,
              }}
              onClick={() => (window.location = `${appUrl}/register-business`)}
            >
              สมัครเป็นลูกค้า BU
            </Button>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
          >
            <Button
              className='button-container'
              style={{ backgroundColor: "#fff", marginBottom: 20 }}
              onClick={() => setIsOpenModalShare(true)}
            >
              Share
            </Button>
          </div>
        </div>
        {isOpenModalShare && (
          <ModalShare
            open={isOpenModalShare}
            onClose={() => setIsOpenModalShare(false)}
          />
        )}
      </>
    );
  }

  if (!dataContent || dataContent.linkId !== name) {
    content = <NotFound />;
  }

  return content;
};

export default MuTeLu;
