import React, { PropTypes, Component } from 'react';
import Helmet from 'react-helmet';
import { Button } from 'react-bootstrap';
import { Header, BreadcrumbHeader } from './../../components';
import './style.css';

import image404 from '../../assets/images/Error_404.svg';

import { withNamespaces } from "react-i18next";

class NotFound extends Component {
  render() {    
    return (
      <div>
        <Helmet>
          <title>404 | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน</title>
          <meta
            name="description"
            content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
          />
        </Helmet>

        <Header full active={3} />
        <div className="wrapper404" >
          <img className="img404" src={image404} alt="404 error" />
          <h1><b>ERROR 404</b></h1>
          <p>{this.props.t('error404:sorryThisPageIsntAvialable')}</p>
          <Button
              bsStyle="primary"
              style={{ marginTop: 10,width: "280px" }}
              onClick={() => window.location = "/"}
          >
            <i className="fal fa-home"></i> {this.props.t('error404:backToHomepage')}
          </Button>
        </div>
      </div>
      
    )
  }
}

export default withNamespaces()(NotFound);