import React from 'react';
import PropTypes from 'prop-types';
import { Navbar, Nav, NavItem, NavDropdown, Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import logo from './../../assets/images/logo_giztix.png';
import logoWhite from './../../assets/images/logo_rw.png';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';
import { Link } from 'react-router-dom';
import color from './../../config/color';
import appUrl from './../../config/appUrl';

import { withNamespaces } from "react-i18next";

const PomotionContainer = styled.div`
	// position: fixed;
	// top: 0;
	// left: 0;
	width: 100%;
	// z-index: 9999;

	& .close-btn{
		cursor: pointer;
		position: absolute;
		top: 5px;
		left: 10px;
		color: #fff;
	}
`;

const HeaderContainer = styled(Navbar)`
	background-color: ${props => props.bgcolor === 'transparent' ?  'transparent' : props.bgcolor ? props.bgcolor : '#FFFFFF'};
	width: 100%;
	border-radius: 0px;
	border: none;
	border-bottom: ${props => props.border === 'true' ? '1px' : '0px'} solid rgba(0, 0, 0, 0.16);
	position: fixed;
	top: ${props => props.bgcolor === 'transparent' ?  'auto' : 0};
	z-index: 999;

	.nav-item-border {
		border-radius: 6px;
		padding: 8px 20px;
	}
	
	.nav-item-border-black {
		border: 1px solid #000000;
	}
	
	.nav-item-border-white {
		border: 1px solid #FFFFFF;
	}
	
	.navbar-nav {
		box-sizing: content-box;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		padding-right: 20px;
	}
	
	.navbar-nav > li > a {
		padding-top: 11px;
		text-align: right;
		padding-right:0px;
	}
	
	.navbar-white > li > a {
		color: #FFFFFF !important;
	}
	
	.navbar-black > li > a {
		color: #000000 !important;
	}

	.dropdown-menu {
		background-color: #FFFFFF !important;
		border: 1px solid #EEEEEE !important;
		border-radius: 6px !important;
	}

	.dropdown-menu::after {
		content: '';
		width: 16px;
		height: 16px;
		position: absolute;
		top: -8px;
    	right: 16px;
		background-color: #FFFFFF !important;
		transform: rotate(45deg);
		z-index: -999;
		border-top: 1px solid #EEEEEE;
		border-left: 1px solid #EEEEEE;
	}

	.dropdown-toggle {
		background-color: transparent !important;
	}
`;

const NavbarBrand = styled(Navbar.Brand)`
	padding-right: 35px;
	padding-left:15px;
	padding-top: 17px;
`;

const IconMenu = styled.i`
	font-size: 20px;
`;

const ItemMenu = styled.div`
	font-size: 22px;
	padding: 10px 20px;
	color: #000000;
	background-color: ${props => (props.active ? '#F2F2F2' : '#FFFFFF')};

	& i {
		color: ${color.SoftGray}
		padding-left: 5px;
		font-size: 14px;
	}

	&:hover {
		background-color: ${props => (props.active ? '#F2F2F2' : '#F2F2F270')};
	}
`;

const LinkA = styled.a`
	text-decoration: none !important;
`;

const LinkItemMenu = styled(Link)`
	text-decoration: none !important;
`;

const DrawerBody = styled.div`
	overflow-y: auto;
	max-height: calc(100vh - 150px);
	padding-bottom: 80px;

	&::-webkit-scrollbar { 
    display: none; 
	}
`;

const DrawerFooter = styled.div`
	background-color: #FFFFFF;
	position: absolute;
	bottom: 0;
	left: 0;
	padding-bottom: 20px;
	padding-left: 20px;
	width: 100%;
	box-sizing: border-box;
	height: 150px;

	& .contact-header {
		font-size: 20px;
	}

	& .contact-detail {
		padding-top: 10px;
		color: rgba(0, 0, 0, 0.6);
		line-height: 1;
	}
`;

const TextChangeLanguage = styled.span`
	font-size: 18px;
`;

class Header extends React.Component {
	state = {
		openMenu: false,
		showDownload: true,
	};

	onTouchEnd = () => {
		this.setState({ openMenu: false });
	};

	onSwitchMenu = () => {
		this.setState({ openMenu: !this.state.openMenu });
	};

	selectActive(pathname) {
		if (pathname === window.location.pathname) {
			return true;
		}

		return false;
	}


	handleSelect = (selectedKey, updateModal) => {
		switch (selectedKey) {
			case 1: //ปุ่มสมัครสมาชิก
				return window.location.href = "#";
			case 2: //ปุ่มเข้าสู่ระบบ
				return window.location.href = "#";
			case 3: //ปุ่มเมนู
				return this.onSwitchMenu();
			case 4: //ปุ่มออกจากระบบ
				localStorage.clear();
				return (window.location = '/');
			default:
				return null;
		}
	};

	render() {
		return (
			<div>
				{ this.state.showDownload && (
					<PomotionContainer>
						<img onClick={() => window.location = "https://giztix.page.link/downloadGiztixApp"} src={`${require('./../../assets/images/popup_loadapp-05.png')}`} style={{width:"100%"}} alt="" />
						<div className="close-btn" onClick={() => this.setState({showDownload : false})} > <i class="fal fa-times"></i> </div>
					</PomotionContainer>
				)}
				<HeaderContainer className={this.props.full ? "full" : ""} bgcolor={this.props.bgcolor} border={`${this.props.border}`}>
					<Navbar.Header>
						<NavbarBrand>
							<a href="/">
								<img
									src={this.props.color === 'black' ? logo : logoWhite}
									className="logo"
									alt="logo"
									style={{ height: 24 }}
								/>
							</a>
						</NavbarBrand>
						<Nav pullRight onSelect={this.handleSelect}  className={`${this.props.color === 'black' ? 'navbar-black' : this.props.color === 'white' ? 'navbar-white' : 'navbar-black'}`}>
							<NavItem
								href="#"
								onClick={() => this.props.i18n.changeLanguage(this.props.t('common:lang') === 'th' ? 'en' : 'th')}
							>
								<img src={`${require('./../../assets/images/flag/' + this.props.t('common:lang').toUpperCase() + '.png')}`} style={{height:15,marginRight:10}} alt="flag" />
								<TextChangeLanguage>{ this.props.t('common:lang').toUpperCase() }</TextChangeLanguage>
							</NavItem>

							<NavItem eventKey={3} href="#">
								<IconMenu className="far fa-bars" />
							</NavItem>
						</Nav>
					</Navbar.Header>
				</HeaderContainer>


				<Drawer
					open={this.state.openMenu}
					width="260px"
					onMaskClick={this.onTouchEnd}
					level={null}
					handler={false}
					placement="right"
				>
					<DrawerBody>
						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/"
						>
							<ItemMenu
								active={this.selectActive('/')}
								style={{ marginTop: 30 }}
							>
								{this.props.t('header:homepage')}
							</ItemMenu>
						</LinkItemMenu>

						<LinkA
							href={`${appUrl}`}
						>
							<ItemMenu active={this.selectActive(`${appUrl}`)}>
							{this.props.t('header:checkTransportationPrice')}
							</ItemMenu>
						</LinkA>

						<LinkA
							href={`${appUrl}/tracking`}
						>
							<ItemMenu active={this.selectActive(`${appUrl}/tracking`)}>
							{this.props.t('header:shipmentTracking')}
							</ItemMenu>
						</LinkA>

						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/pricing"
						>
							<ItemMenu active={this.selectActive('/pricing')}>{this.props.t('header:transportationRate')}</ItemMenu>
						</LinkItemMenu>
						
						<LinkA
							href="https://blog.giztix.com/"
							target="_blank"
						>
							<ItemMenu>{this.props.t('header:newsPromotion')} <i className="fal fa-external-link"></i></ItemMenu>
						</LinkA>

						<hr style={{ margin: '10px 0px 10px 20px' }} />

						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/helpcenter"
						>
							<ItemMenu active={this.selectActive('/helpcenter')}>{this.props.t('header:helpCenter')}</ItemMenu>
						</LinkItemMenu>

						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/insurance"
						>
							<ItemMenu active={this.selectActive('/insurance')}>การคุ้มครองสินค้าจาก GIZTIX EXPRESS <small>(ลูกค้าองค์กร/ธุรกิจ)</small></ItemMenu>
						</LinkItemMenu>
						
						<LinkA
							href={`${appUrl}/giztixbusiness`}
						>
							<ItemMenu active={this.selectActive(`${appUrl}/giztixbusiness`)}>
							{this.props.t('header:forcorporatebusinesscustomers')}
							</ItemMenu>
						</LinkA>
						
						<hr style={{ margin: '10px 0px 10px 20px' }} />
                        
						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/aboutus"
						>
							<ItemMenu active={this.selectActive('/aboutus')}>{this.props.t('header:aboutUs')}</ItemMenu>
						</LinkItemMenu>

						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/contactus"
						>
							<ItemMenu active={this.selectActive('/contactus')}>{this.props.t('header:contactUs')}</ItemMenu>
						</LinkItemMenu>

						<LinkItemMenu
							onClick={() => this.setState({ openMenu: false })}
							to="/career"
						>
							<ItemMenu active={this.selectActive('/career')}>{this.props.t('header:career')}</ItemMenu>
						</LinkItemMenu>

						<LinkA
							href={`https://lin.ee/ateOhRI`}
							target="_blank"
						>
							<ItemMenu>{ this.props.t('header:MenuDrivewithus') }</ItemMenu>
						</LinkA>
					</DrawerBody>

					<DrawerFooter>
						<hr style={{ margin: '0px 0px 10px 0px' }} />
						<div className="contact-header">{this.props.t('header:contactCustomerService')}</div>
						<div className="contact-detail">
						<div>{this.props.t('header:emailGiztix')}service@giztix.com</div>
						<div style={{width:"100%",float: "left"}}>
							<div style={{float:"left"}}>{this.props.t('header:contactGiztix')}</div> 
							<div style={{float:"left",marginLeft:"5px"}}> 02-026-6895</div> 
						</div>
						<div>{this.props.t('header:giztixOpenhours')}</div>
					</div>
					</DrawerFooter>
				</Drawer>
			</div>
		);
	}
}

Header.propTypes = {
	active: PropTypes.number,
	full: PropTypes.bool,
	bgcolor: PropTypes.string,
	border: PropTypes.bool,
	color: PropTypes.string,
};

Header.defaultProps = {
	active: null,
	full: false,
	border: true,
	color: 'black',
};

export default withNamespaces()(Header);

