import React from 'react';
import styled from 'styled-components';
import {
	Header,
	Footer,
} from './../../components';
import { TextHeader } from './components';
import { Row, Col } from 'react-bootstrap';
import imagePlayStore from './../../assets/images/play-store.svg';
import imageAppStore from './../../assets/images/app-store.svg';
import imageQRCode from './../../assets/images/qr-code.svg';
import imageMockupAllApplication from '../../assets/images/applicationDownload/mock-up-all-application.png';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { withNamespaces } from "react-i18next";

const Container = styled.div`
	padding-top: 150px;
	padding-bottom: 110px;
`;

const ContainerBackground = styled.div`
	background-color: #000000;
	background-image: url(${require('../../assets/images/applicationDownload/bg.png')});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
`;

const DeviceContainer = styled.div`
	position: relative;

	& .device-content {
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
		background-color: #FFFFFF;
		border-radius: 6px;
		border: 1px solid #fafafafa;
		padding: 40px;

		& .download-container {
			display: flex;
			flex-direction: row;
			margin-top: 16px;
		}

		& .download-image-grcode {
			& img {
				width: 120px;
			}
		}

		& .download-line-vertical {
			width: 1px;
			background-color: #E3E3E3;
			margin: 0 20px;
		}

		& .download-image-store-container {
			display: flex;
			flex-direction: column;

			& img {
				width: 100%;
				max-width: 175px;
				margin-bottom: 10px;
			}
		}
	}

	& .device-image {
		margin-top: 50px;
    width: 260%;
    position: absolute;
    right: 0;

		& img {
			width: 100%;
		}
	}

	& .text-download {
		margin-top: 20px;
	}

	@media (max-width: 992px) {
		.device-image {
			margin-top: 100px;
    	width: 220%;
		}
	}
`;

const CheckButton = styled(Button)`
	height: 54px;
	font-size: 26px !important;
	border-radius: 6px;
	margin-top: 20px;
	box-shadow: 0px 6px 16px #D9010120;
	background-color: #D90101;

	& i {
		font-size: 24px !important;
	}

	&:hover {
		background-color: #D90101;
		box-shadow: 0px 10px 20px #D9010140;
	}
`;

class Help extends React.Component {
  state = {
		isTop: true,
	};

  componentWillMount() {
    document.addEventListener('scroll', () => {
			const isTop = window.scrollY < 50;
			if (isTop !== this.state.isTop) {
				this.setState({ isTop });
			}
		});
  }
  
  render() {
    return (
      <ContainerBackground className="background">
				<Helmet>
					<title>Application Download | GIZTIX</title>
					<meta name="description" content="บริการรถขนส่งเหมาคัน จองง่าย ได้ชัวร์ ส่งทั่วไทย" />
				</Helmet>

				<Header
          bgcolor={this.state.isTop ? 'transparent' : '#00000090'}
          border={false}
          color="white"
				/>

        <Container className="container">
					<DeviceContainer>
						<Row>
							<Col md={9} sm={9}>
								<div data-aos="fade-up" data-aos-duration="1000" data-aos-anchor-placement="top-center">
									<Col className="device-content">
										<Row>
											<Col md={7} sm={8}>
												<TextHeader
													style={{ marginBottom: 30, textAlign: 'left' }}
													title={this.props.t('homepage:Supporteverydevices')}
													subTitle={this.props.t('homepage:YoucancheckpriceandbookingtransportationservicesonmobiletabletlapbookandPC')}
												/>
												
												<div>
													<Link to="/newshipment" style={{textDecoration: 'none'}}>
														<CheckButton bsStyle="primary" block>
															<i className="fal fa-search" style={{ marginRight: 10 }}/>
															{this.props.t('homepage:FreeCheckPriceNow')}
														</CheckButton>
													</Link>
												</div>
												<div className="text-download">{this.props.t('homepage:orDownloadApplicationhere')}</div>
												<div className="download-container">
													<div className="download-image-grcode"><img src={imageQRCode} alt="QRCode"/></div>
													<div className="download-line-vertical"></div>
													<div className="download-image-store-container">
														<div><a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.giztix.app"><img src={imagePlayStore} alt="google play"/></a></div>
														<div><a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/app/id1439755826"><img src={imageAppStore} alt="app store"/></a></div>
													</div>
												</div>
											</Col>
										</Row>
									</Col>
								</div>
							</Col>

							<Col md={3} sm={3}>
								<div data-aos="fade-up" data-aos-duration="1500" data-aos-anchor-placement="top-center">
									<div className="device-image">
										<img src={imageMockupAllApplication} alt="application" />
									</div>
								</div>
							</Col>
						</Row>
					</DeviceContainer>
        </Container>
        <Footer />
      </ContainerBackground>
    )
  }
}

export default withNamespaces()(Help);