import React, { Component } from "react";
import { Header, Footer, LazyImage } from "./../../../../../../components";
import { Cover, TextHeader } from "./components";
import styled from "styled-components";
import { Row, Col, Button, Modal ,Table } from "react-bootstrap";
import Dotdotdot from "react-dotdotdot";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link as LinkScroll, Element } from "react-scroll";
import { Helmet } from "react-helmet";
import "./style.css";

//StepSupport
import imageSupport from "../../../../../../assets/images/gtxBusiness/Icon/Support.svg";
import fullTransportService from "../../../../../../assets/images/gtxBusiness/Icon/fullTransportService.svg";
import SecurityService from "../../../../../../assets/images/gtxBusiness/Icon/SecurityService.svg";

//InvoiceContainer
import PaymentService from "../../../../../../assets/images/gtxBusiness/Icon/PaymentService.svg";
import SavePrice from "../../../../../../assets/images/gtxBusiness/Icon/SavePrice.svg";

//PriceContainer
import SaveCost from "../../../../../../assets/images/gtxBusiness/Icon/SaveCost.svg";
import ManageShipment from "../../../../../../assets/images/gtxBusiness/Icon/ManageShipment.svg";

//ContactContainer
import bgContact from "../../../../../../assets/images/career/1366x455/Accounting.jpg";

import color from "../../../../../../config/color";
import "./style.css";
import appUrl from "./../../../../../../config/appUrl";

//privilege Container
import imagePrivilege1 from "../../../../../../assets/images/homepage/privilege/privilege01.jpg";
import imagePrivilege2 from "../../../../../../assets/images/homepage/privilege/privilege02.png";
import imagePrivilege3 from "../../../../../../assets/images/homepage/privilege/privilege03.png";

//StepCheckPriceContainer
import imageEasyBook from '../../../../../../assets/images/homepage/hover/easy-book.svg';
import imagePricing from '../../../../../../assets/images/homepage/hover/pricing.svg';
import imageTruckType from '../../../../../../assets/images/homepage/hover/truck-type.svg';
import imageTrustShipment from '../../../../../../assets/images/homepage/hover/trust-shipment.svg';

import imageEasyBookW from '../../../../../../assets/images/homepage/hover/easy-book-w.svg';
import imagePricingW from '../../../../../../assets/images/homepage/hover/pricing-w.svg';
import imageTruckTypeW from '../../../../../../assets/images/homepage/hover/truck-type-w.svg';
import imageTrustShipmentW from '../../../../../../assets/images/homepage/hover/trust-shipment-w.svg';

import { withNamespaces } from "react-i18next";
import { bold } from "ansi-colors";

AOS.init({
  delay: 100
});

const Container = styled.div``;


const MoreContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: -100px;
  width: 100%;

  & .text-button-more {
    color: #ffffff;
    text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    text-decoration: none;

    & i {
      font-size: 40px;
    }
  }

  @media (max-width: 992px) {
    bottom: 0px;
  }
`;

const StepSupportContainer = styled.div`
  text-align: center;
  margin-top: 30px;

  & .box-container {
    height: 310px;
    display: flex;
    align-items: center;
  }

  & .box {
    padding: 35px 20px;
    background-color: #ffffff;
    box-sizing: border-box;
    border-radius: 5px;
    height: 280px;
    vertical-align: middle;
    display: block;
    transition: all 0.3s;
    margin: 0 auto;

    &:not(:last-child) {
      margin-right: 20px;
    }

    & .box-image {
      display: block;

      & img {
        width: 120px;
        height: 120px;
      }
    }

    & .box-title {
      margin-top: 30px;
      font-weight: 700;
      text-align: center;
      font-size: 26px;
      line-height: 1;

      &-subtitle {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        line-height: 1;
      }
    }
  }
`;

const InvoiceContainer = styled.div`
  margin-top: 0px;
  margin-bottom: 40px;

  & .box-container {
    height: auto;
    margin-bottom: 40px;
    display: flex;
    align-items: top;
  }

  & .box-image {
    display: block;

    & img {
      width: 100px;
      height: 100px;
    }
  }

  & .box {
    padding: 0;
    padding-top: 10px;
    text-align: right;
    height: auto;
    display: block;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    margin: 0 auto;

    &:not(:last-child) {
      margin-right: 40px;
    }

    & .box-title {
      margin-top: 0;
      font-weight: 700;
      text-align: right;
      font-size: 26px;
      line-height: 1;

      &-subtitle {
        display: block;
        margin-top: 5px;
        font-size: 20px;
        line-height: 1;
      }
    }
  }
`;

const PriceContainer = styled.div`
	margin-top: 0px;
	margin-bottom: 40px;

	& .box-container {    
		height: auto;
		margin-bottom: 40px;
		display: flex;
		align-items: top;
	}

	& .box-image {
		display: block;

		& img {
			width: 100px;
			height: 100px;
		}
    & i {
        font-size: 100px;
      }
	}

	& .box {
		padding: 0;
		padding-top: 10px;
		text-align: left;
		height: auto;
		display: block;
		-webkit-transition: all .3s;
		transition: all .3s;
		margin: 0 auto;
		margin-left: 40px;

		&:not(:last-child) {
			margin-right: 40px;
		}

		& .box-title {
			margin-top: 0;
			font-weight: 700;
			text-align: left;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: block;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;

const ContactContainer = styled.div`
 background:
        /* top, transparent black, faked with gradient */ 
        linear-gradient(
          rgba(0, 0, 0, 0.4), 
          rgba(0, 0, 0, 0.4)
        ),
        /* bottom, image */
        url(${bgContact});
        text-shadow: 0px 3px 6px #000;
  height: 460px;
  padding: 50px 0;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;

  .container {
    text-align: center;
    color: #fff;
    padding: 120px;
    position: relative;
    height: 140px;

    .check-button {
      height: 40px;
      width: 140px;
      font-size: 24px;
    }
  }

  @media only screen and (max-width: 767px) {
    padding: 70px 0;
    .container {
      text-align: center;
      color: #fff;
      padding:60px;
      position: relative;
      height: 140px;
    }
  }
`;

const LineHorizontal = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e3e3e3;
`;

const StepInvoiveContainer = styled.div`
  margin-top: 30px;
`;

const ColStepLeft = styled(Col)`
  padding-top: 50px;
  padding-right: 60px;
  padding-bottom: 40px;
  @media (max-width: 992px) {
    padding-right: 30px;
  }
`;
const ColStepRight = styled(Col)`
  padding-left: 60px;
  padding-top: 50px;
  padding-bottom: 40px;
  border-left: 1px dashed #ddd;
  @media (max-width: 992px) {
    padding-left: 30px;
  }
`;

const PrivilegeContainer = styled.div`
  font-size: 24px;
  margin-top: 120px;

  & .privilege-cover {
    border-radius: 6px;
    border: 1px solid #fafafafa;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 16%);
		
    & img {
      width: 100%;
			
    }
  }
	& a {
      text-decoration: none;
      color: #000000;
      line-height: 2;
    }

  & .privilege-title {
    margin-top: 10px;
    font-weight: 700;
		text-align: center;
  }

  & .privilege-detail {
    color: #00000090;
    line-height: 1;
		text-align: center;
		padding-bottom: 20px;
  }

  & .privilege-button-more {
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    & a {
      text-decoration: none;
      color: #000000;
      line-height: 1;
    }

    &:hover {
      font-weight: 700;
      border-bottom: 2px solid #d90101;
    }
  }
`;

const DeviceContainer = styled.div`
  position: relative;
  margin-top: 30px;

  & .device-content {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    border-radius: 6px;
    border: 1px solid #fafafafa;
    padding: 40px;

    & .download-container {
      display: flex;
      flex-direction: row;
      margin-top: 16px;
    }

    & .download-image-grcode {
      & img {
        width: 120px;
      }
    }

    & .download-line-vertical {
      width: 1px;
      background-color: #e3e3e3;
      margin: 0 20px;
    }

    & .download-image-store-container {
      display: flex;
      flex-direction: column;

      & img {
        width: 100%;
        max-width: 175px;
        margin-bottom: 10px;
      }
    }
  }

  & .device-image {
    margin-top: 50px;
    width: 260%;
    position: absolute;
    right: 0;

    & img {
      width: 100%;
    }
  }

  & .text-download {
    margin-top: 20px;
  }

  @media (max-width: 992px) {
    .device-image {
      margin-top: 100px;
      width: 220%;
    }
  }
`;

const Symbol = styled.div`
  display: inline-block;
  position: absolute;
  top: -10px;
  height: 14px;
  width: 14px;
  border-radius: 4px;
  background-color: ${color.Primary};
`;

const StepCheckPriceContainer = styled.div`
	text-align: center;

	.check-button {
		height: 36px;
		width: 140px;
		font-size: 22px;
		font-weight: bold;
		border-radius: 6px;
		margin-top: 20px;
		box-shadow: 0px 6px 16px #d9010120;
		background-color: #fff;
		color: #d90101;

		& i {
			font-size: 20px;
		}

		&:hover {
			color: #fff;
			background-color: #d90101;
			box-shadow: 0px 10px 20px #d9010140;
		}
	}

	@media (min-width: 992px) {
		.cover-right {    
			padding-top: 150px;
		}

		.img-cover {    
			position: absolute;
			width: 200%;
			right: 0;
		}

		.title-header {
			font-size: 60px;
		}

		.title-sub-header {
			font-size: 30px;    
			font-weight: normal;
		}

		.check-button {
			height: 36px;
			width: 140px;
			font-size: 22px;

			& i {
				font-size: 20px;
			}
		}
	}

	@media (min-width: 1200px) {
		.img-cover {    
			position: absolute;
			width: 200%;
			right: 0;
		}

		.cover-right {    
			padding-top: 150px;
		}
		
		.title-header {
			font-size: 60px;
		}

		.title-sub-header {
			font-size: 30px;    
			font-weight: normal;
		}

		.check-button {
			height: 36px;
			width: 140px;
			font-size: 22px;

			& i {
				font-size: 26px;
			}
		}
	}

	@media (max-width: 992px) {
		padding-top: calc(45vh - 200px);
		.cover-right {
			padding-top: 200px;
		}
	}

	& .box-container {
		height: 310px;
		display: flex;
		align-items: center;
	}

	& .box {
		flex: 1;
		box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
		border: 1px solid #eeeeee50;
		padding: 35px 20px;
		background-color: #ffffff;
    box-sizing: border-box;
		border-radius: 5px;
		height: 280px;
		vertical-align: middle;

		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: all .3s;

    &:hover {
			height: 310px;
      background-image: linear-gradient(to bottom right, ${
				color.Primary
			}, #9D0000);
			box-shadow: 0px 5px 10px rgba(217, 1, 1, 0.2);
			color: #FFFFFF;
			line-height: 1;

			& .box-title-subtitle {
				display: block;
			}

			& .box-image {
				display: none;

				&-hover {
					display: block;
				}
			}
    }

		&:not(:last-child) {
			margin-right: 20px;
		}

		& .box-image {
			display: block;

			& img {
				width: 120px;
				height: 120px;
			}
      & i {
        font-size: 100px;
      }

			&-hover {
				display: none;

				& img {
					width: 120px;
					height: 120px;
				}
      & i {
        font-size: 100px;
      }
			}
		}

		& .box-title {
			margin-top: 30px;
			font-weight: 700;
			text-align: center;
			font-size: 26px;
			line-height: 1;

			&-subtitle {
				display: none;
				margin-top: 5px;
				font-size: 20px;
				line-height: 1;
			}
		}
	}
`;


const TablePosition = styled(Table)`
  width: 100%;
  font-size: 24px;

  & tr {
    & td {
    border: none !important;
    }
   }

   & .title {
      border-left: 4px solid #D90101;
      border-radius: 2px;
    }
     .styleTitle {
      margin-left: 10px;
      font-weight: bold;
      font-size: 30px;
      text-align: left;
    }
    ul {
      list-style: none;
    }
    ul li {
      position: relative;
    }
    ul li::before {
      content: "• ";
      color: #D90101;
      width: 2em;
      position: absolute;
      left: -20px;
    }
`;

class GiztixBusiness extends Component {
  state = {
    isTop: true,
    onOpenModal: false
  };

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const isTop = window.scrollY < 100;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
  }

  render() {
    return (
      <Container>
        <Helmet>
          <title>
          Financing & Accounting | GIZTIX บริการขนส่ง บริษัทขนส่ง รถรับจ้าง รถส่งของ
            รถ6ล้อรับจ้าง รถกระบะรับจ้าง รถส่งสินค้า ส่งสินค้าด่วน
          </title>
          <meta
            name="description"
            content="บริการรถขนส่งสินค้าที่ทั้งง่ายและปลอดภัย เช็คค่าบริการขนส่งได้ทันที จองออนไลน์ได้ทุกที่ ทุกเวลา"
          />
        </Helmet>
        <Header
          bgcolor={this.state.isTop ? "transparent" : "#000000"}
          border={false}
          color="white"
        />

        <ContactContainer>
          <div className="container">
            <TextHeader
              className="title"
              style={{ textAlign: "center" }}
              styleTitle={{
                marginBottom: 10,
                textAlign: "center",
                fontSize: 60,
                fontWeight: "bold",
                color: "#fff"
              }}
              title="Financing & Accounting"
            />
						<p style={{ textAlign: "center" }}>
            วางบิลดั่งพายุ จ่ายเร็วเหมือนสายฟ้า ทวงหนี้ดุจฟ้าผ่า รับเงินราวแสงแดด
          </p>
           
          </div>
        </ContactContainer>


        <div className="container" style={{marginTop: -70}}>
				<Element name="stepCheckPriceContainer" />
				<TextHeader
									styleTitle={{marginTop: 120 ,fontSize:30}}
									title="ตำแหน่งงาน"
								/>
        </div>
            <div className="statusJob">
              <p>
                Available Jobs in Financing & Accounting
                <br/>There are no current openings, please check back soon.
              </p>
            </div>
       

        <DeviceContainer>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.5404450240753!2d100.60889471482976!3d13.685684990390254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x311d672e8097f671%3A0x8b3d7495efdf67d5!2sGIZTIX+HQ!5e0!3m2!1sth!2sth!4v1562662978115!5m2!1sth!2sth"
            style={{
              width: "100%",
              height: "300px",
              border: 0,
              marginBottom: -10
            }}
            allowfullscreen
          />
        </DeviceContainer>

        <Footer />
      </Container>
    );
  }
}

export default withNamespaces()(GiztixBusiness);
