import ApolloClient from 'apollo-boost';
import baseUrl from './baseUrl';

const shipmentConfirmStatus = {
	isConfirm: false,
	__typename: 'ShipmentConfirmStatus',
}

const creditCardEdit = {
	id: '',
	bank: '',
	last_digits: '',
	brand: '',
	expiration_month: '',
	expiration_year: '',
	name: '',
	__typename: 'CreditCardEdit',
};

const creditCardDefault = {
	isDefault: true,
	__typename: 'creditCardDefault',
};

const locationTemp = {
	address: '',
	addressDefault: '',
	latitude: 0,
	longitude: 0,
	latitudeCenter: 0,
	longitudeCenter: 0,
	isFitBounds: false,
	isSelected: false,
	type: '',
	__typename: 'LocationTemp',
};

const modalLogin = {
	isOpen: false,
	__typename: 'ModalLogin',
};

const modalRegister = {
	isOpen: false,
	isBack: false,
	__typename: 'ModalRegister',
};

const modalForgetPassword = {
	isOpen: false,
	__typename: 'ModalForgetPassword',
};

const instancePriceStep = {
	createShipment: true,
	searchLocation: false,
	contactInformation: false,
	confirmShipment: false,
	selectTruck: false,
	paymentChanel: false,
	paymentCOD: false,
	additional: false,
	promotion: false,
	priceResult: false,
	selectDateTime: false,
	modalAdditional: false,
	paymentCredit: false,
	paymentCreditList: false,
	paymentCreditEdit: false,
	__typename: 'InstancePriceStep',
};

const creditCard = {
	id: '',
	bank: '',
	last_digits: '',
	brand: '',
	expiration_month: '',
	expiration_year: '',
	name: '',
	__typename: 'CreditCard',
};

const instancePrice = {
	// Location
	pickupAddress: '',
	pickupLatitude: 0,
	pickupLongitude: 0,
	pickupContactName: '',
	pickupPhoneNumber: '',
	pickupRemark: '',
	deliveryAddress: '',
	deliveryLatitude: 0,
	deliveryLongitude: 0,
	deliveryContactName: '',
	deliveryPhoneNumber: '',
	deliveryRemark: '',
	// Shipment
	setting_truck_id: '5be5057787415b16d8cf48f6',
	setting_truck_name: '4 ล้อ',
	setting_truck_type_id: '5be5057787415b16d8cf48f7',
	setting_truck_type_name: 'ตู้ทึบ',
	setting_truck_type_size: '150 * 175 * 210 ซม.',
	setting_truck_type_pic: 'upload/setting_truck/4-WHEELS-CABINET.png',
	additional: [],
	creditCard,
	pickupDate: '',
	promotion_name: '',
	paymentType: '',
	paymentCodDropPoint: '',
	remark: '',
	__typename: 'InstancePrice',
};

const forgotPassword = {
	email: '',
	password: '',
	ref: '',
	otp: '',
	__typename: 'ForgotPassword',
};

const user = {
	token: '',
	__typename: 'User',
};

const register = {
	email: '',
	password: '',
	phoneCode: '',
	phoneNumber: '',
	taxID: '',
	__typename: 'Register',
};

const selectDate = {
	//สำหรับเก็บสถานะเลือกเวลาจองล่วงหน้า
	isCompleted: false,
	__typename: 'SelectDate',
};

export const defaults = {
	instancePrice,
	forgotPassword,
	user,
	register,
	modalLogin,
	modalRegister,
	modalForgetPassword,
	instancePriceStep,
	locationTemp,
	selectDate,
	creditCardEdit,
	creditCardDefault,
	shipmentConfirmStatus,
};

export default new ApolloClient({
	clientState: {
		defaults: {
			...defaults,
		},
		resolvers: {
			Mutation: {
				updateCreditCardDefault: (
					_,
					{
						isDefault
					},
					{ cache },
				) => {
					cache.writeData({
						data: {
							creditCardDefault: {
								isDefault,
								__typename: 'CreditCardDefault',
							},
						},
					});

					return null;
				},
				updateCreditCardEdit: (
					_,
					{
						id,
						bank,
						last_digits,
						brand,
						expiration_month,
						expiration_year,
						name,
					},
					{ cache },
				) => {
					cache.writeData({
						data: {
							creditCardEdit: {
								id,
								bank,
								last_digits,
								brand,
								expiration_month,
								expiration_year,
								name,
								__typename: 'CreditCardEdit',
							},
						},
					});

					return null;
				},
				updateLocationTemp: (
					_,
					{
						address,
						addressDefault,
						latitude,
						longitude,
						latitudeCenter,
						longitudeCenter,
						isFitBounds,
						isSelected,
						type,
					},
					{ cache },
				) => {
					cache.writeData({
						data: {
							locationTemp: {
								address,
								addressDefault,
								latitude,
								longitude,
								latitudeCenter,
								longitudeCenter,
								isFitBounds,
								isSelected,
								type,
								__typename: 'LocationTemp',
							},
						},
					});

					return null;
				},
				updateInstancePriceStep: (
					_,
					{
						createShipment,
						searchLocation,
						contactInformation,
						confirmShipment,
						selectTruck,
						paymentChanel,
						paymentCOD,
						additional,
						promotion,
						priceResult,
						selectDateTime,
						modalAdditional,
						paymentCredit,
						paymentCreditList,
						paymentCreditEdit,
					},
					{ cache },
				) => {
					cache.writeData({
						data: {
							instancePriceStep: {
								createShipment,
								searchLocation,
								contactInformation,
								confirmShipment,
								selectTruck,
								paymentChanel,
								paymentCOD,
								additional,
								promotion,
								priceResult,
								selectDateTime,
								modalAdditional,
								paymentCredit,
								paymentCreditList,
								paymentCreditEdit,
								__typename: 'InstancePriceStep',
							},
						},
					});

					return null;
				},
				updateModalLogin: (_, { isOpen }, { cache }) => {
					cache.writeData({
						data: {
							modalLogin: {
								isOpen,
								__typename: 'ModalLogin',
							},
						},
					});

					return null;
				},
				updateModalRegister: (_, { isOpen, isBack }, { cache }) => {
					cache.writeData({
						data: {
							modalRegister: {
								isOpen,
								isBack,
								__typename: 'ModalRegister',
							},
						},
					});

					return null;
				},
				updateModalForgetPassword: (_, { isOpen }, { cache }) => {
					cache.writeData({
						data: {
							modalForgetPassword: {
								isOpen,
								__typename: 'ModalForgetPassword',
							},
						},
					});

					return null;
				},
				clearInstancePrice: (_, args, { cache }) => {
					cache.writeData({
						data: {
							instancePrice,
						},
					});

					return null;
				},
				updateInstancePrice: (
					_,
					{
						pickupAddress,
						pickupLatitude,
						pickupLongitude,
						pickupContactName,
						pickupPhoneNumber,
						pickupRemark,
						deliveryAddress,
						deliveryLatitude,
						deliveryLongitude,
						deliveryContactName,
						deliveryPhoneNumber,
						deliveryRemark,
						setting_truck_id,
						setting_truck_name,
						setting_truck_type_id,
						setting_truck_type_name,
						setting_truck_type_size,
						setting_truck_type_pic,
						additional,
						creditCard,
						pickupDate,
						promotion_name,
						paymentType,
						paymentCodDropPoint,
						remark,
					},
					{ cache },
				) => {
					cache.writeData({
						data: {
							instancePrice: {
								pickupAddress,
								pickupLatitude,
								pickupLongitude,
								pickupContactName,
								pickupPhoneNumber,
								pickupRemark,
								deliveryAddress,
								deliveryLatitude,
								deliveryLongitude,
								deliveryContactName,
								deliveryPhoneNumber,
								deliveryRemark,
								setting_truck_id,
								setting_truck_name,
								setting_truck_type_id,
								setting_truck_type_name,
								setting_truck_type_size,
								setting_truck_type_pic,
								additional,
								creditCard,
								pickupDate,
								promotion_name,
								paymentType,
								paymentCodDropPoint,
								remark,
								__typename: 'InstancePrice',
							},
						},
					});

					return null;
				},
				updateForgotPassword: (_, { email, password, ref, otp }, { cache }) => {
					cache.writeData({
						data: {
							forgotPassword: {
								email,
								password,
								ref,
								otp,
								__typename: 'ForgotPassword',
							},
						},
					});

					return null;
				},
				updateToken: (_, { token }, { cache }) => {
					cache.writeData({
						data: {
							user: {
								token,
								__typename: 'User',
							},
						},
					});

					return null;
				},
				clearToken: (_, args, { cache }) => {
					cache.writeData({
						data: {
							user,
						},
					});

					return null;
				},
				register: (
					_,
					{ email, password, phoneCode, phoneNumber, taxID },
					{ cache },
				) => {
					cache.writeData({
						data: {
							register: {
								email,
								password,
								phoneCode,
								phoneNumber,
								taxID,
								__typename: 'Register',
							},
						},
					});

					return null;
				},
				updateSelectDate: (_, { isCompleted }, { cache }) => {
					cache.writeData({
						data: {
							selectDate: {
								isCompleted,
								__typename: 'SelectDate',
							},
						},
					});

					return null;
				},
				updateShipmentConfirmStatus: (_, { isConfirm }, { cache }) => {
					cache.writeData({
						data: {
							shipmentConfirmStatus: {
								isConfirm,
								__typename: 'ShipmentConfirmStatus',
							},
						},
					});

					return null;
				},
			},
		},
	},
	uri: `${baseUrl}/graphql`,
	request: operation => {
		const pathname = window.location.pathname;

		let token = localStorage.getItem('token');
		/* token tracking page */
		if (pathname.indexOf('tracking') != -1) {
			token =
				'giztix ej8RYXCKc4DM3LT5t4pjA72JfyxxtnTMP85N2hrHP38EdxYdTDTfDjgrQ6Zr7s2hgT76SzNg5XHPqBHZVJa9ZbrxUUe5jxqmQTeS77mgZFfWLDdu7mNVSXFrEqEf3J24';
		}

		//รับค่า token จาก context tag mutation
		const contextToken = operation.getContext();
		if(contextToken.authorization) {
			token = contextToken.authorization;
		}

		operation.setContext({
			headers: {
				authorization: `${token}`,
			},
		});
	},
});
